<div class="language">
  <app-language-circle [language]="1"></app-language-circle>
  <!-- <input
    (input)="setDefaultLanguage($event.target.value)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="sliceWords ? slice(defaultLanguage.value) : defaultLanguage.value"
    type="text"
  /> -->

  <textarea
    (input)="setDefaultLanguage($event.target.value)"
    [placeholder]="placeholder"
    [disabled]="disabled"
    [value]="sliceWords ? slice(defaultLanguage.value) : defaultLanguage.value"
    type="text"
    [name]="placeholder + '' + 0"
    [id]="placeholder + '' + 0"
  ></textarea>
  <button
    mat-button
    type="button"
    class="toggle {{ isToggled ? 'active' : '' }}"
    (click)="toggle()"
  >
    <i class="fas fa-chevron-up"></i>
  </button>
</div>
<div class="language-wrapper" [hidden]="!isToggled">
  <div class="language" *ngFor="let l of otherLanguageValues; index as i">
    <app-language-circle [language]="l.language"></app-language-circle>
    <label [for]="placeholder + '' + i + 1">
      <textarea
        (input)="setOtherLanguage($event.target.value, l.language)"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [value]="sliceWords ? slice(l.value) : l.value"
        type="text"
        [id]="placeholder + '' + i + 1"
        [name]="placeholder + '' + i + 1"
      ></textarea>
    </label>
  </div>
</div>
