import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { baseurl } from "../../../baseSettings";
import { DatahubUser } from "../../shared/models/datahubUser";
import { Page } from "../../shared/models/page";
import "./httpclient.extensions";
import { Ext } from "./httpclient.extensions";
import { IPagedSortedFilteredService } from "./ipaged-sorted-filtered.service";

@Injectable({
  providedIn: "root",
})
export class AllUsersService
  implements IPagedSortedFilteredService<DatahubUser>
{
  private URL = `${this.api}/api/user/paged`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public get(
    pageNumber = 1,
    pageSize = 10,
    sort = null,
    filter = null
  ): Observable<Page<DatahubUser>> {
    const t = Ext.getPagedSortedFiltered(
      this.httpClient,
      DatahubUser,
      `${this.URL}`,
      pageNumber,
      pageSize,
      sort,
      filter
    );
    return t;
  }
}
