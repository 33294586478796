import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { DatahubUser, UserRole } from "../../shared/models/datahubUser";
import { UserInvitation } from "../../shared/models/userInvitation";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../shared/dialog/notification-dialog/notification-dialog.component";
import { FarmService } from "../../core/services/farm.service";
import { InvitationResult } from "../../shared/models/invitation-result";
import { UserAuthService } from "../../core/user-auth.service";

@Component({
  selector: "app-manage-farms",
  templateUrl: "./manage-farms.component.html",
  styleUrls: ["./manage-farms.component.scss"],
})
export class ManageFarmsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  farmers = new Array<DatahubUser>();
  farmInvitees = new Array<UserInvitation>();
  private farmId: string;
  constructor(
    private service: FarmService,
    private dialog: MatDialog,
    private authService: UserAuthService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.authService.notifyCurrentContextChanged().subscribe((context) => {
        if (context.roleName === UserRole.Farmer) {
          this.farmId = context.entityId;
          if (this.farmId) {
            this.getFarmers();
          }
        }
      })
    );
    this.subscription.add(
      this.service
        .getUnclaimedInvites()
        .subscribe((invitees) => (this.farmInvitees = invitees))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getFarmers() {
    this.subscription.add(
      this.service
        .getAllFarmers(this.farmId)
        .subscribe((users) => (this.farmers = users))
    );
  }

  removeFarmer(user: DatahubUser) {
    this.subscription.add(
      this.service.deleteFarmer(user.id).subscribe(() => {
        this.farmers = this.farmers.filter((u) => u.email !== user.email);
      })
    );
  }

  inviteFarmer(user: UserInvitation): void {
    this.subscription.add(
      this.service.inviteNewFarmer(user.email).subscribe({
        next: (response: InvitationResult) => {
          if (response.isExistingUser) {
            this.dialog.open(NotificationDialogComponent, {
              width: "400px",
              data: {
                title: "manageUsers.skipInvite.title",
                text: "manageUsers.skipInvite.text",
                textParams: { email: user.email },
              },
            });
            this.subscription.add(
              this.dialog.afterAllClosed.subscribe(() => this.getFarmers())
            );
          }
        },
        complete: () => {
          this.subscription.add(
            this.service
              .getUnclaimedInvites()
              .subscribe((invitees) => (this.farmInvitees = invitees))
          );
        },
      })
    );
  }
}
