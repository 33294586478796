import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-invite-user-dialog',
  templateUrl: './invite-user-dialog.component.html',
  styleUrls: ['./invite-user-dialog.component.scss']
})
export class InviteUserDialogComponent implements OnInit {

  form: UntypedFormGroup;
  subscription: Subscription;
  constructor(public dialogReg: MatDialogRef<InviteUserDialogComponent>, formBuilder: UntypedFormBuilder) {
      this.form = formBuilder.group({
        email: ['', [Validators.required, Validators.email]]
      });
     }

  ngOnInit() {
  }

  onCancel() {
    this.dialogReg.close();
  }

  onInvite(values) {
    this.dialogReg.close(values.email);
  }

}
