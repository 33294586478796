<!-- <form [formGroup]="languageSelector">
  <mat-select
    class="language-selector"
    (selectionChange)="changeLanguage($event.source.value)"
    formControlName="languageSelector"
  >
    <mat-option *ngFor="let language of languages" [value]="language.value">
      {{ language.viewValue | translate }}
    </mat-option>
  </mat-select>
</form> -->

<form novalidate >
  <label
    [for]="language.value"
    *ngFor="let language of languages"
    [ngClass]="{ active: language.value === selectedLanguage }"
    >{{ ((language.shortForm | translate) + "").toUpperCase() }}
    <input
      (change)="changeLanguage($event.target.value)"
      type="radio"
      name="languages"
      [id]="language.value"
      [value]="language.value"
  /></label>
</form>
