<div class="resource-row">
  <app-translatable-string-area
    placeholder="{{ 'manageApisPage.details.resourceDisplayName' | translate }}"
    [value]="displayNameTranslations"
    [required]="false"
    [disabled]="false"
    (changed)="displayNameChanged($event)"
    [sliceWords]="false"
  ></app-translatable-string-area>
  <label for="consentType" class="select">Consent Type</label>
  <select
    [(ngModel)]="resource.consentType"
    #consentType="ngModel"
    class="first-select"
    id="consentType"
    [disabled]="!isEditable"
    (ngModelChange)="changeConsent($event)"
  >
    <option
      *ngFor="let consent of consentOptions"
      [ngValue]="consent.index"
    >
      {{ consent.name }}
    </option>
  </select>

  <div class="three-row" *ngIf="isFarmerConsent">
    <div class="form-part">
      <div *ngIf="error.farmIdType">
        <i class="fas fa-exclamation-circle" [title]="error.farmIdType"></i>
      </div>
      <label for="farmIdType" class="select">Farm id type</label>
      <select
        [(ngModel)]="resource.farmIdType"
        id="farmIdType"
        [disabled]="!isEditable"
        (change)="onChangeFarmIdType()"
      >
        <option *ngFor="let farmId of farmIdTypes" [ngValue]="farmId">
          {{ farmId.name }}
        </option>
      </select>
    </div>

    <div class="form-part">
      <div *ngIf="error.farmIdGetType">
        <i class="fas fa-exclamation-circle" [title]="error.farmIdGetType"></i>
      </div>
      <label for="FarmIdGetType" class="select">Farm Id Type</label>
      <select
        [(ngModel)]="resource.farmIdGetType"
        id="FarmIdGetType"
        [disabled]="!isEditable"
        (change)="onChangeFarmIdGetType()"
      >
        <option
          *ngFor="let farmIdGetType of farmIdGetTypes"
          [ngValue]="farmIdGetType.index"
        >
          {{ farmIdGetType.name }}
        </option>
      </select>
    </div>
    <div class="form-part" *ngIf="isRequestParameter">
      <div *ngIf="error.requestParameter">
        <i
          class="fas fa-exclamation-circle"
          [title]="error.requestParameter"
        ></i>
      </div>
      <input
        placeholder="{{
          'manageApisPage.details.farmIdParameterName' | translate
        }}"
        [disabled]="!isEditable || isFarmIdGetTypeEmpty"
        [(ngModel)]="resource.requestParameter"
        (change)="onChangeRequestParameter()"
        type="text"
      />
    </div>
    <div class="row-full-width" *ngIf="isJsonPath">
      <div *ngIf="error.jsonObjectPath">
        <i
          class="fas fa-exclamation-circle"
          [title]="error.jsonObjectPath"
        ></i>
      </div>
      <input
        placeholder="{{
          'manageApisPage.details.jsonObjectPath' | translate
        }}"
        [disabled]="!isEditable || isFarmIdGetTypeEmpty"
        [(ngModel)]="resource.jsonObjectPath"
        (change)="onChangeJsonObjectPath()"
        type="text"
      />
      <div class="form-part">
        <div *ngIf="error.jsonFarmIdPath">
          <i
            class="fas fa-exclamation-circle"
            [title]="error.jsonFarmIdPath"
          ></i>
        </div>
        <input
          placeholder="{{
            'manageApisPage.details.jsonPathtoFarmID' | translate
          }}"
          [disabled]="!isEditable || isFarmIdGetTypeEmpty"
          [(ngModel)]="resource.jsonFarmIdPath"
          (change)="onChangeJsonFarmIdPath()"
          type="text"
        />
      </div>
    </div>
    <div class="form-part" *ngIf="isFarmerConsent">
      <div *ngIf="error.externalDocumentationUrl">
        <i
          class="fas fa-exclamation-circle"
          [title]="error.externalDocumentationUrl"
        ></i>
      </div>
      <input
        placeholder="{{
          'manageApisPage.details.documentationUrl' | translate
        }}"
        [disabled]="false"
        [(ngModel)]="resource.externalDocumentationUrl"
        (change)="onChangeRequestParameter()"
        type="text"
      />
      <br>
      <div *ngIf="error.description">
        <i
          class="fas fa-exclamation-circle"
          [title]="error.description"
        ></i>
      </div>
      <app-translatable-string-area
        placeholder="{{
          'manageApisPage.details.resourceDescription' | translate
        }}"
        [value]="descriptionTranslations"
        [required]="false"
        [disabled]="false"
        (changed)="descriptionChanged($event)"
        [sliceWords]="false"
      ></app-translatable-string-area>
    </div>
  </div>
</div>
