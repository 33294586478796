<div>
    <mat-form-field class="flex-fill">
        <input
          matInput
          placeholder="{{ 'manageApisPage.details.header.namePlaceholder' | translate }}"
          [disabled]="!isEditable"
          [(ngModel)]="header.key"
          (input)="onInputChange($event.target.value)"
        />
      </mat-form-field>
    <mat-form-field class="flex-fill">
        <input
          matInput
          placeholder="{{ 'manageApisPage.details.header.valuePlaceholder' | translate }}"
          [disabled]="!isEditable"
          [(ngModel)]="header.value"
          (input)="onInputChange($event.target.value)"
        />
      </mat-form-field>
</div>