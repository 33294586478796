import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { Subscription, filter } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { UserAuthService } from "../../core/user-auth.service";
import { InvitationService } from "../../core/services/invitation.service";
import { ClaimResult, ClaimStatus } from "../models/userInvitation";
import { OtherEmailDialogComponent } from "./other-email-dialog/other-email-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../dialog/notification-dialog/notification-dialog.component";
import { MsalGuardConfiguration, MSAL_GUARD_CONFIG, MsalBroadcastService } from "@azure/msal-angular";
import { RedirectRequest, InteractionStatus, EventMessage, EventType, InteractionType, AuthenticationResult } from "@azure/msal-browser";

@Component({
  selector: "app-invitation",
  templateUrl: "./invitation.component.html",
  styleUrls: ["./invitation.component.css"],
})
export class InvitationComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();

  claimingInvitation = true;
  private claimResultType: string;
  private claimErrorResult: string;
  private claimResult: ClaimResult;
  private readonly forcedLoggedInKey: string = "forcedLoggedIn";
  private hasLoginSuccess: boolean = false;
  hasLoginfailure: boolean = false;
  private hasStartWithClaiming: boolean = false;
  constructor(
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private route: ActivatedRoute,
    private router: Router,
    private userAuthService: UserAuthService,
    private invitationService: InvitationService,
    private dialog: MatDialog, 
    private broadcastService: MsalBroadcastService,
  ) {}

  ngOnInit() {
    //page is called from outside
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        //see https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/redirects.md
        //else in progress problem
        this.onInit();
      })
    
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.interactionType === InteractionType.Redirect)
      )
      .subscribe((result) => {
        if (result.eventType === EventType.LOGIN_SUCCESS ||  result.eventType === EventType.ACQUIRE_TOKEN_SUCCESS){
          //if the same user was already logged in: no LOGIN_SUCCESS fired
          this.hasLoginSuccess = true;
          const payload = result.payload as AuthenticationResult;
          this.userAuthService.setActiveAccount(payload.account);
        }
        if (result.eventType === EventType.LOGIN_FAILURE || result.eventType ===EventType.ACQUIRE_TOKEN_FAILURE){
          this.hasLoginfailure = true;
        }
        if (result.eventType === EventType.HANDLE_REDIRECT_END){
          this.hasStartWithClaiming = true;
          if(this.hasLoginSuccess && !this.hasLoginfailure){
            sessionStorage.removeItem(this.forcedLoggedInKey);
            this.route.params.subscribe((params) => {
              const invitationId = params["invitationId"];
              if (invitationId){
                this.subscription.add(this.claimInvitation(invitationId));
              }
            })
          }
        }
      });
  }

  private onInit(){
    let forcedLoggedIn = sessionStorage.getItem(this.forcedLoggedInKey);
    /* Force to always login. 
       To not start a loop, mark it in sessionstorage to not login again after redirect
       except when more than 5 minutes ago */
    if(!this.hasStartWithClaiming &&
      (!forcedLoggedIn || Math.abs((new Date()).getTime() - Number(forcedLoggedIn)) > 300000))
    {
      this.forceLogin();
    }
    else {
      /* Fallback -> it's possible that sessionstorage is not cleaned up after a aborted attempt.
         When screen is not doing anything for 45 sec -> login */
      setTimeout(() => {
        if (!this.hasStartWithClaiming) {
          this.forceLogin();
        }
      }, 45000);
    }
  }

  private forceLogin(){
    sessionStorage.setItem(this.forcedLoggedInKey,(new Date()).getTime().toString());
    let redirectRequest = {
      ...this.msalGuardConfig.authRequest,
      } as RedirectRequest;
    redirectRequest.prompt = "login";
    this.userAuthService.msalService.loginRedirect(redirectRequest); 
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private claimInvitation(invitationId: string): Subscription {
    return this.invitationService.ClaimInvitation(invitationId).subscribe(
      (pending) => {
        this.claimResult = pending.body as ClaimResult;
      },
      (response) => {
        this.claimingInvitation = false;
        if (response.error.StatusCode === 500) {
          this.claimResultType = "Server error";
          this.claimErrorResult =
            "An exception has been logged, please contact your administrator.";
        } else {
          this.claimResultType = "Invitation flow error";
          this.claimErrorResult = response.error.detail;
        }
      },
      () => {
        this.subscription.add(
          this.userAuthService.notifyCurrentContextChanged().subscribe(() => {
            this.redirectToCreationPageAfterClaiming(invitationId);
          })
        );
        this.userAuthService.initContexts();
      }
    );
  }

  private redirectToCreationPageAfterClaiming(invitationId: string) {
    // code below causes bug where page gets stuck on invitation, so it's commented out for now

  //   if (this.claimResult.isFirstLogin)
  //   {
  //     const dialogRef = this.dialog.open(TermsDialogComponent, {
  //       width: '800px',
  //       height: '800px'
  //     });

  //     this.subscription.add(dialogRef.afterClosed()
  //       .subscribe(hasAccepted => {
  //         if (hasAccepted) {
  //           this.choseRedirect(invitationId);
  //         }
  //         else {
  //           this.redirectToCreationPageAfterClaiming(invitationId);
  //         }
  //       })
  //     );
  //   }
  //   else {
  //     this.choseRedirect(invitationId);
  //   }
  // }

  // private choseRedirect(invitationId: string) {
    if (this.claimResult.status === ClaimStatus.PendingFarmer) {
      this.router.navigate([""]);
    } else if (this.claimResult.status === ClaimStatus.PendingPartner) {
      this.router.navigate(["partner-registration"]);
    } else if (this.claimResult.status === ClaimStatus.OtherEmailFound) {
      this.mergeEmail(invitationId);
    } else if (this.claimResult.status === ClaimStatus.EmailAlreadyRegistered) {
      this.emailAlreadyLinked();
    } else {
      this.router.navigate([""]);
    }
  }

  private mergeEmail(invitationId: string) {
    const dialogRef = this.dialog.open(OtherEmailDialogComponent, {
      width: "600px",
      data: {
        currentEmail: this.claimResult.currentEmail,
        newEmail: this.claimResult.newEmail,
      },
    });

    this.subscription.add(
      dialogRef
        .afterClosed()
        .subscribe((email) =>
          this.invitationService
            .MergeInvitations(invitationId, email)
            .subscribe(() => this.claimInvitation(invitationId))
        )
    );
  }

  private emailAlreadyLinked() {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      width: "400px",
      data: {
        title: "invitationPage.alreadyLinked.title",
        text: "invitationPage.alreadyLinked.text",
      },
    });

    this.subscription.add(
      dialogRef
        .afterClosed()
        .subscribe(() => this.userAuthService.msalService.logout())
    );
  }
}
