<ng-container *ngIf="details$ | async as details; else userNotFound">
  <form [formGroup]="currentUserDetailsForm">
    <section>
      <label for="userpage-info">
        <h2>
          {{ "info.userOverview" | translate }}
        </h2>
      </label>
      <input type="checkbox" name="userpage-info" id="userpage-info" checked />
      <div class="expandable">
        <div class="inner">
          <div class="fields">
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.firstName' | translate,
                value: details.firstName
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.lastName' | translate,
                value: details.lastName
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.email' | translate,
                value: details.email
              }"
            ></ng-template>
            <div class="field-item">
              <label for="consent">
                {{ "models.datahubUser.marketingConsent" | translate }}:
              </label>
              <mat-checkbox
                    class="box-center"
                    formControlName="allowDirectMarketing"
                  ></mat-checkbox>
            </div>
            <ng-container *ngIf="details.roles.length">
              <div class="field-item">
                <p class="bold">{{ "viewUserPage.roles" | translate }}:</p>
                <div class="chip-list">
                  <p class="small bold" *ngFor="let role of details.roles">
                    {{ "roles." + role | translate }}
                  </p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>
    <section>
      <label for="userpage-partners">
        <h2>
          {{ "viewUserPage.partners" | translate }}
        </h2>
      </label>
      <input
        type="checkbox"
        name="userpage-partners"
        id="userpage-partners"
        checked
      />
      <div class="expandable">
        <div class="inner">
          <div class="fields" formArrayName="userPartners">
            <table class="current-user-table">
              <thead>
                <td class="current-user-table-header">
                  {{ "viewUserPage.name" | translate }}
                </td>
                <td class="current-user-table-header">
                  {{ "viewUserPage.role" | translate }}
                </td>
                <td class="current-user-table-header">
                  {{ "viewUserPage.receiveNotifications" | translate }}
                </td>
                <td *ngIf="isAdmin" class="edit-header">
                  {{ "viewUserPage.edit" | translate }}
                </td>
                <td class="delete-header">
                  {{ "viewUserPage.delete" | translate }}
                </td>
              </thead>
              <tr
                *ngFor="let userPartner of userPartners.controls; index as i"
                [formGroupName]="i"
              >
                <td>
                  <app-translatable-string-view
                    class="truncate"
                    [translation]="
                      userPartner.get('partner')?.value.value.translations
                    "
                    property="name"
                  ></app-translatable-string-view>
                </td>
                <td>
                  {{
                    userPartner.get("partner")?.value.value.role
                      | IntRoleTranslation
                      | translate
                  }}
                </td>
                <td>
                  <!-- <mat-checkbox class="box-center" [checked]="userPartner.receiveNotifications"></mat-checkbox> -->
                  <mat-checkbox
                    class="box-center"
                    formControlName="receiveNotifications"
                  ></mat-checkbox>
                </td>
                <td *ngIf="isAdmin">
                  <button
                    mat-button
                    (click)="editPartner(userPartner.get('partnerId').value)"
                  >
                    <ng-template [ngTemplateOutlet]="editIcon"></ng-template>
                  </button>
                </td>
                <td>
                  <button
                    mat-button
                    type="button"
                    (click)="
                      removeUserConfirmation(
                        userPartner.get('partnerId').value,
                        'partner'
                      )
                    "
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>
    <section>
      <label for="userpage-farms">
        <h2>
          {{ "viewUserPage.farms" | translate }}
        </h2>
      </label>
      <input
        type="checkbox"
        name="userpage-farms"
        id="userpage-farms"
        checked
      />
      <div class="expandable">
        <div class="inner">
          <div class="fields" formArrayName="userFarms">
            <table class="current-user-table">
              <thead>
                <td class="current-user-table-header">
                  {{ "viewUserPage.kbo" | translate }}
                </td>
                <td class="current-user-table-header box-center">
                  {{ "viewUserPage.receiveNotifications" | translate }}
                </td>
                <td *ngIf="isAdmin" class="edit-header">
                  {{ "viewUserPage.edit" | translate }}
                </td>
                <td class="delete-header">
                  {{ "viewUserPage.delete" | translate }}
                </td>
              </thead>
              <tr
                *ngFor="let userFarm of userFarms.controls; index as i"
                [formGroupName]="i"
              >
                <td>
                  {{ userFarm.get("kbo").value }}
                </td>
                <td>
                  <mat-checkbox
                    class="box-center"
                    formControlName="receiveNotifications"
                  ></mat-checkbox>
                </td>
                <td *ngIf="isAdmin">
                  <button
                    (click)="editFarm(userFarm.get('farmId').value)"
                    mat-button
                  >
                    <ng-template [ngTemplateOutlet]="editIcon"></ng-template>
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    (click)="
                      removeUserConfirmation(
                        userFarm.get('farmId').value,
                        'farm'
                      )
                    "
                    mat-button
                  >
                    <i class="fas fa-trash-alt"></i>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>

    <div class="button-group">
      <button
        class="save-button dialog-button green"
        (click)="update(currentUserDetailsForm)"
      >
        {{ "actions.save" | translate }}
      </button>
      <button
        class="dialog-button blue"
        type="button" 
        (click)="openReportDialog()"
      >
        {{ "actions.reportErrorUserProfile" | translate }}
      </button>
      <button
        class="delete-button dialog-button rose"
        type="button" 
        (click)="deleteUserAccountConfirmation()"
      >
        {{ "actions.deleteUserAccount" | translate }}
      </button>
    </div>
  </form>
</ng-container>

<!--
  Templates
-->
<ng-template #userNotFound>
  <div class="text-center">
    <h3 class="red">{{ "viewUserPage.userNotFound" | translate }}</h3>
  </div>
</ng-template>

<ng-template #editIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-pencil-square"
    viewBox="0 0 16 16"
  >
    <path
      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
    />
    <path
      fill-rule="evenodd"
      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    />
  </svg>
</ng-template>

<ng-template #formField let-label="label" let-value="value">
  <div class="field-item">
    <label for="{{ label }}">{{ label }}</label>
    <input
      type="text"
      readonly
      value="{{ value }}"
      name="{{ label }}"
      id="{{ label }}"
    />
  </div>
</ng-template>
