import { NgModule } from '@angular/core';
import { ManageUsersComponent } from './manage-users/manage-users.component';
import { InviteUserDialogComponent } from './manage-invites/invite-user-dialog/invite-user-dialog.component';
import { SharedModule } from '../../modules/shared/shared.module';
import { ManageInvitesComponent } from './manage-invites/manage-invites.component';


@NgModule({
    declarations: [
        ManageUsersComponent,
        InviteUserDialogComponent,
        ManageInvitesComponent
    ],
    imports: [
        SharedModule
    ],
    exports: [
        ManageUsersComponent,
        ManageInvitesComponent
    ]
})
export class UserModule { }
