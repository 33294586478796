export enum Language {
  Unknown = 0,
  en = 1,
  nl = 2,
  fr = 3,
}

export function getDefaultTranslatableStringArray(): Array<TranslatableString> {
  return Object.values(Language)
    .map((item) => Number(item))
    .filter((item) => item > 0)
    .map((o) => ({ language: o, value: "" }));
}

export class TranslatableString {
  language: Language;
  value: string;
}
