import { Component, Input, OnInit } from "@angular/core";
import { UserAuthService } from "../../core/user-auth.service";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { MessageDialogComponent } from "../message-dialog/message-dialog.component";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-relog-link",
  templateUrl: "./relog-link.component.html",
  styleUrls: ["./relog-link.component.scss"],
})
export class RelogLinkComponent implements OnInit {

  thirdPartyCookieDisabled: boolean;
  subscription = new Subscription();
  private thirdPartyCookieSubscription: Subscription;
  constructor(
    private auth: UserAuthService,
    private dialog: MatDialog,
    private translationService: TranslateService
  ) {
    this.thirdPartyCookieSubscription = auth.checkThirdPartyCookies().subscribe(value => 
      {
        this.thirdPartyCookieDisabled = value;
      });
  }

  @Input() linkText: string = "actions.relog";
  
  ngOnInit() {}
 
  ngOnDestroy() {
    this.subscription.unsubscribe();
    this.thirdPartyCookieSubscription.unsubscribe();
  }

  relog() {
    if (this.thirdPartyCookieDisabled){
      const dialogRef = this.dialog.open(MessageDialogComponent, {
        data: this.translationService.instant("cookies.thirdParty.disabled.warning")
      });
  
      this.subscription.add(
        dialogRef.afterClosed().subscribe(() => {
          this.auth.relog();
        })
      );
    } else {
      this.auth.relog();
    }
  }
}
