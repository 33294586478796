import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable()
export class SpinnerService {

  constructor(private ngxSpinnerService: NgxSpinnerService) {}

  private totalRequests = 0;
  private keepVisible = false;

  increaseNumberOfRequests() {
      this.totalRequests++;
      this.ngxSpinnerService.show();
  }

  decreaseNumberOfRequests() {
      this.totalRequests--;
      if (this.totalRequests === 0 && !this.keepVisible) {
          this.ngxSpinnerService.hide();
      }
  }

  //also possible to show spinner next to spinnerInterceptor
  showSpinnerAndBlock(){
    this.keepVisible = true;
    this.ngxSpinnerService.show();
  }

  unblockSpinner(){
    this.keepVisible = false;
    if (this.totalRequests === 0 ) {
      this.ngxSpinnerService.hide();
    }
  }
}
