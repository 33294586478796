import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { map } from 'rxjs/operators';
import { ProviderPushEndpoint } from '../../shared/models/provider-push-endpoint';
import { EventType } from '../../data-provider-module/models/event-type';
import { EventTypeValidationResult } from '../../data-provider-module/models/event-type-validation-result';

@Injectable({
  providedIn: 'root'
})
export class PushEventsService {
  private providerPushEndointURL = `${this.api}/api/ProviderPushEndpoint`;
  private eventTypeURL = `${this.api}/api/EventTypes`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) { }

  createProviderPushEndpoint(): Observable<any> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.post(this.providerPushEndointURL, {});
    return httpResponse;
  }

  createProviderEventType(eventType: EventType): Observable<string> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.post(this.eventTypeURL, eventType);
    return httpResponse.pipe(
      map(response => response as string)
    );
  }

  saveProviderEventType(eventType: EventType): Observable<any> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.put(`${this.eventTypeURL}/${eventType.id}/saveAsDraft`, eventType);
    return httpResponse;
  }

  validateProviderEventType(eventType: EventType): Observable<EventTypeValidationResult> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.post(`${this.eventTypeURL}/validate`, eventType);
    return httpResponse.pipe(
      map(response => response ? Object.assign(new EventTypeValidationResult(), response) : null));
  }

  submitProviderEventType(eventType: EventType): Observable<EventTypeValidationResult> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.put(`${this.eventTypeURL}/submit`, eventType );
    return httpResponse.pipe(
      map(response => response ? Object.assign(new EventTypeValidationResult(), response) : null));
  }

  publishProviderEventType(eventTypeId: string): Observable<any> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.put(`${this.eventTypeURL}/${eventTypeId}/publish`, null);
    return httpResponse;
  }

  rejectProviderEventType(eventTypeId: string): Observable<any> {
    return this.httpClient.put(`${this.eventTypeURL}/${eventTypeId}/reject`, undefined)
  }

  getProviderPushEndpoint(): Observable<ProviderPushEndpoint> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.get(this.providerPushEndointURL);
    return httpResponse.pipe(
      map(response => response ? Object.assign(new ProviderPushEndpoint(), response) : null));
  }

  getProviderEventTypes(): Observable<EventType[]> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.get(`${this.eventTypeURL}`);
    return httpResponse.pipe(
      map(response => response.map(eventType => Object.assign(new EventType(), eventType))));
  }

  getProviderEventType(id: string): Observable<EventType> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.get(`${this.eventTypeURL}/${id}`);
    return httpResponse.pipe(
      map(response => Object.assign(new EventType(), response)));
  }

  deleteProviderEventType(id: string): Observable<any> {
    return this.httpClient.delete(`${this.eventTypeURL}/${id}`)
  }

  uploadDocumentation(id: string, file: File): Observable<any> {
    const data = new FormData();
    data.append('file', file, file.name)
    return this.httpClient.put(`${this.eventTypeURL}/${id}/uploadDocumentation`, data);
  }
}
