import { UserRole } from './datahubUser';

//currently, partners prefer to sign their contracts on paper, so code for that flow has been commented out
export class TermsSummaryDTO {
    signedPrivacyPolicyVersion: string;
    allowDirectMarketing: boolean;
    signedFarmContractVersion: string;
    // signedPartnerContractVersion: string;

    isNewestPrivacyPolicyVersion: boolean;
    isNewestFarmContractVersion: boolean;
    // isNewestPartnerContractVersion: boolean;

    farmId: string;
    // partnerId: string;
    userId: string;
    isAdmin: boolean;
}

export class TermsUpdateDTO{
    signedPrivacyPolicy: boolean;
    allowDirectMarketing: boolean;
    signedFarmContract: boolean;
    // signedPartnerContract: boolean;

    farmId: string;
    // partnerId: string;
    userId: string;
}