<div *ngFor="let header of headerKvps">
  <div class="row">
    <div class="col col-md-2">
      <b>{{ "manageApisPage.details.header.placeholder" | translate }}: </b>
    </div>
    <div class="col col-md-4">
      <app-apiheader
        [header]="header"
        [isEditable]="isEditable"
        (headersChanged)="onHeadersChanged()"
      ></app-apiheader>
    </div>
    <div class="col col-md-2 align-self-end">
      <button
        [disabled]="!isEditable"
        type="button"
        (click)="removeHeader(header.key)"
        class="rose"
      >
        {{ "actions.removeHeader" | translate }}
      </button>
    </div>
  </div>
</div>
<button
  type="button"
  (click)="addNewHeader()"
  [disabled]="!canAddNewHeader || !isEditable"
  class="green"
  id="addHeader"
>
  {{ "actions.addHeader" | translate }}
</button>
