import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ApiValidationResponse } from '../../../models/api-validation-response';

@Component({
  selector: 'app-validation-result-dialog',
  templateUrl: './validation-result-dialog.component.html',
  styleUrls: ['./validation-result-dialog.component.css']
})
export class ValidationResultDialogComponent implements OnInit {
  constructor(
    public dialogReg: MatDialogRef<ValidationResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      farmIdUrl: string;
      results: ApiValidationResponse;
      isSubmitted: boolean;
    }
  ) {}

  ngOnInit() {}

  onOk = () => {
    if (this.isSuccessfullySubmitted()) {
      this.dialogReg.close(true);
      return;
    }
    this.dialogReg.close();
  }

  isSuccessfullySubmitted() {
    return this.data.isSubmitted && this.data.results.isValid;
  }
}
