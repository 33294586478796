import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { Product } from '../../shared/models/product';

@Injectable()
export class ProductService {
    private URL = `${this.api}/api/product`;
    constructor(private client: HttpClient, @Inject(baseurl) private api: string) {}

    public getProducts(): Observable<Array<Product>> {
        return this.client.get<Array<Product>>(`${this.URL}`);
    }
}
