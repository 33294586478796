import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { UserAuthService } from '../../../core/user-auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-farm-mapping-mistake-dialog',
  templateUrl: './farm-mapping-mistake-dialog.component.html',
  styleUrls: ['./farm-mapping-mistake-dialog.component.css']
})
export class FarmMappingMistakeDialogComponent implements OnInit {

  kbo: string;
  form: UntypedFormGroup;
  subscription = new Subscription();
  
  constructor(public dialogReg: MatDialogRef<FarmMappingMistakeDialogComponent>,
    auth: UserAuthService, formBuilder: UntypedFormBuilder) {
      this.subscription.add(
        auth.notifyCurrentContextChanged().subscribe(context => {
          this.kbo = context.entityName;
        })
      );
      
      this.form = formBuilder.group({
        input: ['', Validators.required]
      });
     }

  ngOnInit() {
  }

  report(values) {
    this.dialogReg.close(values.input);
  }

  cancel() {
    this.dialogReg.close();
  }

}
