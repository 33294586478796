<h1 mat-dialog-title>{{ "manageUsers.inviteUser.title" | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="onInvite(form.getRawValue())">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        formControlName="email"
        placeholder="{{ 'models.datahubUser.email' | translate }}"
        cdkFocusInitial
      />
    </mat-form-field>
  </div>
  <div class="button-group">
    <button type="reset" (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button
      type="submit"
      [disabled]="!form.valid"
      [promiseBtn]="subscription"
      class="green"
    >
      {{ "actions.invite" | translate }}
    </button>
  </div>
</form>
