import { Pipe, PipeTransform } from '@angular/core';
import { ApiStatus } from '../../data-provider-module/models/api-status';

@Pipe({
    name: 'ApiStatusTranslation'
})
export class ApiStatusTranslation implements PipeTransform {
    transform(value: string): any {
        return `models.providerApi.statusses.${ApiStatus[value]}`;
    }
}
