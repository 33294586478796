<h1>{{ "info.eventTypeDetails" | translate }}</h1>
<form novalidate>
  <div class="form-part">
    <div *ngIf="error.name">
      <i class="fas fa-exclamation-circle" [title]="error.name"></i>
    </div>
    <label
      [for]="
        ('managePushEventsPage.eventType.name.placeholder' | translate) + '' + 0
      "
      >{{
        "managePushEventsPage.eventType.name.placeholder" | translate
      }}</label
    >
    <app-translatable-string
      placeholder="{{
        'managePushEventsPage.eventType.name.placeholder' | translate
      }}"
      required="true"
      [disabled]="!eventType.isEditable"
      [value]="names"
      (changed)="nameChanged($event)"
    ></app-translatable-string>
    <!-- <input matInput [(ngModel)]="eventType.name" [disabled]="!isEditable" (change)="onChangeName()" /> -->
    <!-- <mat-hint>{{'managePushEventsPage.eventType.name.description' | translate }}</mat-hint> -->
  </div>
  <div class="form-part" *ngIf="eventType.id">
    <label class="id-label" for="event_id"
      >{{ "managePushEventsPage.eventType.id" | translate
      }}<span class="small">{{
        "managePushEventsPage.automaticId" | translate
      }}</span></label
    >
    <input
      [(ngModel)]="eventType.id"
      disabled
      name="event_id"
      id="event_id"
      type="text"
    />
  </div>
  <div class="form-part" (click)="(!isEditable)">
    <div *ngIf="error.description">
      <i class="fas fa-exclamation-circle" [title]="error.description"></i>
    </div>
    <label
      [for]="
        ('manageApisPage.details.resourceDescription' | translate) + '' + 0
      "
    >
      {{ "managePushEventsPage.eventType.description" | translate }}
    </label>

    <app-translatable-string-area
      placeholder="{{
        'manageApisPage.details.resourceDescription' | translate
      }}"
      [value]="descriptions"
      [required]="false"
      [disabled]="!isEditable"
      (changed)="descriptionChanged($event)"
    ></app-translatable-string-area>
    <!-- <textarea matInput [disabled]="!isEditable" cdkTextareaAutosize #autosize="cdkTextareaAutosize"
          cdkAutosizeMinRows="1" cdkAutosizeMaxRows="{{ this.maxRowSize }}" (focus)="onDescriptionFocus()"
          (focusout)="onDescriptionFocusOut()" [(ngModel)]="description"></textarea> -->
  </div>
  <div class="form-part">
    <label for="externalDocumentationUrl">{{
      "managePushEventsPage.eventType.externalDocumentationUrl" | translate
    }}</label>
    <input
      type="text"
      [(ngModel)]="eventType.documentationUrl"
      [disabled]="!isEditable"
      id="externalDocumentationUrl"
      name="externalDocumentationUrl"
      placeholder="{{
        'managePushEventsPage.eventType.externalDocumentationUrl' | translate
      }}"
    />
  </div>

  <label class="form-part">
    {{ "managePushEventsPage.eventType.externalDocumentationFile" | translate }}
    <input
      #documentation
      type="file"
      name="externalDocumentationFile"
      id="externalDocumentationFile"
    />
    <div class="file-upload">
      <div *ngIf="documentation.empty">
        <i class="fas fa-folder-open"></i>
      </div>
      <p>Choose file</p>
      <button
        id="file-upload-btn"
        *ngIf="!documentation.empty"
        (click)="documentation.clear($event)"
        style="padding: 0.5rem 1rem !important"
        type="button"
      >
        <i class="fa fa-times"></i>
      </button>
    </div>
    <!-- <ngx-mat-file-input #documentation></ngx-mat-file-input> -->
    <!-- <mat-icon matSuffix><span *ngIf="documentation.empty" class='glyphicon glyphicon-folder-open'></span></mat-icon>
          <button mat-icon-button matSuffix *ngIf="!documentation.empty" (click)="documentation.clear($event)">
              <mat-icon><span class='glyphicon glyphicon-remove'></span></mat-icon>
          </button> -->
  </label>
  <div class="form-part">
    <label for="consentType">{{
      "managePushEventsPage.eventType.consentType" | translate
    }}</label>
    <select
      [(ngModel)]="eventType.consentType"
      #consentType="ngModel"
      [disabled]="!isEditable"
      (change)="changeConsent($event)"
      name="consentType"
      id="consentType"
    >
      <option *ngFor="let consent of consentOptions" [ngValue]="consent.index">
        {{ consent.name }}
      </option>
    </select>
    <div *ngIf="isFarmerConsent" class="form-part sub-select">
      <div class="sub-select-part">
        <div *ngIf="error.farmIdType">
          <i class="fas fa-exclamation-circle" [title]="error.farmIdType"></i>
        </div>
        <label for="farmIdType">{{
          "managePushEventsPage.eventType.farmIdType" | translate
        }}</label>
        <select
          [(ngModel)]="eventType.farmIdTypeId"
          name="farmIdType"
          id="farmIdType"
          [disabled]="!isEditable"
          (change)="onChangeFarmIdType()"
        >
          <option
            *ngFor="let farmId of farmIdTypes$ | async; index as i"
            [ngValue]="farmId.id"
            [attr.Selected]="i === 0"
          >
            {{ farmId.name }}
          </option>
        </select>
      </div>
      <div class="sub-select-part">
        <div *ngIf="error.farmIdUrl">
          <i class="fas fa-exclamation-circle" [title]="error.farmIdUrl"></i>
        </div>
        <label for="farmIdUrl">{{
          "managePushEventsPage.eventType.farmIdUrl.placeholder" | translate
        }}</label>
        <input
          [disabled]="!isEditable"
          class="farmIdUrl"
          [(ngModel)]="eventType.farmIdUrl"
          (change)="onChangeFarmIdUrl()"
          type="text"
          name="farmIdUrl"
          id="farmIdUrl"
          placeholder="{{
            'managePushEventsPage.eventType.farmIdUrl.placeholder' | translate
          }}"
        />

        <p class="small">
          {{
            "managePushEventsPage.eventType.farmIdUrl.description" | translate
          }}
        </p>
      </div>
      <div class="sub-select-part">
        <app-credentials [api]="this.eventType" [showLabel]="false">
        </app-credentials>
      </div>
      <div class="sub-select-part">
        <app-manage-headers [isEditable]="canSave" [object]="eventType">
        </app-manage-headers>
      </div>
    </div>
  </div>

  <div class="form-button-part">
    <button class="rose" type="button" (click)="back()">
      <i class="fas fa-chevron-left"></i>
      <span>
        {{ "actions.back" | translate }}
      </span>
    </button>
    <div class="button-group">
      <button *ngIf="canSubmit" class="rose" type="button" (click)="delete()">
        <i class="fas fa-trash"></i>
        <span>
          {{ "actions.delete" | translate }}
        </span>
      </button>
      <button *ngIf="canPublish" class="rose" type="button" (click)="reject()">
        <i class="fas fa-times"></i>
        <span>
          {{ "actions.reject" | translate }}
        </span>
      </button>
      <div class="button-group">
        <button
          *ngIf="canCreate"
          type="button"
          class="green"
          (click)="create()"
        >
          <i class="fas fa-plus"></i>
          <span>
            {{ "actions.create" | translate }}
          </span>
        </button>
        <button *ngIf="canSave" type="button" class="green" (click)="save()">
          {{ "actions.save" | translate }}
        </button>
        <button
          *ngIf="canPublish"
          type="button"
          class="green"
          (click)="publish()"
        >
          <i class="fas fa-upload"></i>
          <span>
            {{ "actions.publish" | translate }}
          </span>
        </button>
      </div>
    </div>
  </div>
  <ng-template [ngIf]="canValidate">
    <button type="button" class="green" (click)="validate()">
      {{ "actions.validate" | translate }}
    </button>
  </ng-template>
  <ng-template [ngIf]="canSubmit">
    <button type="button" class="green" (click)="submit()">
      {{ "actions.submit" | translate }}
    </button>
  </ng-template>
</form>
