export class ClearingHouseLog {
  correlationId: string;
  consumerName: string;
  providerName: string;
  resourceName: string;
  farmNumber?: string;
  decision?: AccessDecision;
  timeStamp: Date;
  responseSize: number;
  durationMs: number;
  callerIp?: string;
}

export enum AccessDecision {
  BadRequest = 0,
  UnknownCertificate = 10,
  NoProviderApproval = 20,
  NoFarmerApproval = 30,
  Granted = 40,
  MissingCertificate = 50,
  GrantedWithResponseFiltering = 60,
  MissingFarmNumber = 70,
}
