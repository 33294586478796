import { Component, Input, Renderer2 } from "@angular/core";
import { UserContextDTO } from "../models/userContextDTO";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { UserAuthService } from "../../core/user-auth.service";
import { Observable, Subscription } from "rxjs";
import { CurrentUserDetailsDTO } from "../models/datahubUser";
import { UserService } from "../../core/services/user.service";
import { ContextService } from "../../core/services/context.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent {
  @Input() isLoggedIn: boolean;
  @Input() hasMultipleContexts: boolean;
  @Input() isLoggedInAndHasRole: boolean;
  @Input() context: UserContextDTO;
  @Input() getBannerCookieValue: number;
  hamburgerState: boolean = false;
  subscription = new Subscription();
  firstName: string;

  constructor(
    private appInsights: ApplicationInsights,
    private userAuthService: UserAuthService,
    private renderer: Renderer2,
    private userService: UserService,
    private contextService: ContextService
  ) {
    this.isLoggedIn = false;
    this.hasMultipleContexts = false;
    this.isLoggedInAndHasRole = false;
  }

  ngOnInit() {
    const c = this.GetBannerCookieValue();

    this.subscription.add(
      this.userService
        .notifyUserDetailsChanged()
        .subscribe((userDetails) => {
          this.firstName = userDetails?.identityFirstName;
        })
    );
  }

  GetBannerCookieValue() {
    const cookieValue = getCookie("cookie-agreed");
    if (cookieValue) return cookieValue;
    return null;
  }

  changeHamburgerState() {
    this.hamburgerState = !this.hamburgerState;
    if (this.hamburgerState) {
      this.renderer.addClass(document.querySelector("html"), "no-scroll");
    } else {
      this.renderer.removeClass(document.querySelector("html"), "no-scroll");
    }
  }

  logout() {
    const bannerCookie = this.GetBannerCookieValue();
    if (bannerCookie && bannerCookie.functional) {
      this.appInsights.clearAuthenticatedUserContext();
    }

    this.userAuthService.msalService.logoutRedirect();
    this.userAuthService.cleanupAfterLogout()
  }
}

function getCookie(name: string) {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const parts = cookie.split("=");
    if (parts[0] === name) {
      return JSON.parse(parts[1]);
    }
  }
  return null;
}
