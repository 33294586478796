import { Pipe, PipeTransform } from '@angular/core';
import { DatahubUserStatus } from '../models/datahubUser';

@Pipe({
    name: 'DatahubUserStatusTranslation'
})
export class DatahubUserStatusTranslation implements PipeTransform {
    transform(value: DatahubUserStatus): any {
        return `models.datahubUser.statusses.${DatahubUserStatus[value]}`;
    }
}