import DataAccessRequest from './data-access-request';

export default class ConsumerDataAccessRequests {
    consumerId: string;
    description: string;
    name: string;
    companyName:string;
    applicationUrl: string;
    applicationLogoUrl: string;
    dataAccessRequests: Array<DataAccessRequest>;
    informativeFlow: boolean;
}
