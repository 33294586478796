import { Observable } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map } from "rxjs/operators";
import { Page } from "../../shared/models/page";

// extra interface to avoid build errors
export interface IExtendedHttpClient {
  getPagedSortedFiltered<T>(
    factory: new () => T,
    baseUrl: string,
    pageNumber: number,
    pageSize: number,
    sort: string,
    filterObj?: any
  ): Observable<Page<T>>;
}

export function extension(ctr: any) {
  let originalFunction: Function;
  return function (
    target,
    propertyKey: string,
    descriptor: PropertyDescriptor
  ) {
    originalFunction = descriptor.value;

    ctr.prototype[propertyKey] = function (...args) {
      return originalFunction(this, ...args);
    };
  };
}

export class Ext {
  @extension(HttpClient)
  static getPagedSortedFiltered<T>(
    httpClient: HttpClient,
    factory: new () => T,
    baseUrl: string,
    pageNumber: number,
    pageSize: number,
    sort: string = "",
    filterObj: any = null
  ): Observable<Page<T>> {
    const filterStr = Ext.createFilterStr(filterObj);

    const url = `${baseUrl}?pageNumber=${pageNumber}&pageSize=${pageSize}${
      sort ? `&sort=${sort}` : ""
    }${filterStr}`;

    const httpResponse = httpClient.get<Array<T>>(url, { observe: "response" });
    return httpResponse.pipe(
      map((r) =>
        Object.assign(new Page<T>(), {
          totalCount: r.headers.get("x-totalcount"),
          pageSize: r.headers.get("x-pagesize"),
          pageNumber: r.headers.get("x-pagenumber"),
          pages: r.headers.get("x-pages"),
          items: r.body.map((farmMapping) =>
            Object.assign(new factory(), farmMapping)
          ),
        })
      )
    );
  }

  static createFilterStr(filterObj) {
    if (filterObj) {
      return Object.entries(filterObj)
        .map(([key, value]) => `${key}=${value}`)
        .reduce((acc, kvpStr) => `${acc}&${kvpStr}`, "");
    }
    return "";
  }
}
