import { PipeTransform, Pipe } from '@angular/core';
import { ConsumerRequestCard } from '../../farmer/models/consumer-request-card';
@Pipe({
    name: 'AccessConsumersFilter'
})
export class AccessConsumersFilter implements PipeTransform{
    transform( value: ConsumerRequestCard[], hasPending:boolean) {
            if(!value){
                return value;
            }
            return value.filter(x => (x.pending === 0) === hasPending);
    }

}