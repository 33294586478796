import { PipeTransform, Pipe } from '@angular/core';
import DataAccessRequest from '../models/data-access-request';
import { DarStatus } from '../models/data-access-request-status';
@Pipe({
    name: 'ConsumerDarsPendingFilter'
})
export class ConsumerDarsPendingFilter implements PipeTransform{
    transform( value: DataAccessRequest[], isPending:boolean) {
            if(!value){
                return value;
            }
            return value.filter(x => (x.status === DarStatus.Pending) === isPending);
    }

}