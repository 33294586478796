<label [for]="placeholder" *ngIf="viewOnly">{{ placeholder }}</label>
<input
  [disabled]="disabled"
  [value]="currentLanguageValue"
  [placeholder]="placeholder"
  type="text"
  *ngIf="viewOnly; else noViewOnly"
  [id]="placeholder"
  [name]="placeholder"
/>

<ng-template #noViewOnly>
  <div class="language">
    <app-language-circle [language]="1"></app-language-circle>
    <label [for]="placeholder + '' + 0">
      <input
        (input)="setDefaultLanguage($event.target.value)"
        [placeholder]="placeholder"
        [disabled]="disabled"
        [value]="defaultLanguage.value"
        [id]="placeholder + '' + 0"
        [name]="placeholder + '' + 0"
        type="text"
      />
    </label>
    <button
      mat-button
      type="button"
      class="toggle {{ isToggled ? 'active' : '' }}"
      (click)="toggle()"
      aria-label="Show or hide other languages button"
    >
      <i class="fas fa-chevron-up"></i>
    </button>
  </div>
  <div class="language-wrapper" [hidden]="!isToggled">
    <div class="language" *ngFor="let l of otherLanguageValues; index as i">
      <app-language-circle [language]="l.language"></app-language-circle>
      <label [for]="placeholder + '' + i + 1">
        <input
          (input)="setOtherLanguage($event.target.value, l.language)"
          [placeholder]="placeholder"
          [disabled]="disabled"
          [value]="l.value"
          [id]="placeholder + '' + i + 1"
          [name]="placeholder + '' + i + 1"
          type="text"
        />
      </label>
    </div>
  </div>
</ng-template>
