import { PipeTransform, Pipe } from '@angular/core';
import { AccessDecision } from '../../data-consumer/models/clearing-house-log';

@Pipe({
    name: 'AccessDecisionTranslation'
})
export class AccessDecisionTranslation implements PipeTransform {
    transform(value: string): any {
        return `models.clearingHouseLog.decisions.${AccessDecision[value]}`;
    }
}
