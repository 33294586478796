import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpInterceptor, } from '@angular/common/http';
import { catchError, finalize } from 'rxjs/operators';
import { SpinnerService } from '../services/spinner.service';

@Injectable()
export class SpinnerInterceptor implements HttpInterceptor {
    constructor(private spinnerService: SpinnerService) { }
    
    intercept(request: HttpRequest<any>, next: HttpHandler) {
        this.spinnerService.increaseNumberOfRequests();
        return next.handle(request).pipe(
            finalize(() => {
                this.spinnerService.decreaseNumberOfRequests();
            })
        );
    }    
}
