import { Component, Input, OnInit } from "@angular/core";
import { AdminService } from "../admin.service";
import { AdminStatistics } from "../../shared/models/admin-statistics";
import { Observable, take } from "rxjs";
import { ChartData, ChartOptions } from "chart.js";
import { TranslateService } from "@ngx-translate/core";

@Component({
  selector: "app-admin-statistics",
  templateUrl: "./admin-statistics.component.html",
  styleUrl: "./admin-statistics.component.scss",
})
export class AdminStatisticsComponent implements OnInit {
  @Input() loadingData: boolean;

  adminStatistics: Observable<AdminStatistics> = null;
  columnsListKboWithoutDataRequests: string[] = [
    "id",
    "farmIdTypeId",
    "KBO",
    "farmId",
  ];
  months = [1,2,3,4,5,6,7,8,9,10,11,12];
  monthAbbr: string [] = [];
  monthFull: string [] = [];
  
  constructor(
    private adminService: AdminService,
    private translate: TranslateService
  ) {
    this.monthsLabels()
    this.adminStatistics = this.adminService.getAdminStatistics();
    this.adminStatistics.pipe(take(1)).subscribe((data) => {
      // Refresh the chart if the data comes
      this.dataFarmersMonth.datasets[0].data = this.totalPerDay(
        "month",
        data
      ).map((x) => x.value);
      this.dataFarmersCountYearMonth.datasets = data.newFarmersCountYearMonth;
      this.dataFarmsCountYearMonth.datasets = data.newFarmsCountYearMonth;
    });
  }
  ngOnInit() {}

  private dateKey(date: Date): string {
    return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
  }

  private monthLabels = (): string[] => {
    let a = new Array<string>();
    for (let i = 0; i < 30; i++) {
      let date = new Date();
      date.setDate(date.getDate() - i);
      a.push(this.dateKey(date));
    }
    return a.reverse();
  };

  private monthsLabels()  {
    for (let i = 1; i < 13; i++){
      this.monthAbbr.push(this.translate.instant("month.abbreviation." + i));
      this.monthFull.push(this.translate.instant("month.full." + i));
    }
  }

  private totalPerDay = (
    type: "week" | "month" | "weekConsent",
    data: AdminStatistics
  ): Array<{ date: string; value: number }> => {
    if (!this.adminStatistics) return [];

    // Fill the array with the last 7 days
    let a = new Array<{ date: string; value: number }>();
    switch (type) {
      case "month":
        a = this.monthLabels().map((x) => {
          return { date: x, value: 0 };
        });
        data.newFarmersMonth.forEach((element) => {
          let dateKey = this.dateKey(new Date(element.createdAt));
          let index = a.findIndex((x) => x.date === dateKey);
          if (index !== -1) a[index].value++;
        });
        return a;
      default:
        return null;
    }
  };

  public dataFarmersMonth: ChartData = {
    datasets: [
      {
        label: "New Farmers month",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    labels: this.monthLabels(),
    xLabels: this.monthLabels(),
    yLabels: [],
  };
  public dataFarmersCountYearMonth: ChartData = {
    datasets: [
      {
        label: "New Farmers count per month",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    xLabels: this.months,
  };
  public dataFarmsCountYearMonth: ChartData = {
    datasets: [
      {
        label: "New Farms count per month",
        data: [],
        borderColor: "rgb(75, 192, 192)",
        backgroundColor: "rgba(75, 192, 192, 0.25)",
      },
    ],
    xLabels: this.months,
  };

  public options: ChartOptions = {
    font: {
      family: "Raleway",
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        ticks: {
          stepSize: 1,
        },
      },
    },
    color: "#1b1b1b",
    datasets: {
      line: {
        fill: true
      },
    },
    maintainAspectRatio: false  //cause shivering of the chart
  };

  public monthYearOptions: ChartOptions = {
    font: {
      family: "Raleway",
    },
    plugins: {
      title: {
        display: false,
      },
      legend: {
        display: true,
        position: "right",
        labels: {
          boxHeight: 1,
        }
      },
      tooltip: {
        callbacks:{
          title: (tooltipItems)  => {
            return this.monthFull[ +tooltipItems[0].label - 1]
          }
        }
      }
    },
    scales: {
      x: {
       ticks: {
          callback: (value, index, values) => this.monthAbbr[index],
        }
      },
      y: {
        ticks: {
          stepSize: 1,
        },
        suggestedMin: 0
      },
    },
    color: "#1b1b1b",
    datasets: {
      line: {
        fill: false,
        tension: 0,
        spanGaps: 1
      }
    },
    maintainAspectRatio: false  //cause shivering of the chart
  };
}
