import { Component, Input, OnChanges } from "@angular/core";
import { Router } from "@angular/router";
import { UserRole } from "../../../shared/models/datahubUser";
import { Subscription } from "rxjs";
import { UserService } from "../../../../modules/core/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { TermsDialogComponent } from "../../../../modules/shared/invitation/terms-dialog/terms-dialog.component";
import { TermsUpdateDTO } from "../../../../modules/shared/models/termsSummaryDTO";

@Component({
  selector: "app-context-start-page",
  templateUrl: "./context-start-page.component.html",
  styleUrls: ["./context-start-page.component.scss"],
})
export class ContextStartPageComponent implements OnChanges {
  @Input()
  role: UserRole;
  subscription = new Subscription();

  constructor(
    private router: Router,
    private userService: UserService,
    private dialog: MatDialog) { }

  ngOnChanges() {
    this.subscription.add(
      this.userService.getTermsSummary(this.role.toString()).subscribe((termsSummaryDTO) => {
        if (
          termsSummaryDTO.allowDirectMarketing == null ||
          termsSummaryDTO.signedPrivacyPolicyVersion == null || termsSummaryDTO.signedPrivacyPolicyVersion === ""
          || termsSummaryDTO.isNewestPrivacyPolicyVersion == false
          || (termsSummaryDTO.isNewestFarmContractVersion == false && termsSummaryDTO.signedFarmContractVersion != null && termsSummaryDTO.signedFarmContractVersion != "")
          || (this.isFarmer && (termsSummaryDTO.signedFarmContractVersion == null || termsSummaryDTO.signedFarmContractVersion === ""))
        ) {
          const dialogRef = this.dialog.open(TermsDialogComponent, {
            disableClose: true,
            width: "800px",
            height: "400px",
            data: {
              termsSummaryDTO, isFarmer: this.isFarmer,
              //  isPartner: (this.isConsumer || this.isProvider), 
              isAdmin: termsSummaryDTO.isAdmin
            },
          });
          dialogRef.afterClosed().subscribe((result) => {
            if (result) {
              this.userService
                .updateTerms(result)
                .subscribe(() => { });
            }
          });
        }
      })
    );
    if (this.isFarmer) {
      this.router.navigate([`manage-data-access`]);
    }
  }

  get isAdmin() {
    return this.role === UserRole.Admin;
  }
  get noRole() {
    return this.role === UserRole.Unknown;
  }
  get isFarmer() {
    return this.role === UserRole.Farmer;
  }
  get isProvider() {
    return this.role === UserRole.Provider;
  }
  get isConsumer() {
    return this.role === UserRole.Consumer;
  }
}
