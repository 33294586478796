import { FarmIdType } from "../../data-provider-module/models/farm-id-type";

export class FarmMappingPagedSummary {
    farmId: number;
    number: string;
    kbo: string;
    farmIdTypeId: string;
    farmIdType: FarmIdTypeEnum;
}

export enum FarmIdTypeEnum {
    "KBO" = 0,
    "Beslag nummer" = 1,
    "Perceelsnummer" = 2,
    "Keuring Spuittoestellen" = 3,
    "Vestigingseenheidsnummer" = 4,
    "Leveraarsnummer" = 5,
    "PE nummer" = 6
}
