import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAuthService } from '../../core/user-auth.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
 
@Injectable()
export class RoleGuard  {

    constructor(private authService: UserAuthService) {
    }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (!this.authService.msalService.instance.getActiveAccount) { return false; }
        return this.authService.notifyCurrentContextChanged().pipe(map((context) => {

            const routeRoles = route.routeConfig.data['roles'] as Array<string>;
            let hasAccess = false;
            routeRoles.forEach(role => {
                if (context.roleName === role) { hasAccess = true; }
            });
            return hasAccess;
        }));
    }
}
