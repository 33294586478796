import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import { Observable, Subscription } from "rxjs";
import { UserService } from "./../../../../core/services/user.service";
import {
  DatahubUserDetails,
  DatahubUserStatus,
  UserDetailFarmMappingDTO,
  UserRole,
} from "./../../../../shared/models/datahubUser";
import { ConfirmationDialogComponent } from "../../../../shared/confirmation-dialog/confirmation-dialog.component";
import { NotificationDialogComponent } from "../../../../shared/dialog/notification-dialog/notification-dialog.component";
import { UserAuthService } from "../../../../core/user-auth.service";
import { LastUserDeleteContextInfo } from "../../../models/lastUserDeleteContextInfo";
import { LastUserDeleteDialogComponent } from "../../../../shared/last-user-delete-dialog/last-user-delete-dialog.component";

@Component({
  selector: "app-view-user",
  templateUrl: "./view-user.component.html",
  styleUrls: ["./view-user.component.scss"],
})
export class ViewUserComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  msalService: UserAuthService;

  constructor(
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.route.paramMap.subscribe((params: ParamMap) => {
      this.viewedUserId = params.get("id");
    });
  }

  details$: Observable<DatahubUserDetails>;
  viewedUserId: string;

  ngOnInit() {
    this.details$ = this.userService.getUserDetailsByUserId(this.viewedUserId);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  groupFarmMappingsByType(
    mappings: UserDetailFarmMappingDTO[]
  ): Map<string, string[]> {
    let groups = new Map<string, string[]>();
    mappings.forEach((mapping) => {
      let group = groups.get(mapping.type);
      if (group !== undefined) {
        group.push(mapping.value);
      } else {
        groups.set(mapping.type, [mapping.value]);
      }
    });
    return groups;
  }

  editFarm(id: string) {
    this.router.navigate([`farmmapping/edit-farmmapping/`, id]);
  }

  editPartner(id: string) {
    this.router.navigate([`manage-partners/manage-partner-details/`, id]);
  }

  getRole(role: number) {
    return Object.keys(UserRole)[role];
  }

  getStatus(status: number) {
    return Object.values(DatahubUserStatus)[status];
  }

  deleteUserAccountAsAdminConfirmation() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "fit-content",
      data: {
        title: "viewUserPage.deleteUserAccountAsAdmin.title",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteUserAsAdmin();
      }
    });
  }

  deleteUserAsAdmin() {
    this.userService
      .deleteUserAccountAsAdmin(this.viewedUserId)
      .subscribe((response) => {
        const lastUserDeleteContextInfo: LastUserDeleteContextInfo =
          response as LastUserDeleteContextInfo;
        if (lastUserDeleteContextInfo.isLastUserForContext == false) {
          this.dialog.open(NotificationDialogComponent, {
            width: "fit-content",
            data: {
              title: "viewUserPage.deleteUserAccountAsAdmin.finalUserTitle",
            },
          });
        } else {
          this.dialog.open(LastUserDeleteDialogComponent, {
            width: "fit-content",
            data: {
              title: "viewUserPage.deleteUserAccountAsAdmin.finalUserTitle",
              text: "viewUserPage.deleteUserAccountAsAdmin.finalUserText",
              info: lastUserDeleteContextInfo,
            },
          });
        }
        this.router.navigate(["/admin-manage-users"]);
      });
  }
}
