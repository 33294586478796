import { ResourceAccessRequest } from "../../shared/models/resourceAccessRequest";
import { ResourceAccessRequestDTO } from "../../shared/models/resourceAccessRequestDTO";


export class ResourceRequestsCard {
    consumerId: string;
    consumerName: string;
    consumerPurpose: string;
    description: string;
    companyName: string;
    allFarms: string;
    informativeFlow: boolean = false;
    creationDate: Date;
    approved: number;
    rejected: number;
    pending: number;

    applicationUrl: string;
    applicationLogoUrl: string;
    requests: Array<ResourceAccessRequestDTO>;

    get total(): number {
        return this.approved + this.pending + this.rejected;
    }
}