import { Component, Input, SimpleChanges } from "@angular/core";
import {
  ChartConfiguration,
  ChartData,
  ChartOptions,
  ChartType,
} from "chart.js";
import { Chart } from "chart.js/auto";

@Component({
  selector: "app-chartjs-barchart",
  templateUrl: "./chartjs-barchart.component.html",
  styleUrl: "./chartjs-barchart.component.scss",
})
export class ChartjsBarchartComponent {
  @Input() data: ChartData;
  @Input() options: ChartOptions;
  @Input() title: string;
  @Input() exportable: boolean;

  private type: ChartType = "line";
  private config: ChartConfiguration = {
    type: this.type,
    data: null,
    options: null,
  };
  public chart: Chart;

  constructor() {}

  ngAfterViewInit(): void {
    this.initializeChart();
  }

  ngOnDestroy(): void {
    this.destroyChart();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.chart) {
      this.chart.destroy();
    }
    this.initializeChart();
  }

  private initializeChart(): void {
    if (!this.data) {
      throw new Error("Input 'data' is required for ChartjsBarchartComponent");
    }
    if (!this.options) {
      throw new Error(
        "Input 'options' is required for ChartjsBarchartComponent"
      );
    }
    if (!this.title) {
      throw new Error("Input 'title' is required for ChartjsBarchartComponent");
    }

    this.config.data = this.data;
    this.config.options = this.options;

    setTimeout(() => {
      this.createChart();
    });
  }

  private createChart(): void {
    this.destroyChart();
    const ctx = document.getElementById(this.title) as HTMLCanvasElement;
    if (ctx) {
      this.chart = new Chart(ctx, this.config);
      this.chart.update();
      return;
    }
    console.error(`Element with id '${this.title}' not found`);
  }

  private destroyChart() {
    if (this.chart) {
      this.chart.destroy();
      this.chart = undefined;
    }
  }

  async exportToCsv(): Promise<any> {
    if (!this.exportable) return;
    if (!this.data.datasets || !this.data.datasets.length) return;

    const date = new Date();
    const filename = `${this.title}${date.getFullYear()}${date.getMonth()}${date.getDay()}${date.getHours()}${date.getMinutes()}${date.getMilliseconds()}.csv`;

    let csvArray = [];
    const keys = Object.keys(this.data.datasets[0].data[0]);
    this.data.datasets.forEach(dataset => {
      csvArray.push(dataset.data.map(data => {
        return dataset.label + ";" + keys.map(k => {
          let cell = data[k] === null || data[k] === undefined ? '' : data[k];
          cell = cell.toString();
          if (cell.search(/("|,|\n)/g) >= 0) {
            cell = `"${cell}"`;
          }
          return cell;
        }).join(";");
      }).join("\r\n"));
    });
    const blob = new Blob([csvArray.join("\r\n")], {
      type: "text/csv;charset=utf-8;",
    });
    if (navigator.msSaveBlob) {
      // IE 10+
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement("a");
      if (link.download !== undefined) {
        // Browsers that support HTML5 download attribute
        const url = URL.createObjectURL(blob);
        link.setAttribute("href", url);
        link.setAttribute("download", filename);
        link.style.visibility = "hidden";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
