<h2 mat-dialog-title>{{ "farmDetails.reportDialog.title" | translate }}</h2>
<form [formGroup]="form" (ngSubmit)="report(form.getRawValue())">
  <mat-dialog-content>
    <p>{{ "farmDetails.reportDialog.subject" | translate : { kbo: kbo } }}</p>
    <mat-form-field>
      <mat-label>{{ "farmDetails.reportDialog.remark" | translate }}</mat-label>
      <textarea matInput formControlName="input" rows="13"></textarea>
    </mat-form-field>
  </mat-dialog-content>

  <mat-dialog-actions>
    <button type="button" (click)="cancel()" class="rose">
      {{ "actions.close" | translate }}
    </button>
    <button type="submit" [disabled]="!form.valid" class="yellow">
      {{ "actions.report" | translate }}
    </button>
  </mat-dialog-actions>
</form>
