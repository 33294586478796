import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import { FarmStatus } from "../../data-consumer/models/farm-status-summary";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { FarmAccessService } from "../../core/services/farm-access.service";

@Component({
  selector: "app-view-farm-access",
  templateUrl: "./view-farm-access.component.html",
  styleUrls: ["./view-farm-access.component.css"],
})
export class ViewFarmAccessComponent implements OnInit {
  @ViewChild("partnerNameCell", { static: true })
  partnerNameCell: TemplateRef<any>;
  @ViewChild("farmNumberCell", { static: true })
  farmNumberCell: TemplateRef<any>;
  @ViewChild("statusCell", { static: true }) statusCell: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("partnerName");

  constructor(public farmAccessService: FarmAccessService) {}

  ngOnInit() {
    this.columns = [
      {
        name: "partnerName",
        translationKey: "models.farmAccess.farmAccessTable.partnerName",
        cellTemplate: this.partnerNameCell,
        filter: new TextFilterDef("partnerNameFilter"),
        displayed: true,
        sort: "partnerName",
      },
      {
        name: "farmNumber",
        translationKey: "models.farmAccess.farmAccessTable.number",
        cellTemplate: this.farmNumberCell,
        filter: new TextFilterDef("farmNumberFilter"),
        displayed: true,
        sort: "farmNumber",
      },
      {
        name: "status",
        translationKey: "models.farmAccess.farmAccessTable.status",
        cellTemplate: this.statusCell,
        filter: new SelectFilterDef(
          "statusFilter",
          Object.keys(FarmStatus)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.farmAccess.statuses.${FarmStatus[x]}`
                )
            )
        ),
        displayed: true,
        sort: "status",
      },
    ];
  }
}
