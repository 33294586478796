import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { baseurl } from "../../baseSettings";
import { DatahubUser } from "../shared/models/datahubUser";
import { InvitationResult } from "../shared/models/invitation-result";
import { UserInvitation } from "../shared/models/userInvitation";
import { APIResourceHealth } from "../shared/models/api-resource-health";
import { AdminStatistics } from "../shared/models/admin-statistics";

@Injectable({
  providedIn: "root",
})
export class AdminService {
  private URL = `${this.api}/api/admin`;
  constructor(
    private client: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getAllAdmins(): Observable<Array<DatahubUser>> {
    return this.client.get<Array<DatahubUser>>(this.URL);
  }

  public getUnclaimedInvites(): Observable<Array<UserInvitation>> {
    return this.client.get<Array<UserInvitation>>(`${this.URL}/invite`);
  }

  public inviteNewAdmin(email: string): Observable<InvitationResult> {
    let httpResponse: Observable<InvitationResult>;
    httpResponse = this.client.post<any>(`${this.URL}/invite`, {
      email: email,
    });
    return httpResponse.pipe(
      map((response) => Object.assign(new InvitationResult(), response))
    );
  }

  public deleteAdmin(id: string): Observable<any> {
    return this.client.delete(`${this.URL}/${id}`);
  }

  public updateAdmin(admin: DatahubUser): Observable<any> {
    return this.client.put<any>(this.URL, admin);
  }

  public getResourceHealthAsAdmin(): Observable<Array<APIResourceHealth>> {
    return this.client.get<Array<APIResourceHealth>>(
      `${this.URL}/resource-health-admin`
    );
  }

  public getAdminStatistics(): Observable<AdminStatistics> {
    return this.client.get<AdminStatistics>(`${this.URL}/Statistics`);
  }
}
