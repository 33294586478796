import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page';
import './httpclient.extensions';
import { IPagedSortedFilteredService } from './ipaged-sorted-filtered.service';
import { IExtendedHttpClient, Ext } from './httpclient.extensions';
import { ManageUnclaimedInvitesSummary } from '../../data-consumer/models/manage-unclaimed-invites-summary';

@Injectable({
  providedIn: 'root'
})
export class ManageUnclaimedInvitesService implements IPagedSortedFilteredService<ManageUnclaimedInvitesSummary> {
  private URL = `${this.api}/api/Invitation/unclaimed-invites-paged`;

  constructor(private httpClient: HttpClient,
    @Inject(baseurl) private api: string) { }

  get(pageNumber = 1, pageSize = 10, sort = null, filter = null): Observable<Page<ManageUnclaimedInvitesSummary>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<FarmMappingSummary>(FarmMappingSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<ManageUnclaimedInvitesSummary>(
      this.httpClient, ManageUnclaimedInvitesSummary, this.URL, pageNumber, pageSize, sort, filter);
  }
}
