import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ObjectHelper } from '../../../../shared/helpers/object-helper';

@Component({
  selector: 'app-add-headers-dialog',
  templateUrl: './add-headers-dialog.component.html',
  styleUrls: ['./add-headers-dialog.component.css']
})
export class AddHeadersDialogComponent implements OnInit {


  headerKvps: Array<any> = [];

  constructor(
    public dialogReg: MatDialogRef<AddHeadersDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { isEditable: boolean, headers: any }
  ) {
    this.headerKvps = ObjectHelper.objectToKvps(data.headers);
  }
  ngOnInit() {

  }

  onCancel() {
    this.dialogReg.close(this.data.headers);
  }

  onOk() {
    this.dialogReg.close(ObjectHelper.kvpsToObject(this.headerKvps));
  }

  addHeader() {
    this.headerKvps.push({ key: null, value: null });
  }

  removeHeader(key: string) {
    this.headerKvps = this.headerKvps.filter(kvp => kvp.key !== key);
  }
}


