export class ResourceValidationResult {
    resourceId: string;
    name: string;
    isValid: boolean;
    callResponse: number;
    isFarmIdCallChecked: boolean;
    errors: Array<PropertyError>;
}

export class PropertyError {
    propertyName: string;
    message: string;
}