<div
  class="spinner-container"
  *ngIf="dataSource && dataSource.loading$ | async"
>
  <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<mat-table
  [dataSource]="dataSource"
  class="mat-elevation-z8"
  matSort
  [matSortActive]="initialSort.column"
  [matSortDirection]="initialSort.direction"
  matSortDisableClear
  class="material {{ lastColumnIcon ? 'lastIcon' : '' }} {{
    firstColumnIcon ? 'firstIcon' : ''
  }}"
>
  <ng-container *ngFor="let column of columns" [matColumnDef]="column.name" ]>
    <mat-header-cell *matHeaderCellDef>
      <span
        [mat-sort-header]="this.column.sort"
        [disabled]="this.column.sort === null"
        >{{ column.translationKey | translate }}</span
      >

      <div
        *ngIf="column.filter"
        [ngSwitch]="column.filter.type"
        class="header-bottom-wrapper"
      >
        <ng-template [ngSwitchCase]="'text'">
          <ng-container *ngTemplateOutlet="textTemplate; context: column">
          </ng-container>
        </ng-template>
        <ng-template [ngSwitchCase]="'select'">
          <ng-container *ngTemplateOutlet="selectTemplate; context: column">
          </ng-container>
        </ng-template>
        <ng-template [ngSwitchCase]="'date'">
          <ng-container *ngTemplateOutlet="dateTemplate; context: column">
          </ng-container>
        </ng-template>
        <ng-template [ngSwitchDefault]>
          <ng-container *ngTemplateOutlet="column.filter; context: column">
          </ng-container>
        </ng-template>
      </div>
    </mat-header-cell>
    <mat-cell *matCellDef="let row">
      <ng-container *ngTemplateOutlet="column.cellTemplate; context: row">
      </ng-container>
    </mat-cell>
  </ng-container>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

  <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
</mat-table>

<div class="table-footer">
  <button (click)="exportToCsv()" *ngIf="this.exportable" class="green">
    <i class="fas fa-file-export"></i>
    {{ "grid.export" | translate }}
  </button>
  <mat-paginator
    #paginator
    [length]="dataSource.totalCount$ | async"
    [pageSize]="10"
    showFirstLastButtons
  >
  </mat-paginator>
</div>

<ng-template
  #selectTemplate
  let-options="filter.options"
  let-filterKey="filter.filterKey"
>
  <mat-select
    [value]="-1"
    (selectionChange)="
      filterChanges$.next([{ key: filterKey, value: $event.value }])
    "
  >
    <mat-option [value]="-1">-- {{ "none" | translate }} --</mat-option>
    <mat-option *ngFor="let data of options" [value]="data.value">
      {{ data.translationKey | translate }}
    </mat-option>
  </mat-select>
</ng-template>

<ng-template
  #textTemplate
  let-translationKey="translationKey"
  let-filterKey="filter.filterKey"
  let-initialValue="filter.initialValue"
>
  <form novalidate class="full-width">
    <input
      matInput
      placeholder="Filter {{ translationKey | translate }}"
      type="text"
      (keyup)="
        filterChanges$.next([{ key: filterKey, value: $event.target.value }])
      "
      [value]="initialValue"
    />
  </form>
</ng-template>

<ng-template
  #dateTemplate
  let-translationKey="translationKey"
  let-filterFromKey="filter.filterFromKey"
  let-filterToKey="filter.filterToKey"
>
  <div class="date-filter">
    <input
      matInput
      hidden
      [satDatepicker]="picker"
      placeholder="Filter {{ translationKey | translate }}"
      (dateChange)="
        filterChanges$.next([
          { key: filterFromKey, value: $event.value.begin.toDateString() },
          { key: filterToKey, value: $event.value.end.toDateString() }
        ])
      "
    />
    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle>
  </div>
</ng-template>
