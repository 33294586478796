import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page';
import { DarStatusSummary } from '../../data-consumer/models/dar-status-summary';
import './httpclient.extensions';
import { IPagedSortedFilteredService } from './ipaged-sorted-filtered.service';
import { IExtendedHttpClient, Ext } from './httpclient.extensions';

@Injectable({
  providedIn: 'root'
})
export class ConsumerFarmResourceAccessService implements IPagedSortedFilteredService<DarStatusSummary> {
  private URL = `${this.api}/api/DarStatus/consumer`;

  constructor(private httpClient: HttpClient,
    @Inject(baseurl) private api: string) { }

  get(pageNumber = 1, pageSize = 10, sort = null,  filter = null): Observable<Page<DarStatusSummary>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<DarStatusSummary>(DarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<DarStatusSummary>(
      this.httpClient, DarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
  }
}
