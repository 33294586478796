import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import {
  APIResource,
  APIResourceTranslation,
} from "../../../../shared/models/apiResource";
import { FarmIdType } from "../../../models/farm-id-type";
import { FarmIdTypesService } from "../../../../core/services/farmIdType.service";
import { ConsentType } from "../../../models/consent-type";
import { Subscription } from "rxjs";
import { FarmIdGetType } from "../../../models/client-number-get-type";
import { TranslateService } from "@ngx-translate/core";
import { ResourceError } from "../../../models/resource-error";
import { ResourceValidationResult } from "../../../models/resource-validation-result";
import { TranslatableString } from "../../../../../modules/shared/models/language";

@Component({
  selector: "app-resource",
  templateUrl: "./resource.component.html",
  styleUrls: ["./resource.component.scss"],
})
export class ResourceComponent implements OnInit, OnDestroy {
  private _validationResult: ResourceValidationResult;
  @Input() set validationResult(value: ResourceValidationResult) {
    this._validationResult = value;
    this.initResourceError();
  }
  @Input() item: APIResource;
  @Input() isEditable: boolean;
  error = new ResourceError();
  consentOptions: Array<ConsentType>;
  farmIdTypes = Array<FarmIdType>();
  farmIdGetTypes: Array<FarmIdGetType>;
  resource: APIResource;
  farmIdTypesServiceSubscription: Subscription;
  maxRowSize: number;
  displayNameTranslations: Array<TranslatableString>;
  descriptionTranslations: Array<TranslatableString>;
  isDescriptionFocused: boolean;

  constructor(
    private farmIdTypesService: FarmIdTypesService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    this.consentOptions = this.initConsentTypes();
    this.resource = this.item;
    this.displayNameTranslations = this.resource.translations
      .filter((t) => t.language > 0)
      .map((t) => ({
        language: t.language,
        value: t.displayName,
      }));
    this.descriptionTranslations = this.resource.translations
      .filter((t) => t.language > 0)
      .map((t) => ({
        language: t.language,
        value: t.description,
      }));
    this.isDescriptionFocused = false;
    // this.sliceDecription();
    this.initFarmIdTypes();
    this.farmIdGetTypes = this.initFarmIdGetTypes();
  }

  initResourceError() {
    if (
      this._validationResult === null || this._validationResult === undefined ||
      this._validationResult.errors === null || this._validationResult.errors === undefined
    ) {
      return;
    }

    this.error.farmIdType = this.getErrorMessage("FarmIdTypeId");
    this.error.farmIdGetType = this.getErrorMessage("FarmIdGetType");
    this.error.requestParameter = this.getErrorMessage("RequestParameter");
    this.error.jsonObjectPath = this.getErrorMessage("JsonObjectPath");
    this.error.jsonFarmIdPath = this.getErrorMessage("JsonFarmIdPath");
    this.error.description = this.getErrorMessage("Description");
  }

  getErrorMessage(propertyName: string): string {
    const error = this._validationResult.errors.find(
      (e) => e.propertyName === propertyName
    );
    if (error === null || error === undefined) {
      return null;
    }
    return error.message;
  }

  onChangeFarmIdType() {
    this.error.farmIdType = null;
  }

  onChangeFarmIdGetType() {
    this.error.farmIdGetType = null;
    this.error.requestParameter = null;
    this.error.jsonObjectPath = null;
    this.error.jsonFarmIdPath = null;
  }

  onChangeRequestParameter() {
    this.error.requestParameter = null;
  }

  onChangeJsonObjectPath() {
    this.error.jsonObjectPath = null;
  }

  onChangeJsonFarmIdPath() {
    this.error.jsonFarmIdPath = null;
  }

  descriptionChanged(descriptions: Array<TranslatableString>) {
    descriptions.forEach(
      (d) =>
        (this.resource.translations.find(
          (t) => t.language === d.language
        ).description = d.value)
    );
  }

  displayNameChanged(displayNames: Array<TranslatableString>) {
    displayNames.forEach(
      (d) =>
        (this.resource.translations.find(
          (t) => t.language === d.language
        ).displayName = d.value)
    );
  }

  // onDescriptionFocus() {
  //   this.maxRowSize = 3;
  //   this.description = this.resource.description;
  //   this.error.description = null;
  // }

  // onDescriptionFocusOut() {
  //   this.maxRowSize = 1;
  //   this.resource.description = this.description;
  //   this.sliceDecription();
  // }

  // sliceDecription() {
  //   if (this.resource.description && this.resource.description.length > 25) {
  //     this.description = this.resource.description.slice(0, 25) + ' ...';
  //   }
  // }

  initFarmIdGetTypes() {
    const values = [];
    const keys = Object.values(FarmIdGetType).filter((key) =>
      isNaN(Number(FarmIdGetType[key]))
    );
    keys.forEach((x) => {
      const name = this.translate.instant(
        "manageApisPage.details.farmIdGetTypes." + FarmIdGetType[x]
      );
      const farmIdGetType: any = { index: x, name: name };
      values.push(farmIdGetType);
    });
    return values;
  }

  ngOnDestroy() {
    this.farmIdTypesServiceSubscription.unsubscribe();
  }

  private initFarmIdTypes() {
    this.farmIdTypesServiceSubscription = this.farmIdTypesService
      .getAll()
      .subscribe((response) => {
        this.farmIdTypes = response;
        if (this.resource.farmIdType) {
          this.resource.farmIdType = this.farmIdTypes.find(
            (x) => x.id === this.resource.farmIdType.id
          );
        }
      });
  }

  private initConsentTypes(): any[] {
    const consentTypeValues = [];
    const consentTypeKeys = Object.values(ConsentType).filter((key) =>
      isNaN(Number(ConsentType[key]))
    );
    consentTypeKeys.forEach((x) => {
      const name = ConsentType[x].split(/(?=[A-Z])/).join(" ");
      const consentType: any = { index: x, name: name };
      consentTypeValues.push(consentType);
    });
    return consentTypeValues;
  }

  changeConsent($event) {
      this.resetResourceWhenNoFarmerConsent($event);
  }

  private resetResourceWhenNoFarmerConsent(selectedConsentIndex: any) {
    if (ConsentType.ProviderAndFarmerRequired !== selectedConsentIndex) {
      this.resource.jsonFarmIdPath = null;
      this.resource.jsonObjectPath = null;
      this.resource.requestParameter = null;
      this.resource.farmIdGetType = null;
      this.resource.farmIdType = null;
      this.resource.translations.forEach((t) => {
        t.description = null;
      });
    }
  }

  get isFarmerConsent() {
    return (
      this.resource.consentType != null &&
      this.resource.consentType === ConsentType.ProviderAndFarmerRequired
    );
  }

  get isFarmIdGetTypeEmpty() {
    return (this.resource.farmIdGetType === null || this.resource.farmIdGetType === undefined);
  }

  get isRequestParameter() {
    return (
      !this.isFarmIdGetTypeEmpty &&
      this.resource.farmIdGetType === FarmIdGetType.requestParameter
    );
  }

  get isJsonPath() {
    return (
      !this.isFarmIdGetTypeEmpty &&
      this.resource.farmIdGetType === FarmIdGetType.jsonpath
    );
  }
}
