import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConsumerRequestCard } from "../models/consumer-request-card";
import { DataAccessRequestService } from "../../core/services/data-access-request.service";
import { Subscription } from "rxjs";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { InformativeFlowCard } from "../../shared/informative-flow-card/models/informative-flow-card";
import { UserService } from "../../core/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { ContextService } from "../../core/services/context.service";

@Component({
  selector: "app-manage-data-access",
  templateUrl: "./manage-data-access.component.html",
  styleUrls: ["./manage-data-access.component.scss"],
})
export class ManageDataAccessComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  consumers: Array<ConsumerRequestCard>;
  pending: Array<ConsumerRequestCard>;
  handled: Array<ConsumerRequestCard>;
  informativeFlowArray: Array<ConsumerRequestCard>;
  isFarmer: boolean = true;
  hasOpenedDialog: boolean = false;
  farmId: string;

    constructor(
    private dataAccessRequestService: DataAccessRequestService,
    private userService: UserService,
    private dialog: MatDialog,
    private translate: TranslateService,
    private contextService: ContextService
  ) {
    this.translate.onLangChange.subscribe((l: LangChangeEvent) =>
      this.initConsumerRequestCards()
    );
  }

  ngOnInit() {
    this.initConsumerRequestCards();
  }

  private initConsumerRequestCards() {
    const getDarsSubscription = this.dataAccessRequestService
      .getDataAccessRequestsOverview()
      .subscribe((results) => {
        this.consumers = results.filter((x) => x.informativeFlow === false);
        this.informativeFlowArray = results
          .map((card) => {
            if (card.informativeFlow === true) {
              const informativeFlowCard = new InformativeFlowCard();
              informativeFlowCard.consumerId = card.consumerId;
              informativeFlowCard.consumerName = card.consumerName;
              informativeFlowCard.description = card.description;
              informativeFlowCard.applicationUrl = card.applicationUrl;
              informativeFlowCard.applicationLogoUrl = card.applicationLogoUrl;
              informativeFlowCard.companyName = card.companyName;
              informativeFlowCard.informativeFlow = card.informativeFlow;

              return informativeFlowCard;
            } else {
              return null;
            }
          })
          .filter((card) => card !== null);
        this.pending = this.getPending();
        this.handled = this.getHandled();
      });
    this.subscription.add(getDarsSubscription);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  get haveConsumers() {
    return this.consumers && this.consumers.length > 0;
  }
  get haveNoConsumers() {
    return this.consumers && this.consumers.length == 0;
  }
  get havePending() {
    if (!this.consumers) {
      return false;
    }
    return this.consumers.some((x) => x.pending > 0);
  }
  get haveHandled() {
    if (!this.consumers) {
      return false;
    }
    return this.consumers.some((x) => x.pending === 0);
  }

  get haveInformativeFlow() {
    if (!this.informativeFlowArray || this.informativeFlowArray.length === 0) {
      return false;
    }
    return true;
  }

  private getPending() {
    if (!this.consumers) {
      return [];
    }
    return this.consumers.filter((x) => x.pending > 0);
  }
  private getHandled() {
    if (!this.consumers) {
      return [];
    }
    let t = this.consumers.filter((x) => x.pending === 0);
    return t;
  }
}
