import { Component, OnInit, OnDestroy } from "@angular/core";
import { Subscription } from "rxjs";
import { UserAuthService } from "../../core/user-auth.service";
import { UserRole } from "../models/datahubUser";

@Component({
  selector: "app-nav-menu",
  templateUrl: "./nav-menu.component.html",
  styleUrls: ["./nav-menu.component.scss"],
})
export class NavMenuComponent implements OnInit, OnDestroy {
  isExpanded = true;
  userRole: string;
  subscription$: Subscription;

  constructor(private auth: UserAuthService) {
    this.userRole = UserRole.Unknown;
    this.subscription$ = new Subscription();
  }

  ngOnInit() {
    this.subscription$.add(
      this.auth.notifyCurrentContextChanged().subscribe((context) => {
        this.userRole = context.roleName;
      })
    );
  }

  collapse() {
    this.isExpanded = false;
  }

  toggle() {
    this.isExpanded = !this.isExpanded;
  }

  ngOnDestroy(): void {
    this.subscription$.unsubscribe();
  }
}
