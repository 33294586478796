<div id="sliding-popup" class="sliding-popup-bottom">
  <!-- <div role="alertdialog" aria-labelledby="popup-text" class="alert-dialog">
    <div class="popup-content info eu-cookie-compliance-content">
      <div id="popup-text" class="eu-cookie-compliance-message">
        <p>{{'cookiebanner.title' | translate}}</p>
        <button type="button" class="find-more-button eu-cookie-compliance-more-button find-more-button-processed" (click)="CookieMoreInfo()">{{'cookiebanner.moreinfo' | translate}}</button>

        <div id="eu-cookie-compliance-categories" class="eu-cookie-compliance-categories">
          <div class="eu-cookie-compliance-category">
            <div>
              <input type="checkbox" name="cookie-categories" id="cookie-category-required" value="required" checked="" disabled="">
              <label for="cookie-category-required">{{'cookiebanner.necessary.title' | translate}}</label>
            </div>
            <div class="eu-cookie-compliance-category-description">{{'cookiebanner.necessary.description' | translate}}</div>
          </div>

          <div class="eu-cookie-compliance-category">
            <div>
              <input #cbCookieAnalytic type="checkbox" name="cookie-categories" id="cookie-category-analysis" value="analysis" (change)="onAnalysisChanged(cbCookieAnalytic.checked)">
              <label for="cookie-category-analysis">{{'cookiebanner.analytical.title' | translate}}</label>
            </div>
            <div class="eu-cookie-compliance-category-description">{{'cookiebanner.analytical.description' | translate}}</div>
          </div>


          <div class="eu-cookie-compliance-category">
            <div>
              <input #cbCookieMarketing type="checkbox" name="cookie-categories" id="cookie-category-marketing" value="marketing" (change)="onMarketingChanged(cbCookieMarketing.checked)">
              <label for="cookie-category-marketing">{{'cookiebanner.marketing.title' | translate}}</label>
            </div>
            <div class="eu-cookie-compliance-category-description">{{'cookiebanner.marketing.description' | translate}}</div>
          </div>

          <div class="eu-cookie-compliance-categories-buttons">
            <button type="button" class="eu-cookie-compliance-save-preferences-button" (click)="AcceptSelection()">{{'cookiebanner.acceptpreference' | translate}}</button>
          </div>
        </div>

        <div id="popup-buttons" class="eu-cookie-compliance-buttons eu-cookie-compliance-has-categories">
          <button type="button" class="agree-button eu-cookie-compliance-default-button" (click)="AcceptAll()">{{'cookiebanner.acceptall' | translate}}</button>
          <button type="button" class="eu-cookie-withdraw-button hidden">Toestemming intrekken</button>
        </div>
      </div>
    </div>
  </div> -->

  <div class="banner-info">
    <p>{{ "cookiebanner.title" | translate }}</p>
    <!-- Add extra information here, use correct semantic HTML -->
  </div>
  <ul class="banner-actions">
    <li>
      <button (click)="AcceptAll()" class="primary card-button">
        {{ "cookiebanner.acceptall" | translate }}
      </button>
    </li>
    <li>
      <button (click)="DenyAll()" class="secondary card-button">
        {{ "cookiebanner.declineall" | translate }}
      </button>
    </li>
    <li>
      <button (click)="openDialog()" class="secondary card-button">
        {{ "cookiebanner.preferences" | translate }}
      </button>
    </li>
  </ul>

  <dialog #dialog>
    <div class="cookie-preference-header">
      <p>{{ "cookiebanner.preferences" | translate }}</p>
      <button class="closeDialog close" (click)="closeDialog()"></button>
    </div>
    <ul class="cookie-preference-body">
      <li [className]="!CurrentSelection ? 'visible' : ''">
        <button (click)="changeSelection(0)" class="card-button">
          {{ "cookiebanner.necessary.title" | translate }}
        </button>
        <div
          [className]="
            !CurrentSelection
              ? 'visible cookie-bottom-information'
              : 'cookie-bottom-information'
          "
        >
          <p class="cookie-explanation">
            {{ "cookiebanner.necessary.description" | translate }}
          </p>
          <div class="cookie-select">
            <p>{{ "cookiebanner.necessary.subtitle" | translate }}</p>
            <p class="green">
              {{ "cookiebanner.necessary.status" | translate }}
            </p>
          </div>
        </div>
      </li>
      <li [className]="CurrentSelection === 1 ? 'visible' : ''">
        <button (click)="changeSelection(1)" class="card-button">
          {{ "cookiebanner.analytical.title" | translate }}
        </button>
        <div
          [className]="
            CurrentSelection === 1
              ? 'visible cookie-bottom-information'
              : 'cookie-bottom-information'
          "
        >
          <p class="cookie-explanation">
            {{ "cookiebanner.analytical.description" | translate }}
          </p>
          <div class="cookie-select">
            <p>Matomo Analytics</p>
            <mat-slide-toggle
              color="primary"
              [checked]="AnalysisChecked"
              (change)="onAnalysisChanged($event.checked)"
              #analysis
            ></mat-slide-toggle>
          </div>
        </div>
      </li>
      <li [className]="CurrentSelection === 2 ? 'visible' : ''">
        <button (click)="changeSelection(2)" class="card-button">
          {{ "cookiebanner.marketing.title" | translate }}
        </button>
        <div
          [className]="
            CurrentSelection === 2
              ? 'visible cookie-bottom-information'
              : 'cookie-bottom-information'
          "
        >
          <p class="cookie-explanation">
            {{ "cookiebanner.marketing.description" | translate }}
          </p>
          <div class="cookie-select">
            <p>{{ "cookiebanner.marketing.subtitle" | translate }}</p>
            <mat-slide-toggle
              color="primary"
              [checked]="MarketingChecked"
              (change)="onMarketingChanged($event.checked)"
              #marketing
            ></mat-slide-toggle>
          </div>
        </div>
      </li>
    </ul>

    <div class="cookie-preference-footer">
      <button (click)="AcceptSelection()" class="secondary card-button">
        {{ "cookiebanner.acceptpreference" | translate }}
      </button>
      <a href="https://djustconnect.be/nl/cookieverklaring" target="_blank">
        {{ "cookiebanner.moreinfo" | translate }}
      </a>
    </div>
  </dialog>
</div>
