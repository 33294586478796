import {
  Component,
  OnInit,
  OnDestroy,
  Injectable,
  Inject,
} from "@angular/core";
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { UserService } from "../../../core/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../dialog/notification-dialog/notification-dialog.component";
import { InvitationService } from "../.../../../../../../../src/app/modules/core/services/invitation.service";
import { HttpClient, HttpXhrBackend } from "@angular/common/http";
import { baseurl } from "../../../../../../src/app/baseSettings";

@Component({
  selector: "app-register-farmer",
  templateUrl: "./register-farmer.component.html",
  styleUrls: ["./register-farmer.component.scss"],
})
@Injectable()
export class RegisterFarmerComponent implements OnInit, OnDestroy {
  farmerSignUpForm: UntypedFormGroup;
  subscription = new Subscription();
  httpClient = new HttpClient(
    new HttpXhrBackend({
      build: () => new XMLHttpRequest(),
    })
  );
  private URL = `${this.baseUrl}/api/user`;

  constructor(
    formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private dialog: MatDialog,
    private invitationService: InvitationService,
    @Inject(baseurl) private baseUrl: string
  ) {
    this.farmerSignUpForm = formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      privacy: [false, [Validators.requiredTrue]],
      marketing: [false],
    });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  toggle(formControl) {
    formControl.setValue(!formControl.value);
  }

  signUpAsFarmer(formValues) {
    this.httpClient
      .post(`${this.URL}/farmer-sign-up`, {
        email: formValues.email,
      })
      .subscribe(() => {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "registration.user.registered.title",
            text: "registration.user.registered.text",
            link: "info@djustconnect.be",
            linkText: "info@djustconnect.be",
            mailLink: true,
          },
        });
      });
    return;
    // With the subscriptions on the bottom is something wrong
    this.invitationService.signUpAsFarmerObservable().subscribe(() => {});
    this.subscription.add(
      this.userService.farmerSignUp(formValues.email).subscribe(() => {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "registration.user.registered.title",
            text: "registration.user.registered.text",
            link: "info@djustconnect.be",
            linkText: "info@djustconnect.be",
            mailLink: true,
          },
        });
      })
    );
  }
}
