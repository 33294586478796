<h1>{{ "navigation.viewFarmAccess" | translate }}</h1>
<p>{{ "info.farmAccessAdmin" | translate }}</p>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="farmAccessService"
  queryOnLangChange="true"
>
</app-base-remote-datatable>

<ng-template #partnerNameCell let-partnerName="partnerName">
  {{ partnerName }}
</ng-template>

<ng-template #farmNumberCell let-farmNumber="farmNumber">
  {{ farmNumber }}
</ng-template>

<ng-template #statusCell let-status="status">
  {{ status | FarmMappingStatusTranslation | translate }}
</ng-template>
