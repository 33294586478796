import {
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewEncapsulation,
  Inject,
  Injectable,
} from "@angular/core";
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { forkJoin, of, Subscription } from "rxjs";
import { switchMap } from "rxjs/operators";
import { FarmDetailsService } from "../../../core/services/farm-details.service";
import { FarmService } from "../../../core/services/farm.service";
import { FarmIdTypesService } from "../../../core/services/farmIdType.service";
import { FarmIdType } from "../../../data-provider-module/models/farm-id-type";
import { ConfirmationDialogComponent } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { NotificationDialogComponent } from "../../../shared/dialog/notification-dialog/notification-dialog.component";
import { DatahubUser } from "../../../shared/models/datahubUser";
import { InvitationResult } from "../../../shared/models/invitation-result";
import { UserInvitation } from "../../../shared/models/userInvitation";
import { FarmDetailsResult } from "../../models/farmDetailsResult";
import { FarmDTO } from "../../models/farmDTO";
import { FarmMappingDTO } from "../../models/farmMappingDTO";

@Component({
  selector: "app-edit-farmmapping",
  templateUrl: "./edit-farmmapping.component.html",
  styleUrls: ["./edit-farmmapping.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class EditFarmMappingComponent implements OnInit, OnDestroy {
  farmDetailsResult: FarmDetailsResult;
  newKBO: boolean = true;
  readonly: boolean;
  unsavedNewKBO: boolean = false;
  users = Array<DatahubUser>();
  mappings = Array<FarmMappingDTO>();
  oldMappings = Array<FarmMappingDTO>();
  farmMappingForm: UntypedFormGroup;
  subscription = new Subscription();
  farmIdTypes = Array<FarmIdType>();
  showRemoveButton: boolean[] = [];
  farmId: string;
  invitees = Array<UserInvitation>();
  isExistingFarm: boolean = false;
  link: string;
  private editURL = `${window.location.origin}/farmmapping/edit-farmmapping/`;

  constructor(
    public formBuilder: UntypedFormBuilder,
    public detailsService: FarmDetailsService,
    public farmService: FarmService,
    public typesService: FarmIdTypesService,
    private ref: ChangeDetectorRef,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private router: Router
  ) {}

  ngOnInit() {
    this.getTypes();
    this.initFarmDetailsResult();
    this.farmMappingForm = this.formBuilder.group({
      farmId: [{ value: this.farmId, disabled: true }],
      mappings: this.formBuilder.array(
        this.mappings.map((mapping) =>
          this.formBuilder.group({
            farmIdTypeId: [mapping.farmIdTypeId],
            number: [mapping.number],
          })
        )
      ),
    });
    this.setValidatorsForNumber(this.farmMappingForm);
  }

  private sortMappings() {
    this.mappings.sort((a, b) => {
      const aIsKBO =
        a.farmIdTypeId.toLowerCase() === "4c17a3f2-c03d-4d65-8440-3a896b245753";
      const bIsKBO =
        b.farmIdTypeId.toLowerCase() === "4c17a3f2-c03d-4d65-8440-3a896b245753";
      if (aIsKBO && !bIsKBO) {
        return -1;
      } else if (!aIsKBO && bIsKBO) {
        return 1;
      } else {
        return a.farmIdTypeId.localeCompare(b.farmIdTypeId);
      }
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getTypes() {
    this.subscription.add(
      this.typesService
        .getAll()
        .subscribe((types) => (this.farmIdTypes = types))
    );
  }

  private initFarmDetailsResult() {
    const farmDetailsResultAsAdmin$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.farmId = params.get("id");
        if (this.farmId) {
          this.newKBO = false;
          let details = this.detailsService.getDetailsByFarmId(this.farmId);
          return details;
        }
        let newGuid = (<any>crypto).randomUUID();
        if (this.newKBO) {
          this.unsavedNewKBO = true;
          this.detailsService.getDetailsByFarmId(newGuid).subscribe((x) => {
            if (x) {
              //should unlikely GUID collision happen, roll again
              newGuid = (<any>crypto).randomUUID();
            } else {
              this.farmId = newGuid;
              this.farmDetailsResult = new FarmDetailsResult();
              this.farmDetailsResult.farmId = this.farmId;
              this.addMapping();
              this.farmMappingForm.controls.farmId.setValue(
                this.farmDetailsResult.farmId
              );
              return of(this.farmDetailsResult);
            }
          });
        }

        return of(new FarmDetailsResult());
      })
    );

    this.subscription.add(
      farmDetailsResultAsAdmin$.subscribe(
        (farmDetailsResult: FarmDetailsResult) => {
          if (farmDetailsResult) {
            this.farmDetailsResult = farmDetailsResult;
          }
          if (farmDetailsResult && farmDetailsResult.mappings) {
            this.mappings = farmDetailsResult.mappings;
            this.sortMappings();
            this.oldMappings = this.mappings;
          } else {
            let details = this.detailsService.getDetailsByFarmId(this.farmId);
          }
          this.showRemoveButton = [];
          this.mappings.forEach((mapping) => {
            if (
              mapping.farmIdTypeId.toLowerCase() ===
              "4C17A3F2-C03D-4D65-8440-3A896B245753".toLowerCase()
            ) {
              this.showRemoveButton.push(false);
            } else {
              this.showRemoveButton.push(true);
            }
          });
          this.afterFarmDetailsResultInit();
        }
      )
    );
  }

  private afterFarmDetailsResultInit() {
    if (!this.newKBO) {
      this.getInvitations();
      this.toggleEdit();
      this.ref.detectChanges();
    }
    this.initMappingsAndUsers();
  }

  private initMappingsAndUsers() {
    if (!this.newKBO) {
      this.getUsers();
      this.farmMappingForm.controls.farmId.setValue(this.farmId);
      this.farmMappingForm.controls.farmId.disabled;

      const mappingsControl = this.farmMappingForm.get(
        "mappings"
      ) as UntypedFormArray;
      mappingsControl.clear();
      this.mappings.forEach((mapping) => {
        const mappingGroup = this.formBuilder.group(
          {
            farmIdTypeId: [mapping.farmIdTypeId],
            number: [mapping.number],
            id: [mapping.id],
            farmId: [mapping.farmId],
            isNew: [mapping.isNew ?? false],
          },
          { updateOn: "blur" }
        );
        mappingGroup.get("farmIdTypeId").disable();
        mappingGroup.get("number").disable();
        mappingGroup.get("id").disable();
        mappingGroup.get("farmId").disable();
        mappingGroup.get("isNew").disable();
        mappingsControl.push(mappingGroup);
      });
      if (this.farmMappingForm.get("mappings")) {
        const mappingsArray = this.farmMappingForm.get(
          "mappings"
        ) as UntypedFormArray;
        for (let i = 0; i < mappingsArray.length; i++) {
          const mappingGroup = mappingsArray.at(i) as UntypedFormGroup;
          this.setValidatorsForNumber(mappingGroup);
        }
      }
    }
  }

  public async upsertFarmDetailsResult(farm: FarmDetailsResult) {
    const farmDTO = new FarmDTO();
    farmDTO.id = this.farmDetailsResult.farmId;
    if (!this.resultHasOneKBOMapping(farm)) {
      this.dialog.open(NotificationDialogComponent, {
        width: "fit-content",
        data: {
          title: "editFarmmapping.noKbo.title",
          text: "editFarmmapping.noKbo.text",
        },
      });
      return null;
    }
    const createRequests = [];
    if (!this.newKBO) {
      //update functionality
      //this loops over mappings on the form, if it isn't new, it checks if type is KBO to update the farm instead of farmmapping,
      //if it isn't a KBO, updates the farmmapping. If it's new, create a new farmmapping
      farm.mappings.forEach((mapping) => {
        if (!mapping.isNew) {
          if (
            mapping.farmIdTypeId.toLocaleLowerCase() ==
            "4C17A3F2-C03D-4D65-8440-3A896B245753".toLocaleLowerCase()
          ) {
            farmDTO.kbo = this.farmDetailsResult.kbo;
            this.subscription.add(this.farmService.update(farmDTO).subscribe());
          } else {
            this.subscription.add(
              this.detailsService.update(mapping).subscribe()
            );
          }
        } else {
          createRequests.push(
            this.detailsService
              .create(mapping)
              .subscribe({ error: (error) => console.log(error) })
          );
        }
      });
      // insert functionality
    } else {
      this.unsavedNewKBO = false;
      farm.mappings.forEach((mapping: FarmMappingDTO) => {
        if (
          mapping.farmIdTypeId.toLocaleLowerCase() ==
          "4C17A3F2-C03D-4D65-8440-3A896B245753".toLocaleLowerCase()
        ) {
          //Check if kbo is valid. Regular validation fails to activate in this specific scenario, so I chose a notification here instead (Luka).
          if (mapping.number.length !== 10) {
            this.dialog.open(NotificationDialogComponent, {
              width: "fit-content",
              data: {
                title: "editFarmmapping.invalidKbo.title",
                text: "editFarmmapping.invalidKbo.text",
              },
            });
            return null;
          }
          //check if kbo is already in database, link to existing if it is the case or return to edit new if it isn't.
          this.farmService
            .getFarmByKBO(mapping.number)
            .subscribe((existingFarm) => {
              if (existingFarm) {
                this.isExistingFarm = true;
                this.link = `${this.editURL}${existingFarm.id}`;
                this.dialog.open(NotificationDialogComponent, {
                  width: "fit-content",
                  data: {
                    title: "editFarmmapping.duplicateKbo.title",
                    text: "editFarmmapping.duplicateKbo.text",
                    link: this.link,
                    linkText: "editFarmmapping.duplicateKbo.linkText",
                  },
                });
                return null;
              } else {
                farmDTO.kbo = mapping.number;
                this.subscription.add(
                  this.farmService.create(farmDTO).subscribe(() => {})
                );
              }
            });
        } else if (!this.isExistingFarm) {
          let newFarmMappingDTO: FarmMappingDTO = mapping;
          this.subscription.add(
            this.detailsService.create(newFarmMappingDTO).subscribe(() => {})
          );
        }
      });
      this.subscription.add(
        forkJoin([createRequests]).subscribe(() => {
          // Call getDetailsByFarmId here to fetch the updated data from the backend
          this.detailsService
            .getDetailsByFarmId(this.farmId)
            .subscribe((updatedData) => {
              this.farmDetailsResult = updatedData;
            });
        })
      );
    }
  }

  setValidatorsForNumber(mappingGroup: UntypedFormGroup) {
    const farmIdTypeIdControl = mappingGroup.get("farmIdTypeId");
    const numberControl = mappingGroup.get("number");
    if (farmIdTypeIdControl && numberControl) {
      farmIdTypeIdControl.valueChanges.subscribe((value) => {
        numberControl.clearAsyncValidators();
        switch (value) {
          case "2614204B-2EB4-40BF-902A-2FAD649C320B".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(28),
              Validators.minLength(28),
            ]);
            break;
          case "324A23EB-B4BC-4DE1-A01B-0E478AFAC252".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.pattern(/^(BE\d{8}(-\d{4})?|500\d{5})$/),
            ]);
            break;
          case "33F8D764-0F13-412C-905E-848F172D89C1".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(9),
              Validators.minLength(9),
            ]);
            break;
          case "4C17A3F2-C03D-4D65-8440-3A896B245753".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(10),
              Validators.minLength(10),
            ]);
            break;
          case "D55FE787-6EA0-46E8-9F00-D9E5E86BAD2B".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(10),
              Validators.minLength(10),
            ]);
            break;
          case "5664A632-31B8-46A7-95F5-8094453F649C".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(10),
              Validators.minLength(10),
            ]);
            break;
          case "DD03E71C-D114-4CCE-A5FE-6843F1FC8878".toLocaleLowerCase():
            numberControl.setValidators([
              Validators.required,
              Validators.maxLength(15),
              Validators.minLength(15),
            ]);
            break;
          default:
        }
        numberControl.updateValueAndValidity();
        this.ref.detectChanges();
      });
    }
  }

  //#region FarmMappings
  totalNewMappings = 0;
  public addMapping() {
    const newMapping = new FarmMappingDTO();
    newMapping.farmId = this.farmId;
    newMapping.farmIdTypeId = "";
    newMapping.number = "0";
    newMapping.isNew = true;

    if (this.mappings.length === 0) {
      newMapping.farmIdTypeId = "4c17a3f2-c03d-4d65-8440-3a896b245753";
    }
    this.mappings.push(newMapping);

    this.farmDetailsResult.mappings = this.mappings;
    const mappingsControl = this.farmMappingForm.get(
      "mappings"
    ) as UntypedFormArray;
    const newMappingGroup = this.formBuilder.group(
      {
        farmIdTypeId: [newMapping.farmIdTypeId],
        number: [newMapping.number],
        id: [newMapping.id],
        farmId: [newMapping.farmId],
        isNew: [newMapping.isNew],
      },
      { updateOn: "blur" }
    );
    this.showRemoveButton.push(true);
    mappingsControl.push(newMappingGroup);
    this.setValidatorsForNumber(newMappingGroup);
    this.ref.detectChanges();
    this.totalNewMappings++;
  }

  private resultHasOneKBOMapping(farm: FarmDetailsResult) {
    let kboMapping: boolean = false;
    let numberOfKBOs: number = 0;
    farm.mappings.forEach((mapping: FarmMappingDTO) => {
      if (mapping.farmIdTypeId === "4c17a3f2-c03d-4d65-8440-3a896b245753") {
        numberOfKBOs++;
      }
    });
    if (numberOfKBOs === 1) {
      kboMapping = true;
    }
    return kboMapping;
  }

  getControls() {
    return (this.farmMappingForm.get("mappings") as UntypedFormArray).controls;
  }

  public toggleEdit() {
    if (this.readonly) {
      this.readonly = false;
      this.farmMappingForm.controls.mappings.enable();
      if (this.newKBO) {
        this.setFarmId();
      }
    } else {
      this.readonly = true;
      this.farmMappingForm.controls.mappings.disable();
      // Remove new mappings if they are not saved
      const mappingsArray = this.farmMappingForm.get(
        "mappings"
      ) as UntypedFormArray;
      for (let i = 0; i < this.totalNewMappings; i++) {
        mappingsArray.removeAt(mappingsArray.length - 1);
      }
      this.totalNewMappings = 0;
    }
  }

  private setFarmId() {
    this.farmMappingForm.controls.farmId.setValue(
      this.farmDetailsResult.farmId
    );
  }

  public checkIfKBO(value: string, index: number) {
    if (value === "4c17a3f2-c03d-4d65-8440-3a896b245753") {
      this.showRemoveButton[index] = false;
    } else {
      this.showRemoveButton[index] = true;
    }
  }

  public removeMapping(index, mapping) {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "fit-content",
      data: {
        title: "editFarmmapping.deleteMapping.title",
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || !mapping) return;

      // Check if the mapping has an id
      const hasId = !!mapping.value.id;
      if (hasId) {
        this.subscription.add(
          this.detailsService.delete(mapping.value.id).subscribe(() => {
            this.deleteMappingFromForm(index);
          })
        );
      } else {
        // Otherwise only delete locally
        this.deleteMappingFromForm(index);
      }
    });
  }

  private deleteMappingFromForm(index: number) {
    this.mappings.splice(index, 1);
    this.farmDetailsResult.mappings = this.mappings;
    const mappingsControl = this.farmMappingForm.get(
      "mappings"
    ) as UntypedFormArray;
    mappingsControl.removeAt(index);
  }

  //#endregion
  //#region Users
  private getUsers() {
    if (this.farmDetailsResult && this.farmDetailsResult.users) {
      this.users = this.farmDetailsResult.users;
    }
  }

  public removeUser(user: DatahubUser) {
    if (this.farmDetailsResult.farmId) {
      this.subscription.add(
        this.farmService
          .deleteUserFromFarmId(user.id, this.farmDetailsResult.farmId)
          .subscribe(() => {
            this.deleteUser(user);
          })
      );
    } else {
      this.deleteUser(user);
    }
  }

  private deleteUser(user: DatahubUser) {
    const index = this.users.indexOf(user);
    this.users.splice(index, 1);
    this.users = [...this.users];
  }

  viewAccess() {
    this.router.navigate([`farm-resource-access/`], {queryParams: {kbo: this.farmDetailsResult.kbo}});
  }

  //#endregion
  //#region Invitations
  public inviteUser(invite: any) {
    this.subscription.add(
      this.farmService
        .inviteNewFarmerToFarm(invite.email, this.farmDetailsResult.farmId)
        .subscribe({
          next: (response: InvitationResult) => {
            if (response.isExistingUser) {
              this.dialog.open(NotificationDialogComponent, {
                width: "fit-content",
                data: {
                  title: "manageUsers.skipInvite.title",
                  text: "manageUsers.skipInvite.text",
                  textParams: { email: invite.email },
                },
              });
              this.subscription.add(
                this.dialog.afterAllClosed.subscribe(() =>
                  this.subscription.add(
                    this.farmService
                      .getAllFarmers(this.farmDetailsResult.farmId)
                      .subscribe((users) => (this.users = users))
                  )
                )
              );
            }
          },
          complete: () => {
            this.getInvitations();
          },
        })
    );
  }

  private getInvitations() {
    if (this.farmDetailsResult) {
      this.subscription.add(
        this.farmService
          .getUnclaimedInvitesForFarmId(this.farmDetailsResult.farmId)
          .subscribe((invites) => (this.invitees = invites))
      );
    }
  }
  //#endregion
}
