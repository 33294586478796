<h1>{{ "info.clearingHouse" | translate }}</h1>
<!--p>{{ "info.widgetExplanation" | translate }}</p-->
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="clearingHouseService"
>
</app-base-remote-datatable>
<br>
<h1>{{ "info.clearingHousePartnerSummary" | translate }}</h1>
<app-base-remote-datatable
  [columns]="columnsPS"
  [initialSort]="initialSortPS"
  [service]="partnerSummaryService"
>
</app-base-remote-datatable>

<ng-template #consumerNameCell let-consumerName="consumerName">
  {{ consumerName }}
</ng-template>

<ng-template #providerNameCell let-providerName="providerName">
  {{ providerName }}
</ng-template>

<ng-template #resourceNameCell let-resourceName="resourceName">
  {{ resourceName }}
</ng-template>

<ng-template #farmNumberCell let-farmNumber="farmNumber">
  {{ farmNumber }}
</ng-template>

<ng-template #decisionCell let-decision="decision">
  {{ decision | AccessDecisionTranslation | translate }}
</ng-template>

<ng-template #timeStampCell let-timeStamp="timeStamp">
  {{ timeStamp | FromUtcToLocalDate | date : "dd/MM/yyyy HH:mm:ss" }}
</ng-template>

<ng-template #responseSizeCell let-responseSize="responseSize">
  {{ responseSize }} bytes
</ng-template>

<ng-template #durationMsCell let-durationMs="durationMs">
  {{ durationMs }} ms
</ng-template>

<ng-template #callerIpCell let-callerIp="callerIp">
  {{ callerIp }}
</ng-template>

<ng-template #periodCell let-period="period">
  {{ period }}
</ng-template>

<ng-template #numberOfCallsCell let-numberOfCalls="numberOfCalls">
  {{ numberOfCalls }}
</ng-template>

<ng-template #volumeCell let-volume="volume">
  {{ volume }} bytes
</ng-template>
