import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-other-email-dialog',
  templateUrl: './other-email-dialog.component.html',
  styleUrls: ['./other-email-dialog.component.css']
})
export class OtherEmailDialogComponent implements OnInit {

  currentEmail: string;
  newEmail: string;  
  constructor(public dialogReg: MatDialogRef<OtherEmailDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) {
      this.currentEmail = data.currentEmail;
      this.newEmail = data.newEmail;
     }

  ngOnInit() {
  }

  onAccept() {
    this.dialogReg.close(this.newEmail);
  }

  onDecline() {
    this.dialogReg.close(this.currentEmail);
  }

}
