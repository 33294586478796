<p></p>
<ol class="breadcrumb">
  <li
    *ngFor="let breadcrumb of breadcrumbs$ | async; last as isLast"
    class="breadcrumb-item"
    [ngClass]="{ active: isLast }"
    aria-current="page"
  >
    <a
      *ngIf="!isLast; else lastRoute"
      [routerLink]="[breadcrumb.url]"
      routerLinkActive="active"
    >
      {{ breadcrumb.label | translate }}
    </a>
    <ng-template #lastRoute>
      <p>
        {{ breadcrumb.label | translate }}
      </p>
    </ng-template>
  </li>
</ol>
