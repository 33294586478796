<h1>{{ "pushEvents.title" | translate }}</h1>
<p class="information">{{ "info.consumerEventTypes" | translate }}</p>
<form [formGroup]="form" (ngSubmit)="save(form.getRawValue())" novalidate>
  <div class="form-part">
    <label for="active" class="checkbox"
      >{{ "pushEvents.consumer.activatePushEvents" | translate }}
      <input
        type="checkbox"
        name="active"
        id="active"
        (change)="toggle(form.controls.active.value)"
        formControlName="active"
        autocomplete="off"
      />
      <svg
        class="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#1d1d1b"
          rx="3"
        />
        <path
          class="tick"
          stroke="#067C4A"
          fill="none"
          stroke-linecap="round"
          stroke-width="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </label>
  </div>
  <div class="form-part">
    <label for="endpoint">{{ "global.url" | translate }}</label>
    <input
      type="text"
      name="endpoint"
      id="endpoint"
      formControlName="endpoint"
      autocomplete="off"
      placeholder="{{ 'global.url' | translate }}"
    />
    <p class="small" *ngIf="status">
      {{ "pushEvents.consumer.currentStatus" | translate }} {{ status }}
    </p>
  </div>

  <button
    class="green"
    type="submit"
    [disabled]="!form.valid || timeLeft !== 0"
  >
    {{ timeLeft != 0 ? timeLeft : ("actions.save" | translate) }}
  </button>
</form>
