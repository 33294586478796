import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-footer-icon',
  templateUrl: './footer-icon.component.html',
  styleUrls: ['./footer-icon.component.css']
})
export class FooterIconComponent implements OnInit {
  constructor() {}
  @Input()
  iconName: string;
  @Input()
  link: string;
  @Input()
  text: string;

  ngOnInit() {}

  goToLink() {
    window.open(this.link, '_blank');
  }
}
