import { Component, OnInit, Input } from "@angular/core";
import { Api } from "../../../models/api";
import { ApiCredentials } from "../../../models/api-credentials";
import { EventType } from "../../../models/event-type";

@Component({
  selector: "app-credentials",
  templateUrl: "./credentials.component.html",
  styleUrls: ["./credentials.component.scss"],
})
export class CredentialsComponent implements OnInit {
  @Input() api: Api | EventType;
  @Input() showLabel: boolean;
  selectedElement: number = 0;

  constructor() {}

  ngOnInit() {}

  onAuthChange(value: Event) {
    this.selectedElement = parseInt(
      (value.target as HTMLSelectElement).value,
      10
    );
    if (!this.selectedElement) {
      this.api.credentials = undefined;
      this.api.useOAuth = false;
    } else {
      this.api.credentials = new ApiCredentials();
      this.api.useOAuth = true;
    }
  }
}
