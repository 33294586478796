<h2 mat-dialog-title>{{ "browser.incompatible.title" | translate }}</h2>

<mat-dialog-content>
  <p>{{ "browser.incompatible.text" | translate }}</p>
  <ul>
    <li>
      Google Chrome (<a href="https://www.google.com/chrome"
        >https://www.google.com/chrome</a
      >)
    </li>
    <li>
      Mozilla Firefox (<a href="https://www.mozilla.org/en-US/firefox/"
        >https://www.mozilla.org/en-US/firefox/</a
      >)
    </li>
    <li>
      Microsoft Edge (<a href="https://www.microsoft.com/en-us/edge"
        >https://www.microsoft.com/en-us/edge</a
      >)
    </li>
    <li>Opera (<a href="https://www.opera.com">https://www.opera.com</a>)</li>
    <li>
      Safari (<a href="https://www.apple.com/safari/"
        >https://www.apple.com/safari/</a
      >)
    </li>
  </ul>
</mat-dialog-content>

<mat-dialog-actions>
  <button class="green" (click)="onClose()">
    {{ "actions.close" | translate }}
  </button>
</mat-dialog-actions>
