import { Component, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-add-farmids",
  templateUrl: "./add-farmids.component.html",
  styleUrls: ["./add-farmids.component.css"],
})
export class AddFarmidsComponent implements OnInit {
  form: UntypedFormGroup;
  private delimiter = ",";

  constructor(
    public dialogRef: MatDialogRef<AddFarmidsComponent>,
    formBuilder: UntypedFormBuilder
  ) {
    this.form = formBuilder.group({
      data: ["", [Validators.required]],
    });
  }

  ngOnInit() {}

  onCancel() {
    this.dialogRef.close();
  }

  onAdd(data: string) {
    this.dialogRef.close(data.split(this.delimiter).map((f) => f.trim()));
  }
}
