import { Component, Input, OnInit } from "@angular/core";
import { MsalService } from "@azure/msal-angular";
import { Router } from "@angular/router";

@Component({
  selector: "app-not-registered-start-page",
  templateUrl: "./not-registered-start-page.component.html",
  styleUrls: ["./not-registered-start-page.component.css"],
})
export class NotRegisteredStartPageComponent implements OnInit {
  
  @Input()
  firstName: string;
  @Input()
  lastName: string;
  @Input()
  loggedInWithKbo: boolean;

  constructor(
    private msalService: MsalService,
    private router: Router
  ) {}

  ngOnInit() {
    
  }

  gotoRegisterPage(){
    //if (this.loggedInWithKbo){ //without logout
    //  this.router.navigate(['/farmer-signup']);
    //} else {
      const session = JSON.parse(sessionStorage.getItem("appConfig"));
      const postLogoutRedirectUri = session["msal"]["base"]["auth"] ? session["msal"]["base"]["auth"]["postLogoutRedirectUri"] : "/";
      this.msalService.logoutRedirect({ postLogoutRedirectUri: `${postLogoutRedirectUri}/farmer-signup`});
    //}
  }
}
