<div class="part">
  <h1>
    {{ "info.dataAccessRequests" | translate }}
  </h1>
  <p *ngIf="haveConsumers">{{ "info.resourceAccessRequestCards" | translate }}</p>
  <ul *ngIf="haveConsumers">
    <li>
      {{ "resourceAccessRequestPage.list.firstItem" | translate }}
    </li>
    <li>
      {{ "resourceAccessRequestPage.list.secondItem" | translate }}
    </li>
    <li>
      {{ "resourceAccessRequestPage.list.thirdItem" | translate }}
    </li>
    <li>
      {{ "resourceAccessRequestPage.list.fourthItem" | translate }}
    </li>
  </ul>
  <p 
    *ngIf="haveNoConsumers"
    [innerHTML]="'resourceAccessRequestPage.noData' | translate"
  >
  </p>
</div>

<div class="part" *ngIf="havePending">
  <h2>{{ "info.dataAccessRequestsOpen" | translate }}</h2>
  <div class="part-container">
    <app-resource-requests-card
      [data]="pending[i]"
      *ngFor="let index of pending; let i = index"
      (click)="goToEdit(pending[i].consumerId)"
    >
    </app-resource-requests-card>
  </div>
</div>

<div class="part" *ngIf="haveHandled">
  <h2>{{ "info.dataAccessRequestsHandled" | translate }}</h2>
  <div class="part-container">
    <app-resource-requests-card
      [data]="handled[i]"
      *ngFor="let index of handled; let i = index"
      (click)="goToEdit(handled[i].consumerId)"
    >
    </app-resource-requests-card>
  </div>
</div>

<div class="part" *ngIf="haveInformativeFlow">
  <h2>{{ "info.informativeFlow" | translate }}</h2>
  <div class="part-container">
    <app-resource-requests-card
      [data]="informativeFlowArray[i]"
      *ngFor="let index of informativeFlowArray; let i = index"
      (click)="goToEdit(informativeFlowArray[i].consumerId)"
    >
    </app-resource-requests-card>
  </div>
</div>
