import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  TemplateRef,
} from "@angular/core";
import { Subscription, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";
import { FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { ConfirmationDialogComponent } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { ResourceAccessRequestService } from "../../../core/services/resourceAccessRequest.service";
import { AccessRequestStatus } from "../../../shared/models/access-request-status";
import { ResourceRequestsCard } from "../../models/resource-requests-card";
import { ResourceAccessRequestDTO } from "../../../shared/models/resourceAccessRequestDTO";
import { DatahubPartner } from "../../../admin/models/datahubPartner";
import { PartnerService } from "../../../core/services/partner.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Language } from "../../../shared/models/language";

@Component({
  selector: "app-provider-rars",
  templateUrl: "./provider-rars.component.html",
  styleUrls: ["./provider-rars.component.scss"],
})
export class ProviderRarsComponent implements OnInit, OnDestroy {
  @ViewChild("apiDescriptionTranslationTmpl", { static: true }) apiDescriptionTranslationTmpl: TemplateRef<any>;
  @ViewChild("externalDocumentationUrlTmpl", { static: true }) externalDocumentationUrlTmpl: TemplateRef<any>;
  @ViewChild("resourceTranslationTmpl", { static: true }) resourceTranslationTmpl: TemplateRef<any>;
  @ViewChild("dateTmpl", { static: true }) dateTmpl: TemplateRef<any>;
  
  requestsPendingArray: ResourceAccessRequestDTO[];
  requestsHandledArray: ResourceAccessRequestDTO[];
  columns = [];
  consumer: ResourceRequestsCard;
  consumerAccessId: string;
  partnerId: string;
  partner: DatahubPartner;
  subscription = new Subscription();
  baseurl: string;
  expirationDate: Date;
  currentLanguage: Language;
  rarStatusForm: FormGroup = this.formBuilder.group({
    requestsPending: this.formBuilder.array([]),
    requestsHandled: this.formBuilder.array([])
  });
  informativeFlow: boolean;

  constructor(
    private rarService: ResourceAccessRequestService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private partnerService: PartnerService,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() {
    this.translate.currentLang
      ? (this.currentLanguage = Language[this.translate.currentLang])
      : (this.currentLanguage = Language.nl);
    await this.initializeComponent(this.currentLanguage);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = Language[event.lang];
      this.initializeComponent(this.currentLanguage);
    });
  }

  async initializeComponent(currentLanguage) {
    const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.baseurl = appConfig.DatapartnerLogoBaseUrl;
    const consumer$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.consumerAccessId = params.get("id");
        if (this.consumerAccessId !== null) {
          return this.rarService.getResourceAccessRequestsByConsumerAccessId(
            this.consumerAccessId
          );
        }
        return of(new Array<ResourceAccessRequestDTO>());
      })
    );

    this.subscription.add(
      consumer$.subscribe((response) => {
        this.consumer = new ResourceRequestsCard();
        let translations = (response && response[0].translations) 
          ? response[0].translations.find(t=> t.language === currentLanguage) 
          : null;
 
        if (!translations || !translations.consumerName) {
          this.consumer.consumerName = "";
        } else {
          this.consumer.consumerName = translations.consumerName;
        }
        this.consumer.companyName = response[0].companyName;
        this.consumer.applicationLogoUrl = response[0].applicationLogoUrl;
        this.consumer.applicationUrl = response[0].applicationUrl;
        this.consumer.consumerId = this.consumerAccessId;
        if (!translations || !translations.consumerPurpose) {
          this.consumer.consumerPurpose = "";
        } else {
          this.consumer.consumerPurpose = translations.consumerPurpose;
        }
        this.consumer.requests = response;
        this.consumer.allFarms = JSON.stringify(response[0].requestedAllFarms);
        this.expirationDate = response[0].expirationDate;
        this.rarService.checkRequestsStatus(this.consumer);
        this.informativeFlow = response[0].informativeFlow;
        this.requestsPendingArray = response
          .filter((x)=>x.status === AccessRequestStatus.Pending);
        this.requestsHandledArray = response
          .filter((x)=>x.status !== AccessRequestStatus.Pending);
        this.rarStatusForm.setControl('requestsPending', 
          this.formBuilder.array(
            this.requestsPendingArray
              .map((request) =>
                this.createrequestGroup(request)
            )));
        this.rarStatusForm.setControl('requestsHandled', 
          this.formBuilder.array(
            this.requestsHandledArray
              .map((request) =>
                this.createrequestGroup(request)
            )));
    
        if (this.partnerId != null) {
          const partner$ = this.partnerService.getPartner(this.partnerId);
          this.subscription.add(
            partner$.subscribe((response) => {
              this.partner = response;
              this.consumer.applicationLogoUrl =
                this.partner.applicationLogoUrl;
              this.consumer.applicationUrl = this.partner.applicationUrl;
            })
          );
        }
      })
    );

    this.initColumns();
  }

  createrequestGroup(request: ResourceAccessRequestDTO): FormGroup {
    let req = this.formBuilder.group({
      darId: [request.id],
      status: [request.status],
      translations: [request.translations],
      externalDocumentationUrl: [request.externalDocumentationUrl],
      creationDate: [request.creationDate],
      reviewDate: [request.reviewDate],
      expirationDate:[request.expirationDate],
      prevStatus: [request.status]
    });
    req.controls['status'].valueChanges.subscribe(value => {
      const prevStatus = req.controls['prevStatus'].value;
      if (value === AccessRequestStatus.Approved) {
        this.approve(
          request.id,
          prevStatus,
          () => {
              req.controls['prevStatus'].setValue(AccessRequestStatus.Approved);
              req.controls['reviewDate'].setValue(new Date(Date.now()));
            },
          () => req.controls['status'].setValue(prevStatus, {emitEvent: false})
        );
      } else {
        this.reject(
          request.id,
          prevStatus,
          () => {
              req.controls['prevStatus'].setValue(AccessRequestStatus.Rejected);
              req.controls['reviewDate'].setValue(new Date(Date.now()));
            },
          () => req.controls['status'].setValue(prevStatus, {emitEvent: false})
        );
      }
      
    });
    return req
  }

  get requestsPending(){
    return this.rarStatusForm.get('requestsPending') as FormArray;
  }

  get requestsHandled(){
    return this.rarStatusForm.get('requestsHandled') as FormArray;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initColumns() {
    this.columns = [
      {
        name: "models.accessRequest.resource.apiDescription",
      },
      {
        name: "models.accessRequest.resource.resource",
      },
      {
        name: "models.accessRequest.resource.creationDate",
      },
      {
        name: "models.accessRequest.resource.reviewDate",
      },
      {
        name: "models.farmDataAccessRequest.status",
      },
    ];
  }

  approve(
    id: string, 
    prevStatus: AccessRequestStatus, 
    onSuccess:() => void, 
    onError:() => void
  ) {
    if (prevStatus === AccessRequestStatus.Pending) {
      this.approveRarById(id, onSuccess, onError);
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "popup.updateRar.updateToApprove",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.approveRarById(id, onSuccess, onError);
        } else {
          onError();
        }
      })
    );
  }

  private approveRarById(
    id: string, 
    onSuccess:() => void, 
    onError:() => void
  ) {
    this.subscription.add(
      this.rarService.approveResourceAccessRequest(id).subscribe({
        next: (n) => {
          onSuccess();
        },
        error: (e) =>{
          onError();
        }})
      );
  }

  reject(
    id: string, 
    prevStatus: AccessRequestStatus, 
    onSuccess:() => void, 
    onError:() => void
  ) {
    if (prevStatus === AccessRequestStatus.Pending) {
      this.rejectRarById(id, onSuccess, onError);
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "popup.updateRar.updateToReject",
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.rejectRarById(id, onSuccess, onError);
        } else {
          onError();
        }
      })
    );
  }

  private rejectRarById(
    id: string, 
    onSuccess:() => void, 
    onError:() => void
  ) {
    this.subscription.add(
      this.rarService.rejectResourceAccessRequest(id).subscribe({
        next: (n) => {
          onSuccess();
        },
        error: (e) =>{
          onError();
        }})
    );
  }

  goBack() {
    this.router.navigate(["resource-access-requests"]);
  }

  approveAllPending = () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "dataAccessRequestPage.popup.acceptAll",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.subscription.add(
            this.rarService
              .approveAllPendingDataAccessRequests(this.consumerAccessId)
              .subscribe(() => {
                while(this.requestsPending.controls.length > 0)
                {
                  let request = this.requestsPending.controls.pop();
                  if(request.get('status').value === AccessRequestStatus.Pending)
                  request.get('status').setValue(AccessRequestStatus.Approved, {emitEvent: false});
                  this.requestsHandled.insert(0, request);
                }
              })
          );
        }
      })
    );
  };

  rejectAllPending = () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "dataAccessRequestPage.popup.rejectAll",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.subscription.add(
            this.rarService
              .rejectAllPendingAccessRequests(this.consumerAccessId)
              .subscribe(() => {
                while(this.requestsPending.controls.length > 0)
                {
                  let request = this.requestsPending.controls.pop();
                  if(request.get('status').value === AccessRequestStatus.Pending)
                  request.get('status').setValue(AccessRequestStatus.Rejected, {emitEvent: false});
                  this.requestsHandled.insert(0, request);
                }
              })
          );
        }
      })
    );
  };
  
  hasApplicationUrl() {
    if (!this.consumer)
      return false;
    return this.consumer.applicationUrl;
  }

  hasApplicationLogoUrl() {
    if (!this.consumer)
      return false;
    return this.consumer.applicationLogoUrl;
  }

  arePending(): boolean {
    return this.requestsPending.controls.some((r) => r.get('status').value === AccessRequestStatus.Pending)
  }
}
