export class Filter {
    prop: string;
    value: any;
    filterFunc: any;

    constructor(prop: string, value: any, filterFunc: any) {
        this.prop = prop;
        this.value = value;
        this.filterFunc = filterFunc;
    }
}