import { HttpClient } from "@angular/common/http";
import { Inject, Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { baseurl } from "../../../baseSettings";
import { FarmDTO } from "../../admin/models/farmDTO";
import { Farm } from "../../admin/models/farm";
import { FarmDetails } from "../../farmer/models/farm-details";
import { DatahubUser } from "../../shared/models/datahubUser";
import { InvitationResult } from "../../shared/models/invitation-result";
import { UserInvitation } from "../../shared/models/userInvitation";

@Injectable()
export class FarmService {
  private URL = `${this.api}/api/farm`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public signUpFarm(kboNumber: string): Observable<any> {
    return this.httpClient.post(`${this.URL}/sign-up`, { kboNumber });
  }

  create(farmDTO: FarmDTO): Observable<any> {
    return this.httpClient.post(this.URL, farmDTO);
  }

  update(farmDTO: FarmDTO): Observable<any> {
    return this.httpClient.put(this.URL, farmDTO);
  }

  getAllFarmers(farmId: string): Observable<any> {
    return this.httpClient.get<Array<DatahubUser>>(
      `${this.URL}/${farmId}/farmers`
    );
  }

  getFarmDetails(): Observable<FarmDetails> {
    return this.httpClient.get<FarmDetails>(`${this.URL}/details`);
  }

  reportErrors(
    view: string,
    userInput: string,
    kboNumber: string | undefined = undefined
  ): Observable<any> {
    return this.httpClient.post(`${this.URL}/report-errors`, {
      view,
      userInput,
      kboNumber,
    });
  }

  deleteFarmer(id: string) {
    return this.httpClient.delete(`${this.URL}/farmer/${id}`);
  }

  deleteUserFromFarmId(userId: string, farmId: string) {
    return this.httpClient.delete(`${this.URL}/${farmId}/farmer/${userId}`);
  }

  public getUnclaimedInvites(): Observable<Array<UserInvitation>> {
    return this.httpClient.get<Array<UserInvitation>>(`${this.URL}/invites`);
  }

  public getUnclaimedInvitesForFarmId(
    farmId: string
  ): Observable<Array<UserInvitation>> {
    return this.httpClient.get<Array<UserInvitation>>(
      `${this.URL}/${farmId}/invites`
    );
  }

  public inviteNewFarmer(email: string): Observable<InvitationResult> {
    let httpResponse: Observable<InvitationResult>;
    httpResponse = this.httpClient.post<any>(`${this.URL}/invite`, {
      email: email,
    });
    return httpResponse.pipe(
      map((response) => Object.assign(new InvitationResult(), response))
    );
  }

  public inviteNewFarmerToFarm(
    email: string,
    farmId: string
  ): Observable<InvitationResult> {
    let httpResponse: Observable<InvitationResult>;
    httpResponse = this.httpClient.post<any>(`${this.URL}/${farmId}/invite`, {
      email: email,
    });
    return httpResponse.pipe(
      map((response) => Object.assign(new InvitationResult(), response))
    );
  }

  public getFarmByKBO(kboNumber: string): Observable<Farm> {
    return this.httpClient.get<Farm>(`${this.URL}/kbo/${kboNumber}`);
  }
}
