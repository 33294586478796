import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy
} from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { AddApiDialogComponent } from './add-api-dialog/add-api-dialog.component';
import { Api } from '../models/api';
import { ApiService } from '../../core/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription, of } from 'rxjs';
import { UserAuthService } from '../../core/user-auth.service';
import { BaseDatatableComponent } from '../../shared/base-datatable/base-datatable.component';
import { SelectFilter } from '../../shared/base-datatable/models/select-filter';
import { ApiStatus } from '../models/api-status';
import { ApiStatusTranslation } from '../../shared/pipes/api-status';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-manage-apis',
  templateUrl: './manage-apis.component.html',
  styleUrls: ['./manage-apis.component.scss']
})
export class ManageApisComponent implements OnInit, OnDestroy {
  apis = new Array<Api>();
  isAdmin: boolean;
  columns = [];
  @ViewChild('editTmpl', { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild('translationTmpl', { static: true }) translationTmpl: TemplateRef<any>;
  @ViewChild('editHdrTmpl', { static: true }) editHdrTmpl: TemplateRef<any>;
  @ViewChild('statusTmpl', { static: true }) statusTmpl: TemplateRef<any>;
  @ViewChild(BaseDatatableComponent, { static: true }) apiTable: BaseDatatableComponent;
  statusTranslator = new ApiStatusTranslation;
  subscription = new Subscription;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private userService: UserAuthService
  ) { }

  ngOnInit() {
    this.initColumns();
    this.isAdmin = this.userService.isAdmin();
    if (this.isAdmin) {
      this.subscription.add(this.apiService.getAll(true).subscribe(response => this.setApis(response)));
    } else {
      this.subscription.add(this.apiService.getForProvider().subscribe(response => {
        this.setApis(response);
      }));
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private setApis(apis: Array<Api>) {
    this.apis = apis;
  }

  initColumns() {
    this.columns = [
      {
        prop: 'apiName',
        cellTemplate: this.translationTmpl,
        name: 'manageApisPage.api.name',
        flexGrow: 4,
        headerTemplate: this.apiTable.hdrTranslationTpl,
        sortable: false
      },
      {
        prop: 'status',
        name: 'manageApisPage.api.status',
        flexGrow: 4,
        headerTemplate: this.apiTable.hdrSelectTpl,
        cellTemplate: this.statusTmpl,
        data: [
          new SelectFilter(ApiStatus.Draft, this.statusTranslator.transform(ApiStatus.Draft)),
          new SelectFilter(ApiStatus.Published, this.statusTranslator.transform(ApiStatus.Published)),
          new SelectFilter(ApiStatus.Submitted, this.statusTranslator.transform(ApiStatus.Submitted))
        ]
      },
      {
        headerTemplate: this.editHdrTmpl,
        cellTemplate: this.editTmpl,
        name: 'actions.actions',
        flexGrow: 2,
        sortable: false
      }
    ];
  }

  createNewApi = () => {
    const widthSize = '900px';
    const heightSize = '520px';
    const dialogRef = this.dialog.open(AddApiDialogComponent, {
      width: widthSize,
      height: heightSize,
      disableClose: true
    });

    this.subscription.add(
      dialogRef.afterClosed()
        .pipe(switchMap(value => {
          if (value) {
            const api = {apiUrl: value.apiUrl, openApiSpecUrl: value.openApiSpecUrl, farmIdUrl: value.farmIdUrl, translations: value.apiName.map(t => ({
              language: t.language,
              apiName: t.value
            })) }
          const apiobj = Object.assign(new Api(), api);

          return this.apiService.save(apiobj);
          }
          return of();
        })
        ).subscribe(response => {
          if (response) {
            this.router.navigate([`api-details`, response], {
              relativeTo: this.route
            });
          }
        })
    );
  }

  viewDetails(api: Api) {
    this.router.navigate([`api-details`, api.id], {
      relativeTo: this.route
    });
  }
}
