<app-base-remote-datatable
  [columns]="columns"
  [service]="AllUsersService"
  [initialSort]="initialSort"
  [firstColumnIcon]="true"
  queryOnLangChange="true"
/>

<div class="dialog-backdrop" *ngIf="dialogIsOpen()"></div>
<dialog #dialogDetails id="dialog-details">
  <ng-container *ngIf="details$ | async as details; else loading">
    <form novalidate *ngIf="details.farms.length; else noFarms">
      <section>
        <label for="userpage-farms">
          <h2>
            {{ "viewUserPage.farms" | translate }}
          </h2>
        </label>
        <input
          type="checkbox"
          name="userpage-farms"
          id="userpage-farms"
          checked
        />
        <div class="expandable">
          <div class="inner">
            <div class="fields">
              <ng-container *ngIf="details.farms.length; else noFarms">
                <div
                  class="farm-expandable"
                  *ngFor="let farm of details.farms; index as i"
                >
                  <label for="{{ 'userpage-' + farm.farmId }}">
                    <h3>
                      {{ "viewUserPage.farm" | translate }}
                    </h3>
                  </label>
                  <input
                    type="checkbox"
                    name="{{ 'userpage-' + farm.farmId }}"
                    id="{{ 'userpage-' + farm.farmId }}"
                    [checked]="i === 0"
                  />
                  <div class="expandable">
                    <div class="inner">
                      <div class="fields">
                        <div class="description">
                          <p>{{ farm.farmId }}</p>
                        </div>
                        <div
                          *ngFor="
                            let type of groupFarmMappingsByType(farm.mappings)
                              | keyvalue
                          "
                        >
                          <p>
                            {{
                              "models.farmMapping.farmIdTypeId." + type.key
                                | translate
                            }}
                          </p>
                          <ng-container [ngSwitch]="type.key">
                            <ng-container *ngSwitchCase="'KBO'">
                              <div class="indent">
                                <a
                                  href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer={{
                                    type.value
                                  }}"
                                  target="_blank"
                                >
                                  {{ type.value }}
                                </a>
                              </div>
                            </ng-container>
                            <ng-container *ngSwitchDefault>
                              <mat-list>
                                <mat-list-item
                                  *ngFor="let mapping of type.value"
                                >
                                  {{ mapping }}
                                </mat-list-item>
                              </mat-list>
                            </ng-container>
                          </ng-container>
                        </div>
                      </div>
                    </div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="24"
                      viewBox="0 -960 960 960"
                      width="24"
                      class="icon"
                    >
                      <path
                        d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"
                      />
                    </svg>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            class="icon"
          >
            <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
          </svg>
        </div>
      </section>
    </form>
  </ng-container>
  <app-base-datatable
    #farmAccessTable
    [rows]="rowsFarms | async"
    [columns]="columnsFarms || []"
    [headerHeight]="70"
    [footerHeight]="50"
    [limit]="10"
    [exportable]="false"
  />
  <button (click)="closeModal()" class="rose" id="closeModal">Close</button>
</dialog>

<div class="divider"></div>

<!-- Statistics -->
<app-admin-statistics [loadingData]="loadingData" />

<ng-template #noFarms>
  <p>{{ "viewUserPage.noFarms" | translate }}</p>
</ng-template>

<ng-template #loading>
  <span class="loader"></span>
</ng-template>

<ng-template #firstNameCell let-firstName="firstName">
  <p class="text-cell">{{ firstName }}</p>
</ng-template>

<ng-template #lastNameCell let-lastName="lastName">
  <p class="text-cell">{{ lastName }}</p>
</ng-template>

<ng-template #emailCell let-email="email">
  <p class="text-cell">{{ email }}</p>
</ng-template>

<ng-template #rolesCell let-roles="roles">
  <ul>
    <li *ngFor="let role of roles">
      {{ "roles." + getRole(role) | translate }}
    </li>
  </ul>
</ng-template>

<ng-template #editTmpl let-id="id">
  <button
    (click)="viewUser(id)"
    class="card-button view-user"
    aria-label="view user"
  >
    <i class="fa fa-eye"></i>
  </button>
</ng-template>

<ng-template #kboNumberCell let-kboNumber="kboNumber">
  <p class="text-cell">
    {{ kboNumber }}
  </p>
</ng-template>

<ng-template #resourceNameCell let-resourceName="resourceName">
  <p class="text-cell">
    {{ resourceName }}
  </p>
</ng-template>

<ng-template #resourceStatusCell let-value="value">
  <p class="text-cell">
    {{ value | AccessRequestStatusTranslation | translate }}
  </p>
</ng-template>

<ng-template #darStatusCell let-value="value">
  <p class="text-cell">
    {{ value | FarmResourceAccessStatusTranslation | translate }}
  </p>
</ng-template>
