import { Pipe, PipeTransform } from '@angular/core';
import { ResourceHealth } from '../models/api-resource-health';

@Pipe({
    name: 'ApiResourceHealthTranslation'
})
export class ApiResourceHealthTranslation implements PipeTransform {
    transform(value: ResourceHealth): string {
        return `models.apiResourceHealth.health.${ResourceHealth[value]}`;
    }
}
