<h1>{{ "navigation.adminManageUsers" | translate }}</h1>
<p>{{ "info.viewUser" | translate }}</p>
<ng-container *ngIf="details$ | async as details; else userNotFound">
  <form novalidate>
    <section>
      <label for="userpage-info">
        <h2>
          {{ "viewUserPage.userInfo" | translate }}
        </h2>
      </label>
      <input type="checkbox" name="userpage-info" id="userpage-info" checked />
      <div class="expandable">
        <div class="inner">
          <div class="fields">
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.id' | translate,
                value: details.id
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.identityId' | translate,
                value: details.identityId
              }"
            ></ng-template>
            <ng-container *ngIf="details.roles.length">
              <div class="field-item">
                <p class="bold">{{ "viewUserPage.roles" | translate }}:</p>
                <div class="chip-list">
                  <p class="small bold" *ngFor="let role of details.roles">
                    {{ "roles." + role | translate }}
                  </p>
                </div>
              </div>
            </ng-container>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.firstName' | translate,
                value: details.firstName
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.lastName' | translate,
                value: details.lastName
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.email' | translate,
                value: details.email
              }"
            ></ng-template>
            <ng-template
              [ngTemplateOutlet]="formField"
              [ngTemplateOutletContext]="{
                label: 'models.datahubUser.status' | translate,
                value:
                  'models.datahubUser.statusses.' + getStatus(details.status)
                  | translate
              }"
            ></ng-template>
            <mat-checkbox
              labelPosition="before"
              [checked]="details.allowDirectMarketing"
              disabled
            >
              {{ "models.datahubUser.notifications" | translate }}:
            </mat-checkbox>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>

    <section>
      <label for="userpage-farms">
        <h2>
          {{ "viewUserPage.farms" | translate }}
        </h2>
      </label>
      <input
        type="checkbox"
        name="userpage-farms"
        id="userpage-farms"
        checked
      />
      <div class="expandable">
        <div class="inner">
          <div class="fields">
            <ng-container *ngIf="details.farms.length; else noFarms">
              <div class="farm-expandable" *ngFor="let farm of details.farms">
                <label for="{{ 'userpage-' + farm.farmId }}">
                  <h3>
                    {{ "viewUserPage.farm" | translate }}
                  </h3>
                </label>
                <input
                  type="checkbox"
                  name="{{ 'userpage-' + farm.farmId }}"
                  id="{{ 'userpage-' + farm.farmId }}"
                />
                <div class="expandable">
                  <div class="inner">
                    <div class="fields">
                      <div class="description">
                        <p>{{ farm.farmId }}</p>

                        <button (click)="editFarm(farm.farmId)" mat-button>
                          <ng-template
                            [ngTemplateOutlet]="editIcon"
                          ></ng-template>
                        </button>
                      </div>

                      <div
                        *ngFor="
                          let type of groupFarmMappingsByType(farm.mappings)
                            | keyvalue
                        "
                      >
                        <p>
                          {{
                            "models.farmMapping.farmIdTypeId." + type.key
                              | translate
                          }}
                        </p>
                        <ng-container [ngSwitch]="type.key">
                          <ng-container *ngSwitchCase="'KBO'">
                            <div class="indent">
                              <a
                                href="https://kbopub.economie.fgov.be/kbopub/zoeknummerform.html?nummer={{
                                  type.value
                                }}"
                                target="_blank"
                              >
                                {{ type.value }}
                              </a>
                            </div>
                          </ng-container>
                          <ng-container *ngSwitchDefault>
                            <mat-list>
                              <mat-list-item *ngFor="let mapping of type.value">
                                {{ mapping }}
                              </mat-list-item>
                            </mat-list>
                          </ng-container>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="24"
                    viewBox="0 -960 960 960"
                    width="24"
                    class="icon"
                  >
                    <path
                      d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z"
                    />
                  </svg>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>

    <section>
      <label for="userpage-partners">
        <h2>
          {{ "viewUserPage.partners" | translate }}
        </h2>
      </label>
      <input
        type="checkbox"
        name="userpage-partners"
        id="userpage-partners"
        checked
      />
      <div class="expandable">
        <div class="inner">
          <div class="fields">
            <table *ngIf="details.partners.length; else noPartners">
              <tr *ngFor="let partner of details.partners">
                <td>
                  {{ partner }}
                </td>
                <td>
                  <button (click)="editPartner(partner)" mat-button>
                    <ng-template [ngTemplateOutlet]="editIcon"></ng-template>
                  </button>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          height="24"
          viewBox="0 -960 960 960"
          width="24"
          class="icon"
        >
          <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
        </svg>
      </div>
    </section>
  </form>
  <button
    class="delete-button rose"
    (click)="deleteUserAccountAsAdminConfirmation()"
  >
    {{ "actions.deleteUserAccount" | translate }}
  </button>
</ng-container>

<!--
  Templates
-->
<ng-template #noFarms>
  <p>{{ "viewUserPage.noFarms" | translate }}</p>
</ng-template>

<ng-template #noPartners>
  <p>{{ "viewUserPage.noPartners" | translate }}</p>
</ng-template>

<ng-template #userNotFound>
  <h3 class="red">{{ "viewUserPage.userNotFound" | translate }}</h3>
</ng-template>

<ng-template #formField let-label="label" let-value="value">
  <div class="field-item">
    <label for="{{ label }}">{{ label }}</label>
    <input
      type="text"
      readonly
      value="{{ value }}"
      name="{{ label }}"
      id="{{ label }}"
    />
  </div>
</ng-template>

<ng-template #editIcon>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    class="bi bi-pencil-square"
    viewBox="0 0 16 16"
  >
    <path
      d="M15.502 1.94a.5.5 0 0 1 0 .706L14.459 3.69l-2-2L13.502.646a.5.5 0 0 1 .707 0l1.293 1.293zm-1.75 2.456-2-2L4.939 9.21a.5.5 0 0 0-.121.196l-.805 2.414a.25.25 0 0 0 .316.316l2.414-.805a.5.5 0 0 0 .196-.12l6.813-6.814z"
    />
    <path
      fill-rule="evenodd"
      d="M1 13.5A1.5 1.5 0 0 0 2.5 15h11a1.5 1.5 0 0 0 1.5-1.5v-6a.5.5 0 0 0-1 0v6a.5.5 0 0 1-.5.5h-11a.5.5 0 0 1-.5-.5v-11a.5.5 0 0 1 .5-.5H9a.5.5 0 0 0 0-1H2.5A1.5 1.5 0 0 0 1 2.5v11z"
    />
  </svg>
</ng-template>
