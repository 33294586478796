<nav class="navbar-menu">
  <button
    type="button"
    class="navbar-toggle"
    data-toggle="collapse"
    data-target=".navbar-collapse"
    [attr.aria-expanded]="!isExpanded"
    (click)="toggle()"
  >
    {{ "navigation.toggle" | translate }}
  </button>
  <ul [ngClass]="{ in: isExpanded }">
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['manage-admins']" (click)="collapse()">
        {{ "navigation.manageAdmins" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['manage-partners']" (click)="collapse()">
        {{ "navigation.managePartners" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['admin-manage-users']" (click)="collapse()">
        {{ "navigation.adminManageUsers" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['farmmapping']" (click)="collapse()">
        {{ "navigation.farmMapping" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['manage-unclaimed-invites']" (click)="collapse()">
        {{ "navigation.manageUnclaimedInvites" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['farm-access']" (click)="collapse()">
        {{ "navigation.viewFarmAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['resource-access']" (click)="collapse()">
        {{ "navigation.viewResourceAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['farm-resource-access']" (click)="collapse()">
        {{ "navigation.viewFarmResourceAccess" | translate }}
      </a>
    </li>
    <li
      [routerLinkActive]="['link-active']"
      *ngIf="userRole === 'Provider' || userRole === 'Consumer'"
    >
      <a [routerLink]="['partner-details']" (click)="collapse()">
        {{ "navigation.partnerDetails" | translate }}
      </a>
    </li>
    <li
      [routerLinkActive]="['link-active']"
      *ngIf="userRole === 'Provider' || userRole === 'Consumer'"
    >
      <a [routerLink]="['manage-partner-users']" (click)="collapse()">
        {{ "navigation.managePartnerUsers" | translate }}
      </a>
    </li>
    <li
      [routerLinkActive]="['link-active']"
      *ngIf="userRole === 'Provider' || userRole === 'Admin'"
    >
      <a [routerLink]="['manage-apis']" (click)="collapse()">
        {{
          (userRole === "Provider" ? "navigation.myAPIs" : "navigation.allAPIs")
            | translate
        }}
      </a>
    </li>
    <li
      [routerLinkActive]="['link-active']"
      *ngIf="userRole === 'Provider' || userRole === 'Admin'"
    >
      <a [routerLink]="['manage-push-events']" (click)="collapse()">
        {{
          (userRole === "Provider"
            ? "navigation.myPushEvents"
            : "navigation.allEventTypes"
          ) | translate
        }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-access']" (click)="collapse()">
        {{ "navigation.manageAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-push-events']" (click)="collapse()">
        {{ "navigation.consumerPushEvents" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-farm-access']" (click)="collapse()">
        {{ "navigation.viewFarmAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-resource-access']" (click)="collapse()">
        {{ "navigation.viewResourceAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-farm-resource-access']" (click)="collapse()">
        {{ "navigation.viewFarmResourceAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a href="https://apidataviewer.djustconnect.be/Identity/Account/Login" (click)="collapse()" target="_blank">
        {{ "navigation.APIDataViewer" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Consumer'">
      <a [routerLink]="['consumer-resource-health']" (click)="collapse()">
        {{ "navigation.consumerResourceHealth" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Admin'">
      <a [routerLink]="['resource-health-admin']" (click)="collapse()">
        {{ "navigation.consumerResourceHealth" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Provider'">
      <a [routerLink]="['resource-access-requests']" (click)="collapse()">
        {{ "navigation.manageResourceRequests" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Farmer'">
      <a [routerLink]="['manage-data-access']" (click)="collapse()">
        {{ "navigation.manageDataAccess" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Farmer'">
      <a [routerLink]="['manage-farmers']" (click)="collapse()">
        {{ "navigation.manageFarmers" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']">
      <a [routerLink]="['user-overview']" (click)="collapse()">
        {{ "navigation.userOverview" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole === 'Farmer'">
      <a [routerLink]="['farm-details']" (click)="collapse()">
        {{ "navigation.farmDetails" | translate }}
      </a>
    </li>
    <li [routerLinkActive]="['link-active']" *ngIf="userRole !== 'Farmer'">
      <a [routerLink]="['logs']" (click)="collapse()">
        {{ "navigation.clearingHouse" | translate }}
      </a>
    </li>
  </ul>
</nav>
