import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { UserService } from '../../../core/services/user.service';
import { NotificationDialogComponent } from '../../dialog/notification-dialog/notification-dialog.component';

@Component({
  selector: 'app-register',
  templateUrl: './register-partner.component.html',
  styleUrls: ['./register-partner.component.css']
})
export class RegisterPartnerComponent implements OnInit, OnDestroy {

  userSignUpForm: UntypedFormGroup;
  subscription = new Subscription;

  constructor(formBuilder: UntypedFormBuilder, private userService: UserService, private dialog: MatDialog) {
    this.userSignUpForm = formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  signUpAsUser(formValues) {
    this.subscription.add(
      this.userService.partnerSignUp(formValues.email).subscribe(() => {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: '400px',
          data: {
            title: 'registration.user.registered.title',
            text: 'registration.user.registered.text'
          }
        });
      })
    );
  }

}
