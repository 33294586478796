import { ConsentType } from './consent-type';
import { ApiCredentials } from './api-credentials';
import { Language } from '../../shared/models/language';

export class EventType {
    id: string;
    farmIdUrl: string;
    consentType: ConsentType;
    farmIdTypeId: string;
    status: EventTypeStatus;
    documentationUrl: string;
    externalDocumentationFileUrl: string;
    useOAuth: boolean;
    credentials: ApiCredentials;
    headers: any;
    translations: Array<EventTypeTranslation>;

    isEditable(): boolean {
        return this.status === EventTypeStatus.Draft;
    }
}

export class EventTypeTranslation {
    language: Language;
    name: string;
    description: string;
}

export enum EventTypeStatus {
    Draft = 0,
    Submitted = 1,
    Published = 2,
}