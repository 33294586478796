<div class="dialog-content">
  <h2>{{ title | translate }}</h2>

  <div class="content">
    <p [innerHTML]="text | translate : textParams"></p>
    <a *ngIf="mailLink" href="mailto: {{ link }}">{{ linkText | translate }}</a>
    <a *ngIf="!mailLink" href="{{ link }}" target="_blank">{{
      linkText | translate
    }}</a>
  </div>

  <button class="green" (click)="onClose()">
    {{ "actions.close" | translate }}
  </button>
</div>
