import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page';
import { RarStatusSummary } from '../../data-consumer/models/rar-status-summary';
import './httpclient.extensions';
import { IPagedSortedFilteredService } from './ipaged-sorted-filtered.service';
import { IExtendedHttpClient, Ext } from './httpclient.extensions';

@Injectable({
  providedIn: 'root'
})
export class ConsumerResourceAccessService implements IPagedSortedFilteredService<RarStatusSummary> {
  private URL = `${this.api}/api/RarStatus/consumer`;

  constructor(private httpClient: HttpClient,
    @Inject(baseurl) private api: string) { }

  get(pageNumber = 1, pageSize = 10, sort = null, filter = null): Observable<Page<RarStatusSummary>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<RarStatusSummary>(RarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<RarStatusSummary>(
      this.httpClient, RarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
  }
}

