import { Injectable, Inject } from '@angular/core';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ConsumerAccess } from '../../data-consumer/models/consumer-access';

@Injectable({
  providedIn: 'root'
})
export class ConsumerAccessService {
  private URL = `${this.api}/api/ConsumerAccess`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  get(): Observable<ConsumerAccess> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.get(this.URL);
    return httpResponse.pipe(
          map(consumerAccess =>
            Object.assign(new ConsumerAccess(), consumerAccess)
          )
        );
  }

  save(consumerAccess: ConsumerAccess): Observable<string> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.post(this.URL, consumerAccess);
    return httpResponse.pipe(map(response => String(response)));
  }
}