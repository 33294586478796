import { Component, OnInit } from "@angular/core";
import { FarmService } from "../../core/services/farm.service";
import { FarmDetails } from "../models/farm-details";
import { Observable, Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { FarmMappingMistakeDialogComponent as ErrorsDialogComponent } from "./farm-mapping-mistake-dialog/farm-mapping-mistake-dialog.component";
import { FarmTypeTranslation } from "../../shared/pipes/farm-type-translation";
import { UserContextDTO } from "../../shared/models/userContextDTO";
import { ContextService } from "../../core/services/context.service";

export enum Order {
  "KBO" = 1,
  "PE nummer" = 2,
  "Beslag nummer" = 3,
  "Leveraarsnummer" = 4,
  "Keuring Spuittoestellen" = 5,
  "Perceelsnummer" = 6,
  "Vestigingsnummer" = 7,
}

@Component({
  selector: "app-farm-details",
  templateUrl: "./farm-details.component.html",
  styleUrls: ["./farm-details.component.scss"],
})
export class FarmDetailsComponent implements OnInit {
  public farmTypeTranslation: FarmTypeTranslation;
  private subscription = new Subscription();
  details$: Observable<FarmDetails>;
  userContext: UserContextDTO;

  constructor(
    private farmService: FarmService,
    private dialog: MatDialog,
    private contextService: ContextService
  ) {}

  order = Order;

  ngOnInit() {
    this.subscription.add(
      this.contextService.getContext().subscribe((context) => {
        this.userContext = context;
      })
    );

    this.details$ = this.farmService.getFarmDetails();
    // sorting code in comment for now until later
    this.details$.subscribe((response) => {
      let reArr = [];
      //order = KBO, PE, Leveraarsnummer, Vesting, Beslag, Keuring
      let mappingIds = [
        "4c17a3f2-c03d-4d65-8440-3a896b245753",
        "d55fe787-6ea0-46e8-9f00-d9e5e86bad2b",
        "f0d81658-2434-46b2-af79-a6de11f09da8",
        "5664a632-31b8-46a7-95f5-8094453f649c",
        "324a23eb-b4bc-4de1-a01b-0e478afac252",
        "dd03e71c-d114-4cce-a5fe-6843f1fc8878",
      ];
      mappingIds.forEach((mappingId) => {
        reArr.push(response.mappings.filter((m) => m.type == mappingId));
      });
      //re-assign
      response.mappings = reArr;
    });
  }

  openReportDialog() {
    const dialogRef = this.dialog.open(ErrorsDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((input) => {
        if (input) this.farmService.reportErrors(
          "farm-detail",
          input,
          this.userContext.entityName
            ? this.userContext.entityName
            : undefined).subscribe();
      })
    );
  }
}
