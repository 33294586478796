import { BrowserModule } from "@angular/platform-browser";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { AppComponent } from "./app.component";
import {
  MsalModule,
  MsalInterceptor,
  MsalService,
  MsalGuardConfiguration,
  MsalInterceptorConfiguration,
  MSAL_GUARD_CONFIG,
  MSAL_INSTANCE,
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalGuard,
  MsalRedirectComponent,
} from "@azure/msal-angular";
import { CoreModule } from "./modules/core/core.module";
import { SharedModule } from "./modules/shared/shared.module";
import { AdminModule } from "./modules/admin/admin.module";
import { DataProviderModule } from "./modules/data-provider-module/data-provider.module";
import { RoleGuard } from "./modules/shared/guards/role-guard";
import { AuthGuard } from "./modules/shared/guards/auth-guard";
import { DataConsumerModule } from "./modules/data-consumer/data-consumer.module";
import { CanDeactivateGuard } from "./modules/shared/guards/can-deactivate-guard";
import { FarmerModule } from "./modules/farmer/farmer.module";
import { StartPageModule } from "./modules/start-page/start-page.module";
import { UserAuthService } from "./modules/core/user-auth.service";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ClipboardModule } from "ngx-clipboard";
import {
  IPublicClientApplication,
  PublicClientApplication,
  BrowserCacheLocation,
  InteractionType,
  LogLevel,
} from "@azure/msal-browser";

export function loggerCallback(logLevel, message, piiEnabled) {
  console.log(message);
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    CoreModule,
    SharedModule,
    AdminModule,
    DataProviderModule,
    DataConsumerModule,
    FarmerModule,
    StartPageModule,
    MsalModule,
    ClipboardModule,
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true,
    },
    {
      provide: ApplicationInsights,
      useFactory: appInsightsFactory,
    },
    {
      provide: APP_INITIALIZER,
      multi: true,
      useFactory: () => {
        return () => {
          return fetch("/assets/config/appConfig.json")
            .then((response) => response.json())
            .then((json) => {
              sessionStorage.setItem("appConfig", JSON.stringify(json));
            });
        };
      },
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [APP_INITIALIZER],
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    MsalGuard,
    MsalService,
    MsalBroadcastService,
    RoleGuard,
    AuthGuard,
    CanDeactivateGuard,
    UserAuthService,
  ],
})
export class AppModule {}

export function appInsightsFactory(): ApplicationInsights {
  const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
  return new ApplicationInsights({
    config: {
      instrumentationKey: appConfig.appInsights.instrumentationKey,
      enableAutoRouteTracking: true,
    },
  });
}


export function MSALInstanceFactory(): IPublicClientApplication {
  const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
  
  let keyLogLevel = Object.keys(LogLevel).find(x => LogLevel[x] == LogLevel[appConfig.msal.base.auth.logLevel] )
  var msalLogLevel = LogLevel[keyLogLevel];

  return new PublicClientApplication({
    auth: {
      clientId: appConfig.msal.base.auth.clientId, //  "32a5fb7c-d6db-4c36-8c69-4688bc5a76c2",//environment.msalConfig.auth.clientId,
      authority: localStorage.getItem("useflow")?? appConfig.msal.base.auth.authority, // "https://ilvodatahubidentity.b2clogin.com/ilvodatahubidentity.onmicrosoft.com/B2C_1_acm",
      redirectUri: appConfig.msal.base.auth.redirectUri, // "http://localhost:4200",
      postLogoutRedirectUri: appConfig.msal.base.auth.postLogoutRedirectUri, // "http://localhost:4200",
      knownAuthorities: [appConfig.msal.base.auth.knownAuthorities], //"https://ilvodatahubidentity.b2clogin.com"
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: msalLogLevel,
        piiLoggingEnabled: false,
      },
    },
  });
}


export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
  const protectedResourceMap = new Map<string, Array<string>>();
  const ResourceMapArray = appConfig.msal.angular.protectedResourceMap;

  ResourceMapArray.forEach((e) => {
    protectedResourceMap.set(e.uri, e.scopes);
  });

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap,
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: appConfig.msal.angular.consentScopes,
    },
    loginFailedRoute: "/login-failed",
  };
}
