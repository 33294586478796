import {
  Component,
  OnInit,
  OnDestroy,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import { PushEventsService } from "../../core/services/push-events.service";
import { switchMap, tap, map } from "rxjs/operators";
import { Observable, Subscription, of } from "rxjs";
import { ProviderPushEndpoint } from "../../shared/models/provider-push-endpoint";
import { EventType, EventTypeStatus } from "../models/event-type";
import { ConsentType } from "../models/consent-type";
import { Router, ActivatedRoute } from "@angular/router";
import { BaseDatatableComponent } from "../../shared/base-datatable/base-datatable.component";
import { SelectFilter } from "../../shared/base-datatable/models/select-filter";
import { EventTypeStatusTranslation } from "../../shared/pipes/event-type-status";
import { UserAuthService } from "../../core/user-auth.service";

@Component({
  selector: "app-manage-push-events",
  templateUrl: "./manage-push-events.component.html",
  styleUrls: ["./manage-push-events.component.scss"],
})
export class ManagePushEventsComponent implements OnInit, OnDestroy {
  @ViewChild("defaultHdrTmpl", { static: true })
  defaultHdrTmpl: TemplateRef<any>;
  @ViewChild("translationTmpl", { static: true })
  translationTmpl: TemplateRef<any>;
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("statusTmpl", { static: true }) statusTmpl: TemplateRef<any>;
  @ViewChild(BaseDatatableComponent, { static: true })
  apiTable: BaseDatatableComponent;
  statusTranslator = new EventTypeStatusTranslation();
  providerPushEndpoint$: Observable<ProviderPushEndpoint>;
  providerPushEndpointValue: ProviderPushEndpoint;
  eventTypes$: Observable<EventType[]>;
  subscription = new Subscription();
  columns = [];

  constructor(
    private pushEventsService: PushEventsService,
    private router: Router,
    private route: ActivatedRoute,
    private authService: UserAuthService
  ) {}

  ngOnInit() {
    this.initColumns();
    this.providerPushEndpoint$ =
      this.pushEventsService.getProviderPushEndpoint();
    this.providerPushEndpoint$.subscribe((endpoint) => {
      if (!endpoint) {
        this.providerPushEndpointValue = new ProviderPushEndpoint();
        this.providerPushEndpointValue.endpointUrl = "endpoint.com";
        this.providerPushEndpointValue.key1 = "key1";
        this.providerPushEndpointValue.key2 = "key2";
      } else {
        this.providerPushEndpointValue = endpoint;
      }
    });

    this.eventTypes$ = this.pushEventsService.getProviderEventTypes();
  }

  get isAdmin() {
    return this.authService.isAdmin();
  }

  initColumns() {
    this.columns = [
      {
        headerTemplate: this.apiTable.hdrTranslationTpl,
        prop: "name",
        name: "models.eventType.name",
        cellTemplate: this.translationTmpl,
        flexGrow: 4,
        sortable: false,
      },
      {
        headerTemplate: this.apiTable.hdrTextTpl,
        prop: "id",
        name: "models.eventType.id",
        flexGrow: 4,
      },
      {
        headerTemplate: this.apiTable.hdrSelectTpl,
        prop: "status",
        cellTemplate: this.statusTmpl,
        name: "models.eventType.status",
        flexGrow: 4,
        data: [
          new SelectFilter(
            EventTypeStatus.Draft,
            this.statusTranslator.transform(EventTypeStatus.Draft.toString())
          ),
          new SelectFilter(
            EventTypeStatus.Published,
            this.statusTranslator.transform(
              EventTypeStatus.Published.toString()
            )
          ),
          new SelectFilter(
            EventTypeStatus.Submitted,
            this.statusTranslator.transform(
              EventTypeStatus.Submitted.toString()
            )
          ),
        ],
      },
      {
        headerTemplate: this.defaultHdrTmpl,
        cellTemplate: this.editTmpl,
        name: "actions.actions",
        flexGrow: 2,
        sortable: false,
      },
    ];
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createProviderPushEndpoint() {
    this.providerPushEndpoint$ = this.pushEventsService
      .createProviderPushEndpoint()
      .pipe(switchMap((_) => this.pushEventsService.getProviderPushEndpoint()));
  }

  createEventType() {
    this.subscription.add(
      this.eventTypes$
        .pipe(
          map((eventTypes) => {
            eventTypes.push(
              Object.assign(new EventType(), {
                name: "my-event",
                consentType: ConsentType.NoConsentRequired,
              })
            );
            return eventTypes;
          }),
          tap((x) => (this.eventTypes$ = of(x)))
        )
        .subscribe((x) =>
          this.router.navigate([`event-type-details`], {
            relativeTo: this.route,
          })
        )
    );
  }

  viewDetails(eventType: EventType) {
    this.router.navigate([`event-type-details`, eventType.id], {
      relativeTo: this.route,
    });
  }
}
