import { Injectable, Inject } from '@angular/core';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FarmIdType } from '../../data-provider-module/models/farm-id-type';
import {
  map,
  publishReplay,
  refCount
} from 'rxjs/operators';

@Injectable()
export class FarmIdTypesService {
  private URL = `${this.api}/api/farmIdType`;

  farmIdTypes: Observable<Array<FarmIdType>>;
  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getAll(): Observable<Array<FarmIdType>> {
    if (!this.farmIdTypes) {
      this.farmIdTypes = this.httpClient.get(this.URL).pipe(
        map(farmIdTypeObjects => {
          const farmIdTypes = (farmIdTypeObjects as Array<FarmIdType>)
          .map(farmIdType => Object.assign(new FarmIdType(), farmIdType));
          return farmIdTypes;
        }),
        publishReplay(1),
        refCount()
      );
    }
    return this.farmIdTypes;
  }
}
