<button (click)="gotoList()" class="card-button">
  <!-- <div class="label red">
    <i class="fas fa-eye"></i>
    <p>{{ "dataAccessRequestPage.readOnly" | translate }}</p>
  </div> -->

  <div class="card-row">
    <div class="upper-part">
      <img
        class="app-logo"
        src="{{ this.baseurl + data.applicationLogoUrl }}"
        *ngIf="hasApplicationLogoUrl(); else dclogo"
        [alt]="data.consumerName"
      />
      <ng-template #dclogo>
        <img
          class="app-logo"
          alt="blauwmiddel"
          src="/assets/images/blauwMiddel 1.svg"
        />
      </ng-template>
      <h3>{{ data?.consumerName }}</h3>
    </div>
    <h4>{{ data?.companyName }}</h4>
  </div>
  <div class="card-row">
    <p title="{{ data?.description }}" class="description">
      {{ data?.description }}
    </p>
  </div>
  <p class="go-to-page">
    <span>
      {{ "dataAccessRequestPage.allInfo" | translate }}
    </span>
    <i class="fas fa-chevron-right"></i>
  </p>
  <div class="padding-y-15 pb-5">
    <a class="btn font-larger btn-outline-success">
      <span class="accent-color"></span> 
      {{'models.accessRequest.statusses.informativeFlow' | translate}}
    </a>
  </div>
</button>
