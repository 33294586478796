import { Pipe, PipeTransform } from '@angular/core';
import { DatahubPartnerRole } from '../../admin/models/datahubPartner';

@Pipe({
    name: 'DataPartnerRoleTranslation'
})
export class DataPartnerRoleTranslation implements PipeTransform {
    transform(value: DatahubPartnerRole): string {
        return `roles.${DatahubPartnerRole[value]}`;
    }
}
