import { Component, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-browser-dialog',
  templateUrl: './browser-dialog.component.html',
  styleUrls: ['./browser-dialog.component.css']
})
export class BrowserDialogComponent implements OnInit {

  constructor(public dialogReg: MatDialogRef<BrowserDialogComponent>) { }

  ngOnInit() {
  }

  onClose() {
    this.dialogReg.close();
  }

}
