import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseurl } from "../../../baseSettings";
import { Observable } from "rxjs";
import { Page } from "../../shared/models/page";
import "./httpclient.extensions";
import { IPagedSortedFilteredService } from "./ipaged-sorted-filtered.service";
import { Ext } from "./httpclient.extensions";
import { ClearingHousePartnerSummary } from "../../shared/models/clearing-house-partner-summary";

@Injectable({
  providedIn: "root",
})
export class ClearingHousePartnerSummaryService
  implements IPagedSortedFilteredService<ClearingHousePartnerSummary>
{
  private URL = `${this.api}/api/ClearingHouse/PartnerSummary`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  get(
    pageNumber = 1,
    pageSize = 10,
    sort = null,
    filter = null
  ): Observable<Page<ClearingHousePartnerSummary>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<DarStatusSummary>(DarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<ClearingHousePartnerSummary>(
      this.httpClient,
      ClearingHousePartnerSummary,
      this.URL,
      pageNumber,
      pageSize,
      sort,
      filter
    );
  }
}
