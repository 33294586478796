import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { EventTypeValidationResult } from '../../models/event-type-validation-result';

@Component({
  selector: 'app-validation-result-dialog',
  templateUrl: './validation-result-dialog.component.html',
  styleUrls: ['./validation-result-dialog.component.css']
})
export class ValidationResultDialogComponent implements OnInit {
  constructor(
    public dialogReg: MatDialogRef<ValidationResultDialogComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      farmIdUrl: string;
      result: EventTypeValidationResult;
      isSubmitted: boolean;
    }
  ) {}

  ngOnInit() {}

  onOk = () => {
    if (this.isSuccessfullySubmitted()) {
      this.dialogReg.close(true);
      return;
    }
    this.dialogReg.close();
  }

  isSuccessfullySubmitted() {
    return this.data.isSubmitted && this.data.result.isValid;
  }

}
