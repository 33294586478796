import { Injectable, Inject } from '@angular/core';
import { baseurl } from '../../../baseSettings';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConsumerPushNotificationDetails } from '../../data-consumer/models/consumer-push-notification';
import { APIResourceHealth } from '../../shared/models/api-resource-health';

@Injectable({
  providedIn: 'root'
})
export class ConsumerService {
  private URL = `${this.api}/api/Consumer`;


  constructor(
    private http: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  getResourceHealth(): Observable<Array<APIResourceHealth>> {
    return this.http.get<Array<APIResourceHealth>>(`${this.URL}/resource-health`);
  }

  getPushEndpoint(): Observable<ConsumerPushNotificationDetails> {
      return this.http.get<ConsumerPushNotificationDetails>(`${this.URL}/push`);
  }

  activatePushEndpoint(endpoint: string): Observable<ConsumerPushNotificationDetails> {
      return this.http.post<ConsumerPushNotificationDetails>(`${this.URL}/push/activate`, { endpoint: endpoint });
  }

  deactivatePushEndpoint(): Observable<any> {
    return this.http.post(`${this.URL}/push/deactivate`, null);
  }
}
