export class UserInvitation {
    id: string;
    email: string;
}

export class ClaimResult {
    isFirstLogin: boolean;
    status: ClaimStatus;
    currentEmail: string;
    newEmail: string;
}

export enum ClaimStatus {
    NotPending = 0,
    PendingFarmer = 1,
    PendingPartner = 2,
    OtherEmailFound = 3,
    EmailAlreadyRegistered = 4
}
