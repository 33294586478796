export interface DataPartnerSignUp {
    name: string;
    purpose: string;
    wantsToProvide: boolean;
    acceptTerms: boolean;
}

export enum DataPartnerSignUpResult {
    Created = 0,
    MailSendToAdminForEvaluation = 1
}