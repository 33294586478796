<h1 mat-dialog-title>{{ "validationDialog.title" | translate }}</h1>

<mat-dialog-content class="dialog-content">
  <div class="row section">
    <div class="col col-md-12" *ngIf="data.results.isValid">
      {{ "validationDialog.allDataValid" | translate }}
    </div>
    <div class="col col-md-12" *ngIf="!data.results.isValid">
      {{ "validationDialog.notAllDataValid" | translate }}
    </div>
  </div>

  <div *ngIf="data.results.credentialsValidationResult" class="row section">
    <div>
      <b class="col col-md-7">{{ "validationDialog.apiConfig" | translate }}</b>
      <b class="col col-md-2">{{ "validationDialog.config" | translate }}</b>
      <b class="col col-md-3">{{ "validationDialog.response" | translate }}</b>
    </div>
    <div>
      <span class="col col-md-7">{{
        "validationDialog.credentials" | translate
      }}</span>
      <span
        class="col col-md-2"
        *ngIf="data.results.credentialsValidationResult.isValid"
      >
        {{ "validationDialog.statusOk" | translate }}</span
      >
      <span
        class="col col-md-2"
        *ngIf="!data.results.credentialsValidationResult.isValid"
      >
        {{ "validationDialog.statusFail" | translate }}</span
      >

      <span class="col col-md-3"
        >{{ data.results.credentialsValidationResult.oAuthResponseCode }}
        {{ data.results.credentialsValidationResult.oAuthResponseReason }}</span
      >
    </div>
  </div>

  <div class="row section">
    <div>
      <b class="col col-md-7">{{ "validationDialog.resource" | translate }}</b>
      <b class="col col-md-2">{{ "validationDialog.config" | translate }}</b>
      <b class="col col-md-3">{{ "validationDialog.response" | translate }}</b>
    </div>
    <div *ngFor="let resource of data.results.resourceValidationResults">
      <span class="col col-md-7">{{ resource.name }}</span>
      <span class="col col-md-2" *ngIf="resource.isValid">
        {{ "validationDialog.statusOk" | translate }}</span
      >
      <span class="col col-md-2" *ngIf="!resource.isValid">
        {{ "validationDialog.statusFail" | translate }}</span
      >

      <span class="col col-md-3" *ngIf="resource.isFarmIdCallChecked">
        {{ resource.callResponse }}</span
      >
      <span class="col col-md-3" *ngIf="!resource.isFarmIdCallChecked">NA</span>
    </div>
  </div>
</mat-dialog-content>

<mat-dialog-actions class="pull-right">
  <button
    *ngIf="isSuccessfullySubmitted()"
    type="reset"
    (click)="onOk()"
    class="green"
  >
    {{ "actions.okWithSubmit" | translate }}
  </button>
  <button
    *ngIf="!isSuccessfullySubmitted()"
    type="reset"
    (click)="onOk()"
    class="green"
  >
    {{ "actions.ok" | translate }}
  </button>
</mat-dialog-actions>
