<h1>{{ "navigation.manageAdmins" | translate }}</h1>
<p>{{ "info.manageAdmins" | translate }}</p>
<div class="user-management">
  <app-manage-users
    [showExport]="true"
    [users]="admins"
    (remove)="removeAdmin($event)"
  >
  </app-manage-users>
</div>
<div>
  <app-manage-invites
    [invitees]="adminInvitees"
    [users]="admins"
    (invite)="inviteAdmin($event)"
    [showExport]="true"
  >
  </app-manage-invites>
</div>
