<h1>{{ "navigation.viewFarmResourceAccess" | translate }}</h1>
<p>{{ "info.farmResourceAccess" | translate }}</p>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="farmResourceAccessService"
  queryOnLangChange="true"
>
</app-base-remote-datatable>

<ng-template #farmNumberCell let-farmNumber="farmNumber">
  {{ farmNumber }}
</ng-template>

<ng-template #resourceNameCell let-resourceName="resourceName">
  {{ resourceName }}
</ng-template>

<ng-template #resourceStatusCell let-resourceStatus="resourceStatus">
  {{ resourceStatus | AccessRequestStatusTranslation | translate }}
</ng-template>

<ng-template #darStatusCell let-darStatus="darStatus">
  {{ darStatus | DarStatusTranslation | translate }}
</ng-template>

<ng-template #darStatusChangedDateCell let-darStatusChangedDate="darStatusChangedDate">
  <p class="text-cell" *ngIf="darStatusChangedDate; else noDate">
    {{ darStatusChangedDate | date : "dd/MM/yyyy" }}
  </p>
  <ng-template #noDate>
    <p>/</p>
  </ng-template>
</ng-template>