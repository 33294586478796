import { Component, OnInit, Input, SimpleChanges } from "@angular/core";
import { ObjectHelper } from "../../../../shared/helpers/object-helper";

@Component({
  selector: "app-manage-headers",
  templateUrl: "./manage-headers.component.html",
  styleUrls: ["./manage-headers.component.css"],
})
export class ManageHeadersComponent implements OnInit {
  @Input() object: any;
  @Input() isEditable: boolean;

  headerKvps: Array<{ key: string; value: string }>;

  constructor() {}

  ngOnInit() {
    this.headerKvps = ObjectHelper.objectToKvps(this.object.headers);
  }

  addNewHeader() {
    if (this.canAddNewHeader) {
      this.headerKvps.push({ key: null, value: null });
      this.changeApiHeaders();
    }
  }

  removeHeader(key) {
    this.headerKvps = this.headerKvps.filter((kvp) => kvp.key !== key);
    this.changeApiHeaders();
  }

  onHeadersChanged() {
    this.changeApiHeaders();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.object.currentValue.headers) {
      this.headerKvps = ObjectHelper.objectToKvps(
        changes.object.currentValue.headers
      );
      this.changeApiHeaders();
    }
  }

  private changeApiHeaders() {
    this.object.headers = ObjectHelper.kvpsToObject(this.headerKvps);
  }

  get canAddNewHeader(): boolean {
    return (
      this.headerKvps &&
      (this.headerKvps.length === 0 ||
        this.headerKvps.every((x) => {
          if (x.key && x.value) {
            return true;
          }
          return false;
        }))
    );
  }
}
