import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-apiheader',
  templateUrl: './api-header.component.html',
  styleUrls: ['./api-header.component.css']
})
export class ApiHeaderComponent implements OnInit {

  constructor() { }
  @Input() header: {key:string, value:string};
  @Input() isEditable: boolean;
  @Output() headersChanged = new EventEmitter();

  ngOnInit() {
  }

  onInputChange(input: string) {
    this.headersChanged.emit();
  }
}
