import { Pipe, PipeTransform } from '@angular/core';
import { AccessRequestStatus } from '../models/access-request-status';

@Pipe({
    name: 'AccessRequestStatusTranslation'
})
export class AccessRequestStatusTranslation implements PipeTransform {
    transform(value: AccessRequestStatus): any {
        return `models.accessRequest.statusses.${AccessRequestStatus[value]}`;
    }
}
