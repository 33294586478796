import { ResourceValidationResult } from './resource-validation-result';

export class ApiValidationResponse {
    isValid: boolean;
    resourceValidationResults: Array<ResourceValidationResult>;
    credentialsValidationResult: CredentialsValidationResult;
}

export class CredentialsValidationResult {
    isValid: boolean;
    oAuthResponseCode: string;
    oAuthResponseReason: string;
}
