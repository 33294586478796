<h1 mat-dialog-title>
  {{ "manageConsumerAccessPage.farmDialog.title" | translate }}
</h1>
<form [formGroup]="form" (ngSubmit)="onAdd(form.getRawValue().data)">
  <div mat-dialog-content>
    <mat-form-field>
      <input
        matInput
        formControlName="data"
        placeholder="{{
          'manageConsumerAccessPage.farmDialog.farmNumbers' | translate
        }}"
        cdkFocusInitial
      />
    </mat-form-field>
  </div>
  <div class="button-group">
    <button type="reset" (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button type="submit" [disabled]="!form.valid" class="green">
      {{ "actions.add" | translate }}
    </button>
  </div>
</form>
