import { Pipe, PipeTransform } from "@angular/core";
import { DarStatus } from "../models/data-access-request-status";

@Pipe({
  name: "FarmResourceAccessStatusTranslation",
})
export class FarmResourceAccessStatusTranslation implements PipeTransform {
  transform = (value: DarStatus): string =>
    `models.farmResourceAccess.statuses.${DarStatus[value]}`;
}
