import { isUndefined } from 'util';

export class ObjectHelper {
    static skipUndefined = fn => x => (!isUndefined(x) ? fn(x) : x);
    static skipUndefinedPipe = (...fns) => x => fns.reduce((arg, fn) => ObjectHelper.skipUndefined(fn)(arg), x);
    static pipe = (...fns) => x => fns.reduce((arg, fn) => fn(arg), x);
    static objToEntries = x => Object.entries(x);
    static entriesToObj = x => x.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
    static filter = filterFn => x => x.filter(filterFn);
    static map = mapFn => x => x.map(mapFn);
    static emptyIfUndefined = (obj: any) => obj ? obj : {};
    static undefinedIfEmpty = (array: []) => array.length > 0 ? array : undefined;
    static entryToKeyValuePair = (entry: [string, string]) => ({ key: entry[0], value: entry[1] });
    static kvpToEntry = (kvp: { key: string, value: string }) => [kvp.key, kvp.value];
    static entriesToKvps = (entries: []) => ObjectHelper.map(ObjectHelper.entryToKeyValuePair)(entries);
    static kvpsToEntries = (kvps: []) => ObjectHelper.map(ObjectHelper.kvpToEntry)(kvps);
  
    static objectToKvps = (obj) => ObjectHelper.pipe(
      ObjectHelper.emptyIfUndefined,
      ObjectHelper.objToEntries,
      ObjectHelper.entriesToKvps)(obj)
  
    static kvpsToObject = (obj) => ObjectHelper.pipe(
      ObjectHelper.undefinedIfEmpty,
      ObjectHelper.skipUndefined(ObjectHelper.kvpsToEntries),
      ObjectHelper.skipUndefined(ObjectHelper.entriesToObj))(obj)
  }
  