export class InitialSort {
    static None: InitialSort = new InitialSort('', '');

    constructor(
        public column: string,
        public direction: string = 'asc') {
    }

    getSortString() {
        if (this.column === '' && this.direction === '') {
            return null;
        }
        return `${this.column}-${this.direction}`;
    }
}