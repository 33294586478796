import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseurl } from "../../../baseSettings";
import { Observable } from "rxjs";
import { Page } from "../../shared/models/page";
import "./httpclient.extensions";
import { IPagedSortedFilteredService } from "./ipaged-sorted-filtered.service";
import { Ext } from "./httpclient.extensions";
import { ClearingHouseLog } from "../../data-consumer/models/clearing-house-log";

@Injectable({
  providedIn: "root",
})
export class ClearingHouseService
  implements IPagedSortedFilteredService<ClearingHouseLog>
{
  private URL = `${this.api}/api/ClearingHouse`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  get(
    pageNumber = 1,
    pageSize = 10,
    sort = null,
    filter = null
  ): Observable<Page<ClearingHouseLog>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<DarStatusSummary>(DarStatusSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<ClearingHouseLog>(
      this.httpClient,
      ClearingHouseLog,
      this.URL,
      pageNumber,
      pageSize,
      sort,
      filter
    );
  }
}
