import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { DatahubPartner, DatahubPartnerRole } from "../models/datahubPartner";
import { PartnerService } from "../../core/services/partner.service";
import { BaseDatatableComponent } from "../../shared/base-datatable/base-datatable.component";
import { SelectFilter } from "../../shared/base-datatable/models/select-filter";
import { Observable, Subscription } from "rxjs";
import { DataPartnerRoleTranslation } from "../../shared/pipes/partner-role-translation";
import { ActivityTranslation } from "../../shared/pipes/activity-translation";

@Component({
  selector: "app-manage-partners",
  templateUrl: "./manage-partners.component.html",
  styleUrls: ["./manage-partners.component.scss"],
})
export class ManagePartnersComponent implements OnInit, OnDestroy {
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("editHdrTmpl", { static: true }) editHdrTmpl: TemplateRef<any>;
  @ViewChild("activateTmpl", { static: true }) activateTmpl: TemplateRef<any>;
  @ViewChild("translationTmpl", { static: true })
  translationTmpl: TemplateRef<any>;
  @ViewChild("roleTmpl", { static: true }) roleTmpl: TemplateRef<any>;
  @ViewChild(BaseDatatableComponent, { static: true })
  partnerTable: BaseDatatableComponent;
  subscription = new Subscription();
  partnerRoleTranslation = new DataPartnerRoleTranslation();
  activityTranslation = new ActivityTranslation();
  partners = new Observable<Array<DatahubPartner>>();
  columns = [];

  constructor(
    private partnerService: PartnerService,
    private route: ActivatedRoute,
    private router: Router
  ) {}

  ngOnInit() {
    this.initColumns();
    this.getPartners();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initColumns() {
    this.columns = [
      {
        prop: "name",
        name: "models.datahubPartner.name",
        headerTemplate: this.partnerTable.hdrTranslationTpl,
        cellTemplate: this.translationTmpl,
        flexGrow: 3,
        sortable: false,
      },
      {
        prop: "companyName",
        name: "models.datahubPartner.companyName",
        headerTemplate: this.partnerTable.hdrTextTpl,
        flexGrow: 3,
      },
      {
        prop: "role",
        name: "models.datahubPartner.role",
        headerTemplate: this.partnerTable.hdrSelectTpl,
        cellTemplate: this.roleTmpl,
        flexGrow: 3,
        data: [
          new SelectFilter(
            DatahubPartnerRole.Unknown,
            this.partnerRoleTranslation.transform(DatahubPartnerRole.Unknown)
          ),
          new SelectFilter(
            DatahubPartnerRole.Consumer,
            this.partnerRoleTranslation.transform(DatahubPartnerRole.Consumer)
          ),
          new SelectFilter(
            DatahubPartnerRole.Provider,
            this.partnerRoleTranslation.transform(DatahubPartnerRole.Provider)
          ),
        ],
      },
      {
        prop: "isActive",
        name: "models.datahubPartner.isActive",
        headerTemplate: this.partnerTable.hdrSelectTpl,
        cellTemplate: this.activateTmpl,
        flexGrow: 3,
        data: [
          new SelectFilter(true, this.activityTranslation.transform(true)),
          new SelectFilter(false, this.activityTranslation.transform(false)),
        ],
      },
      {
        name: "actions.actions",
        headerTemplate: this.editHdrTmpl,
        cellTemplate: this.editTmpl,
        flexGrow: 2,
        sortable: false,
      },
    ];
  }

  viewDetails(partner: DatahubPartner) {
    this.router.navigate([`manage-partner-details`, partner.id], {
      relativeTo: this.route,
    });
  }

  addPartner() {
    this.router.navigate([`manage-partner-details`], {
      relativeTo: this.route,
    });
  }

  activatePartner(partnerId: string) {
    this.subscription.add(
      this.partnerService
        .activatePartner(partnerId)
        .subscribe(() => this.getPartners())
    );
  }

  private getPartners() {
    this.partners = this.partnerService.getAllPartners();
  }
}
