import {
    Component, OnInit, OnDestroy, ViewChild, TRANSLATIONS, ElementRef,
 } from '@angular/core';
import { Subscription, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { EventType, EventTypeStatus } from '../../models/event-type';
import { EventTypeError } from '../../models/event-type-error';
import { ConsentType } from '../../models/consent-type';
import { FarmIdType } from '../../models/farm-id-type';
import { FarmIdTypesService } from '../../../../modules/core/services/farmIdType.service';
import { PushEventsService } from '../../../../modules/core/services/push-events.service';
import { ResourceValidationResult } from '../../models/resource-validation-result';
import { ValidationResultDialogComponent } from '../validation-result-dialog/validation-result-dialog.component';
import { MatDialog } from "@angular/material/dialog";
import { AddHeadersDialogComponent } from "./add-headers-dialog/add-headers-dialog.component";
import { ActivatedRoute, Router } from "@angular/router";
import { UserAuthService } from "../../../core/user-auth.service";
//import { FileInputComponent } from 'ngx-material-file-input';
import {
  getDefaultTranslatableStringArray,
  TranslatableString,
} from "../../../shared/models/language";
import { UntypedFormBuilder } from "@angular/forms";

@Component({
  selector: "app-event-type",
  templateUrl: "./event-type.component.html",
  styleUrls: ["./event-type.component.scss"],
})
export class EventTypeComponent implements OnInit, OnDestroy {
  @ViewChild("documentation", { static: true }) documentation: ElementRef;
  error = new EventTypeError();
  consentOptions: Array<ConsentType>;
  eventType: EventType;
  maxRowSize: number;
  names: Array<TranslatableString>;
  descriptions: Array<TranslatableString>;
  isDescriptionFocused: boolean;
  subscription = new Subscription();
  farmIdTypes$: Observable<FarmIdType[]>;

  constructor(
    private form: UntypedFormBuilder,
    private farmIdTypesService: FarmIdTypesService,
    private pushEventsService: PushEventsService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private authService: UserAuthService
  ) {
    this.eventType = new EventType();
    this.names = getDefaultTranslatableStringArray();
    this.descriptions = getDefaultTranslatableStringArray();
  }

  ngOnInit() {
    const eventType$ = this.route.paramMap.pipe(
      switchMap((params) => {
        const id = params.get("id");
        if (id !== null) {
          return this.pushEventsService.getProviderEventType(id);
        }
        return of(
          Object.assign(new EventType(), {
            name: "my-event",
            consentType: ConsentType.NoConsentRequired,
          })
        );
      })
    );

    this.subscription.add(
      eventType$.subscribe((response) => {
        this.eventType = response;
        if (!this.eventType.translations) {
          this.names = getDefaultTranslatableStringArray();
        } else {
          this.names = this.eventType.translations.map((t) => {
            return {
              language: t.language,
              value: t.name,
            };
          });
        }
        if (!this.eventType.translations) {
          this.descriptions = getDefaultTranslatableStringArray();
        } else {
          this.descriptions = this.eventType.translations.map((t) => {
            return {
              language: t.language,
              value: t.description,
            };
          });
        }

        if (!this.eventType.translations) {
          this.eventType.translations = this.names.map((t) => {
            return {
              language: t.language,
              name: t.value,
              description: null,
            };
          });
          this.eventType.translations.forEach(
            (d) =>
              (this.descriptions.find((t) => t.language === d.language).value =
                d.description)
          );
        }

        // this.description = this.eventType.description;
        // this.isDescriptionFocused = false;
        // this.sliceDecription();
      })
    );

    this.consentOptions = this.initConsentTypes();
    this.initFarmIdTypes();
  }

  get isEditable() {
    if (this.eventType)
      return (
        this.eventType.status === undefined ||
        this.eventType.status === EventTypeStatus.Draft
      );
  }

    get canCreate() {
        return this.eventType.status === undefined || this.eventType.status === null;
    }

  create() {
    this.subscription.add(
      this.pushEventsService
        .createProviderEventType(this.eventType)
        .pipe(
          switchMap((id) => this.pushEventsService.getProviderEventType(id))
        )
        .subscribe((x) => {
          this.eventType = x;
          this.uploadDocument();
        })
    );
  }

  get canSave() {
    return this.eventType.status === EventTypeStatus.Draft;
  }

  save() {
    this.subscription.add(
      this.pushEventsService
        .saveProviderEventType(this.eventType)
        .pipe(
          switchMap((id) =>
            this.pushEventsService.getProviderEventType(this.eventType.id)
          )
        )
        .subscribe((x) => {
          this.eventType = x;
          this.uploadDocument();
        })
    );
  }

  uploadDocument() {
    // if (!this.documentation.empty)
    //     this.subscription.add(
    //         this.pushEventsService.uploadDocumentation(this.eventType.id, this.documentation.value.files[0]).subscribe()
    //     );
  }

  get canValidate() {
    return this.eventType.status === EventTypeStatus.Draft;
  }

  validate() {
    this.subscription.add(
      this.pushEventsService
        .validateProviderEventType(this.eventType)
        .subscribe((x) => {
          this.initEventTypeError(x);
          this.openValidationDialog(x, false);
        })
    );
  }

  get canSubmit() {
    return this.eventType.status === EventTypeStatus.Draft;
  }

  submit() {
    this.subscription.add(
      this.pushEventsService
        .submitProviderEventType(this.eventType)
        .pipe(
          switchMap((x) => {
            if (!x || x.isValid) {
              this.openValidationDialog(x, true);
              return this.pushEventsService.getProviderEventType(
                this.eventType.id
              );
            } else {
              this.initEventTypeError(x);
              this.openValidationDialog(x, true);
              return of(this.eventType);
            }
          })
        )
        .subscribe((x) => (this.eventType = x))
    );
  }

  get canPublish() {
    return (
      this.eventType.status === EventTypeStatus.Submitted &&
      this.authService.isAdmin()
    );
  }

  publish() {
    this.subscription.add(
      this.pushEventsService
        .publishProviderEventType(this.eventType.id)
        .pipe(
          switchMap((_) =>
            this.pushEventsService.getProviderEventType(this.eventType.id)
          )
        )
        .subscribe((x) => (this.eventType = x))
    );
  }

  reject() {
    this.subscription.add(
      this.pushEventsService
        .rejectProviderEventType(this.eventType.id)
        .pipe(
          switchMap((_) =>
            this.pushEventsService.getProviderEventType(this.eventType.id)
          )
        )
        .subscribe((x) => (this.eventType = x))
    );
  }

    initEventTypeError(validationResult: ResourceValidationResult) {
        if (
            validationResult === null || validationResult === undefined ||
            validationResult.errors === null || validationResult.errors === undefined
        ) {
            return;
        }

    this.error.name = this.getErrorMessage(validationResult, "Name");
    this.error.farmIdType = this.getErrorMessage(
      validationResult,
      "FarmIdTypeId"
    );
    this.error.farmIdUrl = this.getErrorMessage(validationResult, "FarmIdUrl");
    this.error.description = this.getErrorMessage(
      validationResult,
      "Description"
    );
  }

    getErrorMessage(validationResult: ResourceValidationResult, propertyName: string): string {
        const error = validationResult.errors.find(
            e => e.propertyName === propertyName
        );
        if (error === null || error === undefined) {
            return null;
        }
        return error.message;
    }

  onChangeFarmIdType() {
    this.error.farmIdType = null;
  }

  onChangeFarmIdUrl() {
    this.error.farmIdUrl = null;
  }

  onChangeName() {
    this.error.name = null;
  }

  nameChanged(names: Array<TranslatableString>) {
    names.forEach(
      (d) =>
        (this.eventType.translations.find(
          (t) => t.language === d.language
        ).name = d.value)
    );
  }

  descriptionChanged(descriptions: Array<TranslatableString>) {
    descriptions.forEach(
      (d) =>
        (this.eventType.translations.find(
          (t) => t.language === d.language
        ).description = d.value)
    );
  }

  // onDescriptionFocus() {
  //     this.maxRowSize = 3;
  //     this.description = this.eventType.description;
  //     this.error.description = null;
  // }

  // onDescriptionFocusOut() {
  //     this.maxRowSize = 1;
  //     this.eventType.description = this.description;
  //     this.sliceDecription();
  // }

  // onDescriptionClick() {
  //     this.isDescriptionFocused = !this.isDescriptionFocused;
  //     this.isDescriptionFocused
  //         ? this.onDescriptionFocus()
  //         : this.onDescriptionFocusOut();
  // }

  // sliceDecription() {
  //     if (this.eventType.description && this.eventType.description.length > 25) {
  //         this.description = this.eventType.description.slice(0, 25) + ' ...';
  //     }
  // }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private initFarmIdTypes() {
    this.farmIdTypes$ = this.farmIdTypesService.getAll();
  }

  private initConsentTypes(): any[] {
    const consentTypeValues = [];
    const consentTypeKeys = Object.values(ConsentType).filter((key) =>
      isNaN(Number(ConsentType[key]))
    );
    consentTypeKeys.forEach((x) => {
      const name = ConsentType[x].split(/(?=[A-Z])/).join(" ");
      const consentType: any = { index: x, name: name };
      consentTypeValues.push(consentType);
    });
    return consentTypeValues;
  }

  changeConsent(event: Event) {
    const selectedElement = parseInt(
      (event.target as HTMLSelectElement).value,
      10
    );
    if (
      !isNaN(selectedElement) &&
      (event.target as HTMLSelectElement).value !== ""
    ) {
      this.resetEventTypeWhenNoFarmerConsent(selectedElement);
    }
  }

  private resetEventTypeWhenNoFarmerConsent(selectedElement: number) {
    if (ConsentType.ProviderAndFarmerRequired !== selectedElement) {
      this.eventType.farmIdUrl = null;
      this.eventType.farmIdTypeId = null;
      this.eventType.translations.forEach((t) => {
        t.description = null;
      });
    }
  }

  get isFarmerConsent() {
    return (
      this.eventType.consentType !== null &&
      this.eventType.consentType === ConsentType.ProviderAndFarmerRequired
    );
  }

  private openValidationDialog(validationResult: any, isSubmitted: boolean) {
    this.dialog.open(ValidationResultDialogComponent, {
      width: "fit-content",
      disableClose: true,
      data: {
        farmIdUrl: this.eventType.farmIdUrl,
        result: validationResult,
        isSubmitted: isSubmitted,
      },
    });
  }

  addHeaders() {
    const dialogRef = this.dialog.open(AddHeadersDialogComponent, {
      width: "fit-content",
      disableClose: true,
      data: { isEditable: this.isEditable, headers: this.eventType.headers },
    });
    this.subscription.add(
      dialogRef
        .afterClosed()
        .subscribe((headers) => (this.eventType.headers = headers))
    );
  }

  back = () => {
    this.router.navigate([`manage-push-events`]);
  };

  delete() {
    if (this.eventType.id) {
      this.subscription.add(
        this.pushEventsService
          .deleteProviderEventType(this.eventType.id)
          .subscribe((response) => this.back())
      );
    }
  }
}
