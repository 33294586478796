import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-certificate-confirmation-dialog',
  templateUrl: './certificate-confirmation-dialog.component.html',
  styleUrls: ['./certificate-confirmation-dialog.component.css']
})
export class CertificateConfirmationDialogComponent implements OnInit {

  constructor(public dialogReg: MatDialogRef<CertificateConfirmationDialogComponent>) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialogReg.close(null);
  }

  OnConfirm() {
    this.dialogReg.close(true);
  }

}
