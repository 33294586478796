import { UserRole } from "../../shared/models/datahubUser";
import { UserInvitation } from "../../shared/models/userInvitation";

export class ManageUnclaimedInvitesSummary {
    partnerName: string;
    createdAt: Date;
    email: string;
    userRole: UserRole;
    isSignUp: boolean;
    kbo: string;
    invitation: string;
}

