<p class="mb-30">{{ 'info.resourceHealth' | translate }}</p>
<app-base-datatable #healthTable
  [rows]="resourceHealth$ | async"
  [columns]="columns"
  [headerHeight]="70"
  [footerHeight]="50"
  [limit]="10">
</app-base-datatable>

<ng-template #healthTmp let-value="value">
    <div class="float-right">
        <div>{{ value | ApiResourceHealthTranslation | translate }}</div>
    </div>
</ng-template>