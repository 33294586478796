<div class="header-part">
  <h1>{{ consumer.name }}</h1>
  <img
    class="app-logo"
    src="{{ this.baseurl + consumer.applicationLogoUrl }}"
    *ngIf="hasApplicationLogoUrl(); else dclogo"
    [alt]="consumer.companyName"
  />
  <ng-template #dclogo>
    <img
      class="app-logo"
      alt="blauwmiddel"
      src="/assets/images/blauwMiddel 1.svg"
    />
  </ng-template>
</div>
<form [formGroup]="darStatusForm">
  <div class="description-part">
    <h2>
      {{ consumer.companyName }}
    </h2>
    <p>
      {{ consumer.description }}
    </p>
    <ng-template [ngIf]="consumer?.applicationUrl">
      <div class="more-info">
        <p class="bold">{{ "info.moreInfo" | translate }}</p>
        <a [href]="consumer.applicationUrl" target="_blank">
          {{
            "dataAccessRequestPage.card.goToApplication" | translate : consumer
          }}
        </a>
      </div>
    </ng-template>
  </div>

  <div class="datatable-part" *ngIf="requestsPending.controls.length > 0">
    <p *ngIf="!informativeFlow" class="lowercase">{{ "info.dataAccessRequests" | translate }}</p>
    <p *ngIf="informativeFlow">{{ "info.informativeFlowAccessRights" | translate }}</p>
    <div *ngIf="!informativeFlow" class="button-group">
      <button (click)="approveAllPending()" class="green" [disabled]="arePending() ? null : true">
        {{ "dataAccessRequestPage.card.approveAllPending" | translate }}
      </button>
      <button (click)="rejectAllPending()" class="rose" [disabled]="arePending() ? null : true">
        {{ "dataAccessRequestPage.card.rejectAllPending" | translate }}
      </button>
    </div>
  
    <div class="table">
      <div class="table-header">
        <div class="header-part" *ngFor="let column of columns">
          <p class="bold">{{ column.name | translate }}</p>
        </div>
      </div>
      <div class="table-body" formArrayName="requestsPending">
        <div
          class="table-row"
          *ngFor="let rq of requestsPending.controls; index as pendingIndex"
          [formGroupName]="pendingIndex"
        >
          <div class="table-row-part">
            <p>{{ rq.get('resourceName').value }}</p>
          </div>
          <div class="table-row-part">
            <p>{{ rq.get('resourceDescription').value }}</p>
            <a 
              *ngIf="rq.get('resourceDocumentationUrl').value"
              [href]="rq.get('resourceDocumentationUrl').value" 
              target="_blank"
            >
                {{ "dataAccessRequestPage.readMore" | translate }}
            </a>
          </div>
          <div class="table-row-part">
            <p *ngIf="this.informativeFlow" class="bg-approved activeStatus">
              {{ "models.accessRequest.statusses.informativeFlow" | translate }}
            </p>
            <app-approve-reject-selector
              *ngIf="!this.informativeFlow"
              formControlName="status"
            ></app-approve-reject-selector>
          </div>
        </div>
      </div>
      <!--p class="total">
        {{ "grid.total" | translate }}:
        {{ requestsPendingArray.length }}
      </p-->
    </div>
  </div>

  <div class="datatable-part" *ngIf="requestsHandled.controls.length > 0">
    <p *ngIf="!informativeFlow">{{ "info.dataAccessRequestsTreated" | translate | uppercase }}</p>
    <p *ngIf="informativeFlow">{{ "info.informativeFlowAccessRights" | translate | uppercase }}</p>
    <div class="table">
      <div class="table-header">
        <div class="header-part" *ngFor="let column of columns">
          <p class="bold">{{ column.name | translate }}</p>
        </div>
      </div>
      <div class="table-body" formArrayName="requestsHandled">
        <div
          class="table-row"
          *ngFor="let rq of requestsHandled.controls; index as handledIndex"
          [formGroupName]="handledIndex"
        >
          <div class="table-row-part">
            <p>{{ rq.get('resourceName').value }}</p>
          </div>
          <div class="table-row-part">
            <p>{{ rq.get('resourceDescription').value }}</p>
            <a 
              *ngIf="rq.get('resourceDocumentationUrl').value"
              [href]="rq.get('resourceDocumentationUrl').value" 
              target="_blank"
            >
                {{ "dataAccessRequestPage.readMore" | translate }}
            </a>
          </div>
          <div class="table-row-part">
            <p *ngIf="this.informativeFlow" class="bg-approved activeStatus">
              {{ "models.accessRequest.statusses.informativeFlow" | translate }}
            </p>
            <app-approve-reject-selector
              *ngIf="!this.informativeFlow"
              formControlName="status"
            ></app-approve-reject-selector>
          </div>
        </div>
      </div>
      <!--p class="total">
        {{ "grid.total" | translate }}:
        {{ requestsHandledArray.length }}
      </p-->
    </div>
  </div>
</form>
<button class="green" (click)="goBack()">
  {{ "actions.back" | translate }}
</button>
