import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { MsalBroadcastService } from "@azure/msal-angular";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { TranslateService } from "@ngx-translate/core";
import { Subject, Subscription, filter, takeUntil } from "rxjs";
import { appVersion, environment } from "./baseSettings";
import { UserAuthService } from "./modules/core/user-auth.service";
import { BrowserDialogComponent } from "./modules/shared/browser-dialog/browser-dialog.component";
import { NotificationDialogComponent } from "./modules/shared/dialog/notification-dialog/notification-dialog.component";
import { UserRole } from "./modules/shared/models/datahubUser";
import { UserContextDTO } from "./modules/shared/models/userContextDTO";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
})
export class AppComponent implements OnInit, OnDestroy {
  title = "Ilvodatahub";
  subscription: Subscription;
  isLoggedInAndHasRole = false;
  isLoggedIn = false;
  hasMultipleContexts = true;
  email: string;
  context: UserContextDTO;
  dataSource: any = [];
  public isIframe: boolean;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private broadcastService: MsalBroadcastService,
    private translate: TranslateService,
    private userAuthService: UserAuthService,
    private dialog: MatDialog,
    private appInsights: ApplicationInsights,
    @Inject(appVersion) public version: string,
    @Inject(environment) public env: string
  ) {
    this.subscription = new Subscription();
    this.context = new UserContextDTO();

    this.isIframe = window !== window.parent && !window.opener;
    const bannerCookie = this.GetBannerCookieValue();
    if (bannerCookie && bannerCookie.functional) {
      this.appInsights.loadAppInsights();
      this.appInsights.trackPageView();
    } else {
      this.userAuthService.cleanupCookie();
    }

   if (!this.isBrowserCompatibile()) {
      this.dialog.open(BrowserDialogComponent);
    }
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang("nl");

    this.userAuthService.initContexts();
  }

  ngOnInit() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result) => {
          const payload = result.payload as AuthenticationResult;
          this.userAuthService.setActiveAccount(payload.account);

          this.appInsights.setAuthenticatedUserContext(
            this.userAuthService.msalService.instance.getAllAccounts()[0]
              .homeAccountId
          );
      });
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_FAILURE)
      )
      .subscribe((result) => {
        this.logout();
      });

    this.broadcastService.msalSubject$
      .pipe(
        filter(
          (msg: EventMessage) =>
            msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE
        )
      )
      .subscribe((result) => {
        this.sessionExpired();
      });

    this.subscription.add(
      this.userAuthService
        .getAllContexts()
        .subscribe(
          (results) =>
            (this.hasMultipleContexts = results && results.length > 1)
        )
    );

    this.subscription.add(
      this.userAuthService
      .notifyIsLoggedInChanged()
      .subscribe((isLoggedIn)=>{
        this.isLoggedIn = isLoggedIn;
        this.isLoggedInAndHasRole = this.userAuthService.isLoggedInAndHasRole();
        if (this.isLoggedIn) {
          if (this.userAuthService.isTokenExpired()) {
            this.logout();
          } else {
            this.email =
              this.userAuthService.msalService.instance.getAllAccounts()[0].name;
            const bannerCookie = this.GetBannerCookieValue();
            if (bannerCookie && bannerCookie.functional) {
              this.appInsights.setAuthenticatedUserContext(
                this.userAuthService.msalService.instance.getAllAccounts()[0]
                  .homeAccountId
              );
            }
          }
        }
      })
    );

    this.subscription.add(
      this.userAuthService
        .notifyCurrentContextChanged()
        .subscribe((context) => {
          this.isLoggedIn = this.userAuthService.isLoggedIn();
          this.isLoggedInAndHasRole =
            this.userAuthService.isLoggedInAndHasRole();
          this.context = new UserContextDTO();
          this.context.roleName = context.roleName;
          if (context.roleName === UserRole.Admin) {
            this.context.entityName = null;
          } else {
            this.context.entityName = context.entityName;
          }
        })
    );

    this.broadcastService.inProgress$
      .pipe(
        filter(
          (status: InteractionStatus) => status === InteractionStatus.None
        ),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {});
  }

  getClaims(claims: Record<string, any>) {
    if (claims) {
      Object.entries(claims).forEach(
        (claim: [string, unknown], index: number) => {
          this.dataSource.push({ id: index, claim: claim[0], value: claim[1] });
        }
      );
    }
  }

  ngOnDestroy() {
    this._destroying$.next(undefined);
    this.subscription.unsubscribe();
    this.appInsights.stopTrackPage();
    this.appInsights.flush();
  }

  logout() {
    const bannerCookie = this.GetBannerCookieValue();
    if (bannerCookie && bannerCookie.functional) {
      this.appInsights.clearAuthenticatedUserContext();
    }

    this.userAuthService.msalService.logoutRedirect();
    this.userAuthService.cleanupAfterLogout()
  }
  
  sessionExpired() {
    const dialogRef = this.dialog.open(NotificationDialogComponent, {
      data: {
        title: "session.expired.title",
        text: "session.expired.text",
      },
    });
    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        this.logout();
      })
    );
  }

  get isDevEnv() {
    return this.env === "dev";
  }

  isBrowserCompatibile(): boolean {
    return (
      this.isChrome() ||
      this.isOpera() ||
      this.isEdge() ||
      this.isFirefox() ||
      this.isSafari()
    );
  }

  hasCookie = function () {
    let ck = getCookie("cookie-agreed");
    if (ck && ck != "") return true;

    return false;
  };

  GetBannerCookieValue() {
    const cookieValue = getCookie("cookie-agreed");
    if (cookieValue) return cookieValue;
    return null;
  }

  private isChrome(): boolean {
    return (
      navigator.userAgent.indexOf("Chrome") > -1 &&
      !this.isOpera() &&
      !this.isEdge()
    );
  }
  private isEdge(): boolean {
    return navigator.userAgent.indexOf("Edge") > -1;
  }
  private isFirefox(): boolean {
    return navigator.userAgent.indexOf("Firefox") > -1;
  }
  private isOpera(): boolean {
    return navigator.userAgent.indexOf("OPR") > -1;
  }
  private isSafari(): boolean {
    return navigator.userAgent.indexOf("Safari") > -1 && !this.isChrome();
  }
}

function getCookie(name: string) {
  const cookies = document.cookie.split("; ");
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const parts = cookie.split("=");
    if (parts[0] === name) {
      return JSON.parse(parts[1]);
    }
  }
  return null;
}
