<footer>
  <p *ngIf="isDevEnv">
    {{ "layout.footer.version" | translate : { version: version } }}
  </p>
  <ul class="about">
    <li>
      <h2>DJUSTCONNECT</h2>
    </li>
    <li>
      <p class="description">{{ "layout.footer.baseline" | translate }}</p>
    </li>
  </ul>
  <ul class="logos">
    <li>
      <img
        alt="EU img"
        src="../../../../assets/images/europese-unie.png"
        width="100"
      />
    </li>
    <li>
      <img
        alt="EFRO img"
        src="../../../../assets/images/EFRO.png"
        width="170"
      />
    </li>
    <li>
      <img
        alt="VLAIO img"
        src="../../../../assets/images/logo-vlaio.png"
        width="170"
      />
    </li>
    <li>
      <img
        alt="ILVO img"
        src="../../../../assets/images/ILVO.jpg"
        width="170"
      />
    </li>
  </ul>
  <div class="socialsandstatements">
    <div class="statements">
      <a href="https://djustconnect.be/nl/privacyverklaring" target="_blank">
        {{ "layout.footer.privacyStatement" | translate }}
      </a>
      <br>
      <a href="https://djustconnect.be/nl/cookieverklaring" target="_blank">
        {{ "layout.footer.cookieStatement" | translate }}
      </a>
    </div>
      <a
        href="https://www.facebook.com/DjustConnect"
        target="_blank"
        aria-label="Facebook ILVO"
        class="social first-social"
        ><img
          alt="Facebook logo"
          src="../../../../assets/images/facebook.svg"
          width="50"
      /></a>
      <a
        href="https://www.instagram.com/djustconnect/"
        target="_blank"
        aria-label="Instagram ILVO"
        class="social"
        ><img
          alt="Instagram logo"
          src="../../../../assets/images/instagram.svg"
          width="50"
      /></a>
      <a
        href="https://X.com/DjustConnect"
        target="_blank"
        aria-label="X ILVO"
        class="social"
        ><img
          alt="X logo"
          src="../../../../assets/images/X.svg"
          width="50"
      /></a>
      <a
        href="https://www.linkedin.com/company/djustconnect/"
        target="_blank"
        aria-label="LinkedIn ILVO"
        class="social"
        ><img
          alt="LinkedIn Logo"
          src="../../../../assets/images/linkedin.svg"
          width="50"
      /></a>
      <a
        href="https://www.youtube.com/channel/UC23_PJrPYkgxH_qhUdHdRvg"
        target="_blank"
        aria-label="YouTube ILVO"
        class="social"
        ><img
          alt="YouTube Logo"
          src="../../../../assets/images/youtube.svg"
          width="50"
      /></a>
  </div>
</footer>
