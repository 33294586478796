<iframe
  src="https://sailvodatahubprod01.blob.core.windows.net/cookie-check/cookie.html"
  style="display: none"
></iframe>
<div>
  <div class="register-content d-flex-evenly flex-wrap mb-30">
    <mat-card class="register-content-item flex-base">
      <form novalidate>
        <p
          class="ymargins pre-wrap colored text-left"
          [innerHtml]="'landingPage.noRoles.greeting' | translate"
        ></p>
        <p
          class="ymargins pre-wrap colored text-left"
          [innerHtml]="'landingPage.noRoles.introduction' | translate  : {firstName: firstName, lastName: lastName}"
        ></p>
        <p
          class="ymargins pre-wrap colored text-left"
          [innerHtml]="'landingPage.noRoles.introductionP2' | translate  : {firstName: firstName, lastName: lastName}"
        ></p>

        <section>
          <label for="noRoles-legalRepresentive">
            <h2>
              {{ "landingPage.noRoles.legalRepresentative" | translate : {firstName: firstName} }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="noRoles-legalRepresentive"
            id="noRoles-legalRepresentive"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.noRoles.instruction.legalRepresentative' | translate"
                ></p>
                <button
                  class="action-button" 
                  (click)="loginAsLegalRepresentative()" 
                  [innerHtml]="'landingPage.noRoles.action.legalRepresentative' | translate"
                ></button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
        <section>
          <label for="noRoles-employee">
            <h2>
              {{ "landingPage.noRoles.employee" | translate : {firstName: firstName} }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="noRoles-employee"
            id="noRoles-employee"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.noRoles.instruction.employee' | translate"
                ></p>
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.noRoles.instruction.employeeP2' | translate"
                ></p>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
        <section>
          <label for="noRoles-otherEmail">
            <h2>
              {{ "landingPage.noRoles.otherEmail" | translate : {firstName: firstName} }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="noRoles-otherEmail"
            id="noRoles-otherEmail"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p 
                  class="pre-wrap colored text-left"
                  [innerHTML]="'landingPage.noRoles.instruction.otherEmail' | translate"
                ></p>
                <button
                  class="action-button"
                  (click)="gotoRegisterPage()" 
                  [innerHtml]="'landingPage.noRoles.action.otherEmail' | translate"
                ></button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>

        <section>
          <label for="noRoles-reuseEmail">
            <h2>
              {{ "landingPage.noRoles.reuseEmail" | translate : {firstName: firstName, emailAddress: emailAddress} }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="noRoles-reuseEmail"
            id="noRoles-reuseEmail"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.noRoles.instruction.reuseEmail' | translate : {emailAddress: emailAddress}"
                ></p>
                <button
                  class="action-button"
                  (click)="reuseEmail()" 
                  [innerHtml]="'landingPage.noRoles.action.reuseEmail' | translate"
                ></button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
        
        <section>
          <label for="noRoles-deleteUser">
            <h2>
              {{ "landingPage.noRoles.deleteUser" | translate : {firstName: firstName} }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="noRoles-deleteUser"
            id="noRoles-deleteUser"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p class="pre-wrap colored text-left">
                  {{"landingPage.noRoles.instruction.deleteUser" | translate}}
                </p>
                <button 
                  class="action-button delete-button" 
                  (click)="deleteUserAccountConfirmation()" 
                  [innerHtml]="'actions.deleteUserAccount' | translate">
                </button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
      </form>
    </mat-card>
  </div>
</div>
