export class APIResourceHealth {
    companyName: string;
    resourceId: string;
    resourceName: string;
    resourceHealth: ResourceHealth;
}

export enum ResourceHealth {
    Unknown,
    NOK,
    OK
}