import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ManagePartnerUsersComponent } from './manage-partner-users/manage-partner-users.component';
import { RouterModule } from '@angular/router';
import { UserModule } from '../user/user.module';
import { PartnerDetailsComponent } from './partner-details/partner-details.component';
import {
  CertificateConfirmationDialogComponent
} from './partner-details/certificate-confirmation-dialog/certificate-confirmation-dialog.component';
import { SharedModule } from '../shared/shared.module';
import { RoleGuard } from '../shared/guards/role-guard';
import { AuthGuard } from '../shared/guards/auth-guard';

@NgModule({
    declarations: [
        ManagePartnerUsersComponent,
        PartnerDetailsComponent,
        CertificateConfirmationDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UserModule,
        RouterModule.forChild([
            {
                path: 'manage-partner-users',
                canActivate: [AuthGuard, RoleGuard],
                component: ManagePartnerUsersComponent,
                data: {
                    breadcrumb: 'navigation.partnerDetails',
                    roles: ['Consumer', 'Provider']
                }
            },
            {
                path: 'partner-details',
                canActivate: [AuthGuard, RoleGuard],
                component: PartnerDetailsComponent,
                data: {
                    breadcrumb: 'navigation.partnerDetails',
                    roles: ['Consumer', 'Provider']
                }
            }
        ])
    ],
    exports: [
        ManagePartnerUsersComponent
    ]
})
export class PartnerModule { }
