import { Component, Inject, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { externalAuthority } from "../../../../baseSettings";
import { UserAuthService } from "../../../core/user-auth.service";
import { MsalBroadcastService } from "@azure/msal-angular";

@Component({
  selector: "app-not-logged-in-start-page",
  templateUrl: "./not-logged-in-start-page.component.html",
  styleUrls: ["./not-logged-in-start-page.component.scss"],
})
export class NotLoggedInStartPageComponent implements OnInit {
  constructor(
    private userAuthService: UserAuthService,
    private route: ActivatedRoute,
    @Inject(externalAuthority) private extAuth: string,
    private broadcastService: MsalBroadcastService
  ) {}

  ngOnInit() {
    this.userAuthService.msalService.initialize();

    this.route.queryParams.subscribe((params) => {
      const login = params["login"];
      if (login) {
        this.userAuthService.msalService.loginRedirect({
          prompt: "login",
          scopes: [],
        });
      }
    });
  }

  login() {
    localStorage.removeItem("useflow");
    this.userAuthService.msalService.loginRedirect({
      prompt: "login",
      scopes: [],
    }); // Forcing re-authentication is not required, but preferable UX-wise.
  }

  loginExternal() {
    localStorage.setItem("useflow" , this.extAuth);
    this.userAuthService.msalService.loginRedirect({
      prompt: "login",
      authority: this.extAuth,
      scopes: [],
    }); // Login via Azure AD
  }
}
