import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirmation-dialog",
  templateUrl: "./confirmation-dialog.component.html",
  styleUrls: ["./confirmation-dialog.component.css"],
})
export class ConfirmationDialogComponent {
  title: string;
  textParams;

  constructor(
    public dialogReg: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {
    this.title = data.title;
    this.textParams = data.textParams;
  }

  onCancel() {
    this.dialogReg.close(false);
  }

  onConfirm() {
    this.dialogReg.close(true);
  }
}
