<div class="d-flex-column">
  <mat-dialog-content class="text-center">{{
    title | translate : textParams
  }}</mat-dialog-content>
  <div class="button-group">
    <button (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button (click)="onConfirm()" class="green">
      {{ "actions.confirm" | translate }}
    </button>
  </div>
</div>
