<div class="select">
    <button *ngIf="!viewChangePending(viewStatus)"
        (click)="toggleSelect()"
        class="{{ getClass(status) }}"
        style="padding: 0.5rem 1rem !important"
    >
        {{
        "models.accessRequest.statusses." +
            getText(status) 
            | translate
        }}
        <div *ngIf="viewShowClickOpen(viewStatus)">
            <i class="fas fa-chevron-down"></i>
        </div>
    </button>
    <div class="select-options" *ngIf="viewShowApprove(viewStatus)">
        <label
            (click)="onSubmit(1)"
            class="bg-approved"
        >
            {{ "dataAccessRequestPage.details.approve" | translate }}
        </label>
        <div *ngIf="viewMustShowStatus(viewStatus)"
            (click)="toggleSelect()" 
            class="chevron"
        >
            <i class="fas fa-chevron-up"></i>
        </div>
    </div>
    <div class="select-options" *ngIf="viewShowReject(viewStatus)">
        <label 
          (click)="onSubmit(2)"
          class="bg-denied"
        >
            {{ "dataAccessRequestPage.details.reject" | translate }}
        </label>
        <div *ngIf="viewMustShowStatus(viewStatus)"
          (click)="toggleSelect()" 
          class="chevron"
        >
            <i class="fas fa-chevron-up"></i>
        </div>
    </div>
</div>
