import { ValidatorFn, AbstractControl, FormGroup, ValidationErrors, Validators } from "@angular/forms";
import { Injectable } from "@angular/core";
import { Language, TranslatableString } from "../../shared/models/language";
import {
  FarmIdType,
  FarmIdTypeEnum,
} from "../../data-provider-module/models/farm-id-type";

@Injectable()
export class ValidatorService {
  private urlRe: RegExp = new RegExp(
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
  );

  private httpsUrlRe: RegExp = new RegExp(
    "(https://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})"
  );

  urlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url = this.urlRe.test(control.value);
      return url ? null : { invalidUrl: { value: control.value } };
    };
  }

  httpsUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const url = this.httpsUrlRe.test(control.value);
      return url ? null : { invalidUrl: { value: control.value } };
    };
  }

  nullableUrlValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      if (control.value) {
        const url = this.urlRe.test(control.value);
        return url ? null :  { invalidUrl: { value: control.value }};
      }
      return null;
    };
  }

  nameValidator(): ValidatorFn {
    const nameReg: RegExp = /^[a-zA-Z0-9_ \&\%\~\.\-]+$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      let hasInvalidCharacters: Boolean = false;
      if (control.value) {
        hasInvalidCharacters = control.value.search(nameReg) === -1;
      }
      return hasInvalidCharacters
        ? { invalidName: { value: control.value } }
        : null;
    };
  }

  translatableNameValidator(): ValidatorFn {
    const nameReg: RegExp = /^[a-zA-Z0-9_ \&\%\~\.\-]+$/;
    return (control: AbstractControl): { [key: string]: any } | null => {
      let hasInvalidCharacters: Boolean = false;
      let hasNoDefaultName: Boolean = true;
      if (control.value) {
        hasNoDefaultName = !!control.value.find(
          (v: TranslatableString) => v.language === Language.en && v.value
        );
        hasInvalidCharacters = control.value.every(
          (v: TranslatableString) => v.value.search(nameReg) === -1
        );
      }
      return !hasNoDefaultName || hasInvalidCharacters
        ? { invalidName: { value: control.value } }
        : null;
    };
  }

  fileSizeValidator(maxSize: number): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const file = control.value;
      if (file?.files && file.files[0]) {
        if (file.files[0].size > maxSize)
          return {
            fileSizeValidator: true
          };
      }
      return null;
    };
  }
}
