<!--TODO add dashboard of pending requests and registrations?-->
<ng-template [ngIf]="hasRole">
  <app-context-start-page [role]="role"></app-context-start-page>
</ng-template>
<ng-template [ngIf]="!isLoading && isLoggedIn && validId && !isLoadingRole && !hasRole">
  <app-no-role-start-page [userId]="userId" [identityId]="identityId" [firstName]="firstName" [lastName]="lastName" [emailAddress]="emailAddress"></app-no-role-start-page>
</ng-template>
<ng-template [ngIf]="!isLoading && isLoggedIn && !validId && !isLoadingRole">
  <app-not-registered-start-page [firstName]="firstName" [lastName]="lastName" [loggedInWithKbo]="loggedInWithKbo"></app-not-registered-start-page>
</ng-template>
<ng-template [ngIf]="!isLoading && !isLoggedIn">
  <app-not-logged-in-start-page></app-not-logged-in-start-page>
</ng-template>
