<ng-template [ngIf]="isAdmin">
  <h1>{{ "navigation.homePage.adminWelcome.title" | translate }}</h1>
  <span>{{ "navigation.homePage.adminWelcome.content" | translate }}</span>
  <app-admin-helpdesk-table-users />
</ng-template>
<ng-template [ngIf]="isConsumer">
  <h1>{{ "navigation.homePage.consumerWelcome.title" | translate }}</h1>
  <span>{{ "navigation.homePage.consumerWelcome.content" | translate }}</span>
</ng-template>
<ng-template [ngIf]="isProvider">
  <h1>{{ "navigation.homePage.providerWelcome.title" | translate }}</h1>
  <span>{{ "navigation.homePage.providerWelcome.content" | translate }}</span>
</ng-template>
