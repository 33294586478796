<h1>{{ "manageUsers.title" | translate }}</h1>
<p>{{ "info.partnerUsers" | translate }}</p>
<app-manage-users 
  [showExport]="false"
  [users]="users" 
  (remove)="removeUser($event)">
</app-manage-users>
<app-manage-invites
  [showExport]="false"
  [invitees]="invitees"
  [users]="users"
  (invite)="inviteUser($event)"
>
</app-manage-invites>
