<div class="part">
  <h1>{{ "navigation.manageDataAccess" | translate }}</h1>
  <p *ngIf="haveConsumers">{{ "info.dataAccessRequestCards" | translate }}</p>
  <ul *ngIf="haveConsumers">
    <li>
      {{ "dataAccessRequestPage.list.firstItem" | translate }}
    </li>
    <li>
      {{ "dataAccessRequestPage.list.secondItem" | translate }}
    </li>
    <li>
      {{ "dataAccessRequestPage.list.thirdItem" | translate }}
    </li>
  </ul>
</div>

<div class="part" *ngIf="havePending">
  <h2>{{ "info.dataAccessRequestsOpen" | translate }}</h2>

  <div class="part-container">
    <app-consumer-requests-card
      [data]="pending[i]"
      *ngFor="let index of pending; let i = index"
    >
    </app-consumer-requests-card>
  </div>
</div>

<div class="part" *ngIf="haveHandled">
  <h2>{{ "info.dataAccessRequestsHandled" | translate }}</h2>
  <div class="part-container">
    <app-consumer-requests-card
      [data]="handled[i]"
      *ngFor="let index of handled; let i = index"
    >
    </app-consumer-requests-card>
  </div>
</div>

<div class="part" *ngIf="haveNoConsumers">
  <p [innerHtml]="'dataAccessRequestPage.noData' | translate">
  </p>
</div>

<div class="part" *ngIf="haveInformativeFlow">
  <h2>
    {{ "info.informativeFlow" | translate | uppercase }}
  </h2>
  <p>
    {{ "info.informativeFlowText" | translate }}
  </p>
  <div class="part-container">
    <div
      class="part-container-card"
      *ngFor="let index of informativeFlowArray; let i = index"
    >
      <ng-template [ngIf]="!((i + 1) % 2)">
        <app-informative-flow-card [data]="informativeFlowArray[i - 1]">
        </app-informative-flow-card>
        <app-informative-flow-card [data]="informativeFlowArray[i]">
        </app-informative-flow-card>
      </ng-template>
      <ng-template
        [ngIf]="i + 1 === informativeFlowArray.length && (i + 1) % 2 > 0"
      >
        <app-informative-flow-card [data]="informativeFlowArray[i]">
        </app-informative-flow-card>
      </ng-template>
    </div>
  </div>
</div>
