import { ClipboardModule } from "ngx-clipboard";
import { InvitationComponent } from "./invitation/invitation.component";
import { MaterialModule } from "./material.module";
import { CommonModule } from "@angular/common";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { Angular2PromiseButtonModule } from "angular2-promise-buttons";
import { TranslateModule, TranslateLoader } from "@ngx-translate/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { NavMenuComponent } from "./nav-menu/nav-menu.component";
import { BreadcrumbComponent } from "./breadcrumb/breadcrumb.component";
import { SelectLanguageComponent } from "./language/select-language/select-language.component";
import { RouterModule } from "@angular/router";
import { HttpClientModule } from "@angular/common/http";
import { NgxDatatableModule } from "@swimlane/ngx-datatable";
import { SubscriptionKeyComponent } from "./subscription-key/subscription-key.component";
import { CertificateComponent } from "./certificate/certificate.component";
import { NotificationDialogComponent } from "./dialog/notification-dialog/notification-dialog.component";
import { BaseDatatableComponent } from "./base-datatable/base-datatable.component";
import { NgSelectModule } from "@ng-select/ng-select";
import { RegisterPartnerComponent } from "./register/register-partner/register-partner.component";
import { RegisterPartnerDetailsComponent } from "./register/register-partner/register-partner-details/register-partner-details.component";
import { RegisterFarmerComponent } from "./register/register-farmer/register-farmer.component";
import { EllipsisModule } from "ngx-ellipsis";
import { ConfirmationDialogComponent } from "./confirmation-dialog/confirmation-dialog.component";
import { MessageDialogComponent } from "./message-dialog/message-dialog.component";
import { ContextComponent } from "./context/context.component";
import { MatButtonModule } from "@angular/material/button";
import { MatCardModule } from "@angular/material/card";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatToolbarModule } from "@angular/material/toolbar";
//angular update todo
//import { MdePopoverModule } from '@material-extended/mde';
import { FooterIconComponent } from "./footer-icon/footer-icon.component";
import { AccessRequestStatusTranslation } from "./pipes/access-request-status";
import { ApiStatusTranslation } from "./pipes/api-status";
import { BooleanTranslation } from "./pipes/boolean-translation";
import { BooleanCheckmarkTranslation } from "./pipes/boolean-checkmark-translation";
import { DataPartnerRoleTranslation } from "./pipes/partner-role-translation";
import { ActivityTranslation } from "./pipes/activity-translation";
import { BrowserDialogComponent } from "./browser-dialog/browser-dialog.component";
import { DatahubUserStatusTranslation } from "./pipes/user-status-translation";
import { ContextRoleTranslation } from "./pipes/context-role-translation";
import { IntRoleTranslation } from "./pipes/int-role-translation";
import { FarmMappingStatusTranslation } from "./pipes/farm-mapping-status";
import { DarStatusTranslation } from "./pipes/dar-status";
import { BaseRemoteDatatableComponent } from "./base-remote-datatable/base-remote-datatable.component";
import { ClearingHouseComponent } from "./clearing-house/clearing-house.component";
import { AccessDecisionTranslation } from "./pipes/access-decision-translation";
import { from, Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { EventTypeStatusTranslation } from "./pipes/event-type-status";
import { ApiResourceHealthTranslation } from "./pipes/api-resource-health-translation";
import { FromUtcToLocalDateTranslation } from "./pipes/utc-to-local-date";
import { RelogLinkComponent } from "./relog-link/relog-link.component";
import { OtherEmailDialogComponent } from "./invitation/other-email-dialog/other-email-dialog.component";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { CookieBannerComponent } from "./cookie-banner/cookie-banner.component";
import { TranslatableStringEditorComponent } from "./language/translatable-string/editor/translatable-string-editor.component";
import { TranslatableStringViewComponent } from "./language/translatable-string/view/translatable-string-view/translatable-string-view.component";
import { TranslatableStringAreaComponent } from "./language/translatable-string/area/translatable-string-area.component";
import { LanguageKeyTranslation } from "./pipes/language-translation";
import { LanguageCircleComponent } from "./language/language-circle/language-circle.component";
import { PasswordDialogComponent } from "./certificate/password-dialog/password-dialog.component";
import { GenerateDialogComponent } from "./certificate/generate-dialog/generate-dialog.component";
import { AccessConsumersFilter } from "./pipes/access-consumers-filter";
import { ConsumerDarsPendingFilter } from "./pipes/consumer-dars-pending-filter";
import { FarmTypeTranslation } from "./pipes/farm-type-translation";
import { InformativeFlowCardComponent } from "./informative-flow-card/informative-flow-card.component";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { HeaderComponent } from "./header/header.component";
import { FooterComponent } from "./footer/footer.component";
import { MatInputModule } from "@angular/material/input";
import { MatChip, MatChipsModule } from "@angular/material/chips";
import { MdePopoverModule } from "@muntazir86/material-extended";
import { BrowserModule } from "@angular/platform-browser";
import { TermsDialogComponent } from "./invitation/terms-dialog/terms-dialog.component";
import { LastUserDeleteDialogComponent } from "./last-user-delete-dialog/last-user-delete-dialog.component";
import { ViewCurrentUserOverviewComponent } from "./view-current-user-overview/view-current-user-overview.component";
import { ApproveRejectSelectorComponent } from "./approve-reject-selector/approve-reject-selector.component";
import { FarmResourceAccessStatusTranslation } from "./pipes/farm-resource-access-status-translation";
import { ChartjsBarchartComponent } from "./chartjs-barchart/chartjs-barchart.component";
import { AuthGuard } from "./guards/auth-guard";
import { RoleGuard } from "./guards/role-guard";

export class LazyTranslateLoader implements TranslateLoader {
  getTranslation(lang: string): Observable<any> {
    return from(import(`../../../assets/i18n/${lang}.json`)).pipe(
      catchError((err) => of({}))
    );
  }
}

@NgModule({
  declarations: [
    NavMenuComponent,
    BreadcrumbComponent,
    ContextComponent,
    RegisterPartnerComponent,
    SelectLanguageComponent,
    SubscriptionKeyComponent,
    CertificateComponent,
    InvitationComponent,
    RegisterPartnerDetailsComponent,
    NotificationDialogComponent,
    BaseDatatableComponent,
    RegisterFarmerComponent,
    AccessRequestStatusTranslation,
    DarStatusTranslation,
    ApiStatusTranslation,
    EventTypeStatusTranslation,
    ActivityTranslation,
    DataPartnerRoleTranslation,
    FarmResourceAccessStatusTranslation,
    ApiResourceHealthTranslation,
    DatahubUserStatusTranslation,
    FromUtcToLocalDateTranslation,
    LanguageKeyTranslation,
    ContextRoleTranslation,
    IntRoleTranslation,
    BooleanTranslation,
    BooleanCheckmarkTranslation,
    FarmMappingStatusTranslation,
    ConfirmationDialogComponent,
    MessageDialogComponent,
    FooterIconComponent,
    BrowserDialogComponent,
    BaseRemoteDatatableComponent,
    ClearingHouseComponent,
    AccessDecisionTranslation,
    RelogLinkComponent,
    OtherEmailDialogComponent,
    CookieBannerComponent,
    TranslatableStringEditorComponent,
    TranslatableStringViewComponent,
    TranslatableStringAreaComponent,
    LanguageCircleComponent,
    PasswordDialogComponent,
    GenerateDialogComponent,
    AccessConsumersFilter,
    ConsumerDarsPendingFilter,
    FarmTypeTranslation,
    InformativeFlowCardComponent,
    HeaderComponent,
    FooterComponent,
    RegisterFarmerComponent,
    TermsDialogComponent,
    LastUserDeleteDialogComponent,
    ViewCurrentUserOverviewComponent,
    ApproveRejectSelectorComponent,
    ChartjsBarchartComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    MatDatepickerModule,
    MatInputModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    ClipboardModule,
    NgSelectModule,
    EllipsisModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MdePopoverModule,
    ScrollingModule,
    MatChipsModule,
    Angular2PromiseButtonModule.forRoot({
      btnLoadingClass: "is-loading",
      spinnerTpl: '<span class="spinner"></span>',
      disableBtn: true,
    }),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useClass: LazyTranslateLoader,
      },
    }),
    RouterModule.forChild([
      {
        path: "user-overview",
        canActivate: [AuthGuard, RoleGuard], 
        component: ViewCurrentUserOverviewComponent,
        data: {
          breadcrumb: "navigation.userOverview",
          roles: ['Consumer', 'Provider','Admin','Farmer'] //roleguard added to force loading context before change route
        },
      },
      {
        path: "partner-signup",
        component: RegisterPartnerComponent,
        data: {
          breadcrumb: "navigation.register",
        },
      },
      {
        path: "partner-registration",
        canActivate: [AuthGuard],
        component: RegisterPartnerDetailsComponent,
        data: {
          breadcrumb: "navigation.register.partner",
        },
      },
      {
        path: "farmer-signup",
        component: RegisterFarmerComponent,
        data: {
          breadcrumb: "navigation.register.farmer",
        },
      },
      {
        path: "invitation/:invitationId",
        component: InvitationComponent,
        data: {
          breadcrumb: "navigation.invitation",
        },
      },
      {
        path: "logs",
        canActivate: [AuthGuard],
        component: ClearingHouseComponent,
        data: {
          breadcrumb: "navigation.clearingHouse",
        },
      },
    ]),
  ],
  exports: [
    CommonModule,
    FormsModule,
    MaterialModule,
    ReactiveFormsModule,
    Angular2PromiseButtonModule,
    TranslateModule,
    NgxSpinnerModule,
    NgxDatatableModule,
    ClipboardModule,
    RouterModule,
    NgSelectModule,
    EllipsisModule,
    NavMenuComponent,
    BreadcrumbComponent,
    ContextComponent,
    SelectLanguageComponent,
    InvitationComponent,
    SubscriptionKeyComponent,
    CertificateComponent,
    BaseDatatableComponent,
    FooterIconComponent,
    AccessRequestStatusTranslation,
    FarmResourceAccessStatusTranslation,
    DarStatusTranslation,
    ApiStatusTranslation,
    EventTypeStatusTranslation,
    FarmMappingStatusTranslation,
    ActivityTranslation,
    DataPartnerRoleTranslation,
    ApiResourceHealthTranslation,
    DatahubUserStatusTranslation,
    FromUtcToLocalDateTranslation,
    LanguageKeyTranslation,
    ContextRoleTranslation,
    IntRoleTranslation,
    BooleanTranslation,
    BooleanCheckmarkTranslation,
    MatDatepickerModule,
    MatInputModule,
    MatCardModule,
    MatButtonModule,
    MatToolbarModule,
    MatExpansionModule,
    MdePopoverModule,
    BaseRemoteDatatableComponent,
    ClearingHouseComponent,
    AccessDecisionTranslation,
    RelogLinkComponent,
    ScrollingModule,
    CookieBannerComponent,
    TranslatableStringEditorComponent,
    TranslatableStringViewComponent,
    TranslatableStringAreaComponent,
    LanguageCircleComponent,
    AccessConsumersFilter,
    ConsumerDarsPendingFilter,
    FarmTypeTranslation,
    InformativeFlowCardComponent,
    HeaderComponent,
    FooterComponent,
    RegisterFarmerComponent,
    TermsDialogComponent,
    LastUserDeleteDialogComponent,
    ApproveRejectSelectorComponent,
    ChartjsBarchartComponent, 
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<AccessConsumersFilter> {
    return {
      ngModule: AccessConsumersFilter,
      providers: [],
    };
  }
}
