import { PipeTransform, Pipe } from '@angular/core';

@Pipe({
    name: 'FromUtcToLocalDate'
})
export class FromUtcToLocalDateTranslation implements PipeTransform {
    transform(value: string|number|Date): any {
        const d = new Date(value);
        return new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate(),
            d.getHours(), d.getMinutes(), d.getSeconds()));
    }
}
