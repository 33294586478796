import { Component, OnInit, OnDestroy } from "@angular/core";
import { UserAuthService } from "../../core/user-auth.service";
import { Subscription, filter } from "rxjs";
import { MsalBroadcastService } from "@azure/msal-angular";
import { UserRole } from "../../shared/models/datahubUser";
import {
  EventMessage,
  EventType,
  InteractionStatus,
} from "@azure/msal-browser";
import { UserService } from "../../core/services/user.service";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: "app-start-page",
  templateUrl: "./start-page.component.html",
  styleUrls: ["./start-page.component.scss"],
})
export class StartPageComponent implements OnInit, OnDestroy {
  subscription$: Subscription;

  role: UserRole;
  isLoggedIn: boolean = true;
  dataSource: any = [];
  isLoading: boolean = false;
  isLoadingRole: boolean = false;

  loggedInWithKbo: boolean = false;
  firstName: string;
  lastName: string;
  
  userId: string;
  validId: boolean = false;
  identityId: string;
  emailAddress: string;

  constructor(
    private broadcastService: MsalBroadcastService,
    private auth: UserAuthService,
    private userService: UserService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.subscription$ = new Subscription();
    this.checkLogin();
  }

  ngOnInit() {
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)
      )
      .subscribe((result) => {
        this.checkLogin();
      }
    );

    this.auth.notifyCurrentContextChanged().subscribe((context) => {
      this.setRole(context.roleName);
    });

    this.auth.notifyIsLoadingRolesChanged().subscribe((loading) =>{
      this.isLoadingRole = loading;
    });

    this.isLoading = true;
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        //when loggedin, no roles -> check ACM data from claims
        if (this.auth.isLoggedIn() && !this.hasRole){
           this.userService.notifyUserDetailsChanged().subscribe( userDetails =>{
            if(!userDetails) return;
            //if userId is a valid Guid, assign it to userId
            if (userDetails.userId?.length == 36) {
              this.userId = userDetails.userId;
              this.validId = true;
            }
            this.identityId = userDetails.identityId;
            this.loggedInWithKbo = userDetails.loggedInWithKbo;
            this.firstName = userDetails.identityFirstName ?? "";
            this.lastName = userDetails.identityLastName ?? "";
            this.emailAddress = userDetails.email ?? "";
            this.isLoading = false;
          });
        } else {
           if(this.hasRole){
              this.validId = true;
           }
           this.isLoading = false;
        }
        
        this.getClaims(
          this.auth.msalService.instance.getActiveAccount()
            ?.idTokenClaims as Record<string, any>
        );
        const pathParam = this.route.snapshot.queryParamMap.get('path');
        if (this.auth.isLoggedIn() && pathParam){
          this.router.navigate([pathParam]);
        }
      }
    );
  }

  ngOnDestroy() {
    //MSAL todo?
    //this.broadcastService.getMSALSubject().next(1);
    this.subscription$.unsubscribe();
  }

  private setRole(role: string) {
    if (
      this.auth.isLoggedIn() &&
      (role === null || role === undefined || role === UserRole.Unknown)
    ) {
      this.role = UserRole.Unknown;
    } else {
      this.role = UserRole[role];
    }
  }

  private checkLogin() {
    this.isLoggedIn = this.auth.isLoggedIn();
  }

  get hasRole() {
    if (!this.role) return false;

    return this.role !== UserRole.Unknown;
  }

  getClaims(claims: Record<string, any>) {
    if (claims) {
      Object.entries(claims).forEach(
        (claim: [string, unknown], index: number) => {
          this.dataSource.push({ id: index, claim: claim[0], value: claim[1] });
        }
      );
    }
  }
}
