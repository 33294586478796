import { Pipe, PipeTransform } from '@angular/core';
import { DarStatus } from '../../data-consumer/models/dar-status-summary';

@Pipe({
    name: 'DarStatusTranslation'
})
export class DarStatusTranslation implements PipeTransform {
    transform(value: string): any {
        return `models.farmResourceAccess.statuses.${DarStatus[value]}`;
    }
}