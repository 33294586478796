import { Component, OnInit, OnDestroy } from "@angular/core";
import { AdminService } from "../admin.service";
import { DatahubUser } from "../../shared/models/datahubUser";
import { UserInvitation } from "../../shared/models/userInvitation";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../shared/dialog/notification-dialog/notification-dialog.component";
import { switchMap, map, mergeMap } from "rxjs/operators";

@Component({
  selector: "app-manage-admins",
  templateUrl: "./manage-admins.component.html",
  styleUrls: ["./manage-admins.component.scss"],
})
export class ManageAdminsComponent implements OnInit, OnDestroy {
  subscription = new Subscription();

  admins = new Array<DatahubUser>();
  adminInvitees = new Array<UserInvitation>();

  email: string;

  constructor(private adminService: AdminService, private dialog: MatDialog) {}

  ngOnInit() {
    this.subscription.add(
      this.adminService
        .getAllAdmins()
        .subscribe((users) => (this.admins = users))
    );
    this.subscription.add(
      this.adminService
        .getUnclaimedInvites()
        .subscribe((invitees) => (this.adminInvitees = invitees))
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  inviteAdmin(user: UserInvitation): void {
    this.subscription.add(
      this.adminService
        .inviteNewAdmin(user.email)
        .pipe(
          map((response) => {
            if (response.isExistingUser) {
              this.email = user.email;
              this.dialog.open(NotificationDialogComponent, {
                width: "fit-content",
                data: {
                  title: "manageUsers.skipInvite.title",
                  text: "manageUsers.skipInvite.text",
                  textParams: { email: this.email },
                },
              });

              this.subscription.add(
                this.dialog.afterAllClosed
                  .pipe(switchMap(() => this.adminService.getAllAdmins()))
                  .subscribe((admins) => (this.admins = admins))
              );
            }
          }),
          mergeMap(() => this.adminService.getUnclaimedInvites())
        )
        .subscribe((invitees) => (this.adminInvitees = invitees))
    );
  }

  removeAdmin(user: DatahubUser) {
    this.subscription.add(
      this.adminService.deleteAdmin(user.id).subscribe(() => {
        this.admins = this.admins.filter((u) => u.email != user.email);
      })
    );
  }
}
