import { Component, OnInit, ViewChild, OnDestroy } from "@angular/core";
import { DropdownItem } from "../models/dropdownItem";
import { UserAuthService } from "../../core/user-auth.service";
import { UserContext } from "../models/userContext";
import { UserContextDTO } from "../models/userContextDTO";
import { UserRole } from "../models/datahubUser";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-context",
  templateUrl: "./context.component.html",
  styleUrls: ["./context.component.scss"],
})
export class ContextComponent implements OnInit, OnDestroy {
  constructor(
    private userAuthService: UserAuthService,
    private router: Router
  ) {}

  roles = new Array<DropdownItem>();
  consumers: Array<DropdownItem>;
  providers: Array<DropdownItem>;
  farms: Array<DropdownItem>;
  context: UserContext;
  subscription = new Subscription();

  // Custom because popover only works in Angular 16
  togglePopover: boolean = false;
  changePopoverState() {
    this.togglePopover = !this.togglePopover;
  }

  ngOnInit() {
    this.subscription.add(
      this.userAuthService.getAllContexts().subscribe((contexts) => {
        this.setUpContexts(contexts);
      })
    );
    this.context = new UserContext();
    this.userAuthService
      .notifyCurrentContextChanged()
      .subscribe((context) => {
        if (!this.context.role && context.id){
          this.context.role = this.roles.find(r => r.name === context.roleName);
          if (context.roleName === UserRole.Farmer) {
            this.context.farm = this.farms.find(r => r.id === context.id);
          } else if (this.farms.length == 1) {
            this.context.farm = this.farms[0];
          }
          if (context.roleName === UserRole.Consumer) {
            this.context.consumer = this.consumers.find(r => r.id === context.id);
          } else if (this.consumers.length == 1) {
            this.context.consumer = this.consumers[0];
          }
          if (context.roleName === UserRole.Provider) {
            this.context.provider = this.providers.find(r => r.id === context.id);
          } else if (this.providers.length == 1) {
            this.context.provider = this.providers[0];
          }
        }
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private setUpContexts(contexts: Array<UserContextDTO>) {
    contexts.forEach((element) => {
      if (!this.roles.find((i) => i.name === element.roleName)) {
        this.roles.push(new DropdownItem(element.id, element.roleName));
      }
    });
    this.farms = contexts
      .filter((item) => item.roleName === UserRole.Farmer)
      .map((context) => new DropdownItem(context.id, context.entityName));
    this.consumers = contexts
      .filter((item) => item.roleName === UserRole.Consumer)
      .map((context) => new DropdownItem(context.id, context.entityName))
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
    this.providers = contexts
      .filter((item) => item.roleName === UserRole.Provider)
      .map((context) => new DropdownItem(context.id, context.entityName))
      .sort((a, b) => {
        return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
      });
}

  onChangeClick() {
    this.changePopoverState();
    this.subscription.add(
      this.userAuthService.getAllContexts().subscribe((contexts) => {
        this.router.navigate([""]).then((b) => {
          if (b || this.router.url === "/") {
            let context = contexts.filter(
              (c) => c.id === this.getCurrentSelectedContextId()
            )[0];
            this.userAuthService.setUserContext(context).subscribe(() => {
              sessionStorage.removeItem(
                "contextIdAgainstClearingHouseLostContextBug"
              );
            });
          }
        });
      })
    );
  }

  private getCurrentSelectedContextId(): string {
    switch (this.context.role.name) {
      case UserRole.Admin:
        return this.context.role.id;
      case UserRole.Consumer:
        return this.context.consumer.id;
      case UserRole.Provider:
        return this.context.provider.id;
      case UserRole.Farmer:
        return this.context.farm.id;
      default:
        return "";
    }
  }

  canChange() {
    return (
      this.context.role &&
      (this.context.role.name === UserRole.Admin ||
        (this.context.farm && this.context.role.name === UserRole.Farmer) ||
        (this.context.provider &&
          this.context.role.name === UserRole.Provider) ||
        (this.context.consumer && this.context.role.name === UserRole.Consumer))
    );
  }

  isSelectionVisible(dropdownOptions: Array<DropdownItem>, role: string) {
    return (
      this.context.role &&
      this.context.role.name === role &&
      dropdownOptions != null &&
      dropdownOptions.length > 0
    );
  }

  hasRole(role: string){
    return this.roles.filter(r => r.name == role).length > 0;
  }
}
