<button (click)="gotoList()" class="card-button">
  <div class="card-row">
    <div class="upper-part">
      <img
        src="{{ this.baseurl + data.applicationLogoUrl }}"
        class="app-logo"
        *ngIf="hasApplicationLogoUrl(); else dclogo"
        [alt]="data.consumerName"
      />
      <ng-template #dclogo>
        <img src="/assets/images/blauwMiddel 1.svg" alt="blauwmiddel" />
      </ng-template>
      <h3>
        {{ data?.consumerName }}
      </h3>
    </div>
    <h4>
      {{ data?.companyName }}
    </h4>
  </div>
  <div class="card-row">
    <p title="{{ data?.description }}" class="description">
      {{ data?.description }}
    </p>
    <div class="statistics">
      <div class="statistic green">
        <i class="fas fa-check-circle"></i>
        <p class="small">
          {{ data.approved }}
          {{ "models.accessRequest.statusses.Approved" | translate }}
        </p>
      </div>
      <div class="statistic red">
        <i class="fas fa-times-circle"></i>
        <p class="small">
          {{ data.rejected }}
          {{ "models.accessRequest.statusses.Rejected" | translate }}
        </p>
      </div>
    </div>
  </div>

  <p *ngIf="data.pending" class="go-to-page pending">
    <span>
      {{ "dataAccessRequestPage.card.goToDetails" | translate }}
    </span>
  </p>
</button>
