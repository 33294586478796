<h1>{{ "info.partnerDetails" | translate }}</h1>
<form
  [formGroup]="partnerForm"
  (ngSubmit)="updateCertificate(partnerForm.getRawValue())"
>
  <app-translatable-string
    formControlName="name"
    [placeholder]="'partnerLandingPage.name' | translate"
    required="false"
    [viewOnly]="true"
  ></app-translatable-string>

  <app-translatable-string
    formControlName="purpose"
    [placeholder]="'partnerLandingPage.purpose' | translate"
    required="false"
    [viewOnly]="true"
  ></app-translatable-string>

  <div class="form-part">
    <label for="application-url">Application Url</label>
    <input
      formControlName="applicationUrl"
      [placeholder]="'partnerLandingPage.applicationUrl' | translate"
      type="text"
      name="application-url"
      id="application-url"
    />
  </div>

  <div class="form-part">
    <label for="company-name">Naam data aanvrager</label>

    <input
      formControlName="companyName"
      [placeholder]="'partnerLandingPage.nameDataPartner' | translate"
      type="text"
      id="company-name"
      name="company-name"
    />
  </div>

  <div class="form-part">
    <label for="application-logo-url">Url Application logo</label>
    <input
      formControlName="applicationLogoUrl"
      [placeholder]="'partnerLandingPage.applicationUrlLogo' | translate"
      type="text"
      id="application-logo-url"
      name="application-logo-url"
    />
  </div>

  <app-subscription-key
    [formGroup]="partnerForm"
    [controlName]="primaryKey"
    [placeholder]="'partnerLandingPage.primaryKey' | translate"
    [value]="primaryKey"
    [label]="'partnerLandingPage.primaryKey' | translate"
  ></app-subscription-key>
  <app-subscription-key
    [formGroup]="partnerForm"
    [controlName]="secondaryKey"
    [placeholder]="'partnerLandingPage.secondaryKey' | translate"
    [value]="secondaryKey"
    [label]="'partnerLandingPage.secondaryKey' | translate"
  ></app-subscription-key>

  <app-certificate
    [formGroup]="partnerForm"
    [currentCertificateThumbprint]="thumbprintText"
    (password)="handlePassword($event)"
    (certificateChange)="certificateHasInput = true"
    name="certificate"
    (certificateCleared)="onCertificateCleared($event)"
  ></app-certificate>
  <div class="cert-buttons">
    <button
      type="button"
      [disabled]="!thumbprint"
      (click)="downloadCertificate()"
      class="green"
    >
      {{ "actions.downloadCertificate" | translate }}
    </button>
    <button type="button" (click)="generateCertificate()" class="green">
      {{ "actions.generateCertificate" | translate }}
    </button>
  </div>
  <div *ngIf="informativeFlow">
    <mat-checkbox formControlName="informativeFlow">{{
      "models.datahubPartner.informativeFlow" | translate
    }}</mat-checkbox>
  </div>
  <button class="green" type="submit" [disabled]="!certificateHasInput">
    {{ "actions.save" | translate }}
  </button>
</form>
