import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ConsumerService } from '../../core/services/consumer.service';
import { APIResourceHealth, ResourceHealth } from '../../shared/models/api-resource-health';
import { Observable } from 'rxjs';
import { BaseDatatableComponent } from '../../shared/base-datatable/base-datatable.component';
import { SelectFilter } from '../../shared/base-datatable/models/select-filter';
import { ApiResourceHealthTranslation } from '../../shared/pipes/api-resource-health-translation';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'app-resource-health',
  templateUrl: './resource-health.component.html',
  styleUrls: ['./resource-health.component.css']
})
export class ResourceHealthComponent implements OnInit {
  @ViewChild('healthTmp', { static: true }) healthTmp: TemplateRef<any>;
  @ViewChild(BaseDatatableComponent, { static: true }) healthTable: BaseDatatableComponent;

  resourceHealth$: Observable<Array<APIResourceHealth>>;
  columns = [];
  resourceHealthTranslation = new ApiResourceHealthTranslation();

  constructor(private consumerService: ConsumerService) { }

  ngOnInit() {
    this.resourceHealth$ = this.consumerService.getResourceHealth();
    this.initColumns();
  }

  initColumns() {
    this.columns = [
      {
        prop: 'resourceName',
        name: 'models.apiResourceHealth.resourceName',
        flexGrow: 4,
        headerTemplate: this.healthTable.hdrTextTpl
      },
      {
        prop: 'resourceHealth',
        name: 'models.apiResourceHealth.resourceHealth',
        flexGrow: 4,
        headerTemplate: this.healthTable.hdrSelectTpl,
        cellTemplate: this.healthTmp,
        data: [
          new SelectFilter(ResourceHealth.Unknown, this.resourceHealthTranslation.transform(ResourceHealth.Unknown)),
          new SelectFilter(ResourceHealth.OK, this.resourceHealthTranslation.transform(ResourceHealth.OK)),
          new SelectFilter(ResourceHealth.NOK, this.resourceHealthTranslation.transform(ResourceHealth.NOK))
        ]
      }];
  }
}
