<h1>{{ "info.managePushEvents" | translate }}</h1>

<div class="part" *ngIf="!isAdmin">
  <h2>
    {{ "managePushEventsPage.pushEndpoint" | translate }}
  </h2>
  <form
    *ngIf="
      providerPushEndpoint$ | async as providerPushEndpoint;
      else noProviderPushEndpoint
    "
  >
    <fieldset class="content" [disabled]="true">
      <div class="form-part">
        <label for="endpointUrl">{{
          "managePushEventsPage.endpointUrl" | translate
        }}</label>
        <input
          id="endpointUrl"
          name="endpointUrl"
          type="text"
          [(ngModel)]="providerPushEndpointValue.endpointUrl"
        />
      </div>
      <div class="form-part">
        <label for="pushKey1">{{
          "managePushEventsPage.key1" | translate
        }}</label>
        <input
          id="pushKey1"
          name="pushKey1"
          type="text"
          [(ngModel)]="providerPushEndpointValue.key1"
        />
      </div>
      <div class="form-part">
        <label for="pushKey2">{{
          "managePushEventsPage.key2" | translate
        }}</label>
        <input
          id="pushKey2"
          name="pushKey2"
          type="text"
          [(ngModel)]="providerPushEndpointValue.key2"
        />
      </div>
    </fieldset>
  </form>
  <ng-template #noProviderPushEndpoint>
    <p>{{ "managePushEventsPage.noProviderEndpointMessage" | translate }}</p>
    <button (click)="createProviderPushEndpoint()" class="green">
      {{ "actions.createProviderPushEndpoint" | translate }}
    </button>
  </ng-template>
</div>

<div class="part">
  <h2>
    {{ "managePushEventsPage.eventTypes" | translate }}
  </h2>

  <div class="content">
    <button
      *ngIf="!isAdmin && !providerPushEndpoint$ | async as providerPushEndpoint"
      (click)="createEventType()"
      class="green"
    >
      {{ "actions.createEventType" | translate }}
    </button>
    <app-base-datatable
      #apiTable
      [rows]="eventTypes$ | async"
      [columns]="columns"
      [headerHeight]="70"
      [footerHeight]="50"
      [limit]="10"
      [exportable]="false"
    >
    </app-base-datatable>
  </div>
</div>

<ng-template #translationTmpl let-row="row">
  <app-translatable-string-view
    [translation]="row.translations"
    property="name"
  ></app-translatable-string-view>
</ng-template>

<ng-template #defaultHdrTmpl let-column="column">
  <div class="float-right">
    <div>{{ column.name | translate }}</div>
  </div>
</ng-template>

<ng-template #editTmpl let-row="row">
  <div class="float-right">
    <button
      (click)="viewDetails(row)"
      type="button"
      class="card-button view-details"
      aria-label="View details"
    >
      <i class="fa fa-edit"></i>
    </button>
  </div>
</ng-template>

<ng-template #statusTmpl let-value="value">
  <div class="float-right">
    <div>{{ value | EventTypeStatusTranslation | translate }}</div>
  </div>
</ng-template>
