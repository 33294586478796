import { DarStatus } from './data-access-request-status';

export default class DataAccessRequest {
    darId: string;
    resourceName: string;
    resourceDescription: string;
    resourceDocumentationUrl: string;
    providerName: string;
    applicationUrl: string;
    requestedOn: Date;
    accessUntil: Date;
    status: DarStatus;
}
