import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '../models/datahubUser';

@Pipe({
    name: 'IntRoleTranslation'
})
export class IntRoleTranslation implements PipeTransform {
    transform(value: number): string {
        if (value)
            return Object.values(UserRole)[value];
        return Object.values(UserRole)[0];
    }
}
