import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Renderer2,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ConsumerRequestCard } from "../models/consumer-request-card";
import { DataAccessRequestService } from "../../core/services/data-access-request.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-consumer-requests-card",
  templateUrl: "./consumer-requests-card.component.html",
  styleUrls: ["./consumer-requests-card.component.scss"],
})
export class ConsumerRequestsCardComponent implements OnInit, OnDestroy {
  @Input() data: ConsumerRequestCard;
  subscription = new Subscription();

  constructor(
    private dataAccessRequestService: DataAccessRequestService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
    private el: ElementRef
  ) {}

  public baseurl: string;

  ngOnInit() {
    const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.baseurl = appConfig.DatapartnerLogoBaseUrl;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  ngAfterViewInit() {
    this.checkHeightCards();
  }

  @HostListener("window:resize")
  onResize() {
    this.checkHeightCards();
  }

  private checkHeightCards() {
    const element = this.el.nativeElement.querySelector(".description");
    const height = parseInt(window.getComputedStyle(element).height);
    if (height > 100) {
      this.renderer.addClass(element, "exceeds-max-height");
    }
  }

  gotoList = () => {
    this.router.navigate([this.navigationRoute, this.data.consumerId], {
      relativeTo: this.route,
    });
  };

  get navigationRoute(): string {
    let detailsNavigation = `details`;
    if (this.router.url === `/`) {
      detailsNavigation = `manage-data-access/details`;
    }
    return detailsNavigation;
  }

  hasApplicationUrl() {
    return this.data.applicationUrl;
  }

  hasApplicationLogoUrl() {
    return this.data.applicationLogoUrl ? true : false;
  }

  goToApplication() {
    if (this.data.applicationUrl) {
      window.open(this.data.applicationUrl);
    }
  }
}
