import { Component, OnInit, OnDestroy } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder } from "@angular/forms";
import { Subscription } from "rxjs";
import { UserService } from "../../core/services/user.service";
import { MatDialog } from "@angular/material/dialog";
import { TranslateService } from "@ngx-translate/core";
import { CertificateConfirmationDialogComponent } from "./certificate-confirmation-dialog/certificate-confirmation-dialog.component";
import { getDefaultTranslatableStringArray } from "../../shared/models/language";
import { PartnerService } from "../../core/services/partner.service";
import { GenerateDialogComponent } from "../../shared/certificate/generate-dialog/generate-dialog.component";

@Component({
  selector: "app-partner-details",
  templateUrl: "./partner-details.component.html",
  styleUrls: ["./partner-details.component.scss"],
})
export class PartnerDetailsComponent implements OnInit, OnDestroy {
  partnerId: string;
  partnerForm: UntypedFormGroup;
  subscription = new Subscription();
  primaryKey: string;
  secondaryKey: string;
  thumbprint: string;
  thumbprintText: string;
  informativeFlow: boolean;
  certificateHasInput: boolean;
  private password: string;
  translationService: TranslateService;

  constructor(
    formBuilder: UntypedFormBuilder,
    private userService: UserService,
    private partnerService: PartnerService,
    private dialog: MatDialog,
    translationService: TranslateService
  ) {
    this.translationService = translationService;
    this.partnerForm = formBuilder.group({
      name: [getDefaultTranslatableStringArray()],
      purpose: [getDefaultTranslatableStringArray()],
      applicationUrl: [""],
      companyName: [""],
      applicationLogoUrl: [""],
      informativeFlow: [],
    });
    this.subscription.add(
      userService.getPartner().subscribe((partner) => {
        if (partner) {
          this.partnerId = partner.id;
          this.informativeFlow = partner.informativeFlow;
          this.partnerForm.controls.name.setValue(
            partner.translations.map((t) => {
              return {
                language: t.language,
                value: t.name,
              };
            })
          );
          this.partnerForm.controls.purpose.setValue(
            partner.translations.map((t) => {
              return {
                language: t.language,
                value: t.purpose,
              };
            })
          );
          this.partnerForm.controls.applicationUrl.setValue(
            partner.applicationUrl
          );
          this.partnerForm.controls.companyName.setValue(partner.companyName);
          this.partnerForm.controls.applicationLogoUrl.setValue(
            partner.applicationLogoUrl
          );
          this.partnerForm.controls.informativeFlow.setValue(
            partner.informativeFlow
          );
          this.primaryKey = partner.primaryKey;
          this.secondaryKey = partner.secondaryKey;
          this.changeThumbprint(partner.thumbprint);
          this.partnerForm.get("name").disable();
          this.partnerForm.get("purpose").disable();
        }
      })
    );
  }

  private changeThumbprint(value: string) {
    this.thumbprint = value;
    const thumbprintPrefix = this.translationService.instant(
      "certificate.currentThumbprint"
    );
    this.thumbprintText = `${thumbprintPrefix}: ${value}`;
  }

  onCertificateCleared(value: boolean) {
    this.certificateHasInput = value;
  }
  ngOnInit() {
    this.disableControls();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  generateCertificate() {
    const dialogRef = this.dialog.open(GenerateDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.subscription.add(
            this.partnerService
              .generateCertificate(this.partnerId)
              .subscribe((thumbprint) => (this.changeThumbprint(thumbprint)))
          );
        }
      })
    );
  }

  handlePassword(password: string) {
    this.password = password;
  }

  downloadCertificate() {
    this.partnerService
      .downloadCertificate(this.partnerId)
      .subscribe((cert) => {
        var fileURL = URL.createObjectURL(cert);
        window.open(fileURL, "_blank");
      });
  }

  async updateCertificate(values) {
    let newCertificate: string;
    if (values.certificateControl.files) {
      newCertificate = btoa(
        String.fromCharCode(
          ...new Uint8Array(
            await new Response(values.certificateControl.files[0]).arrayBuffer()
          )
        )
      );
      const dialogRef = this.dialog.open(
        CertificateConfirmationDialogComponent,
        {
          width: "fit-content",
        }
      );

      this.subscription.add(
        dialogRef.afterClosed().subscribe((confirmed) => {
          if (confirmed) {
            this.subscription.add(
              this.userService
                .updateCertificate(newCertificate, this.password)
                .subscribe()
            );
            setTimeout(() => {
              // The thumbprint is updated after a delay to ensure the certificate is updated because it comes from the API
              this.userService
                .getPartner()
                .subscribe((partner) =>
                  this.changeThumbprint(partner.thumbprint)
                );
            }, 1500);
            this.partnerForm.get("certificateControl").setValue(null);
          }
        })
      );
    }
  }

  disableControls() {
    this.partnerForm.controls.name.disable();
    this.partnerForm.controls.purpose.disable();
    this.partnerForm.controls.applicationUrl.disable();
    this.partnerForm.controls.applicationLogoUrl.disable();
    this.partnerForm.controls.companyName.disable();
    this.partnerForm.controls.informativeFlow.disable();
  }
}
