<h1 mat-dialog-title>{{ "manageApisPage.publishDialog.title" | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="onPublish(form.getRawValue())">
  <mat-dialog-content class="mh-230">
    <mat-form-field>
      <mat-label>{{
        "manageApisPage.publishDialog.products" | translate
      }}</mat-label>
      <mat-select formControlName="products" multiple>
        <mat-option *ngFor="let product of products" [value]="product">{{
          product.name
        }}</mat-option>
      </mat-select>
    </mat-form-field>
  </mat-dialog-content>
  <div class="button-group">
    <button (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button [disabled]="!form.valid" class="green">
      {{ "actions.publish" | translate }}
    </button>
  </div>
</form>
