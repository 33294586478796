import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpInterceptor,
} from "@angular/common/http";
import { UserAuthService } from "../user-auth.service";

@Injectable()
export class ContextHeaderInterceptor implements HttpInterceptor {
  constructor(private authService: UserAuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler) {
    if (this.authService.ContextIdentifier) {
      if (!sessionStorage.getItem("contextIdAgainstClearingHouseLostContextBug")) {
        sessionStorage.setItem(
          "contextIdAgainstClearingHouseLostContextBug",
          this.authService.ContextIdentifier
        );
      }
      request = request.clone({
        setHeaders: {
          DJustConnectContext: this.authService.ContextIdentifier,
        },
      });
    } else if (sessionStorage.getItem("contextIdAgainstClearingHouseLostContextBug")) {
      let contextId = sessionStorage.getItem(
        "contextIdAgainstClearingHouseLostContextBug"
      );
      request = request.clone({
        setHeaders: {
          DJustConnectContext: contextId,
        },
      });
    }
    return next.handle(request);
  }
}
