import { Component, OnInit, ChangeDetectorRef, OnDestroy } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription, of } from "rxjs";
import {
  DatahubPartner,
  CreateDatahubPartnerRequest,
  DatahubPartnerRole,
  DatahubParterTranslation,
} from "../../models/datahubPartner";
import { DatahubUser } from "../../../shared/models/datahubUser";
import { PartnerService } from "../../../core/services/partner.service";
import { DataspaceService } from "../../../core/services/dataspace.service"
import { UserInvitation } from "../../../shared/models/userInvitation";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../../shared/dialog/notification-dialog/notification-dialog.component";
import { InvitationResult } from "../../../../modules/shared/models/invitation-result";
import { switchMap, map, mergeMap } from "rxjs/operators";
import { getDefaultTranslatableStringArray } from "../../../shared/models/language";
import { GenerateDialogComponent } from "../../../shared/certificate/generate-dialog/generate-dialog.component";

@Component({
  selector: "app-partner-details",
  templateUrl: "./partner-details.component.html",
  styleUrls: ["./partner-details.component.scss"],
})
export class PartnerDetailsComponent implements OnInit, OnDestroy {
  partner: DatahubPartner;

  readonly: boolean;
  thumbprint: string;
  users = Array<DatahubUser>();
  invitees = Array<UserInvitation>();
  partnerForm: UntypedFormGroup;
  subscription = new Subscription();
  roles = [
    { name: "roles.Consumer", value: DatahubPartnerRole.Consumer },
    { name: "roles.Provider", value: DatahubPartnerRole.Provider },
  ];
  dataspaces = [];
  selectedRole: string;
  partnerId: string;
  private password: string;

  constructor(
    formBuilder: UntypedFormBuilder,
    private service: PartnerService,
    private dataspaceService: DataspaceService,
    private ref: ChangeDetectorRef,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog
  ) {
    this.partnerForm = formBuilder.group({
      name: [
        getDefaultTranslatableStringArray(),
        { disabled: false, validators: [Validators.required] },
      ],
      purpose: [
        getDefaultTranslatableStringArray(),
        { disabled: false, validators: [Validators.required] },
      ],
      applicationUrl: [""],
      companyName: [""],
      applicationLogoUrl: [""],
      role: ["", Validators.required],
      informativeFlow: [false],
      externalDataspaceId: [null],
      externalDataspaceKey: [null],
    });
  }

  ngOnInit() {
    this.initPartner();
    this.subscription.add(
      this.dataspaceService.getAll().subscribe((dataspaces) => this.dataspaces = dataspaces)
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initPartner() {
    const dataPartnerAsAdmin$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.partnerId = params.get("id");
        if (this.partnerId !== null) {
          return this.service.getPartner(this.partnerId);
        }
        return of(new DatahubPartner());
      })
    );

    this.subscription.add(
      dataPartnerAsAdmin$.subscribe((partner) => {
        this.partner = partner;
        this.afterPartnerInit();
      })
    );
  }

  private afterPartnerInit() {
    if (!this.isNewPartner) {
      this.toggleEdit();
      this.ref.detectChanges();
    }
    this.initInviteeAndUsers();
  }

  private initInviteeAndUsers() {
    if (!this.isNewPartner) {
      this.getUsers();
      this.getInvitations();
      this.thumbprint = this.partner.thumbprint;
      this.partnerForm.controls.name.setValue(
        this.partner.translations.map((t) => {
          return {
            language: t.language,
            value: t.name,
          };
        })
      );
      this.partnerForm.controls.purpose.setValue(
        this.partner.translations.map((t) => {
          return {
            language: t.language,
            value: t.purpose,
          };
        })
      );
      this.partnerForm.controls.applicationUrl.setValue(
        this.partner.applicationUrl
      );
      this.partnerForm.controls.companyName.setValue(this.partner.companyName);
      this.partnerForm.controls.applicationLogoUrl.setValue(
        this.partner.applicationLogoUrl
      );
      this.partnerForm.controls.role.setValue(
        this.roles.find((r) => r.value === this.partner.role).value
      );
      this.partnerForm.controls.informativeFlow.setValue(
        this.partner.informativeFlow
      );
      this.partnerForm.controls.externalDataspaceId.setValue(
        this.partner.externalDataspace ? this.partner.externalDataspace.id : null
      );
      this.partnerForm.controls.externalDataspaceKey.setValue(
        this.partner.externalDataspaceKey
      );
    }
  }

  onRoleChange(event) {
    this.partnerForm.get("informativeFlow").setValue(false);
    this.selectedRole = this.roles.find(
      (role) => String(role.value) === String(event.target.value)
    ).name;
  }

  generateCertificate() {
    const dialogRef = this.dialog.open(GenerateDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed) {
          this.subscription.add(
            this.service
              .generateCertificate(this.partnerId)
              .subscribe((thumbprint) => (this.thumbprint = thumbprint))
          );
        }
      })
    );
  }

  handlePassword(password: string) {
    this.password = password;
  }

  downloadCertificate() {
    this.service.downloadCertificate(this.partnerId).subscribe((cert) => {
      let fileURL = URL.createObjectURL(cert);
      window.open(fileURL, "_blank");
    });
  }

  async upsertPartner(values) {
    const partner = new CreateDatahubPartnerRequest();
    partner.translations = this.mergeTranslations(values);
    partner.applicationUrl = values.applicationUrl;
    partner.companyName = values.companyName;
    partner.applicationLogoUrl = values.applicationLogoUrl;
    partner.informativeFlow = values.informativeFlow;
    partner.role = DatahubPartnerRole[values.role];
    partner.externalDataspaceId = values.externalDataspaceId;
    partner.externalDataspaceKey = values.externalDataspaceKey;
    if (values.certificateControl?.files) {
      const certificateFile = await new Response(
        values.certificateControl.files[0]
      ).arrayBuffer();
      partner.certificate = btoa(
        String.fromCharCode(...new Uint8Array(certificateFile))
      );
      partner.password = this.password;
    }

    if (!this.isNewPartner) {
      partner.id = this.partner.id;
      this.subscription.add(
        this.service.updatePartner(partner).subscribe(() => {
          this.router.navigate([`manage-partners`]);
        })
      );
    } else {
      partner.inviteEmails = this.invitees.map((i) => i.email);
      this.subscription.add(
        this.service.createPartner(partner).subscribe(() => {
          this.router.navigate([`manage-partners`]);
        })
      );
    }
  }

  private mergeTranslations(values): Array<DatahubParterTranslation> {
    return values.name.map((n) => {
      return {
        language: n.language,
        name: n.value,
        purpose: values.purpose.find((p) => p.language === n.language).value,
      };
    });
  }

  inviteUser(invite) {
    if (this.isNewPartner) {
      this.addInviteToNewPartner(invite);
    } else {
      this.addInviteToExistingPartner(invite);
    }
  }

  private addInviteToExistingPartner(invite: any) {
    this.service
      .invitePartner(this.partner.id, invite.email)
      .pipe(
        map((response: InvitationResult) => {
          if (response.isExistingUser) {
            this.dialog.open(NotificationDialogComponent, {
              width: "400px",
              data: {
                title: "manageUsers.skipInvite.title",
                text: "manageUsers.skipInvite.text",
                textParams: { email: invite.email },
              },
            });
            this.dialog.afterAllClosed
              .pipe(
                switchMap(() => this.service.getUserByPartner(this.partner.id))
              )
              .subscribe((users) => (this.users = users));
          }
        }),
        mergeMap(() => this.service.getInvitations(this.partner.id))
      )
      .subscribe((invites) => (this.invitees = invites));
  }

  private addInviteToNewPartner(invite) {
    this.invitees.push(invite);
    this.invitees = [...this.invitees];
  }

  private getUsers() {
    this.subscription.add(
      this.service
        .getUserByPartner(this.partner.id)
        .subscribe((users) => (this.users = users))
    );
  }

  private getInvitations() {
    this.subscription.add(
      this.service
        .getInvitations(this.partner.id)
        .subscribe((invites) => (this.invitees = invites))
    );
  }

  removeUser(user) {
    if (this.partner.id) {
      this.subscription.add(
        this.service
          .deleteUserFromPartner(this.partner.id, user.id)
          .subscribe(() => {
            this.deleteUser(user);
          })
      );
    } else {
      this.deleteUser(user);
    }
  }

  private deleteUser(user) {
    const index = this.users.indexOf(user);
    this.users.splice(index, 1);
    this.users = [...this.users];
  }

  toggleEdit() {
    if (this.readonly) {
      this.readonly = false;
      this.partnerForm.enable();
      if (!this.isNewPartner) {
        this.partnerForm.controls.informativeFlow.disable();
      }
    } else {
      this.readonly = true;
      this.partnerForm.disable();
    }
  }

  get isNewPartner() {
    return this.partner && !this.partner.id;
  }

  get isConsumer() {
    return this.partner && this.partner.role === DatahubPartnerRole.Consumer;
  }
}
