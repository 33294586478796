import { Component, OnInit, OnDestroy } from "@angular/core";
import { ConsumerService } from "../../core/services/consumer.service";
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { ValidatorService } from "../../core/validators/validator-service";
import { ConsumerPushNotificationDetails } from "../models/consumer-push-notification";

@Component({
  selector: "app-consumer-push-events",
  templateUrl: "./consumer-push-events.component.html",
  styleUrls: ["./consumer-push-events.component.scss"],
})
export class ConsumerPushEventsComponent implements OnInit, OnDestroy {
  form: UntypedFormGroup;
  timeLeft: number = 0;
  pollInterval: number = 10;
  interval;
  status: string;
  private subscription = new Subscription();
  private requestedEndpoint: string;
  constructor(
    private consumerService: ConsumerService,
    formBuilder: UntypedFormBuilder,
    private validatorService: ValidatorService
  ) {
    this.form = formBuilder.group({
      active: ["", Validators.required],
      endpoint: [""],
    });
  }

  ngOnInit() {
    this.getDetails();
  }

  getDetails() {
    this.subscription.add(
      this.consumerService
        .getPushEndpoint()
        .subscribe((result) => this.setPushDetails(result))
    );
  }

  setPushDetails(details: ConsumerPushNotificationDetails) {
    if (details) {
      this.form.controls.active.setValue(details.receiveNotifications);
      const endpoint = details.endpoint
        ? details.endpoint
        : this.requestedEndpoint;
      this.form.controls.endpoint.setValue(endpoint);
      this.status = details.subscriptionStatus;
      this.toggle(details.receiveNotifications);
    }
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  save(values) {
    if (values.active) {
      this.subscription.add(
        this.consumerService
          .activatePushEndpoint(values.endpoint)
          .subscribe((result) => {
            this.setPushDetails(result);
            this.requestedEndpoint = result.endpoint;
            this.startTimer();
          })
      );
    } else {
      this.subscription.add(
        this.consumerService.deactivatePushEndpoint().subscribe()
      );
      this.status = undefined;
    }
  }

  toggle(value: Boolean) {
    if (value) {
      this.form.controls.endpoint.enable();
      this.form.controls.endpoint.setValidators([
        Validators.required,
        this.validatorService.httpsUrlValidator(),
      ]);
    } else {
      this.form.controls.endpoint.setValue(null);
      this.form.controls.endpoint.disable();
      this.form.controls.endpoint.clearValidators();
      this.status = undefined;
    }

    this.form.controls.endpoint.updateValueAndValidity();
  }

  startTimer() {
    this.timeLeft = 300;
    this.interval = setInterval(() => {
      if (this.status === "Succeeded" || this.status === "Failed") {
        this.stopTimer();
      } else if (this.timeLeft > 0) {
        this.timeLeft--;
        if (this.timeLeft % this.pollInterval === 0) this.getDetails();
      } else {
        this.stopTimer();
        this.getDetails();
      }
    }, 1000);
  }

  stopTimer() {
    this.timeLeft = 0;
    clearInterval(this.interval);
  }
}
