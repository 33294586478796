import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { baseurl } from '../../../../app/baseSettings';
import { Observable } from 'rxjs';
import { UserContextDTO } from '../../shared/models/userContextDTO';

@Injectable()
export class ContextService {
  private URL = `${this.api}/api/User`;
  constructor(
    private client: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getUserContexts(): Observable<Array<UserContextDTO>> {
    return this.client.get<Array<UserContextDTO>>(`${this.URL}/contexts`);
  }

  public setUserContext(context: UserContextDTO) {
    return this.client.post(`${this.URL}/context/${context.id}`, null);
  }

  public getContext(contextHint?: string, farmIdType?: string, farmNumber?: string): Observable<UserContextDTO> {
    let params = new HttpParams();
    if (contextHint) params = params.append('contextHint', contextHint);
    if (farmIdType) params = params.append('farmIdType', farmIdType);
    if (farmNumber) params = params.append('farmNumber', farmNumber);
    return this.client.get<UserContextDTO>(`${this.URL}/context`, {params: params});
  }
}
