<div class="create-partner">
  <h2>{{ "registration.partner.title" | translate }}</h2>
  <form
    [formGroup]="partnerCreationForm"
    (ngSubmit)="createPartner(partnerCreationForm.getRawValue())"
  >
    <mat-form-field class="full-width">
      <input matInput formControlName="name" placeholder="Name" />
    </mat-form-field>

    <mat-form-field class="full-width">
      <textarea
        matInput
        formControlName="purpose"
        placeholder="Purpose"
      ></textarea>
    </mat-form-field>

    <mat-form-field class="full-width">
      <input
        matInput
        formControlName="applicationUrl"
        placeholder="Application Url"
      />
    </mat-form-field>

    <mat-radio-group
      class="flex-column margin-top-bot"
      formControlName="wantsToProvide"
    >
      <mat-radio-button value="false">{{
        "registration.partner.wantsConsumer" | translate
      }}</mat-radio-button>
      <mat-radio-button value="true">{{
        "registration.partner.wantsProvider" | translate
      }}</mat-radio-button>
    </mat-radio-group>

    <!--TODO set the terms url-->
    <mat-checkbox class="block" formControlName="acceptTerms"
      >{{ "registration.partner.readAndApproved" | translate }}
      <a href="">{{
        "registration.partner.terms" | translate
      }}</a></mat-checkbox
    >

    <div class="create-partner-action">
      <button
        type="submit"
        [disabled]="!partnerCreationForm.valid"
        class="green"
      >
        <div>
          <h3>{{ "registration.partner.register.title" | translate }}</h3>
          <div>{{ "registration.partner.register.text" | translate }}</div>
        </div>
      </button>
    </div>
  </form>
</div>
