import { Component, OnInit, TemplateRef, ViewChild } from "@angular/core";
import { ColumnDef } from "../base-remote-datatable/models/column-def";
import { InitialSort } from "../base-remote-datatable/models/initial-sort";
import { ClearingHouseService } from "../../core/services/clearing-house.service";
import { TextFilterDef } from "../base-remote-datatable/models/text-filter-def";
import { DateFilterDef } from "../base-remote-datatable/models/date-filter-def";
import { UserAuthService } from "../../core/user-auth.service";
import { ClearingHousePartnerSummaryService } from "../../core/services/clearing-house-partner-summary.service";

@Component({
  selector: "app-clearing-house",
  templateUrl: "./clearing-house.component.html",
  styleUrls: ["./clearing-house.component.scss"],
})
export class ClearingHouseComponent implements OnInit {
  @ViewChild("consumerNameCell", { static: true }) consumerNameCell: TemplateRef<any>;
  @ViewChild("providerNameCell", { static: true }) providerNameCell: TemplateRef<any>;
  @ViewChild("resourceNameCell", { static: true }) resourceNameCell: TemplateRef<any>;
  @ViewChild("farmNumberCell", { static: true }) farmNumberCell: TemplateRef<any>;
  @ViewChild("decisionCell", { static: true }) decisionCell: TemplateRef<any>;
  @ViewChild("timeStampCell", { static: true }) timeStampCell: TemplateRef<any>;
  @ViewChild("responseSizeCell", { static: true }) responseSizeCell: TemplateRef<any>;
  @ViewChild("durationMsCell", { static: true }) durationMsCell: TemplateRef<any>;
  @ViewChild("callerIpCell", { static: true }) callerIpCell: TemplateRef<any>;
  @ViewChild("periodCell", { static: true }) periodCell: TemplateRef<any>;
  @ViewChild("numberOfCallsCell", { static: true }) numberOfCallsCell: TemplateRef<any>;
  @ViewChild("volumeCell", { static: true }) volumeCell: TemplateRef<any>;

  columns: Array<ColumnDef>;
  columnsPS: Array<ColumnDef>;
  initialSort = new InitialSort("timeStamp", "desc");
  initialSortPS = new InitialSort("period", "desc");
  isAdmin: boolean = false;

  constructor(
    public clearingHouseService: ClearingHouseService,
    public partnerSummaryService: ClearingHousePartnerSummaryService,
    private authService: UserAuthService
  ) {
    this.isAdmin = this.authService.isAdmin();
  }

  ngOnInit() {
    this.setupColumns();
  }

  private setupColumns() {
    this.isAdmin
      ? (this.columns = [
          {
            name: "consumerName",
            translationKey: "models.clearingHouseLog.consumerName",
            cellTemplate: this.consumerNameCell,
            filter: new TextFilterDef("consumerNameFilter"),
            displayed: !this.authService.isConsumer(),
            sort: "consumerName",
          },
          {
            name: "providerName",
            translationKey: "models.clearingHouseLog.providerName",
            cellTemplate: this.providerNameCell,
            filter: new TextFilterDef("providerNameFilter"),
            displayed: !this.authService.isProvider(),
            sort: "providerName",
          },
          {
            name: "resourceName",
            translationKey: "models.clearingHouseLog.resourceName",
            cellTemplate: this.resourceNameCell,
            filter: undefined,
            displayed: true,
            sort: "resourceName",
          },
          {
            name: "farmNumber",
            translationKey: "models.clearingHouseLog.farmNumber",
            cellTemplate: this.farmNumberCell,
            filter: new TextFilterDef("numberFilter"),
            displayed: !this.authService.isFarmer(),
            sort: "farmNumber",
          },
          {
            name: "decision",
            translationKey: "models.clearingHouseLog.decision",
            cellTemplate: this.decisionCell,
            filter: undefined,
            displayed: true,
            sort: "decision",
          },
          {
            name: "timeStamp",
            translationKey: "models.clearingHouseLog.timeStamp",
            cellTemplate: this.timeStampCell,
            filter: new DateFilterDef(
              "dateRangeFilter.StartDate",
              "dateRangeFilter.EndDate"
            ),
            displayed: true,
            sort: "timeStamp",
          },
          {
            name: "responseSize",
            translationKey: "models.clearingHouseLog.responseSize",
            cellTemplate: this.responseSizeCell,
            filter: undefined,
            displayed: !this.authService.isFarmer(),
            sort: "responseSize",
          },
          {
            name: "durationMs",
            translationKey: "models.clearingHouseLog.durationMs",
            cellTemplate: this.durationMsCell,
            filter: undefined,
            displayed: !this.authService.isFarmer(),
            sort: "durationMs",
          },
          {
            name: "callerIp",
            translationKey: "models.clearingHouseLog.callerIp",
            cellTemplate: this.callerIpCell,
            filter: undefined,
            displayed: !this.authService.isFarmer(),
            sort: "callerIp",
          },
        ])
      : (this.columns = [
          {
            name: "resourceName",
            translationKey: "models.clearingHouseLog.resourceName",
            cellTemplate: this.resourceNameCell,
            filter: undefined,
            displayed: true,
            sort: "resourceName",
          },
          {
            name: "farmNumber",
            translationKey: "models.clearingHouseLog.farmNumber",
            cellTemplate: this.farmNumberCell,
            filter: new TextFilterDef("numberFilter"),
            displayed: !this.authService.isFarmer(),
            sort: "farmNumber",
          },
          {
            name: "timeStamp",
            translationKey: "models.clearingHouseLog.timeStamp",
            cellTemplate: this.timeStampCell,
            filter: new DateFilterDef(
              "dateRangeFilter.StartDate",
              "dateRangeFilter.EndDate"
            ),
            displayed: true,
            sort: "timeStamp",
          },
          {
            name: "responseSize",
            translationKey: "models.clearingHouseLog.responseSize",
            cellTemplate: this.responseSizeCell,
            filter: undefined,
            displayed: !this.authService.isFarmer(),
            sort: "responseSize",
          },
          {
            name: "durationMs",
            translationKey: "models.clearingHouseLog.durationMs",
            cellTemplate: this.durationMsCell,
            filter: undefined,
            displayed: !this.authService.isFarmer(),
            sort: "durationMs",
          },
        ]);
    this.columnsPS = [
      {
        name: "consumerName",
        translationKey: "models.clearingHouseLog.consumerName",
        cellTemplate: this.consumerNameCell,
        filter: new TextFilterDef("consumerNameFilter"),
        displayed: !this.authService.isConsumer(),
        sort: "consumerName",
      },
      {
        name: "providerName",
        translationKey: "models.clearingHouseLog.providerName",
        cellTemplate: this.providerNameCell,
        filter: new TextFilterDef("providerNameFilter"),
        displayed: !this.authService.isProvider(),
        sort: "providerName",
      },
      {
        name: "period",
        translationKey: "models.clearingHouseLog.period",
        cellTemplate: this.periodCell,
        filter: new TextFilterDef("periodFilter"),
        displayed: true,
        sort: "period",
      },
      {
        name: "numberOfCalls",
        translationKey: "models.clearingHouseLog.numberOfCalls",
        cellTemplate: this.numberOfCallsCell,
        filter: undefined,
        displayed: true,
        sort: "numberOfCalls",
      },
      {
        name: "volume",
        translationKey: "models.clearingHouseLog.volume",
        cellTemplate: this.volumeCell,
        filter: undefined,
        displayed: true,
        sort: "volume",
      },
    ]
  }
}
