<h1 mat-dialog-title>{{ "certificate.password.title" | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="OnConfirm(form.getRawValue())">
  <div mat-dialog-content>{{ "certificate.password.text" | translate }}.</div>
  <mat-form-field class="full-width">
    <input
      type="password"
      matInput
      formControlName="password"
      placeholder="{{ 'certificate.password.placeholder' | translate }}"
      cdkFocusInitial
    />
  </mat-form-field>
  <div class="button-group">
    <button type="button" (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button type="submit" class="green">
      {{ "actions.confirm" | translate }}
    </button>
  </div>
</form>
