import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { DatahubPartner, CreateDatahubPartnerRequest } from '../../admin/models/datahubPartner';
import { HttpClient } from '@angular/common/http';
import { DatahubUser } from '../../shared/models/datahubUser';
import { DataPartnerSignUp, DataPartnerSignUpResult } from '../../shared/models/partner-signup';
import { Injectable, Inject } from '@angular/core';
import { UserInvitation } from '../../shared/models/userInvitation';
import { map } from 'rxjs/operators';
import { InvitationResult } from '../../shared/models/invitation-result';

@Injectable()
export class PartnerService {
  private URL = `${this.api}/api/datapartner`;
  constructor(private client: HttpClient, @Inject(baseurl) private api: string) { }

  public getPartner(partnerId: string): Observable<DatahubPartner> {
    return this.client.get<DatahubPartner>(`${this.URL}/${partnerId}`);
  }

  public getAllPartners(): Observable<Array<DatahubPartner>> {
    return this.client.get<Array<DatahubPartner>>(this.URL);
  }

  public getUserByPartner(id: string): Observable<Array<DatahubUser>> {
    return this.client.get<Array<DatahubUser>>(`${this.URL}/${id}/users`);
  }

  public createPartner(partner: CreateDatahubPartnerRequest): Observable<any> {
    return this.client.post(this.URL, partner);
  }

  public updatePartner(partner: CreateDatahubPartnerRequest): Observable<any> {
    return this.client.put(this.URL, partner);
  }

  public generateCertificate(id: string): Observable<any> {
    return this.client.post(`${this.URL}/${id}/generate-certificate`, null);
  }

  public downloadCertificate(id: string): Observable<any> {
    return this.client.get(`${this.URL}/${id}/download-certificate`, { responseType: 'blob' });
  }

  public activatePartner(partnerId: string): Observable<any> {
    return this.client.post(`${this.URL}/${partnerId}/activate`, null);
  }

  public signUp(signUp: DataPartnerSignUp): Observable<DataPartnerSignUpResult> {
    return this.client.post<DataPartnerSignUpResult>(`${this.URL}/sign-up`, signUp);
  }

  public invitePartner(partnerId: string, email: string): Observable<InvitationResult> {
    let httpResponse: Observable<any>;
    httpResponse =  this.client.post(`${this.URL}/${partnerId}/invite`, { email });
    return httpResponse.pipe(map(response => Object.assign(new InvitationResult, response)));
  }

  public getInvitations(partnerId: string): Observable<Array<UserInvitation>> {
    return this.client.get<Array<UserInvitation>>(`${this.URL}/${partnerId}/invite`)
  }

  public deleteUserFromPartner(partnerId: string, userId: string): Observable<any> {
    return this.client.delete(`${this.URL}/${partnerId}/user/${userId}`);
  }
}
