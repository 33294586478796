import { ApiResource } from './apiResource';
import { FarmIdType } from '../../data-provider-module/models/farm-id-type';

export class ConsumerAccess {
  id: string;
  accessUntil: Date;
  resources: Array<ApiResource>;
  resourcesIds: Array<string>;
  farmsIds: Array<string>;
  areAllFarmsSelected: boolean;
  farmIdType: FarmIdType;
}
