import { Pipe, PipeTransform } from '@angular/core';
import { FarmStatus } from '../../data-consumer/models/farm-status-summary';

@Pipe({
    name: 'FarmMappingStatusTranslation'
})
export class FarmMappingStatusTranslation implements PipeTransform {
    transform(value: string): any {
        return `models.farmAccess.statuses.${FarmStatus[value]}`;
    }
}
