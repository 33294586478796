import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatGridListModule } from '@angular/material/grid-list';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Scroll } from '@angular/router';
import { RoleGuard } from '../shared/guards/role-guard';
import { ManageDataAccessComponent } from './manage-data-access/manage-data-access.component';
import { ConsumerRequestsCardComponent } from './consumer-requests-card/consumer-requests-card.component';
import { SharedModule } from '../shared/shared.module';
import { ConsumerDarsComponent } from './consumer-dars/consumer-dars.component';
import { ManageFarmsComponent } from './manage-farms/manage-farms.component';
import { UserModule } from '../user/user.module';
import { FarmDetailsComponent } from './farm-details/farm-details.component';
import { FarmMappingMistakeDialogComponent } from './farm-details/farm-mapping-mistake-dialog/farm-mapping-mistake-dialog.component';
import { AuthGuard } from '../shared/guards/auth-guard';
@NgModule({
    declarations: [
        ManageDataAccessComponent,
        ConsumerRequestsCardComponent,
        ConsumerDarsComponent,
        ManageFarmsComponent,
        FarmDetailsComponent,
        FarmMappingMistakeDialogComponent
    ],
    imports: [
        CommonModule,
        SharedModule,
        UserModule,
        MatGridListModule,
        ScrollingModule,
        RouterModule.forChild([
            {
                path: 'manage-farmers',
                canActivate: [AuthGuard, RoleGuard],
                component: ManageFarmsComponent,
                data: {
                    breadcrumb: 'navigation.manageFarmers',
                    roles: ['Farmer']
                }
            },
            {
                path: 'farm-details',
                canActivate: [AuthGuard, RoleGuard],
                component: FarmDetailsComponent,
                data: {
                    breadcrumb: 'navigation.farmDetails',
                    roles: ['Farmer']
                }
            },
            {
                path: 'manage-data-access',
                canActivate: [AuthGuard, RoleGuard],
                data: {
                    breadcrumb: 'navigation.manageDataAccess',
                    roles: ['Farmer']
                },
                children: [
                    {
                        path: '',
                        pathMatch: 'full',
                        component: ManageDataAccessComponent,
                        data: {
                            breadcrumb: '',
                            roles: ['Farmer']
                        }
                    },
                    {
                        path: 'details/:id',
                        component: ConsumerDarsComponent,
                        data: {
                            breadcrumb: 'navigation.manageConsumerDataAccess',
                            roles: ['Farmer']
                        }
                    }
                ]
            }
        ])
    ],
    exports: [
        ManageDataAccessComponent,
        ConsumerDarsComponent,
        ConsumerRequestsCardComponent,
    ]
})
export class FarmerModule { }
