<div>
  <div class="register-content d-flex-evenly ml-10 mb-30">
    <mat-card class="register-content-item flex-base">
      <form novalidate>
        <p class="pre-wrap colored text-left">{{
          "landingPage.notRegistered.greeting" | translate
        }}</p>
        <p
          class="pre-wrap colored text-left"
          [innerHtml]="'landingPage.notRegistered.introduction' 
            | translate : {firstName: firstName, lastName: lastName}"
        ></p>
        <p
          class="pre-wrap colored text-left"
          *ngIf="loggedInWithKbo"
          [innerHtml]="'landingPage.notRegistered.introductionWithKbo' 
            | translate"
        ></p>
        <p
          class="pre-wrap colored text-left"
          *ngIf="!loggedInWithKbo"
          [innerHtml]="'landingPage.notRegistered.introductionWithoutKbo' 
                | translate"
        ></p>
                
        <section>
          <label for="notRegistered-legalRepresentive">
            <h2>
              {{ "landingPage.notRegistered.header.legalRepresentative" | translate }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="notRegistered-legalRepresentive"
            id="notRegistered-legalRepresentive"
            [checked]="loggedInWithKbo"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.notRegistered.instruction.legalRepresentative' | translate"
                ></p>
                <button
                  class="action-button"
                  (click)="gotoRegisterPage()"
                  [innerHtml]="'landingPage.notRegistered.action.legalRepresentative' | translate"
                ></button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
        <section *ngIf="!loggedInWithKbo">
          <label for="notRegistered-employee">
            <h2>
              {{ "landingPage.notRegistered.header.employee" | translate }}
            </h2>
          </label>
          <input
            type="checkbox"
            name="notRegistered-employee"
            id="notRegistered-employee"
          />
          <div class="expandable">
            <div class="inner">
              <div class="fields">
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.notRegistered.instruction.employee' | translate"
                ></p>
                <p
                  class="pre-wrap colored text-left"
                  [innerHtml]="'landingPage.notRegistered.instruction.employeeP2' | translate"
                ></p>
                <button
                  class="action-button"
                  (click)="gotoRegisterPage()"
                  [innerHtml]="'landingPage.notRegistered.action.employee' | translate"
                ></button>
              </div>
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24"
              viewBox="0 -960 960 960"
              width="24"
              class="icon"
            >
              <path d="m296-345-56-56 240-240 240 240-56 56-184-184-184 184Z" />
            </svg>
          </div>
        </section>
      </form>
    </mat-card>
  </div>
</div>

