<div class="popover-container">
  <button
    (click)="changePopoverState()"
    aria-haspopup="true"
    class="green"
    id="changeContext"
    aria-label="context-popup"
    data-title="context-popup"
  >
    {{ "layout.context.changeContext" | translate }}
  </button>

  <div class="popover-content" [class.popover-show]="togglePopover">
    <button
      class="close"
      (click)="changePopoverState()"
      aria-label="Close context popup"
    ></button>
    <p>{{ "layout.context.info" | translate }}</p>
    <p *ngIf="hasRole('Farmer')">{{ "layout.context.infoFarmer" | translate }}</p>

    <mat-card>
      <mat-card-content>
        <mat-form-field>
          <mat-select
            aria-label="role-select"
            [(ngModel)]="context.role"
            name="roleName"
          >
            <mat-option *ngFor="let role of roles" [value]="role">
              {{ role.name | ContextRoleTranslation | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>

        <div *ngIf="isSelectionVisible(farms, 'Farmer')">
          <mat-form-field>
            <mat-select
              aria-label="role-select-farm"
              [(ngModel)]="context.farm"
              name="farm.name"
            >
              <mat-option *ngFor="let farm of farms" [value]="farm">
                {{ farm.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isSelectionVisible(providers, 'Provider')">
          <mat-form-field>
            <mat-select
              aria-label="role-select-provider"
              [(ngModel)]="context.provider"
              name="provider.name"
            >
              <mat-option *ngFor="let provider of providers" [value]="provider">
                {{ provider.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div *ngIf="isSelectionVisible(consumers, 'Consumer')">
          <mat-form-field>
            <mat-select
              aria-label="role-select-consumer"
              [(ngModel)]="context.consumer"
              name="consumer.name"
            >
              <mat-option *ngFor="let consumer of consumers" [value]="consumer">
                {{ consumer.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <button
          (click)="onChangeClick()"
          [disabled]="!canChange()"
          class="green"
        >
          {{ "layout.context.change" | translate }}
        </button>
      </mat-card-content>
    </mat-card>
    <div *ngIf="isSelectionVisible(farms, 'Farmer')">
      <p class="smal" [innerHtml]="'layout.context.missingKbo' | translate"></p>
      <app-relog-link></app-relog-link>
    </div>
  </div>
</div>
