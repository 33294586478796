<h1>{{ "navigation.managePartnerUsers" | translate }}</h1>
<p>{{ "info.manageFarmers" | translate }}</p>
<app-manage-users
  [users]="farmers"
  (remove)="removeFarmer($event)"
  [showExport]="false"
>
</app-manage-users>
<app-manage-invites
  [invitees]="farmInvitees"
  (invite)="inviteFarmer($event)"
  [users]="farmers"
  [showExport]="false"
>
</app-manage-invites>
