<ngx-datatable
  #table
  [rows]="filteredRows"
  [columns]="shownColumns"
  [columnMode]="'flex'"
  [headerHeight]="headerHeight"
  [footerHeight]="footerHeight"
  [rowHeight]="'auto'"
  [limit]="limit"
  [messages]="{ emptyMessage: ('grid.noData' | translate) }"
  class="{{ lastColumnIcon ? 'lastIcon' : '' }}"
>
  <ngx-datatable-footer>
    <ng-template
      ngx-datatable-footer-template
      let-rowCount="rowCount"
      let-pageSize="pageSize"
      let-selectedCount="selectedCount"
      let-curPage="curPage"
      let-offset="offset"
    >
      <div class="table-footer">
        <div class="footer">{{ "grid.total" | translate }}: {{ rowCount }}</div>
        <div>
          <datatable-pager
            [pagerLeftArrowIcon]="'datatable-icon-left'"
            [pagerRightArrowIcon]="'datatable-icon-right'"
            [pagerPreviousIcon]="'datatable-icon-prev'"
            [pagerNextIcon]="'datatable-icon-skip'"
            [page]="curPage"
            [size]="pageSize"
            [count]="rowCount"
            [hidden]="!(rowCount / pageSize > 1)"
            (change)="table.onFooterPage($event)"
          >
          </datatable-pager>
        </div>
        <button
          *ngIf="this.exportable && showExport"
          (click)="exportToCsv()"
          class="green"
          id="exportToCsv"
          type="button"
        >
          <i class="fas fa-file-export"></i>
          <span>
            {{ "grid.export" | translate }}
          </span>
        </button>
      </div>
    </ng-template>
  </ngx-datatable-footer>
</ngx-datatable>

<ng-template
  #hdrTextTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
>
  <div class="sort-header" (click)="sort()">
    <div>{{ column.name | translate }}</div>
    <span
      [class]="
        sortDir
          ? sortDir === 'asc'
            ? 'sort-btn sort-asc datatable-icon-up'
            : 'sort-btn sort-desc datatable-icon-down'
          : ''
      "
    ></span>
  </div>
  <mat-form-field>
    <input
      matInput
      type="text"
      (keyup)="textfilterData($event, column.prop)"
      value=""
    />
  </mat-form-field>
</ng-template>

<ng-template
  #hdrTranslationTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
>
  <div class="sort-header" (click)="sort()">
    <div>{{ column.name | translate }}</div>
    <span
      [class]="
        sortDir
          ? sortDir === 'asc'
            ? 'sort-btn sort-asc datatable-icon-up'
            : 'sort-btn sort-desc datatable-icon-down'
          : ''
      "
    ></span>
  </div>
  <mat-form-field>
    <input
      matInput
      type="text"
      (keyup)="translationfilterData($event, column.prop)"
    />
  </mat-form-field>
</ng-template>

<ng-template
  #hdrSelectTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
>
  <div class="sort-header" (click)="sort()">
    <div>{{ column.name | translate }}</div>
    <span
      [class]="
        sortDir
          ? sortDir === 'asc'
            ? 'sort-btn sort-asc datatable-icon-up'
            : 'sort-btn sort-desc datatable-icon-down'
          : ''
      "
    ></span>
  </div>
  <mat-form-field>
    <mat-select (selectionChange)="selectfilterData($event, column.prop)">
      <mat-option>-- {{ "none" | translate }} --</mat-option>
      <mat-option *ngFor="let data of column.data" [value]="data.value">
        {{ data.view | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template
  #hdrDateTpl
  let-column="column"
  let-sort="sortFn"
  let-sortDir="sortDir"
>
  <div class="sort-header" (click)="sort()">
    <div>{{ column.name | translate }}</div>
    <span
      [class]="
        sortDir
          ? sortDir === 'asc'
            ? 'sort-btn sort-asc datatable-icon-up'
            : 'sort-btn sort-desc datatable-icon-down'
          : ''
      "
    ></span>
  </div>
  <mat-form-field>
    <!-- TODO UPDATE <input matInput [satDatepicker]="picker" (dateChange)="datefilterData($event, column.prop)" />
    <sat-datepicker #picker [rangeMode]="true"></sat-datepicker>
    <sat-datepicker-toggle matSuffix [for]="picker"></sat-datepicker-toggle> -->
  </mat-form-field>
</ng-template>
