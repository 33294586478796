<h2 class="privacy-title" *ngIf="data.isFarmer">{{ 'invitationPage.termsDialog.kboTitle' | translate }}</h2>
<h2 class="privacy-title" *ngIf="!data.isFarmer && (data.isPartner || data.isAdmin)">{{ 'invitationPage.termsDialog.partnerTitle' | translate }}</h2>

<mat-dialog-content class="checkboxes">
    <!-- <p>{{ 'invitationPage.termsDialog.text' | translate }}</p> -->
    <br>
    <div class="checkbox-item" *ngIf="!this.data.termsSummaryDTO.isNewestPrivacyPolicyVersion" >
        <input type="checkbox" [(ngModel)]="approvePrivacyPolicy"/>
        <span class="checkbox-label" [innerHTML]="'invitationPage.termsDialog.privacyCheckbox' | translate"></span>
    </div>
   
    <div class="checkbox-item" *ngIf="data.isFarmer && !this.data.termsSummaryDTO.isNewestFarmContractVersion" >
        <input type="checkbox" [(ngModel)]="approveFarmContract"/>
        <span class="checkbox-label" [innerHTML]="'invitationPage.termsDialog.farmTermsCheckbox' | translate"></span> 
    </div>
    
<!--This has been commented out because partners request to sign on paper -->

    <!-- <div class="checkbox-item" *ngIf="data.isPartner && !this.data.termsSummaryDTO.isNewestPartnerContractVersion" >
        <input type="checkbox" [(ngModel)]="approvePartnerContract"/>
        <span class="checkbox-label" [innerHTML]="'invitationPage.termsDialog.partnerTermsCheckbox' | translate"></span>
    </div> -->
   
    <div class="checkbox-item">
        <input type="checkbox" [(ngModel)]="allowDirectMarketing"/>
        <span class="checkbox-label" [innerHTML]="'invitationPage.termsDialog.marketingConsent' | translate"></span>
    </div>
   
</mat-dialog-content>

<div class="button-container">
    <mat-dialog-actions *ngIf="!data.isFarmer">
        <button [disabled]="!approvePrivacyPolicy" class="btn-approve-policies" (click)="onAccept()">{{ 'actions.confirm' | translate }}</button>
    </mat-dialog-actions>
    
    <mat-dialog-actions *ngIf="data.isFarmer">
        <button [disabled]="!approvePrivacyPolicy || !approveFarmContract" class="btn-approve-policies" (click)="onAccept()">{{ 'actions.confirm' | translate }}</button>
    </mat-dialog-actions>
    
    <mat-dialog-actions *ngIf="data.isAdmin">
        <button class="btn-logout" (click)="onLeaveOpen()">{{ 'actions.adminLeaveOpen' | translate }}</button>
    </mat-dialog-actions>
</div>

