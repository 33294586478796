import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'BooleanCheckmarkTranslation'
})
//for using this, set [InnerHTML] of element to "value | BooleanCheckmarkTranslation".
export class BooleanCheckmarkTranslation implements PipeTransform {
    transform(value: string): any {
        return value ? '<p>&#10004;</p>' : '<p>&#10008;</p>';
    }
}
