import { ChangeDetectorRef, Pipe, PipeTransform } from "@angular/core";
import { FarmIdType, FarmIdTypeEnum } from "../../data-provider-module/models/farm-id-type";
import { FarmIdTypesService } from "../../core/services/farmIdType.service";

@Pipe({
  name: "FarmTypeTranslation",
  pure: false
})
export class FarmTypeTranslation implements PipeTransform {
  constructor(
    private _ref: ChangeDetectorRef, 
    public farmIdTypesService: FarmIdTypesService) {}
  farmIdTypes: FarmIdType[] = [];
  farmIdType = new FarmIdType();
  subscription = this.farmIdTypesService.getAll().subscribe((types) => {
    this.farmIdTypes = types as FarmIdType[];
    this._ref.markForCheck(); //on start, translations are done before types are loaded > this call pipe again 
  });

  transform(value: string): any {
    for (let i = 0; i < this.farmIdTypes.length; i++) {
      this.farmIdType = this.farmIdTypes[i];
      if (value == this.farmIdType.id || value == this.farmIdType.name) {
        return `models.farmMapping.farmIdTypeId.${this.farmIdType.name}`;
      }
    }
    return "Unknown type";
  }
}
