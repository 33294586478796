import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-message-dialog',
  templateUrl: './message-dialog.component.html',
  styleUrls: ['./message-dialog.component.css']
})
export class MessageDialogComponent{
  defaultWidth: '600px';

  constructor(public dialogReg: MatDialogRef<MessageDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public text: string) {
    }

    onClose() {
      this.dialogReg.close();
    }
}
