import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { StartPageComponent } from "./start-page/start-page.component";
import { SharedModule } from "../shared/shared.module";
import { FarmerModule } from "../farmer/farmer.module";
import { ContextStartPageComponent } from "./start-page/context-start-page/context-start-page.component";
import { NoRoleStartPageComponent } from "./start-page/no-role-start-page/no-role-start-page.component";
import { NotLoggedInStartPageComponent } from "./start-page/not-logged-in-start-page/not-logged-in-start-page.component";
import { AdminModule } from "../admin/admin.module";
import { MatButtonModule } from "@angular/material/button";
import { NotRegisteredStartPageComponent } from "./start-page/not-registered-start-page/not-registered-start-page.component";
import { RegisterFarmerComponent } from "../shared/register/register-farmer/register-farmer.component";
import { AdminHelpdeskTableUsersComponent } from "../admin/admin-helpdesk-table-users/admin-helpdesk-table-users.component";
import { AdminStatisticsComponent } from "../admin/admin-statistics/admin-statistics.component";

@NgModule({
  declarations: [
    StartPageComponent,
    ContextStartPageComponent,
    NoRoleStartPageComponent,
    NotLoggedInStartPageComponent,
    NotRegisteredStartPageComponent,
    AdminHelpdeskTableUsersComponent,
    AdminStatisticsComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    AdminModule,
    FarmerModule,
    MatButtonModule,
    RouterModule.forRoot([
      {
        path: "",
        pathMatch: "full",
        component: StartPageComponent,
        data: {
          breadcrumb: "",
        },
      },
    ]),
  ],
})
export class StartPageModule {}
