import { Pipe, PipeTransform } from '@angular/core';
import { Language } from '../models/language';

@Pipe({
    name: 'LanguageKey'
})
export class LanguageKeyTranslation implements PipeTransform {
    transform(value: Language): any {
        return `localLanguages.${this.getLanguageKey(value)}`;
    }

    private getLanguageKey(value: Language): string {
        switch (value) {
            case Language.en: {
                return "english";
            }
            case Language.fr: {
                return "french";
            }
            case Language.nl: {
                return "dutch";
            }
        }
    }
}