import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from '../../../dialog/notification-dialog/notification-dialog.component';
import { PartnerService } from '../../../../core/services/partner.service';
import { DataPartnerSignUpResult } from '../../../models/partner-signup';

@Component({
  selector: 'app-register-partner',
  templateUrl: './register-partner-details.component.html',
  styleUrls: ['./register-partner-details.component.css']
})
export class RegisterPartnerDetailsComponent implements OnInit, OnDestroy {

  partnerCreationForm: UntypedFormGroup;
  subscription = new Subscription;

  constructor(formBuilder: UntypedFormBuilder, private partnerService: PartnerService, private dialog: MatDialog) {
    this.partnerCreationForm = formBuilder.group({
      name: ['', Validators.required],
      purpose: ['', Validators.required],
      applicationUrl: [''],
      wantsToProvide: ['', Validators.required],
      acceptTerms: ['', Validators.requiredTrue]
    });
  }

  ngOnInit() {
  }
  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  createPartner(values) {
    this.subscription.add(
      this.partnerService.signUp(values).subscribe((result) => {
        let title: string;
        let text: string;

        if (result === DataPartnerSignUpResult.Created) {
          title = 'registration.partner.registered.title';
          text = 'registration.partner.registered.text';
        } else if (result === DataPartnerSignUpResult.MailSendToAdminForEvaluation) {
          title = 'registration.partner.evaluation.title';
          text = 'registration.partner.evaluation.text';
        }

        this.dialog.open(NotificationDialogComponent, {
          width: '400px',
          data: {
            title: title,
            text: text
          }
        });
      })
    );
  }
}
