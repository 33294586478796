import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['./password-dialog.component.css']
})
export class PasswordDialogComponent implements OnInit {

  form: UntypedFormGroup;
  constructor(public dialogReg: MatDialogRef<PasswordDialogComponent>, formBuilder: UntypedFormBuilder) {
    this.form = formBuilder.group({
      password: ['', [Validators.required]]
    });
   }

  ngOnInit() {
  }

  onCancel() {
    this.dialogReg.close(null);
  }

  OnConfirm(values) {
    this.dialogReg.close(values.password);
  }

}
