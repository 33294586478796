<input
  (keyup)="filter()"
  [(ngModel)]="filterValue"
  [disabled]="disabled"
  placeholder="{{ 'actions.search' | translate }}"
  autocomplete="off"
  type="text"
/>
<div class="button-group">
  <button [disabled]="disabled" type="button" (click)="add()" class="green">
    {{ "actions.addFarmids" | translate }}
  </button>
  <button [disabled]="disabled" type="button" (click)="clearAll()" class="rose">
    {{ "actions.clearAll" | translate }}
  </button>
</div>
<!-- multiple columns is not supported
  -- on this moment(aug 2024), a pull request is open for the feature
  -- https://github.com/angular/components/pull/29453  -->
<cdk-virtual-scroll-viewport
  appendOnly
  itemSize="50"
  class="farmids-group"
  *ngIf="farmIdsBuffer.length"
  [style.height.px]="(farmIdsBuffer.length > 5 ? 50*5 : 16 + (50*farmIdsBuffer.length))" 
>
  <button
    class="unset"
    (click)="remove(farmId)"
    [aria-label]="'Remove farm ID:' + farmId"
    *cdkVirtualFor="let farmId of farmIdsBuffer"
  >
    <span class="farmId trashIcon">
      {{ farmId }}
    </span>
  </button>
</cdk-virtual-scroll-viewport>
