import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { ResourceAccessRequestDTO } from '../../shared/models/resourceAccessRequestDTO';
import { ResourceRequestsCard } from '../../data-provider-module/models/resource-requests-card';
import { AccessRequestStatus } from '../../shared/models/access-request-status';

@Injectable()
export class ResourceAccessRequestService {
  private URL = `${this.api}/api/ResourceAccessRequest`;
  constructor(
    private client: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getResourceAccessRequestsAsDTO(): Observable<
    Array<ResourceAccessRequestDTO>
  > {
    return this.client.get<Array<ResourceAccessRequestDTO>>(`${this.URL}`);
  }

  public getResourceAccessRequestsByConsumerAccessId(
    consumerAccessId: string
  ): Observable<Array<ResourceAccessRequestDTO>> {
    return this.client.get<Array<ResourceAccessRequestDTO>>(
      `${this.URL}/consumer/${consumerAccessId}`
    );
  }

  public approveResourceAccessRequest(id: string): Observable<any> {
    return this.client.post(`${this.URL}/${id}/approve`, null);
  }

  public rejectResourceAccessRequest(id: string): Observable<any> {
    return this.client.post(`${this.URL}/${id}/reject`, null);
  }

  public splitArrayByConsumerId(requests: ResourceAccessRequestDTO[]): ResourceAccessRequestDTO[][] {
    if (!requests || requests.length === 0) {
      return [];
    }
    const result: ResourceAccessRequestDTO[][] = [];
    let currentConsumerId = requests[0].consumerId;
    let currentRequests: ResourceAccessRequestDTO[] = [requests[0]];
    for (let i = 1; i < requests.length; i++) {
      const consumerId = requests[i].consumerId;
      if (consumerId === currentConsumerId) {
        currentRequests.push(requests[i]);
        if(requests[i].creationDate > currentRequests[0].creationDate){
          currentRequests[0].creationDate = requests[i].creationDate;
        }
      } else {
        result.push(currentRequests);
        currentRequests = [requests[i]];
        currentConsumerId = consumerId;
      }
    }
    result.push(currentRequests);
    return result;
  }

  public checkRequestsStatus(resourceRequestsCard: ResourceRequestsCard) {
    resourceRequestsCard.pending = 0;
    resourceRequestsCard.rejected = 0;
    resourceRequestsCard.approved = 0;
    resourceRequestsCard.requests.forEach((request) => {
      if (request.status === 0 || request.status === null) {
        resourceRequestsCard.pending++;
      } else if (request.status === 1) {
        resourceRequestsCard.approved++;
      } else if (request.status === 2) {
        resourceRequestsCard.rejected++;
      }
    });
  }

  public getPending(consumers): ResourceRequestsCard[] {
    if (!consumers) {
      return [];
    }
    return consumers.filter((x) =>
      x.requests.some((r) => r.status === AccessRequestStatus.Pending)
    );
  }

  public getHandled(consumers): ResourceRequestsCard[] {
    if (!consumers) {
      return [];
    }
    let t = consumers.filter((x) =>
      x.requests.some((r) => r.status !== 0 && r.status !== null && !r.informativeFlow)
    );
    return t;
  }

  public approveAllPendingDataAccessRequests(
    consumerAccessId: string
  ): Observable<any> {
    return this.client.post(
      `${this.URL}/consumer/${consumerAccessId}/approve`,
      null
    );
  }

  public rejectAllPendingAccessRequests(
    consumerAccessId: string
  ): Observable<any> {
    return this.client.post(
      `${this.URL}/consumer/${consumerAccessId}/reject`,
      null
    );
  }
}
