<h2>{{ "navigation.managePartnerUsers" | translate }}</h2>

<app-base-datatable
  class="material"
  [rows]="users"
  [columns]="columns"
  [headerHeight]="50"
  [footerHeight]="50"
  [limit]="10"
  [showExport]="showExport"
  [lastColumnIcon]="true"
>
</app-base-datatable>

<ng-template let-value="value" #statusTemplate ngx-datatable-cell-template>
  <p>{{ value | DatahubUserStatusTranslation | translate }}</p>
</ng-template>

<ng-template #textTemplate let-column="column">
  <p>{{ column.name | translate }}</p>
</ng-template>

<ng-template #notificationTemplate let-row="row">
  <mat-checkbox
    [checked]="row.receiveNotifications"
    (change)="setReceiveNotifications(row.id)"
    >{{ "manageUsers.receiveNotifications" | translate }}</mat-checkbox
  >
</ng-template>

<ng-template #editTmpl let-row="row">
  <button
    type="button"
    (click)="removeUser(row.id)"
    [disabled]="isTheLoggedInUser(row.id) | async"
    aria-label="remove user"
    class="card-button"
  >
    <i class="fas fa-trash-alt"></i>
  </button>
</ng-template>
