<app-header
  [context]="context"
  [isLoggedIn]="isLoggedIn"
  [hasMultipleContexts]="hasMultipleContexts"
  [isLoggedInAndHasRole]="isLoggedInAndHasRole"
></app-header>

<main [ngClass]="{ loggedIn: isLoggedInAndHasRole }">
  <ng-template [ngIf]="isLoggedInAndHasRole">
    <app-breadcrumb></app-breadcrumb>
  </ng-template>
  <ng-template [ngIf]="isLoggedInAndHasRole && hasCookie()">
    <app-nav-menu></app-nav-menu>
  </ng-template>
  <div
    [ngClass]="{
      'avoid-clicks': !hasCookie(),
      'body-content': isLoggedInAndHasRole,
      'body-content-no-login': !isLoggedInAndHasRole
    }"
  >
    <router-outlet></router-outlet>
  </div>
  <app-cookie-banner *ngIf="!hasCookie()"></app-cookie-banner>
</main>

<app-footer [isDevEnv]="isDevEnv" [version]="version"></app-footer>

<ngx-spinner
  bdColor="rgba(51,51,51,0.7)"
  size="medium"
  color="#fff"
  type="ball-circus"
  [fullScreen]="true"
>
  <p style="color: white">Loading...</p>
</ngx-spinner>
