import { ApiStatus } from './api-status';
import { APIResource } from '../../shared/models/apiResource';
import { ApiTag } from './api-tag';
import { ApiCredentials } from './api-credentials';
import { Language } from '../../shared/models/language';
import { FarmIdApiVersionType } from './farm-id-api-version-type';

export class Api {
  id: string;
  apiUrl: string;
  openApiSpecUrl: string;
  farmIdUrl: string;
  farmIdApiVersion: FarmIdApiVersionType;
  status: ApiStatus;
  useOAuth: boolean;
  resources: Array<APIResource>;
  headers: any;
  credentials: ApiCredentials;
  tags: Array<ApiTag>;
  translations: Array<ApiTranslation>;

  constructor() {
    this.resources = new Array<APIResource>();
    this.tags = new Array<ApiTag>();
  }

  isDeletable(): boolean {
    return this.status === ApiStatus.Draft;
  }

  isEditable(): boolean {
    return this.status === ApiStatus.Draft;
  }

  canBePublished(): boolean {
    return this.status === ApiStatus.Submitted;
  }

  isPublished(): boolean {
    return this.status === ApiStatus.Published;
  }
}

export class ApiTranslation {
  language: Language;
  ApiName: string;
}