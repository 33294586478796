import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { includes } from "lodash";

interface CookieSelection {
  functional: boolean;
  analysis: boolean;
  marketing: boolean;
}

@Component({
  selector: "app-cookie-banner",
  templateUrl: "./cookie-banner.component.html",
  styleUrls: ["./cookie-banner.component.css"],
})
export class CookieBannerComponent implements OnInit {
  AnalysisChecked: boolean = true;
  MarketingChecked: boolean = false;
  CurrentSelection: number = 0;
  private cookieAgreed: CookieSelection = {
    functional: true,
    analysis: false,
    marketing: false,
  };

  constructor() {}

  ngOnInit() {}

  AcceptAll() {
    this.cookieAgreed = {
      functional: true,
      analysis: true,
      marketing: true,
    };
    this.setCookie("cookie-agreed", JSON.stringify(this.cookieAgreed), 100);
  }

  DenyAll() {
    this.cookieAgreed = {
      functional: false,
      analysis: false,
      marketing: false,
    };
    this.setCookie("cookie-agreed", JSON.stringify(this.cookieAgreed), 100);
  }

  AcceptSelection() {
    this.cookieAgreed = {
      functional: true,
      analysis: this.AnalysisChecked,
      marketing: this.MarketingChecked,
    };
    this.setCookie("cookie-agreed", JSON.stringify(this.cookieAgreed), 100);
  }

  private setCookie(
    name: string,
    value: string,
    expireDays: number,
    path: string = ""
  ) {
    let d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    let expires: string = `expires=${d.toUTCString()}`;
    let cpath: string = path ? `; path=${path}` : "";
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  onAnalysisChanged(value: boolean) {
    this.AnalysisChecked = value;
  }

  onMarketingChanged(value: boolean) {
    this.MarketingChecked = value;
  }

  @ViewChild("dialog", { static: true }) dialog: ElementRef;
  openDialog() {
    this.dialog.nativeElement.showModal();
  }

  closeDialog() {
    this.dialog.nativeElement.close();
  }

  changeSelection(value: number) {
    [0, 1, 2].includes(value)
      ? (this.CurrentSelection = value)
      : (this.CurrentSelection = 0);
  }
}
