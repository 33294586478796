import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  OnDestroy,
  ChangeDetectorRef,
} from "@angular/core";
import { Observable, Subscription } from "rxjs";
import { UserService } from "../../core/services/user.service";
import { ActivatedRoute, ParamMap, Router } from "@angular/router";
import {
  CurrentUserDetailsDTO,
  UserDetailsFarmDTO,
  UserPartnerDTO,
  UserRole,
} from "../models/datahubUser";
import { ContextService } from "../../core/services/context.service";
import { UserContextDTO } from "../models/userContextDTO";
import { FarmMappingMistakeDialogComponent } from "../../farmer/farm-details/farm-mapping-mistake-dialog/farm-mapping-mistake-dialog.component";
import { FarmService } from "../../core/services/farm.service";
import { MatDialog } from "@angular/material/dialog";
import { PartnerService } from "../../core/services/partner.service";
import { ConfirmationDialogComponent } from "../confirmation-dialog/confirmation-dialog.component";
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { NotificationDialogComponent } from "../dialog/notification-dialog/notification-dialog.component";
import { UserAuthService } from "../../core/user-auth.service";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Language } from "../models/language";

@Component({
  selector: "app-view-current-user-overview",
  templateUrl: "./view-current-user-overview.component.html",
  styleUrls: ["./view-current-user-overview.component.scss"],
})
export class ViewCurrentUserOverviewComponent implements OnInit, OnDestroy {
  subscription = new Subscription();
  finalFarmAndPartnerDeleted: boolean = false;

  constructor(
    private contextService: ContextService,
    private userService: UserService,
    private farmService: FarmService,
    private partnerService: PartnerService,
    private router: Router,
    private dialog: MatDialog,
    private formBuilder: FormBuilder,
    private cdr: ChangeDetectorRef,
    private msalService: UserAuthService,
    private userAuthService: UserAuthService,
    private translateService: TranslateService
  ) {}

  details$: Observable<CurrentUserDetailsDTO>;
  userPartnersArray: UserPartnerDTO[];
  userFarmsArray: UserDetailsFarmDTO[];
  userId: string;
  userContext: UserContextDTO;
  allContexts: UserContextDTO[];
  isAdmin: boolean;
  currentLanguage: Language;
  currentUserDetailsForm: FormGroup = this.formBuilder.group({
    allowDirectMarketing: { value: null },
    firstName: { value: null, disabled: true },
    lastName: { value: null, disabled: true },
    email: { value: null, disabled: true },
    userPartners: this.formBuilder.array([]),
    userFarms: this.formBuilder.array([]),
  });

  ngOnInit() {
    this.subscription.add(
      this.contextService.getUserContexts().subscribe((contexts) => {
        this.allContexts = contexts;
        if (contexts.length === 0) {
          this.router.navigate(["/start-page/no-role"]);
        }
      })
    );
    this.subscription.add(
      this.contextService.getContext().subscribe((context) => {
        this.userContext = context;
        if (this.userContext.roleName == UserRole.Admin) {
          this.isAdmin = true;
        } else {
          this.isAdmin = false;
        }
        this.userId = this.userContext.userId;
        this.details$ = this.userService.getCurrentUserDetails();
        this.details$.subscribe((details) => {
          this.userPartnersArray = details.userPartners;
          this.userFarmsArray = details.userFarms;
          this.currentUserDetailsForm.patchValue({
            allowDirectMarketing: details.allowDirectMarketing,
            firstName: details.firstName,
            lastName: details.lastName,
            email: details.email,
          });
          this.currentUserDetailsForm.setControl(
            "userPartners",
            this.formBuilder.array(
              this.userPartnersArray.map((userPartner) =>
                this.createPartnerGroup(userPartner)
              )
            )
          );
          this.currentUserDetailsForm.setControl(
            "userFarms",
            this.formBuilder.array(
              this.userFarmsArray.map((farm) => this.createFarmGroup(farm))
            )
          );
        });
      })
    );
    this.translateService.currentLang
      ? (this.currentLanguage = Language[this.translateService.currentLang])
      : (this.currentLanguage = Language.nl);
    this.translateService.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = Language[event.lang];
    });
  }
  get userPartners() {
    return this.currentUserDetailsForm.get("userPartners") as FormArray;
  }

  get userFarms() {
    return this.currentUserDetailsForm.get("userFarms") as FormArray;
  }
  // Method to create a form group for a partner
  createPartnerGroup(userPartner: UserPartnerDTO): FormGroup {
    return this.formBuilder.group({
      userId: [{ value: userPartner.userId, disabled: true }],
      partnerId: [{ value: userPartner.partnerId, disabled: true }],
      receiveNotifications: [userPartner.receiveNotifications],
      partner: [{ value: userPartner.partner }],
    });
  }

  // Method to create a form group for a farm
  createFarmGroup(userFarm: UserDetailsFarmDTO): FormGroup {
    return this.formBuilder.group({
      farmId: [{ value: userFarm.farmId, disabled: true }],
      kbo: [{ value: userFarm.kbo, disabled: true }],
      receiveNotifications: [userFarm.receiveNotifications],
      mappings: [{ value: userFarm.mappings, disabled: true }],
    });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  editPartner(id: string) {
    if (id) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([
          `manage-partners/manage-partner-details/`,
          id,
        ])
      );
      window.open(url, "_blank");
    } else {
      console.error("Cannot navigate to edit farm because id is undefined");
    }
  }

  editFarm(id: string) {
    if (id) {
      const url = this.router.serializeUrl(
        this.router.createUrlTree([`farmmapping/edit-farmmapping/`, id])
      );
      window.open(url, "_blank");
    } else {
      console.error("Cannot navigate to edit farm because id is undefined");
    }
  }

  getRole(role: number) {
    return Object.keys(UserRole)[role];
  }

  removeUserConfirmation(id, type: "partner" | "farm") {
    let companyName = type == "farm" 
      ? this.userFarmsArray.find(f => f.farmId == id)?.kbo
      : this.userPartnersArray.find(p => p.partnerId == id)
        ?.partner.translations.find(t=> t.language == this.currentLanguage)
        ?.name;
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "fit-content",
      data: {
        title: "viewUserPage.deleteUser.title" + type,
        text: "viewUserPage.deleteUser.message",
        textParams: { company: companyName },
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        if (type === "partner" && id != null) {
          let partnerId = id;
          this.subscription.add(
            this.partnerService
              .deleteUserFromPartner(partnerId, this.userId)
              .subscribe(() => {
                const index = this.userPartners.controls.findIndex(
                  (control) => control.value.partnerId === id
                );
                if (index !== -1) {
                  this.userPartners.removeAt(index);
                  this.cdr.detectChanges();
                }
                this.checkIfDeletingFinalPartnerOrFarm();
                this.redirectIfFinalContext(partnerId);
              })
          );
        }

        if (type === "farm" && id != null) {
          let farmId = id;
          this.subscription.add(
            this.farmService
              .deleteUserFromFarmId(this.userId, farmId)
              .subscribe(() => {
                const index = this.userFarms.controls.findIndex(
                  (control) => control.value.farmId === id
                );
                if (index !== -1) {
                  this.userFarms.removeAt(index);
                  this.cdr.detectChanges();
                }
                this.checkIfDeletingFinalPartnerOrFarm();
                this.redirectIfFinalContext(farmId);
              })
          );
        }
      }
    });
  }

  checkIfDeletingFinalPartnerOrFarm() {
    if (
      this.userPartners.controls.length === 0 &&
      this.userFarms.controls.length === 0
    ) {
      this.finalFarmAndPartnerDeleted = true;
    }
  }

  redirectIfFinalContext(contextId: string) {
    if (this.userContext.entityId === contextId) {
      //if this is their last context, then the user is informed and redirected to the login page
      if (this.finalFarmAndPartnerDeleted == true) {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "viewUserPage.updateUser.finalContext",
            text: "viewUserPage.updateUser.finalContextMessage",
          },
        });
        this.userAuthService.msalService.logout();
        this.router.navigate(["/login"]);
      }
      //if this isn't their last context, then the user is redirected to the first context
      else {
        this.userAuthService.getAllContexts().subscribe((contexts) => {
          // filter out the deleted context
          let updatedContexts = contexts.filter(
            (context) => context.entityId !== contextId
          );

          if (updatedContexts.length > 0) {
            this.router.navigate([""]).then((b) => {
              if (b || this.router.url === "/") {
                let context = updatedContexts[0]; // navigate to the first context in the updated array
                this.userAuthService.setUserContext(context).subscribe(() => {
                  sessionStorage.removeItem(
                    "contextIdAgainstClearingHouseLostContextBug"
                  );
                  window.location.reload();
                });
              }
            });
          } else {
            this.router.navigate(["/no-role-start-page"]);
          }
        });
      }
    } else {
      window.location.reload();
      this.router.navigate([""]);
    }
  }

  openReportDialog() {
    const dialogRef = this.dialog.open(FarmMappingMistakeDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((input) => {
        if (input)
          this.farmService
            .reportErrors(
              "current-user-overview",
              input,
              this.userContext.entityName
                ? this.userContext.entityName
                : undefined
            )
            .subscribe();
      })
    );
  }

  update(form: FormGroup) {
    const formValue = form.getRawValue();
    const user = new CurrentUserDetailsDTO();
    user.allowDirectMarketing = formValue.allowDirectMarketing;
    user.userPartners = formValue.userPartners;
    user.userFarms = formValue.userFarms;
    user.id = this.userId;
    this.userService.updateCurrentUserDetails(user).subscribe(
      () => {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "viewUserPage.updateUser.title",
            text: "viewUserPage.updateUser.message",
          },
        });
      },
      (error) => {
        // This function will run if the update fails
        console.error("Update failed", error);
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "viewUserPage.updateUser.failedTitle",
            text: "viewUserPage.updateUser.failedMessage",
          },
        });
      }
    );
  }

  deleteUserAccountConfirmation() {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: "fit-content",
      data: {
        title: "viewUserPage.deleteUserAccount.title",
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.deleteUser();
      }
    });
  }
  deleteUser() {
    this.userService.deleteOwnUserAccount(this.userId).subscribe((response) => {
      const isFinalUserOfAnyContext = response;
      if (isFinalUserOfAnyContext == false) {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "viewUserPage.deleteUserAccount.finalUserTitle",
            text: "viewUserPage.deleteUserAccount.notFinalUserText",
          },
        });
        dialogRef.afterClosed().subscribe(() => {
          this.userAuthService.msalService.logout();
          this.router.navigate(["/login"]);
        });
      } else {
        const dialogRef = this.dialog.open(NotificationDialogComponent, {
          width: "fit-content",
          data: {
            title: "viewUserPage.deleteUserAccount.finalUserTitle",
            text: "viewUserPage.deleteUserAccount.finalUserText",
          },
        });
      }
    });
  }
}
