<h1>{{ "navigation.viewResourceAccess" | translate }}</h1>
<p>{{ "info.resourceAccess" | translate }}</p>
<app-base-remote-datatable
  [columns]="columns"
  [initialSort]="initialSort"
  [service]="resourceAccessService"
  queryOnLangChange="true"
>
</app-base-remote-datatable>

<ng-template #resourceNameCell let-resourceName="resourceName">
  {{ resourceName }}
</ng-template>

<ng-template #apiNameCell let-apiName="apiName">
  {{ apiName }}
</ng-template>

<ng-template #providerNameCell let-providerName="providerName">
  {{ providerName }}
</ng-template>

<ng-template #statusCell let-status="status">
  {{ status | AccessRequestStatusTranslation | translate }}
</ng-template>
