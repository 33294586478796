import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  TemplateRef,
  OnDestroy,
} from "@angular/core";
import { DatahubUser, UserRole } from "../../shared/models/datahubUser";
import { BaseDatatableComponent } from "../../shared/base-datatable/base-datatable.component";
import { MatDialog } from "@angular/material/dialog";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { UserAuthService } from "../../core/user-auth.service";
import { UserService } from "../../core/services/user.service";

@Component({
  selector: "app-manage-users",
  templateUrl: "./manage-users.component.html",
  styleUrls: ["./manage-users.component.scss"],
})
export class ManageUsersComponent implements OnInit, OnDestroy {
  @Input() users: Array<DatahubUser>;
  @Input() showExport: boolean = true;
  @Output() remove = new EventEmitter<DatahubUser>();
  @ViewChild(BaseDatatableComponent, { static: true })
  baseTable: BaseDatatableComponent;
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("statusTemplate", { static: true })
  statusTemplate: TemplateRef<any>;
  @ViewChild("textTemplate", { static: true }) textTemplate: TemplateRef<any>;
  @ViewChild("notificationTemplate", { static: true })
  notificationTemplate: TemplateRef<any>;
  columns = [];
  private subscription = new Subscription();

  constructor(
    private authService: UserAuthService,
    private dialog: MatDialog,
    private userService: UserService
  ) {}

  ngOnInit() {
    this.authService.notifyCurrentContextChanged().subscribe((context) => {
      this.initColumns(context.roleName);
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  initColumns(role: UserRole) {
    this.columns = [
      {
        prop: "firstName",
        name: "models.datahubUser.firstName",
        flexGrow: 3,
        headerTemplate: this.textTemplate,
      },
      {
        prop: "lastName",
        name: "models.datahubUser.lastName",
        flexGrow: 2,
        headerTemplate: this.textTemplate,
      },
      {
        prop: "email",
        name: "models.datahubUser.email",
        flexGrow: 3,
        headerTemplate: this.textTemplate,
      },
      {
        cellTemplate: this.notificationTemplate,
        flexGrow: 2,
        hidden: role === UserRole.Admin || role === UserRole.Consumer,
      },
      {
        cellTemplate: this.editTmpl,
        name: "actions.actions",
        flexGrow: 1,
        headerTemplate: this.textTemplate,
      },
    ];
  }

  removeUser(id: String) {
    const user = this.users.find((x) => x.id === id);
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "popup.removeUser",
        textParams: { email: user.email },
      },
    });

    dialogRef.afterClosed().subscribe((value) => {
      if (value) {
        this.remove.emit(user);
      }
    });
  }

  isTheLoggedInUser(userId: any): Observable<boolean> {
    return this.authService.notifyCurrentContextChanged().pipe(
      map((context) => {
        return context.userId === userId;
      })
    );
  }

  setReceiveNotifications(userId: string) {
    this.subscription.add(
      this.userService.updateNotifications(userId).subscribe(() => {
        this.users.forEach((u) =>
          u.id == userId
            ? (u.allowDirectMarketing = !u.allowDirectMarketing)
            : u.allowDirectMarketing
        );
      })
    );
  }
}
