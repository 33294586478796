<h2 mat-dialog-title>{{ title | translate }}</h2>

<mat-dialog-content>
    <p>{{ text | translate:textParams }}</p>
    <p><a href="{{ link }}" target="_blank">{{ linkText | translate }}</a></p>
    <p *ngIf="info.userEmail">Email: </p>
    <p *ngIf="info.userEmail">{{ info.userEmail }}</p>
    <p *ngIf="info.inviteIds">Invitation Ids: </p>
    <p *ngIf="info.inviteIds">{{ info.inviteIds }}</p>
    <p *ngIf="info.partnerIds">{{ 'viewUserPage.deleteUserAccountAsAdmin.partnerIds' | translate }}</p>
    <p *ngIf="info.partnerIds">{{ info.partnerIds }}</p>
    <p *ngIf="info.farmIds">{{ 'viewUserPage.deleteUserAccountAsAdmin.farmIds' | translate }}</p>
    <p *ngIf="info.farmIds">{{ info.farmIds }}</p>
    <p *ngIf="info.roles">{{ 'viewUserPage.deleteUserAccountAsAdmin.roles' | translate }}</p>
    <p *ngIf="info.roles">{{ info.roles }}</p>
</mat-dialog-content>

<mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onClose()">{{ 'actions.close' | translate }}</button>
</mat-dialog-actions>
