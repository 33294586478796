import { Component, Input, OnInit } from "@angular/core";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Language, TranslatableString } from "../../../../models/language";

@Component({
  selector: "app-translatable-string-view",
  templateUrl: "./translatable-string-view.component.html",
  styleUrls: ["./translatable-string-view.component.css"],
})
export class TranslatableStringViewComponent implements OnInit {
  @Input() translation: Array<TranslatableString>;
  @Input() property: string;
  @Input() suffix: string = "";
  get value() {
    const transl = this.translation
      ? this.translation.find((t) => {
          return t.language === this.currentLanguage;
        })[this.property]
      : "";
    return transl;
  }
  currentLanguage: Language;

  constructor(private translate: TranslateService) {
    this.translate.onLangChange.subscribe(
      (l: LangChangeEvent) => (this.currentLanguage = Language[l.lang])
    );
    this.translate.currentLang
      ? (this.currentLanguage = Language[this.translate.currentLang])
      : (this.currentLanguage = Language.nl);
  }

  ngOnInit() {}
}
