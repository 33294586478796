<h2>{{ "manageInvites.title" | translate }}</h2>

<app-base-datatable
  class="material"
  [rows]="invitees"
  [columns]="columns"
  [headerHeight]="50"
  [footerHeight]="50"
  [limit]="10"
  [showExport]="showExport"
>
</app-base-datatable>

<ng-template #textTemplate let-column="column">
  <div>
    <div>{{ column.name | translate }}</div>
  </div>
</ng-template>

<ng-template #invitationTmpl
  let-row="row">
  <div>
    <button
      (click)="resendInvitation(row)"
      class="card-button"
      aria-label="Send invite"
    >
      <i class="fa fa-envelope"></i>
    </button>
  </div>
</ng-template>

<ng-template #deleteTmpl
  let-row="row">
  <div>
    <button 
      type="button" 
      (click)="deleteInvitation(row)"
      class="card-button"
      aria-label="Delete invite"
    >
      <i class="fa fa-trash-alt"></i>
    </button>
  </div>
</ng-template>

<button
  class="blue"
  (click)="inviteUser()"
  id="invite-user-button"
  type="button"
>
  <i class="fas fa-plus"></i>
  {{ "actions.invite" | translate }}
</button>
