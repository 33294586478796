import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { Subscription, filter } from "rxjs";
import { UserService } from "../../../core/services/user.service";
import { Language } from "../../models/language";
import { UserAuthService } from "../../../core/user-auth.service";
import { EventMessage, EventType } from "@azure/msal-browser";
import { MsalBroadcastService } from "@azure/msal-angular";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-select-language",
  templateUrl: "./select-language.component.html",
  styleUrls: ["./select-language.component.scss"],
})
export class SelectLanguageComponent implements OnInit, OnDestroy {
  languageSelector: UntypedFormGroup;
  languages = [
    {
      value: Language.en,
      viewValue: "languages.english",
      shortForm: Language[1],
    },
    {
      value: Language.nl,
      viewValue: "languages.dutch",
      shortForm: Language[2],
    },
    {
      value: Language.fr,
      viewValue: "languages.french",
      shortForm: Language[3],
    },
  ];
  selectedLanguage: Language;
  subscription = new Subscription();
  
  constructor(
    private broadcastService: MsalBroadcastService,
    builder: UntypedFormBuilder,
    private translate: TranslateService,
    private userService: UserService,
    private auth: UserAuthService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.languageSelector = builder.group({
      languageSelector: [null],
    });
  }

  private languageInit: Boolean = false;
  ngOnInit() {
    this.selectedLanguage = Language.nl;
    this.broadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => {
          return (
            msg.eventType === EventType.LOGIN_SUCCESS ||
            msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS
          );
        })
      )
      .subscribe((result) => {
        if (this.languageInit) return;
        this.userService.getLanguage().subscribe((language) => {
          if (language !== Language.Unknown) {
            this.setPreferredLanguages(language);
            this.languageInit = true;
          } else {
            this.selectedLanguage = Language.nl;
          }
        });
      });
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeLanguage(language) {
    this.selectedLanguage = parseInt(language);
    if (this.auth.isLoggedIn()) {
      this.subscription.add(
        this.userService
          .updateLanguage(this.selectedLanguage)
          .subscribe(() => {
            this.setPreferredLanguages(this.selectedLanguage);
          })
      );
    } else {
      this.setPreferredLanguages(this.selectedLanguage);
    }
  }

  setPreferredLanguages(language) {
    this.translate.use(Language[language]);
    this.selectedLanguage = parseInt(language);

    if (language > 0)
      this.document.documentElement.lang = Language[language];
  }
}
