import { Injectable, Inject } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { baseurl } from "../../../baseSettings";
import { Observable } from "rxjs";
import { FarmDetailsResult } from "../../admin/models/farmDetailsResult";
import { FarmMappingDTO } from "../../admin/models/farmMappingDTO";

@Injectable()
export class FarmDetailsService {
  private URL = `${this.api}/api/FarmMapping`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  getDetailsByFarmId(farmId): Observable<FarmDetailsResult> {
    return this.httpClient.get<FarmDetailsResult>(
      `${this.URL}/details/${farmId}`
    );
  }

  update(farmMappingDTO: FarmMappingDTO): Observable<any> {
    return this.httpClient.put(this.URL, farmMappingDTO);
  }

  updateFarmDetailsResult(farmDetailsResult: FarmDetailsResult): Observable<any> {
    return this.httpClient.put(`${this.URL}/details`, farmDetailsResult);
  }

  create(farmMappingDTO: FarmMappingDTO): Observable<any>{
    return this.httpClient.post(this.URL, farmMappingDTO);
  }

  delete(id){
    return this.httpClient.delete(`${this.URL}/${id}`);
  }
}
