import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { ConsumerRequestCard } from '../../farmer/models/consumer-request-card';
import DataAccessRequest from '../../shared/models/data-access-request';
import { map } from 'rxjs/operators';
import ConsumerDataAccessRequests from '../../shared/models/consumer-data-access-requests';

@Injectable()
export class DataAccessRequestService {
  private URL = `${this.api}/api/FarmAccess`;
  constructor(
    private client: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getDataAccessRequestsOverview(): Observable<Array<ConsumerRequestCard>> {
    let response: Observable<any>;
    response = this.client.get<Array<ConsumerRequestCard>>(`${this.URL}/consumer`);
    return response.pipe(
      map(consumerRequestCards =>
        consumerRequestCards.map(consumerRequestCard =>
          Object.assign(new ConsumerRequestCard(), consumerRequestCard)
        )
      )
    );
  }

  public approveAllPendingDataAccessRequests(consumerId: string): Observable<any> {
    return this.client.post(`${this.URL}/consumer/${consumerId}/approve`, null);
  }

  public rejectAllPendingAccessRequests(consumerId: string): Observable<any> {
    return this.client.post(`${this.URL}/consumer/${consumerId}/reject`, null);
  }

  public getDataAccessRequests(consumerId: string): Observable<ConsumerDataAccessRequests> {
    let response: Observable<any>;
    response =  this.client.get<ConsumerDataAccessRequests>(`${this.URL}/consumer/${consumerId}`);
    return response.pipe(
      map(consumerDataAccessRequests => {
        const cdar = Object.assign(new ConsumerDataAccessRequests(), consumerDataAccessRequests);
        cdar.dataAccessRequests = consumerDataAccessRequests.dataAccessRequests.map(dataAccessRequest =>
            Object.assign(new DataAccessRequest(), dataAccessRequest)
          );
          return cdar;
        }
      )
    );
  }

  public approveDataAccessRequest(darId: string): Observable<any> {
    return this.client.post(`${this.URL}/dar/${darId}/approve`, null);
  }

  public rejectDataAccessRequest(darId: string): Observable<any> {
    return this.client.post(`${this.URL}/dar/${darId}/reject`, null);
  }
}
