import { Component, OnInit, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-notification-dialog",
  templateUrl: "./notification-dialog.component.html",
  styleUrls: ["./notification-dialog.component.css"],
})
export class NotificationDialogComponent implements OnInit {
  title: string;
  text: string;
  textParams;
  link: string;
  linkText: string;
  mailLink: boolean;

  constructor(
    public dialogReg: MatDialogRef<NotificationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.title = data.title;
    this.text = data.text;
    this.textParams = data.textParams;
    this.link = data.link;
    this.linkText = data.linkText;
    this.mailLink = data.mailLink;
  }

  ngOnInit() {}

  onClose() {
    this.dialogReg.close();
  }
}
