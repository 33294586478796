import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LastUserDeleteContextInfo } from '../../../modules/admin/models/lastUserDeleteContextInfo';

@Component({
  selector: 'app-last-user-delete-dialog',
  templateUrl: './last-user-delete-dialog.component.html',
  styleUrls: ['./last-user-delete-dialog.component.css']
})
export class LastUserDeleteDialogComponent implements OnInit {

  title: string;
  text: string;
  textParams;
  link: string;
  linkText: string;
  info: LastUserDeleteContextInfo;

  constructor(public dialogReg: MatDialogRef<LastUserDeleteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data) { 
      this.title = data.title;
      this.text = data.text;
      this.textParams = data.textParams;
      this.link = data.link;
      this.linkText = data.linkText;
      this.info = data.info;
    }

  ngOnInit() {
  }

  onClose() {
    this.dialogReg.close();
  }

}
