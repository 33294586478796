<label class="form-part"
  >{{ "models.datahubPartner.certificate" | translate }}
  <input
    #certificate
    type="file"
    name="certificate-file"
    id="certificate-file"
    accept=".cer, .pfx, .p12"
    (change)="onCertificateChange($event)"
  />
  <div class="file-upload">
    <div *ngIf="upload_name === null && !currentCertificateThumbprint">
      <i class="fas fa-folder-open"></i>
    </div>
    <p>
      {{
        upload_name
          ? upload_name
          : currentCertificateThumbprint
          ? currentCertificateThumbprint
          : "Choose file"
      }}
    </p>
    <button
      id="file-upload-btn"
      *ngIf="!certificate.empty"
      (click)="certificateClear(false)"
      style="padding: 0.5rem 1rem !important"
      type="button"
      aria-label="remove certificate"
    >
      <i class="fa fa-times"></i>
    </button>
  </div>
</label>
