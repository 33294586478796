import { Component, OnInit, OnDestroy } from '@angular/core';
import { PartnerService } from '../../core/services/partner.service';
import { MatDialog } from "@angular/material/dialog";
import { InvitationResult } from '../../shared/models/invitation-result';
import { NotificationDialogComponent } from '../../shared/dialog/notification-dialog/notification-dialog.component';
import { DatahubUser, UserRole } from '../../shared/models/datahubUser';
import { UserInvitation } from '../../shared/models/userInvitation';
import { Subscription } from 'rxjs';
import { UserAuthService } from '../../core/user-auth.service';

@Component({
  selector: 'app-manage-partner-users',
  templateUrl: './manage-partner-users.component.html',
  styleUrls: ['./manage-partner-users.component.scss']
})
export class ManagePartnerUsersComponent implements OnInit, OnDestroy {

  users: Array<DatahubUser>;
  invitees: Array<UserInvitation>;
  subscription = new Subscription();
  private partnerId: string;

  constructor(private service: PartnerService,
    private authService: UserAuthService,
    private dialog: MatDialog) { }

  ngOnInit() {
    this.subscription.add(
      this.authService.notifyCurrentContextChanged()
      .subscribe(context => {
        if (context.roleName === UserRole.Provider || context.roleName === UserRole.Consumer) {
          this.partnerId = context.entityId;
          if (this.partnerId) {
            this.getUsers();
            this.getInvitations();
          }
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private getUsers() {
    this.subscription.add(this.service
      .getUserByPartner(this.partnerId)
      .subscribe(users => (this.users = users)));
  }

  private getInvitations() {
    this.subscription.add(
      this.service
        .getInvitations(this.partnerId)
        .subscribe(invites => (this.invitees = invites))
    );
  }

  inviteUser(invite) {
    this.subscription.add(
      this.service.invitePartner(this.partnerId, invite.email).subscribe({
        next: (response: InvitationResult) => {
          if (response.isExistingUser) {
            this.dialog.open(NotificationDialogComponent, {
              width: '400px',
              data: {
                title: 'manageUsers.skipInvite.title',
                text: 'manageUsers.skipInvite.text',
                textParams: { email: invite.email }
              }
            });
            this.subscription.add(
              this.dialog.afterAllClosed.subscribe(() =>
                this.subscription.add(
                  this.service
                    .getUserByPartner(this.partnerId)
                    .subscribe(users => this.users = users)
                )
              )
            );
          }
        },
        complete: () => {
          this.getInvitations();
        }
      })
    );
  }

  removeUser(user) {
    this.subscription.add(
      this.service
        .deleteUserFromPartner(this.partnerId, user.id)
        .subscribe(() => {
          this.deleteUser(user);
        })
    );
  }

  private deleteUser(user) {
    const index = this.users.indexOf(this.users.filter(u => u.email === user.email)[0]);
    this.users.splice(index, 1);
    this.users = [...this.users];
  }

}
