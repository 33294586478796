import { Component, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Product } from '../../../shared/models/product';
import { Subscription } from 'rxjs';
import { ProductService } from '../../../core/services/product.service';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-publish-api-dialog',
  templateUrl: './publish-api-dialog.component.html',
  styleUrls: ['./publish-api-dialog.component.css']
})
export class PublishApiDialogComponent implements OnDestroy {
  
  form: UntypedFormGroup;
  products: Array<Product>;
  private productSubscription: Subscription;

  constructor(
    public dialogReg: MatDialogRef<PublishApiDialogComponent>,
    formBuilder: UntypedFormBuilder,
    private productService: ProductService,
    private ref: ChangeDetectorRef) {
      this.productSubscription = this.productService.getProducts()
      .subscribe(products => {
        this.products = products
      });

      this.form = formBuilder.group({
        products: ['', Validators.required]
      });
  }

  ngOnDestroy(): void {
    this.productSubscription.unsubscribe();
  }

  onCancel() {
    this.dialogReg.close();
  }

  onPublish(values) {
    this.dialogReg.close(values.products);
  }

}
