import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { Page } from '../../shared/models/page';
import { FarmMappingPagedSummary as FarmMappingPagedSummary } from '../../data-consumer/models/farmmapping-paged-summary';
import './httpclient.extensions';
import { IPagedSortedFilteredService } from './ipaged-sorted-filtered.service';
import { Ext } from './httpclient.extensions';

@Injectable({
  providedIn: 'root'
})
export class FarmMappingService implements IPagedSortedFilteredService<FarmMappingPagedSummary> {
  private URL = `${this.api}/api/FarmMapping/paged`;

  constructor(private httpClient: HttpClient,
    @Inject(baseurl) private api: string) { }

  get(pageNumber = 1, pageSize = 10, sort = null, filter = null): Observable<Page<FarmMappingPagedSummary>> {
    // this code fails on prod build (optimization screws it up)
    // const casted = this.httpClient as unknown as IExtendedHttpClient;
    // return casted.getPagedSortedFiltered<FarmMappingSummary>(FarmMappingSummary, this.URL, pageNumber, pageSize, sort, filter);
    return Ext.getPagedSortedFiltered<FarmMappingPagedSummary>(
      this.httpClient, FarmMappingPagedSummary, this.URL, pageNumber, pageSize, sort, filter);
  }
}
