<p class="bold" *ngIf="showLabel">
  {{ "manageApisPage.details.credentials.authorization" | translate }}:
</p>

<div class="slider-part">
  <select
    name="authorization_selection"
    id="authorization_selection"
    (change)="onAuthChange($event)"
    [disabled]="!this.api.isEditable()"
  >
    <option value="0" selected>
      {{ "manageApisPage.details.credentials.certificate" | translate }}
    </option>
    <option value="1">
      {{ "manageApisPage.details.credentials.oauth" | translate }}
    </option>
  </select>
  <!-- <mat-slide-toggle
    #toggle
    color="black"
    [checked]="this.api.useOAuth"
    [disabled]="!this.api.isEditable()"
    (change)="onAuthChange($event.checked)"
  >
  </mat-slide-toggle>
  <label
    [class]="!this.api.useOAuth ? '' : 'bold'"
    [attr.for]="toggle.inputId"
    >{{ "manageApisPage.details.credentials.oauth" | translate }}</label
  > -->
</div>
<div class="oauth-options" *ngIf="selectedElement">
  <div class="form-part">
    <label for="credentials-auth-url">{{
      "manageApisPage.details.credentials.authUrl" | translate
    }}</label>
    <input
      name="credentials-auth-url"
      id="credentials-auth-url"
      type="text"
      placeholder="{{
        'manageApisPage.details.credentials.authUrl' | translate
      }}"
      [disabled]="!this.api.isEditable()"
      [(ngModel)]="this.api.credentials.authUrl"
    />
  </div>
  <div class="form-part">
    <label for="credentials-client-id">{{
      "manageApisPage.details.credentials.clientId" | translate
    }}</label>
    <input
      name="credentials-client-id"
      id="credentials-client-id"
      type="text"
      placeholder="{{
        'manageApisPage.details.credentials.clientId' | translate
      }}"
      [disabled]="!this.api.isEditable()"
      [(ngModel)]="this.api.credentials.clientId"
    />
  </div>

  <div class="form-part">
    <label for="credentials-key">{{
      "manageApisPage.details.credentials.key" | translate
    }}</label>
    <input
      name="credentials-key"
      id="credentials-key"
      type="text"
      placeholder="{{ 'manageApisPage.details.credentials.key' | translate }}"
      [disabled]="!this.api.isEditable()"
      [(ngModel)]="this.api.credentials.clientKey"
    />
  </div>

  <div class="form-part">
    <label for="credentials-audience">{{
      "manageApisPage.details.credentials.audience" | translate
    }}</label>
    <input
      name="credentials-audience"
      id="credentials-audience"
      type="text"
      placeholder="{{
        'manageApisPage.details.credentials.audience' | translate
      }}"
      [disabled]="!this.api.isEditable()"
      [(ngModel)]="this.api.credentials.audience"
    />
  </div>

  <div class="form-part">
    <label for="credentials-scope">{{
      "manageApisPage.details.credentials.scope" | translate
    }}</label>
    <input
      name="credentials-scope"
      id="credentials-scope"
      type="text"
      placeholder="{{ 'manageApisPage.details.credentials.scope' | translate }}"
      [disabled]="!this.api.isEditable()"
      [(ngModel)]="this.api.credentials.scope"
    />
  </div>
</div>
