import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { ResourceAccessService } from "../../core/services/resource-access.service";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import { AccessRequestStatus } from "../../shared/models/access-request-status";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";

@Component({
  selector: "app-view-resource-access",
  templateUrl: "./view-resource-access.component.html",
  styleUrls: ["./view-resource-access.component.css"],
})
export class ViewResourceAccessComponent implements OnInit {
  @ViewChild("partnerNameCell", { static: true })
  partnerNameCell: TemplateRef<any>;
  @ViewChild("resourceNameCell", { static: true })
  resourceNameCell: TemplateRef<any>;
  @ViewChild("apiNameCell", { static: true }) apiNameCell: TemplateRef<any>;
  @ViewChild("providerNameCell", { static: true })
  providerNameCell: TemplateRef<any>;
  @ViewChild("statusCell", { static: true }) statusCell: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("partnerName");

  constructor(public resourceAccessService: ResourceAccessService) {}

  ngOnInit() {
    this.columns = [
      {
        name: "partnerName",
        translationKey: "models.resourceAccess.resourceAccessTable.partnerName",
        cellTemplate: this.partnerNameCell,
        filter: new TextFilterDef("partnerNameFilter"),
        displayed: true,
        sort: "partnerName",
      },
      {
        name: "resourceName",
        translationKey:
          "models.resourceAccess.resourceAccessTable.resourceName",
        cellTemplate: this.resourceNameCell,
        filter: new TextFilterDef("resourceNameFilter"),
        displayed: true,
        sort: "resourceName",
      },
      {
        name: "apiName",
        translationKey: "models.resourceAccess.resourceAccessTable.apiName",
        cellTemplate: this.apiNameCell,
        filter: new TextFilterDef("apiNameFilter"),
        displayed: true,
        sort: "apiName",
      },
      {
        name: "providerName",
        translationKey:
          "models.resourceAccess.resourceAccessTable.providerName",
        cellTemplate: this.providerNameCell,
        filter: new TextFilterDef("providerNameFilter"),
        displayed: true,
        sort: "providerName",
      },
      {
        name: "status",
        translationKey:
          "models.farmResourceAccess.farmResourceAccessTable.resourceStatus",
        cellTemplate: this.statusCell,
        filter: new SelectFilterDef(
          "statusFilter",
          Object.keys(AccessRequestStatus)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.accessRequest.statusses.${AccessRequestStatus[x]}`
                )
            )
        ),
        displayed: true,
        sort: "status",
      },
    ];
  }
}
