import { Component, OnInit, ViewChild, TemplateRef } from "@angular/core";
import {
  APIResourceHealth,
  ResourceHealth,
} from "../../shared/models/api-resource-health";
import { Observable } from "rxjs";
import { BaseDatatableComponent } from "../../shared/base-datatable/base-datatable.component";
import { SelectFilter } from "../../shared/base-datatable/models/select-filter";
import { ApiResourceHealthTranslation } from "../../shared/pipes/api-resource-health-translation";
import { AdminService } from "../admin.service";

@Component({
  selector: "app-resource-health-admin",
  templateUrl: "./resource-health-admin.component.html",
  styleUrls: ["./resource-health-admin.component.css"],
})
export class ResourceHealthAdminComponent implements OnInit {
  @ViewChild("healthTmp", { static: true }) healthTmp: TemplateRef<any>;
  @ViewChild(BaseDatatableComponent, { static: true })
  healthTable: BaseDatatableComponent;

  resourceHealth$: Observable<Array<APIResourceHealth>>;
  columns = [];
  resourceHealthTranslation = new ApiResourceHealthTranslation();
  rows: APIResourceHealth[];

  constructor(private adminService: AdminService) {}

  ngOnInit() {
    this.resourceHealth$ = this.adminService.getResourceHealthAsAdmin();
    this.resourceHealth$.subscribe((data) => {
      const uniqueRows = data.filter((value, index, self) => {
        return (
          self.findIndex((t) => t.resourceId === value.resourceId) === index
        );
      });
      this.rows = uniqueRows; // Bind filtered data to view
    });
    this.initColumns();
  }

  initColumns() {
    this.columns = [
      {
        prop: "companyName",
        name: "models.apiResourceHealth.providerName",
        flexGrow: 4,
        headerTemplate: this.healthTable.hdrTextTpl,
      },
      {
        prop: "resourceName",
        name: "models.apiResourceHealth.resourceName",
        flexGrow: 4,
        headerTemplate: this.healthTable.hdrTextTpl,
      },
      {
        prop: "resourceHealth",
        name: "models.apiResourceHealth.resourceHealth",
        flexGrow: 4,
        headerTemplate: this.healthTable.hdrSelectTpl,
        cellTemplate: this.healthTmp,
        data: [
          new SelectFilter(
            ResourceHealth.Unknown,
            this.resourceHealthTranslation.transform(ResourceHealth.Unknown)
          ),
          new SelectFilter(
            ResourceHealth.OK,
            this.resourceHealthTranslation.transform(ResourceHealth.OK)
          ),
          new SelectFilter(
            ResourceHealth.NOK,
            this.resourceHealthTranslation.transform(ResourceHealth.NOK)
          ),
        ],
      },
    ];
  }
}
