import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { baseurl } from '../../../baseSettings';
import { ApiTag } from '../../data-provider-module/models/api-tag';

@Injectable()
export class TagService {
    private URL = `${this.api}/api/tag`;
    constructor(private client: HttpClient, @Inject(baseurl) private api: string) {}

    public getTags(): Observable<Array<ApiTag>> {
        return this.client.get<Array<ApiTag>>(`${this.URL}`);
    }
}
