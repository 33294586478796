import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { ManageUnclaimedInvitesService } from "../../core/services/manage-unclaimed-invites.service";
import { Subscription } from "rxjs";
import { InvitationService } from "../../core/services/invitation.service";
import { MatDialog } from "@angular/material/dialog";
import { NotificationDialogComponent } from "../../shared/dialog/notification-dialog/notification-dialog.component";
import { UserInvitation } from "../../shared/models/userInvitation";
import { ClipboardService } from "ngx-clipboard";
import { UserRole } from "../../shared/models/datahubUser";
import { InviteUserDialogComponent } from "../../user/manage-invites/invite-user-dialog/invite-user-dialog.component";
import { BaseRemoteDatatableComponent } from "../../shared/base-remote-datatable/base-remote-datatable.component";

@Component({
  selector: "app-manage-unclaimed-invites",
  templateUrl: "./manage-unclaimed-invites.component.html",
  styleUrls: ["./manage-unclaimed-invites.component.css"],
})
export class ManageUnclaimedInvitesComponent implements OnInit {
  @ViewChild("partnerNameCell", { static: true }) partnerNameCell: TemplateRef<any>;
  @ViewChild("createdAtCell", { static: true }) createdAtCell: TemplateRef<any>;
  @ViewChild("userRoleCell", { static: true }) userRoleCell: TemplateRef<any>;
  @ViewChild("isSignUpCell", { static: true }) isSignUpCell: TemplateRef<any>;
  @ViewChild("kboCell", { static: true }) kboCell: TemplateRef<any>;
  @ViewChild("emailCell", { static: true }) emailCell: TemplateRef<any>;
  @ViewChild("inviteIdCell", { static: true }) inviteIdCell: TemplateRef<any>;
  @ViewChild("copyInviteIdCell", { static: true }) copyInviteIdCell: TemplateRef<any>;
  @ViewChild("invitetable") invitetable: BaseRemoteDatatableComponent;

  @Output() invite = new EventEmitter<UserInvitation>();

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("createdAt", "desc");
  subscription = new Subscription();
  boolOptions = new Array<SelectOption>();
  invitees = new Array<UserInvitation>();
  constructor(
    public manageUnclaimedInvitesService: ManageUnclaimedInvitesService,
    private inviteService: InvitationService,
    private dialog: MatDialog,
    private clipboardService: ClipboardService
  ) {}

  ngOnInit() {
    this.boolOptions.push(
      new SelectOption("true", "boolean.true"),
      new SelectOption("false", "boolean.false")
    );

    this.columns = [
      {
        name: "partnerName",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.partnerName",
        cellTemplate: this.partnerNameCell,
        filter: new TextFilterDef("partnerNameFilter"),
        displayed: true,
        sort: "partnerName",
      },
      {
        name: "createdAt",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.createdAt",
        cellTemplate: this.createdAtCell,
        filter: new TextFilterDef("createdAtFilter"),
        displayed: true,
        sort: "createdAt",
      },
      {
        name: "userRole",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.userRole",
        cellTemplate: this.userRoleCell,
        filter: new SelectFilterDef(
          "userRoleFilter",
          Object.keys(UserRole)
            .filter(
              (value) => !isNaN(Number(Object.keys(UserRole).indexOf(value)))
            )
            .map(
              (role) =>
                new SelectOption(
                  Object.keys(UserRole).indexOf(role).toString(),
                  `roles.${UserRole[role]}`
                )
            )
        ),
        displayed: true,
        sort: "userRole",
      },
      {
        name: "isSignUp",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.isSignUp",
        cellTemplate: this.isSignUpCell,
        filter: new SelectFilterDef("isSignUpFilter", this.boolOptions),
        displayed: true,
        sort: "isSignUp",
      },
      {
        name: "kbo",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.kbo",
        cellTemplate: this.kboCell,
        filter: new TextFilterDef("kboFilter"),
        displayed: true,
        sort: "kbo",
      },
      {
        name: "email",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.email",
        cellTemplate: this.emailCell,
        filter: new TextFilterDef("emailFilter"),
        displayed: true,
        sort: "email",
      },
      {
        name: "inviteId",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.inviteId",
        cellTemplate: this.inviteIdCell,
        filter: null,
        displayed: true,
        sort: null,
      },
      {
        name: "copyInviteId",
        translationKey:
          "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.copyInviteId",
        cellTemplate: this.copyInviteIdCell,
        filter: null,
        displayed: true,
        sort: null,
      },
    ];
  }

  getRole(role: number) {
    return Object.keys(UserRole)[role];
  }

  copyInviteLink(inviteId: string) {
    const link = this.inviteService.getInviteLink(inviteId);
    this.clipboardService.copyFromContent(link);
    // Notify the user
    this.dialog.open(NotificationDialogComponent, {
      width: "fit-content",
      data: {
        title: "manageUsers.linkCopied.title",
        text: "manageUsers.linkCopied.text",
      },
    });
  }

  resendInvite(inviteId, email) {
    this.subscription.add(
      this.inviteService.resendInvitation(inviteId).subscribe({
        complete: () => {
          this.dialog.open(NotificationDialogComponent, {
            width: "fit-content",
            data: {
              title: "manageUsers.reinvited.title",
              text: "manageUsers.reinvited.text",
              textParams: { email: email },
            },
          });
        },
      })
    );
  }

  addInvite(): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((email) => {
        if (email) {
          this.getInviteFromEmail(email);
        }
      })
    );
  }

  public getInviteFromEmail(email: string) {
    this.subscription.add(
      this.inviteService.getInvitationByEmail(email).subscribe(
         (invitationUrlResult) => {
          this.clipboardService.copyFromContent(invitationUrlResult.invitationUrl);
          this.invitetable.loadPage().subscribe();
          this.dialog.open(NotificationDialogComponent, {
            width: "fit-content",
            data: {
              title: "manageUsers.addInvite.title",
              text: "manageUsers.addInvite.text",
              link: invitationUrlResult.invitationUrl,
              linkText: invitationUrlResult.invitationUrl
          }});
      })
    );
  }
}
