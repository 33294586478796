import { Component, Input, OnInit } from "@angular/core";
import { Language } from "../../models/language";

@Component({
  selector: "app-language-circle",
  templateUrl: "./language-circle.component.html",
  styleUrls: ["./language-circle.component.scss"],
})
export class LanguageCircleComponent implements OnInit {
  @Input() language: Language;
  constructor() {}

  ngOnInit() {}
}
