<header>
  <a href="https://djustconnect.be"
    ><img
      src="assets/images/DC logo hor_rgb groen.svg"
      alt="banner"
      class="djustconnect-banner"
      height="60"
  /></a>
  <button
    id="hamburger"
    [ariaExpanded]="hamburgerState"
    (click)="changeHamburgerState()"
    aria-label="Mobile hamburger menu"
  >
    <span></span><span></span><span></span>
  </button>
  <nav>
    <span *ngIf="firstName" class="small name">
      <span *ngIf="firstName">
        {{ "layout.header.greeting" | translate : { firstName: firstName }  }}
      </span>
    </span>
    <div class="first-part">
      <a href="https://djustconnect.be/nl/contact" target="_blank">
        {{ "layout.footer.contactUs" | translate }}
      </a>
      <a href="https://djustconnect.be/nl/help" target="_blank">
        {{ "layout.footer.help" | translate }}
      </a>
      <a href="/">{{ "navigation.home" | translate }}</a>
      <p *ngIf="isLoggedIn" class="role-text">
        <span>{{
          this.context.roleName | ContextRoleTranslation | translate
        }}</span>
        <span *ngIf="context.entityName">{{ this.context.entityName }}</span>
      </p>
    </div>
    <div class="second-part">
      <app-context *ngIf="isLoggedIn && hasMultipleContexts"></app-context>
      <div class="logout">
        <button *ngIf="isLoggedIn" (click)="logout()" class="btn-logout">
          {{ "actions.logout" | translate }}
        </button>
      </div>
      <app-select-language></app-select-language>
    </div>
  </nav>
</header>
