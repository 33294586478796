import { Component, OnInit } from "@angular/core";
import { Router, NavigationEnd, ActivatedRoute } from "@angular/router";
import { distinctUntilChanged, filter, map } from "rxjs/operators";
import { BreadCrumb } from "./breadcrumb";
import { of } from "rxjs";

@Component({
  selector: "app-breadcrumb",
  templateUrl: "./breadcrumb.component.html",
  styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit {
  breadcrumbs$ = of([]);
  constructor(private activatedRoute: ActivatedRoute, private router: Router) {
    this.breadcrumbs$ = of(this.buildBreadCrumb(this.activatedRoute.root));
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.breadcrumbs$ = of(this.buildBreadCrumb(this.activatedRoute.root));
      });
  }

  buildBreadCrumb(
    route: ActivatedRoute,
    url: string = "",
    breadcrumbs: Array<BreadCrumb> = []
  ): Array<BreadCrumb> {
    const label = route.routeConfig
      ? route.routeConfig.data["breadcrumb"]
      : "navigation.home";
    const path = route.routeConfig ? route.routeConfig.path : "";
    const nextUrl = `${url}${path}/`;

    if (label === "") {
      return breadcrumbs;
    }

    const breadcrumb = {
      label: label,
      url: nextUrl,
    };
    const newBreadcrumbs = [...breadcrumbs, breadcrumb];

    if (route.firstChild) {
      return this.buildBreadCrumb(route.firstChild, nextUrl, newBreadcrumbs);
    }
    return newBreadcrumbs;
  }
}
