import { Injectable, Inject } from '@angular/core';
import { baseurl } from '../../../baseSettings';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { ApiResource } from '../../data-consumer/models/apiResource';

@Injectable({
  providedIn: 'root'
})
export class ApiResourceService {
  private URL = `${this.api}/api/Resource`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) { }

  public getAll(): Observable<Array<ApiResource>> {
    let response: Observable<any>;
    response = this.httpClient.get(this.URL);
    return response.pipe(
      map(apiResources => apiResources.map(apiResource => Object.assign(new ApiResource, apiResource)))
    );
  }
}
