import {
  Component,
  OnInit,
  Input,
  ViewChild,
  OnDestroy,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  UntypedFormGroup,
  UntypedFormControl,
  FormControl,
} from "@angular/forms";
import { Subscription } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { PasswordDialogComponent } from "./password-dialog/password-dialog.component";
import { ValidatorService } from "../../core/validators/validator-service";

@Component({
  selector: "app-certificate",
  templateUrl: "./certificate.component.html",
  styleUrls: ["./certificate.component.css"],
})
export class CertificateComponent implements OnInit, OnDestroy {
  @Input() formGroup: UntypedFormGroup;
  @Input() currentCertificateThumbprint: string;
  @Output() password = new EventEmitter<string>();
  @Output() certificateChange = new EventEmitter<string>();
  @Output() certificateCleared = new EventEmitter<boolean>();

  readonly maxSize = 1048576; // max size for the certificate is 1MB
  @ViewChild("certificateControl", { static: true })
    certificateControl: UntypedFormControl;
  private subscription = new Subscription();
  constructor(
    private dialog: MatDialog,
    private validatorService: ValidatorService) {}

  ngOnInit() {
    this.certificateControl = new FormControl(null, [this.validatorService.fileSizeValidator(this.maxSize)]); //todo enforce validation and showing error
    this.formGroup.addControl("certificateControl", this.certificateControl);
  }

  onCertificateChange(value: any) {
    if (
      value?.target?.files &&
      value.target.files[0]
    ) {
      this.formGroup.get('certificateControl').setValue(value.target);
      if (value.target.files[0].type === "application/x-pkcs12") {
        const dialogRef = this.dialog.open(PasswordDialogComponent, {
          width: "400px",
        });

      this.subscription.add(
        dialogRef.afterClosed().subscribe((password) => {
          if (!password) {
            this.certificateControl.reset();
          }
          this.password.emit(password);
        })
      );
    } else {
      this.password.emit(null);
    }
  }
    this.handleUploadName(value?.target?.files[0]);
    this.certificateChange.emit(value);
  }

  upload_name = null;
  handleUploadName(file: File) {
    this.upload_name = file.name;
    return file.name;
  }

  certificateClear(value: boolean) {
    this.certificateCleared.emit(value);
    let input = this.formGroup.get('certificateControl').value;
    if (input != null) input.value = null;
    this.upload_name = this.currentCertificateThumbprint;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private async showThumbprint() {
    // const file = this.certificateControl.value as FileInput;
    // if (file.files) {
    //   const currentFile = file.files[0];
    //   const asn1 = asn1js.fromBER(await this.getBytesFromFile(currentFile.slice(0, currentFile.size, currentFile.type)));
    //   if (asn1.offset === (-1)) {
    //     alert('Can not parse binary data');
    //   } else {
    //     const cert = new pkijs.Certificate({ schema: asn1.result });
    //     this.currentCertificateThumbprint = cert.thumbprint;
    //   }
    // } else {
    //   this.currentCertificateThumbprint = '';
    // }
  }

  //private async getBytesFromFile(blob: Blob): Promise<any> {
  //  return await new Response(blob).arrayBuffer();
  //}
}
