<h1 mat-dialog-title>
  {{
    (data.isEditable ? "actions.editHeaders" : "actions.viewHeaders")
      | translate
  }}
</h1>

<mat-dialog-content class="dialog-content">
  <button
    type="reset"
    (click)="addHeader()"
    *ngIf="data.isEditable"
    class="green"
  >
    {{ "actions.addHeader" | translate }}
  </button>
  <div class="row section" *ngFor="let header of headerKvps">
    <mat-form-field class="col col-md-3">
      <mat-label>{{
        "managePushEventsPage.eventType.headers.key" | translate
      }}</mat-label>
      <input matInput [(ngModel)]="header.key" [disabled]="!data.isEditable" />
    </mat-form-field>
    <mat-form-field class="col col-md-3">
      <mat-label>{{
        "managePushEventsPage.eventType.headers.value" | translate
      }}</mat-label>
      <input
        matInput
        [(ngModel)]="header.value"
        [disabled]="!data.isEditable"
      />
    </mat-form-field>
    <div class="col col-md-3">
      <button
        type="button"
        (click)="removeHeader(header.key)"
        *ngIf="data.isEditable"
        class="rose"
      >
        {{ "actions.removeHeader" | translate }}
      </button>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions class="pull-right">
  <button type="submit" (click)="onOk()" class="green">
    {{ "actions.ok" | translate }}
  </button>
  <button type="button" (click)="onCancel()" class="green">
    {{ "actions.cancel" | translate }}
  </button>
</mat-dialog-actions>
