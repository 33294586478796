import { Injectable, Inject } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Api } from '../../data-provider-module/models/api';
import { baseurl } from '../../../baseSettings';
import { Product } from '../../shared/models/product';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private URL = `${this.api}/api/ProviderAPI`;

  constructor(
    private httpClient: HttpClient,
    @Inject(baseurl) private api: string
  ) {}

  public getAll(includeDraft: boolean): Observable<Array<Api>> {
    let response: Observable<any>;
    const params = new HttpParams().set('includeDraft', String(includeDraft));
    response = this.httpClient.get(this.URL, { params });
    return response.pipe(
      map(apis => Object.assign(new Array<Api>(), apis))
    );
  }

  getForProvider(): Observable<Array<Api>> {
    let response: Observable<any>;
    response = this.httpClient.get(`${this.URL}/GetForProvider`);
    return response.pipe(
      map(apis => Object.assign(new Array<Api>(), apis))
    );
  }

  getDetails(apiId: string): Observable<Api> {
    let response: Observable<any>;
    response = this.httpClient.get(`${this.URL}/${apiId}`);
    let r : Observable<Api> 
    r = response.pipe(map(api => Object.assign(new Api, api)));
    return r;
    //return response.pipe(map(api => Object.assign(new Api, api)));
  }

  save(api: Api): Observable<string> {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.post(this.URL, api);
    return httpResponse.pipe(map(response => String(response)));
  }

  update(providerApi: Api): Observable<string>  {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.put(
      `${this.URL}/${providerApi.id}/saveAsDraft`,
      providerApi
    );
    return httpResponse.pipe(map(response => String(response)));
  }

  updatePublished(providerApi: Api): Observable<string>  {
    let httpResponse: Observable<any>;
    httpResponse = this.httpClient.put(
      `${this.URL}/${providerApi.id}/updatePublished`,
      providerApi
    );
    return httpResponse.pipe(map(response => String(response)));
  }

   delete(apiId: string): Observable<any> {
    return this.httpClient.delete(`${this.URL}/${apiId}`);
  }

  runValidation(api: Api) {
    return this.httpClient.post(`${this.URL}/validate`, api);
  }

  submitForApproval(api: Api): Observable<any> {
    return this.httpClient.post(`${this.URL}/submit`, api);
  }

  publish(apiId: string, products: Array<Product>): Observable<any> {
    return this.httpClient.post(`${this.URL}/${apiId}/publish`, products);
  }

  reject(apiId: string): Observable<any> {
    return this.httpClient.post(`${this.URL}/${apiId}/reject`, undefined);
  }
}
