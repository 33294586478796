import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: 'app-generate-dialog',
  templateUrl: './generate-dialog.component.html',
  styleUrls: ['./generate-dialog.component.css']
})
export class GenerateDialogComponent implements OnInit {

  constructor(public dialogReg: MatDialogRef<GenerateDialogComponent>) { }

  ngOnInit() {
  }

  onCancel() {
    this.dialogReg.close(null);
  }

  OnConfirm() {
    this.dialogReg.close(true);
  }

}
