import { Component, OnInit, Input, OnDestroy } from "@angular/core";
import { InformativeFlowCard } from "./models/informative-flow-card";
import { DataAccessRequestService } from "../../core/services/data-access-request.service";
import { MatDialog } from "@angular/material/dialog";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";
import { UserAuthService } from "../../core/user-auth.service";

@Component({
  selector: "app-informative-flow-card",
  templateUrl: "./informative-flow-card.component.html",
  styleUrls: ["./informative-flow-card.component.scss"],
})
export class InformativeFlowCardComponent implements OnInit, OnDestroy {
  @Input() data: InformativeFlowCard;
  subscription = new Subscription();

  constructor(
    private dataAccessRequestService: DataAccessRequestService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private msalService: UserAuthService
  ) {}

  public baseurl: string;

  ngOnInit() {
    const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.baseurl = appConfig.DatapartnerLogoBaseUrl;
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  gotoList = () => {
    this.router.navigate([this.navigationRoute, this.data.consumerId], {
      relativeTo: this.route,
    });
  };

  get navigationRoute(): string {
    if (this.msalService.isFarmer()) {
      let detailsNavigation = `details`;
      if (this.router.url === `/`) {
        detailsNavigation = `manage-data-access/details`;
      }
      return detailsNavigation;
    } else if (this.msalService.isProvider()) {
      let providerrarsNavigation = `provider-rars`;
      if (this.router.url === `/`) {
        providerrarsNavigation = `resource-access-requests/details`;
      }
      return providerrarsNavigation;
    }
  }

  hasApplicationUrl() {
    return this.data.applicationUrl;
  }

  hasApplicationLogoUrl() {
    return this.data.applicationLogoUrl;
  }

  goToApplication() {
    if (this.data.applicationUrl) {
      window.open(this.data.applicationUrl);
    }
  }
}
