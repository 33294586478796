<div class="farmer-register-container">
<h1>{{ "registration.farmer.title" | translate }}</h1>
<h3>
  <strong class="green">{{ "registration.farmer.attention" | translate }}</strong>
</h3>
<p [innerHtml]="'registration.farmer.beforeInput' | translate"></p>
  <form
  [formGroup]="farmerSignUpForm"
  (ngSubmit)="signUpAsFarmer(farmerSignUpForm.getRawValue())"
  method="POST"
>
  <div class="form-part">
    <label for="email" class="required">{{
      "models.manageUnclaimedInvites.manageUnclaimedInvitesTable.email"
        | translate
    }}</label>
    <input
      placeholder="Email"
      type="text"
      id="email"
      name="email"
      formControlName="email"
    />
  </div>
  <div class="form-part">
    <label for="privacy" class="checkbox required-reverse">
      <span [innerHTML]="'landingPage.notLoggedIn.privacy' | translate"></span>
      <input
        type="checkbox"
        formControlName="privacy"
        id="privacy"
        name="privacy"
        (click)="toggle(farmerSignUpForm.get('privacy'))"
        autocomplete="off"
      />
      <svg
        class="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#1d1d1b"
          rx="3"
        />
        <path
          class="tick"
          stroke="#067C4A"
          fill="none"
          stroke-linecap="round"
          stroke-width="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </label>
  </div>
  <!-- <div class="form-part">
    <label for="marketing" class="checkbox">
      <span
        [innerHTML]="'landingPage.notLoggedIn.marketing' | translate"
      ></span>
      <input
        type="checkbox"
        formControlName="marketing"
        id="marketing"
        name="marketing"
        (click)="toggle(farmerSignUpForm.get('marketing'))"
        autocomplete="off"
      />
      <svg
        class="checkbox__icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 22"
      >
        <rect
          width="21"
          height="21"
          x=".5"
          y=".5"
          fill="#FFF"
          stroke="#1d1d1b"
          rx="3"
        />
        <path
          class="tick"
          stroke="#067C4A"
          fill="none"
          stroke-linecap="round"
          stroke-width="4"
          d="M4 10l5 5 9-9"
        />
      </svg>
    </label>
  </div> -->
  <button type="submit" [disabled]="!farmerSignUpForm.valid" class="green">
    {{ "actions.registerUser" | translate }}
  </button>
</form>
<p>
  {{ "registration.farmer.afterInput" | translate }}
  <a href="https://djustconnect.be/nl/contact-bedrijven" target="_blank">{{
    "info.contactUs" | translate
  }}</a>
</p>
</div>