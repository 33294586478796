import { DatahubUser } from "../../shared/models/datahubUser";
import { FarmMappingDTO } from "./farmMappingDTO";

export class FarmDetailsResult {
  farmId: string;
  farmIdTypeId: string;
  kbo: string;
  mappings?: Array<FarmMappingDTO>;
  users?: Array<DatahubUser>;
  hidden: boolean;
}
