import { FocusMonitor } from "@angular/cdk/a11y";
import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
  ViewChildren,
} from "@angular/core";
import {
  ControlValueAccessor,
  NgControl,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { MatFormFieldControl } from "@angular/material/form-field";
import { MatInput } from "@angular/material/input";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Subject } from "rxjs";
import { Language } from "../../../models/language";

export class TranslatableString {
  language: Language;
  value: string;
}

@Component({
  selector: "app-translatable-string-area",
  templateUrl: "./translatable-string-area.component.html",
  styleUrls: ["./translatable-string-area.component.scss"],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TranslatableStringAreaComponent,
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TranslatableStringAreaComponent,
      multi: true,
    },
  ],
})
export class TranslatableStringAreaComponent
  implements
    OnInit,
    AfterViewInit,
    MatFormFieldControl<Array<TranslatableString>>,
    ControlValueAccessor
{
  @ViewChildren(MatInput, { read: ElementRef }) inputs: Array<ElementRef>;
  languages = [Language.en, Language.nl];
  private _value: TranslatableString[];
  private _placeholder: string;
  static nextId = 0;
  currentLanguage: Language;
  onChange: (value: TranslatableString[]) => void;
  onTouched: () => void;

  @Input()
  set value(value: Array<TranslatableString>) {
    this._value = value;
    if (this.onChange) {
      this.onChange(value);
    }
    if (this.onTouched) {
      this.onTouched();
    }
    this.stateChanges.next();
  }
  get value() {
    return this._value;
  }

  @Output() changed = new EventEmitter<TranslatableString[]>();

  @Input()
  set placeholder(placeholder: string) {
    this._placeholder = placeholder;
    this.stateChanges.next();
  }
  get placeholder() {
    return this._placeholder;
  }

  stateChanges = new Subject<void>();
  @HostBinding()
  id = `translatable-area-id-${TranslatableStringAreaComponent.nextId++}`;

  ngControl: NgControl = null;
  focused: boolean;

  get empty(): boolean {
    if (this._value) {
      const t = this._value.find((t) => t.language == Language.en);
      return !t.value && t.value === "";
    }
    return false;
  }

  @HostBinding("class.floated")
  get shouldLabelFloat(): boolean {
    return this.focused || !this.empty || this.isToggled;
  }

  @Input()
  required: boolean;
  @Input()
  disabled: boolean;

  @Input()
  sliceWords: boolean = true;

  errorState: boolean;
  controlType = "translatable-area";
  autofilled = false;
  isToggled = true;

  get currentLanguageValue() {
    return this._value
      ? this._value.find((t) => t.language === this.currentLanguage).value
      : "";
  }
  get defaultLanguage() {
    return this._value
      ? this._value.find((t) => t.language == Language.en)
      : { language: Language.en, value: "" };
  }
  get otherLanguageValues() {
    return this._value
      ? this._value.filter((t) => t.language != Language.en)
      : [];
  }

  slice(value: string): string {
    if (value && value.length > 25 && !this.focused) {
      return value.slice(0, 25) + " ...";
    }
    return value;
  }

  setDefaultLanguage(value: string) {
    this._value.find((t) => t.language === Language.en).value = value;
    this.changed.emit(this._value);
  }

  setOtherLanguage(value: string, language: Language) {
    this._value.find((t) => t.language === language).value = value;
    this.changed.emit(this._value);
  }

  @HostBinding("attr.aria-describedby") describedBy = "";

  setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(" ");
  }
  onContainerClick(event: MouseEvent): void {}

  constructor(
    private focusMonitor: FocusMonitor,
    private translate: TranslateService
  ) {
    this.translate.onLangChange.subscribe(
      (l: LangChangeEvent) => (this.currentLanguage = Language[l.lang])
    );
    this.currentLanguage = Language[this.translate.currentLang];
  }

  writeValue(obj: Array<TranslatableString>): void {
    this._value = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnInit(): void {}

  toggle() {
    this.isToggled = !this.isToggled;
    this.stateChanges.next();
  }

  ngAfterViewInit(): void {
    this.inputs.forEach((i) =>
      this.focusMonitor.monitor(i).subscribe((focused) => {
        this.focused = !!focused;
        this.stateChanges.next();
      })
    );
  }

  ngOnDestroy() {
    this.inputs.forEach((i) => this.focusMonitor.stopMonitoring(i));
    this.stateChanges.complete();
  }
}
