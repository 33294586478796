import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ActivityTranslation'
})
export class ActivityTranslation implements PipeTransform {
    transform(value: boolean): string {
        return value ? 'models.datahubPartner.isActive' : 'models.datahubPartner.isInactive';
    }
}
