import {
  Component,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  TemplateRef,
  Input,
  OnDestroy,
} from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserInvitation } from "../../shared/models/userInvitation";
import { NotificationDialogComponent } from "../../shared/dialog/notification-dialog/notification-dialog.component";
import { InviteUserDialogComponent } from "./invite-user-dialog/invite-user-dialog.component";
import { InvitationService } from "../../core/services/invitation.service";
import { DatahubUser } from "../../shared/models/datahubUser";
import { Subscription } from "rxjs";

@Component({
  selector: "app-manage-invites",
  templateUrl: "./manage-invites.component.html",
  styleUrls: ["./manage-invites.component.scss"],
})
export class ManageInvitesComponent implements OnInit, OnDestroy {
  @Input() invitees: Array<UserInvitation>;
  @Input() showExport: boolean = true;
  @Input() users: Array<DatahubUser>;
  @Output() invite = new EventEmitter<UserInvitation>();
  @ViewChild("invitationTmpl", { static: true })
  invitationTmpl: TemplateRef<any>;
  @ViewChild("statusTemplate", { static: true })
  statusTemplate: TemplateRef<any>;
  @ViewChild("textTemplate", { static: true }) textTemplate: TemplateRef<any>;
  @ViewChild("deleteTmpl", { static: true }) deleteTmpl: TemplateRef<any>;
  columns = [];
  subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
    private inviteService: InvitationService
  ) {}

  ngOnInit() {
    this.initColumns();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  initColumns() {
    this.columns = [
      {
        prop: "email",
        name: "models.datahubUser.email",
        headerTemplate: this.textTemplate,
        flexGrow: 5,
      },
      {
        cellTemplate: this.invitationTmpl,
        name: "actions.invite",
        headerTemplate: this.textTemplate,
        flexGrow: 2,
      },
      {
        cellTemplate: this.deleteTmpl,
        name: "actions.delete",
        headerTemplate: this.textTemplate,
        flexGrow: 1,
      },
    ];
  }

  resendInvitation(invitation) {
    this.subscription.add(
      this.inviteService.resendInvitation(invitation.id).subscribe({
        complete: () => {
          this.dialog.open(NotificationDialogComponent, {
            width: "fit-content",
            data: {
              title: "manageUsers.reinvited.title",
              text: "manageUsers.reinvited.text",
              textParams: { email: invitation.email },
            },
          });
        },
      })
    );
  }

  deleteInvitation(invitation) {
    this.subscription.add(
      this.inviteService.deleteInvitation(invitation.id).subscribe({
        next: () => {
          // Remove the deleted invitation from the local data
          this.invitees = this.invitees.filter(inv => inv.id !== invitation.id);
        },error: (err) => {
          console.error(err);
        },
      })
    );
  }

  inviteUser(): void {
    const dialogRef = this.dialog.open(InviteUserDialogComponent, {
      width: "fit-content",
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((email) => {
        if (email) {
          const invitee = new UserInvitation();
          invitee.email = email;
          if (this.validateInvite(invitee)) {
            this.invite.emit(invitee);
          }
        }
      })
    );
  }

  private validateInvite(invite: any): boolean {
    if (this.isInviteeAlreadyUser(invite)) {
      this.openNotificationDialog(
        "manageUsers.userAlreadyInRole.title",
        "manageUsers.userAlreadyInRole.text",
        invite.email
      );
      return false;
    }
    if (this.isInviteeAlreadyInvited(invite)) {
      this.openNotificationDialog(
        "manageUsers.userAlreadyInvited.title",
        "manageUsers.userAlreadyInvited.text",
        invite.email
      );
      return false;
    }
    return true;
  }

  private isInviteeAlreadyUser(invite: any) {
    return this.users.some((x) => x.email === invite.email);
  }

  private isInviteeAlreadyInvited(invite: any) {
    return this.invitees.some((x) => x.email === invite.email);
  }

  private openNotificationDialog(
    title: string,
    text: string,
    inviteEmail: string
  ) {
    this.dialog.open(NotificationDialogComponent, {
      width: "400px",
      data: {
        title: title,
        text: text,
        textParams: { email: inviteEmail },
      },
    });
  }
}
