<div class="header-part">
  <h1>{{ "navigation.farmMapping" | translate }}</h1>
  <p>{{ "info.editFarmmapping" | translate }}</p>
  <div>
    <button (click)="viewAccess()" class="green viewAccess">
      Overzicht toegang api's
    </button>
  </div>
</div>

<form
  [formGroup]="farmMappingForm"
  (ngSubmit)="upsertFarmDetailsResult(farmMappingForm.getRawValue())"
>
  <div class="details-header">
    <h2 *ngIf="!newKBO; else noKBOHeader">
      {{ "editFarmmapping.details.titleEdit" | translate }}
    </h2>
    <ng-template #noKBOHeader>
      <h2>
        {{ "editFarmmapping.details.titleNew" | translate }}
      </h2>
    </ng-template>
  </div>

  <div class="form-part">
    <label for="farmId">{{
      "models.farmDetailsResult.farmId" | translate
    }}</label>
    <p class="small" *ngIf="newKBO">
      {{ "models.farmDetailsResult.newFarmId" | translate }}
    </p>
    <input type="text" formControlName="farmId" id="farmId" name="farmId" />
  </div>

  <div class="parts">
    <div class="form-part" *ngFor="let mapping of getControls(); let i = index">
      <div class="part">
        <label [for]="'farm-detail-type-' + i">{{
          "models.farmDetailsResult.farmIdTypeId" | translate
        }}</label>
        <select
          [name]="'farm-detail-type-' + i"
          [id]="'farm-detail-type-' + i"
          (change)="[checkIfKBO($event.target.value, i)]"
          [formControl]="mapping.get('farmIdTypeId')"
          autocomplete="off"
        >
          <option
            [value]="farmIdTypeId.id"
            *ngFor="let farmIdTypeId of farmIdTypes"
          >
            {{ farmIdTypeId.name | FarmTypeTranslation | translate }}
          </option>
        </select>
      </div>
      <div class="part">
        <label [for]="'farm-detail-number' + i">
          {{ "models.farmDetailsResult.number" | translate }}
        </label>
        <input
          type="text"
          [formControl]="mapping.get('number')"
          [name]="'farm-detail-number' + i"
          [id]="'farm-detail-number' + i"
          autocomplete="off"
        />
      </div>
      <div class="remove-btn">
        <button
          type="button"
          (click)="removeMapping(i, mapping, farmId, id)"
          [disabled]="readonly"
          *ngIf="showRemoveButton[i]"
          class="rose"
          style="padding: 0.625rem !important"
          aria-label="Remove mapping"
        >
          <i class="fas fa-trash-alt"></i>
        </button>
      </div>
      <input class="hidden" matInput [formControl]="mapping.get('id')" hidden />
      <input
        class="hidden"
        matInput
        [formControl]="mapping.get('farmId')"
        hidden
      />
      <input
        class="hidden"
        matInput
        [formControl]="mapping.get('isNew')"
        hidden
      />
    </div>
  </div>

  <div class="button-group">
    <button
      (click)="addMapping()"
      [disabled]="readonly"
      class="yellow"
      type="button"
      id="addMapping"
      aria-label="Add mapping"
    >
      <i class="fas fa-plus"></i>
      {{ "actions.createMapping" | translate }}
    </button>

    <button
      (click)="toggleEdit()"
      [class]="readonly ? 'green' : 'rose'"
      id="edit"
      type="button"
      aria-label="Toggle edit"
    >
      <i class="fa fa-user-edit"></i>
      {{ (readonly ? "actions.edit" : "actions.cancel") | translate }}
    </button>

    <button
      class="green"
      type="submit"
      id="submitForm"
      [disabled]="!farmMappingForm.valid || totalNewMappings <= 0"
    >
      {{ "actions.save" | translate }}
    </button>
  </div>

  <div class="user-management">
    <div class="user-management-action-buttons">
      <app-manage-users
        [users]="users"
        (remove)="removeUser($event)"
      ></app-manage-users>
      <app-manage-invites
        *ngIf="!unsavedNewKBO"
        [invitees]="invitees"
        [users]="users"
        (invite)="inviteUser($event)"
      ></app-manage-invites>
    </div>
  </div>
</form>
