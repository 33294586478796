import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'BooleanTranslation'
})
export class BooleanTranslation implements PipeTransform {
    transform(value: string): any {
        return value ? 'boolean.true' : 'boolean.false';
    }
}
