<label [for]="label">
  {{ label }}
</label>
<div class="group-input-buttons">
  <input
    disabled
    [(value)]="value"
    [type]="type"
    [placeholder]="placeholder"
    #key
    [name]="label"
    [id]="label"
  />
  <div class="button-group">
    <button
      aria-label="show password"
      *ngIf="!showing"
      (click)="show()"
      style="padding: 0 !important"
    >
      <span class="glyphicon glyphicon-eye-open"></span>
    </button>
    <button
      aria-label="hide password"
      *ngIf="showing"
      (click)="hide()"
      style="padding: 0 !important"
    >
      <span class="glyphicon glyphicon-eye-close"></span>
    </button>
    <button
      aria-label="copy"
      (click)="showTooltip()"
      style="padding: 0 !important"
    >
      <span
        ngxClipboard
        #tooltip
        matTooltip="{{ 'actions.copied' | translate }}"
        [cbContent]="value"
        class="glyphicon glyphicon-file"
      ></span>
    </button>
  </div>
</div>
