<div class="not-logged-in-container">

  <div class="content row justify-content-center">
    <div class="col">
      <div class="mat-card login-card primary-background-color" (click)="login()">
        <div class="mat-card-content">
          <dl>
            <h4 class="primary-color">
              {{ "landingPage.notLoggedIn.text" | translate }}
            </h4>
            <div class="icon login-icon"></div>
            <h4 class="primary-color">
              <b>{{ "landingPage.notLoggedIn.known" | translate }}</b>
            </h4>
          </dl>
        </div>
      </div>
      <div class="logo-container">
        <div class="logo"></div>
      </div>
      <div class="mat-card external-login-card primary-background-color" (click)="loginExternal()">
        <div class="mat-card-content">
          <dl>
            <h4 class="primary-color">
              {{ "landingPage.notLoggedIn.externalText" | translate }}
            </h4>
          </dl>
        </div>
      </div>
    </div>
    <div class="vl"></div>
    <div class="col">
      <h1 class="primary-color text-center">
        {{ "landingPage.notLoggedIn.title" | translate }}
      </h1>
      <div class="row">
        <div class="col">
          <div class="mat-card primary-background-color" [routerLink]="['farmer-signup']">
            <div class="mat-card-content">
              <dl>
                <div class="icon tractor-icon"></div>
                <h4 class="primary-color">
                  <b>
                    {{
                      "landingPage.notLoggedIn.signUpTitleFarmer" | translate
                    }}
                  </b>
                </h4>
              </dl>
            </div>
          </div>
        </div>
        <div class="col">
          <a href="https://djustconnect.be/nl/contact-bedrijven">
            <div class="mat-card primary-background-color" >
              <!-- [routerLink]="['partner-signup']" -->
              <div class="mat-card-content">
                <dl>
                  <div class="icon api-icon"></div>
                  <h4 class="primary-color">
                    <b>
                      {{
                        "landingPage.notLoggedIn.signUpTitlePartner" | translate
                      }}
                    </b>
                  </h4>
                </dl>
              </div>
            </div>
          </a>
        </div>
      <div class="row">
        <div class="col text-center">
          <a 
            class="btn-help btn" 
            href="https://djustconnect.be/nl/zich-registreren-op-djustconnect"
            > {{ "landingPage.notLoggedIn.helpOnRegister" | translate }}
          </a>
      </div>
    </div>
  </div>
</div> 
</div>
</div>

<!--
  <div class="img-left">
    <img src="../../../../../assets/images/cows-register.jpg" />
  </div>
  <div class="register-container">
    <section>
      <h1>{{ "landingPage.notLoggedIn.title" | translate }}</h1>
      <app-register-farmer></app-register-farmer>
    </section>
    
    <section class="bottom">
      <h2>
        {{ "landingPage.notLoggedIn.signupTitle" | translate }}
      </h2>
      <button (click)="login()" class="rose">
        <i class="fas fa-sign-in"></i>
        {{ "landingPage.notLoggedIn.known" | translate }}
      </button>
      <button
      class="without-id card-button"
      (click)="loginExternal()"
      style="padding: 0 !important"
    >
      {{ "landingPage.notLoggedIn.externalText" | translate }}
    </button>
  </section>
</div>
-->