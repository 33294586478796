<h2>{{ "invitationPage.emailDialog.title" | translate }}</h2>

<p>
  {{
    "invitationPage.emailDialog.text"
      | translate : { currentEmail: currentEmail, newEmail: newEmail }
  }}
</p>

<div class="button-group">
  <button class="green" (click)="onAccept()">{{ "yes" | translate }}</button>
  <button class="rose" (click)="onDecline()">{{ "no" | translate }}</button>
</div>
