import {
  Component,
  OnInit,
  ViewChild,
  TemplateRef,
  Inject,
} from "@angular/core";
import { ColumnDef } from "../../shared/base-remote-datatable/models/column-def";
import { InitialSort } from "../../shared/base-remote-datatable/models/initial-sort";
import { TextFilterDef } from "../../shared/base-remote-datatable/models/text-filter-def";
import { SelectFilterDef } from "../../shared/base-remote-datatable/models/select-filter-def";
import { FarmIdType } from "../../data-provider-module/models/farm-id-type";
import { SelectOption } from "../../shared/base-remote-datatable/models/select-option";
import { FarmMappingService } from "../../core/services/farmmapping.service";
import { Subscription } from "rxjs";
import { FarmIdTypesService } from "../../core/services/farmIdType.service";
import { FarmIdTypeEnum } from "../../data-consumer/models/farmmapping-paged-summary";
import { FarmDetailsService } from "../../core/services/farm-details.service";
import { FarmDetailsResult } from "../models/farmDetailsResult";
import { FarmTypeTranslation } from "../../shared/pipes/farm-type-translation";
import { ActivatedRoute, Router } from "@angular/router";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-farmmapping",
  templateUrl: "./farmmapping.component.html",
  styleUrls: ["./farmmapping.component.scss"],
})
export class FarmMappingComponent implements OnInit {
  @ViewChild("kboCell", { static: true }) kboCell: TemplateRef<any>;
  @ViewChild("farmIdCell", { static: true }) farmIdCell: TemplateRef<any>;
  @ViewChild("farmIdTypeIdCell", { static: true })
  farmIdTypeIdCell: TemplateRef<any>;
  @ViewChild("numberCell", { static: true }) numberCell: TemplateRef<any>;
  @ViewChild("editTmpl", { static: true }) editTmpl: TemplateRef<any>;
  @ViewChild("editHdrTmpl", { static: true }) editHdrTmpl: TemplateRef<any>;

  columns: Array<ColumnDef>;
  initialSort = new InitialSort("farmId");
  subscription = new Subscription();
  farmIdTypes = Array<FarmIdType>();
  selectOptions = Array<SelectOption>();
  newKBO: boolean;

  public farmDetailsArray: FarmDetailsResult[] = [];
  emptyFarmId: string;
  constructor(
    public farmMappingService: FarmMappingService,
    public farmIdTypesService: FarmIdTypesService,
    public farmDetailsService: FarmDetailsService,
    public farmTypeTranslation: FarmTypeTranslation,
    private route: ActivatedRoute,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {}

  ngOnInit() {
    this.columns = [
      {
        name: "kbo",
        translationKey: "models.farmMapping.farmMappingTable.kbo",
        cellTemplate: this.kboCell,
        filter: new TextFilterDef("kboFilter"),
        displayed: true,
        sort: "kbo",
      },
      {
        name: "farmId",
        translationKey: "models.farmMapping.farmMappingTable.farmId",
        cellTemplate: this.farmIdCell,
        filter: new TextFilterDef("farmIdFilter"),
        displayed: true,
        sort: "farmId",
      },
      {
        name: "number",
        translationKey: "models.farmMapping.farmMappingTable.number",
        cellTemplate: this.numberCell,
        filter: new TextFilterDef("farmNumberFilter"),
        displayed: true,
        sort: "number",
      },
      {
        name: "farmIdTypeId",
        translationKey: "models.farmMapping.farmMappingTable.farmIdTypeId",
        cellTemplate: this.farmIdTypeIdCell,
        filter: new SelectFilterDef(
          "FarmIdTypeFilter",
          Object.keys(FarmIdTypeEnum)
            .filter((value) => isNaN(Number(value)) === false)
            .map(
              (x) =>
                new SelectOption(
                  x,
                  `models.farmMapping.farmIdTypeId.${FarmIdTypeEnum[x]}`
                )
            )
        ),
        displayed: true,
        sort: "farmIdTypeId",
      },
      {
        name: "actions.actions",
        translationKey: "models.farmMapping.farmMappingTable.edit",
        cellTemplate: this.editTmpl,
        filter: null,
        displayed: true,
        sort: null,
      },
    ];
  }

  showFarmDetailsForId(farmId, kbo) {
    if (!kbo) return;
    if (this.farmDetailsArray[0] !== undefined && farmId !== null) {
      if (!this.farmDetailsArray.some((detail) => detail.farmId === farmId)) {
        this.farmDetailsService
          .getDetailsByFarmId(farmId)
          .subscribe((detail) => {
            this.farmDetailsArray.push(detail);
            this.sortDetailsArray();
          });
      }
    } else {
      this.farmDetailsService.getDetailsByFarmId(farmId).subscribe((detail) => {
        this.farmDetailsArray[0] = detail;
        this.sortDetailsArray();
      });
    }
    if (
      this.farmDetailsArray.find(
        (cardToShow) => cardToShow.farmId === farmId
      ) !== undefined
    ) {
      this.farmDetailsArray.find(
        (cardToShow) => cardToShow.farmId === farmId
      ).hidden = false;
    }

    this.scrollToBottom();
    // Why would this be here?
    // farmId = undefined;
  }

  scrollToBottom(): void {
    try {
      // Scroll to bottom
      const element = document.documentElement || document.body;
      element.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });
    } catch (err) {}
  }

  sortDetailsArray() {
    this.farmDetailsArray.forEach((farmDetailsResult) => {
      if (farmDetailsResult.mappings) {
        farmDetailsResult.mappings.sort((a, b) => {
          const aIsKBO =
            a.farmIdTypeId.toLowerCase() ===
            "4c17a3f2-c03d-4d65-8440-3a896b245753";
          const bIsKBO =
            b.farmIdTypeId.toLowerCase() ===
            "4c17a3f2-c03d-4d65-8440-3a896b245753";
          if (aIsKBO && !bIsKBO) {
            return -1;
          } else if (!aIsKBO && bIsKBO) {
            return 1;
          } else {
            return a.farmIdTypeId.localeCompare(b.farmIdTypeId);
          }
        });
      }
    });
  }

  hideFarmDetailsForId(farmId) {
    this.farmDetailsArray = this.farmDetailsArray.filter(
      (cardToHide) => cardToHide.farmId !== farmId
    );
    farmId = undefined;
  }

  hideAllDetails() {
    this.farmDetailsArray = [];
  }

  getColor(indexOfElement) {
    let colorsArray = [
      "floralwhite",
      "lavenderblush",
      "lightcyan",
      "lavender",
      "paleturquoise",
    ];
    return colorsArray[indexOfElement % colorsArray.length];
  }

  editFarmmapping(farmId: string) {
    this.router.navigate([`edit-farmmapping`, farmId], {
      relativeTo: this.route,
    });
  }

  viewAccess(kbo: string) {
    this.router.navigate([`farm-resource-access/`], {queryParams: {kbo: kbo}});
  }

  addKBO() {
    this.emptyFarmId = "";
    this.router.navigate([`edit-farmmapping`, this.emptyFarmId], {
      relativeTo: this.route,
    });
  }
}
