import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable()
export class NotificationService {

  constructor(private snackbar: MatSnackBar) {}

  success(message: string) {
    this.snackbar.open(message, 'dismiss', { duration: 5000 });
  }

  error(message: string) {
    this.snackbar.open(message, 'dismiss', { duration: 20000, panelClass: 'error' });
  }
}
