import { DataSource } from "@angular/cdk/table";
import { BehaviorSubject, Observable, Subscription, of } from "rxjs";
import { IPagedSortedFilteredService } from "../../core/services/ipaged-sorted-filtered.service";
import { CollectionViewer } from "@angular/cdk/collections";
import { Page } from "../models/page";
import { catchError, finalize, tap } from "rxjs/operators";

export class RemoteDataSource<T> implements DataSource<T> {
  private dataSubject = new BehaviorSubject<T[]>([]);
  private loadingSubject = new BehaviorSubject<boolean>(false);
  private totalCountSubject = new BehaviorSubject<number>(0);

  public loading$ = this.loadingSubject.asObservable();
  public totalCount$ = this.totalCountSubject.asObservable();

  constructor(private service: IPagedSortedFilteredService<T>) {}
  connect(collectionViewer: CollectionViewer): Observable<T[]> {
    return this.dataSubject.asObservable();
  }

  disconnect(collectionViewer: CollectionViewer): void {
    this.dataSubject.complete();
    this.loadingSubject.complete();
  }

  loadData(pageNumber = 1, pageSize = 10, sort = null, filterObj = null): Observable<Page<T>> {
    this.loadingSubject.next(true);

    return this.service
      .get(pageNumber, pageSize, sort, filterObj)
      .pipe(
        tap((page) => {
          this.totalCountSubject.next(page.totalCount);
          return this.dataSubject.next(page.items);
        }),
        catchError(() => of(new Page<T>())),
        finalize(() => this.loadingSubject.next(false))
      );
  }

  getData(): Observable<Array<T>> {
    return this.dataSubject.asObservable();
  }
}
