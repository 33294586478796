import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { UserAuthService } from '../../core/user-auth.service';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
 
@Injectable()
export class AuthGuard  {

    constructor(private authService: UserAuthService, private router: Router) {
    }

    //when not logged in, route is redirected to home with path as queryparam
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot)
    : boolean | UrlTree | Observable<boolean | UrlTree> | Promise<boolean | UrlTree> {
        if (this.authService.isLoggedIn()) { return true; }
        
        const path = route.routeConfig.path;
        const params = { ...route.queryParams };
        params['path'] = path;
        return this.router.createUrlTree(["/"], { queryParams: params });
    }
}
