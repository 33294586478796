export class FarmStatusSummary {
    farmNumber: number;
    status: FarmStatus;
}

export enum FarmStatus {
    HasUser = 0,
    NotFound = 1,
    HasNoUser = 2
}
