<ng-container *ngIf="adminStatistics | async as statistics">
  <div class="stat-title-part">
    <h2>{{ "navigation.homePage.statistics.title" | translate }}</h2>
    <p>{{ "navigation.homePage.statistics.content" | translate }}</p>
  </div>

  <div class="cards-row">
    <!-- Here you can add cards with values that are numbers -->
    <div class="stat-card">
      <h3>
        {{ "navigation.homePage.statistics.totalKboWithUsers" | translate }}
      </h3>
      <div class="number-card">
        <p>{{ statistics.totalKboWithUsers }}</p>
      </div>
    </div>
    <div class="stat-card">
      <h3>{{ "navigation.homePage.statistics.totalFarmers" | translate }}</h3>
      <div class="number-card">
        <p>{{ statistics.totalFarmers }}</p>
      </div>
    </div>
    <div class="stat-card">
      <h3>
        {{
          "navigation.homePage.statistics.totalKboWithoutDataRequests"
            | translate
        }}
      </h3>
      <div class="number-card">
        <p>{{ statistics.totalKboWithoutDataRequests }}</p>
      </div>
    </div>
  </div>
  <div class="stat-card-big max-height">
    <h3>
      {{
        "navigation.homePage.statistics.listKboWithoutDataRequests" | translate
      }}
    </h3>
    <ng-container
      *ngIf="statistics.listKboWithoutDataRequests.length; else noFarms"
    >
      <table
        mat-table
        [dataSource]="statistics.listKboWithoutDataRequests"
        class="mat-elevation-z8"
      >
        <ng-container matColumnDef="farmId">
          <th mat-header-cell *matHeaderCellDef>farmId</th>
          <td mat-cell *matCellDef="let element">
            <a
              [href]="'/farmmapping/edit-farmmapping/' + element.farmId"
              target="_blank"
            >
              {{ element.id }}
            </a>
          </td>
        </ng-container>
        <ng-container matColumnDef="farmIdTypeId">
          <th mat-header-cell *matHeaderCellDef>farmIdTypeId</th>
          <td mat-cell *matCellDef="let element">
            {{ element.farmIdTypeId }}
          </td>
        </ng-container>
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef>Id</th>
          <td mat-cell *matCellDef="let element">
            {{ element.id }}
          </td>
        </ng-container>
        <ng-container matColumnDef="KBO">
          <th mat-header-cell *matHeaderCellDef>KBO</th>
          <td mat-cell *matCellDef="let element">
            {{ element.number }}
          </td>
        </ng-container>
        <tr
          mat-header-row
          *matHeaderRowDef="columnsListKboWithoutDataRequests"
        ></tr>
        <tr
          mat-row
          *matRowDef="let row; columns: columnsListKboWithoutDataRequests"
        ></tr>
      </table>
    </ng-container>
    <ng-template #noFarms>
      <p>{{ "viewUserPage.noFarms" | translate }}</p>
    </ng-template>
  </div>
  <div class="cards-row">
    <div class="stat-card-big stat">
      <h3>
        {{ "navigation.homePage.statistics.newFarmersMonth" | translate }}
      </h3>
      <app-chartjs-barchart
        [data]="dataFarmersMonth"
        [options]="options"
        title="barChartNewFarmersMonth"
        *ngIf="!loadingData"
      />
    </div>
    <div class="stat-card-big stat">
      <h3>
        {{ "navigation.homePage.statistics.newFarmersCountYearMonth" | translate }}
      </h3>
      <app-chartjs-barchart
        [data]="dataFarmersCountYearMonth"
        [options]="monthYearOptions"
        title="barChartNewFarmersYearMonth"
        exportable=true
        *ngIf="!loadingData"
      />
    </div>
    <div class="stat-card-big stat">
      <h3>
        {{ "navigation.homePage.statistics.newFarmsCountYearMonth" | translate }}
      </h3>
      <app-chartjs-barchart
        [data]="dataFarmsCountYearMonth"
        [options]="monthYearOptions"
        title="barChartNewFarmsYearMonth"
        exportable=true
        *ngIf="!loadingData"
      />
    </div>
  </div>
</ng-container>
