import { Component, Input } from "@angular/core";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class FooterComponent {
  @Input() isDevEnv: boolean;
  @Input() version: string;

  constructor() {
    this.isDevEnv = false;
  }
}
