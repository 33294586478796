<!--div *ngIf="claimingInvitation && !hasLoginfailure">
    {{ 'invitationPage.claiming' | translate }} 
</div-->

<div *ngIf="!claimingInvitation">
    <h3>{{ 'invitationPage.claimingWarning' | translate }} </h3>
    <h4>{{ claimResultType }}</h4>
    <p>{{ claimErrorResult }}</p>
</div>


<div *ngIf="hasLoginfailure">
    {{ 'invitationPage.loginFailure' | translate }} 
</div>