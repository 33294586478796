import { Component, OnInit, Input, ViewChild } from "@angular/core";
import {
  MatTooltipDefaultOptions,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
  MatTooltip,
} from "@angular/material/tooltip";
import { UntypedFormGroup, UntypedFormControl } from "@angular/forms";

export const myCustomTooltipDefaults: MatTooltipDefaultOptions = {
  showDelay: 1000,
  hideDelay: 1000,
  touchendHideDelay: 1000,
};

@Component({
  selector: "app-subscription-key",
  templateUrl: "./subscription-key.component.html",
  styleUrls: ["./subscription-key.component.scss"],
  providers: [
    { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: myCustomTooltipDefaults },
  ],
})
export class SubscriptionKeyComponent implements OnInit {
  @ViewChild(MatTooltip, { static: true }) tooltip: MatTooltip;

  @Input() controlName: string;
  @Input() placeholder: string;
  @Input() value: string;
  @Input() formGroup: UntypedFormGroup;
  @Input() label: string;

  subscriptionKeyControl = new UntypedFormControl("");
  showing: boolean;
  type: string;

  constructor() {
    this.hide();
  }

  ngOnInit() {
    this.tooltip.disabled = true;
    this.formGroup.addControl(this.controlName, this.subscriptionKeyControl);
  }

  show() {
    this.showing = true;
    this.type = "text";
  }

  hide() {
    this.showing = false;
    this.type = "password";
  }

  async showTooltip() {
    this.tooltip.disabled = false;
    this.tooltip.show(0);
    await delay(1000).then(() => (this.tooltip.disabled = true));
  }
}

function delay(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
