import { Language } from "./language";

export function exportDataToCsv(
  keys: Array<string>,
  data: any,
  currentLanguage?: Language,
  addHeaders = true
) {
  let csv = data.map((row) =>
    keys
      .map((fieldName) => {
        if (currentLanguage && row.translations) {
          let translation = row.translations.find(
            (l) => l.language === currentLanguage
          )[fieldName];
          return !translation ? row[fieldName] : translation;
        } else {
          return row[fieldName];
        }
      })
      .join(";")
  );
  if (addHeaders) {
    csv.unshift(keys.join(";"));
  }
  let csvArray = [];
  csv.forEach((element: string) => {
    if (element.endsWith(";")) {
      csvArray.push(element.slice(0, -1));
    } else {
      csvArray.push(element);
    }
  });
  return csvArray.join("\r\n");
}