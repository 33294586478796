export class FarmIdType {
    id: string;
    name: string;
    isDefault: boolean;
}

export enum FarmIdTypeEnum {
    "Beslag nummer",
    "Perceelsnummer",
    "KBO",
    "Keuring Spuittoestellen",
    "Vestigingseenheidsnummer",
    "Leveraarsnummer",
    "PE nummer"
}