import { Pipe, PipeTransform } from '@angular/core';
import { UserRole } from '../models/datahubUser';

@Pipe({
    name: 'ContextRoleTranslation'
})
export class ContextRoleTranslation implements PipeTransform {
    transform(value: UserRole): string {
        if (value)
            return `layout.context.roles.${UserRole[value]}`;
        return '';
    }
}
