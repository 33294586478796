import { Component } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { MatDialogRef } from "@angular/material/dialog";
import { TranslatableString, Language, getDefaultTranslatableStringArray } from '../../../shared/models/language';
import {ValidatorService} from '../../../core/validators/validator-service';
import { Api } from '../../models/api';

@Component({
  selector: 'app-add-api-dialog',
  templateUrl: './add-api-dialog.component.html',
  styleUrls: ['./add-api-dialog.component.css']
})
export class AddApiDialogComponent {
  form: UntypedFormGroup;
  constructor(
    public dialogReg: MatDialogRef<AddApiDialogComponent>,
    formBuilder: UntypedFormBuilder,
    private validatorService: ValidatorService
    ) {
      this.form = formBuilder.group({
        apiName: new UntypedFormControl(getDefaultTranslatableStringArray(), [this.validatorService.translatableNameValidator()]),
        apiUrl: new UntypedFormControl(null, [this.validatorService.urlValidator()]),
        openApiSpecUrl: new UntypedFormControl(null, [this.validatorService.urlValidator()]),
        farmIdUrl: new UntypedFormControl(null, [this.validatorService.nullableUrlValidator()]),
      });
    }

  onCancel() {
    this.dialogReg.close();
  }

  onCreate(values) {
    this.dialogReg.close(values);
  }
}
