import { Component, OnInit, OnDestroy } from "@angular/core";
import DataAccessRequest from "../../shared/models/data-access-request";
import { DarStatus } from "../../shared/models/data-access-request-status";
import { DataAccessRequestService } from "../../core/services/data-access-request.service";
import { Subscription, of } from "rxjs";
import { MatDialog } from "@angular/material/dialog";
import { Router, ActivatedRoute } from "@angular/router";
import { switchMap } from "rxjs/operators";
import ConsumerDataAccessRequests from "../../shared/models/consumer-data-access-requests";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { FormBuilder, FormGroup, FormArray } from "@angular/forms";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";

@Component({
  selector: "app-consumer-dars",
  templateUrl: "./consumer-dars.component.html",
  styleUrls: ["./consumer-dars.component.scss"],
})
export class ConsumerDarsComponent implements OnInit, OnDestroy {
  requestsPendingArray: DataAccessRequest[];
  requestsHandledArray: DataAccessRequest[];
  columns = [];
  consumer: any = {};
  consumerId: string;
  subscription = new Subscription();
  darStatusForm: FormGroup = this.formBuilder.group({
    requestsPending: this.formBuilder.array([]),
    requestsHandled: this.formBuilder.array([])
  });
  informativeFlow: boolean;

  baseurl: string;
  constructor(
    private dataAccessRequestService: DataAccessRequestService,
    private dialog: MatDialog,
    private router: Router,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private formBuilder: FormBuilder
  ) { }

  async ngOnInit() {
    await this.initializeComponent();
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
       this.initializeComponent();
    });
  }

  async initializeComponent() {
    const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.baseurl = appConfig.DatapartnerLogoBaseUrl;
    const consumer$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.consumerId = params.get("id");
        if (this.consumerId !== null) {
          return this.dataAccessRequestService.getDataAccessRequests(
            this.consumerId
          );
        }
        return of(new ConsumerDataAccessRequests());
      })
    );

    this.subscription.add(
      consumer$.subscribe((response) => {
        this.consumer = {
          name: response.name,
          companyName: response.companyName,
          description: response.description,
      
          informativeFlow: response.informativeFlow,
          applicationUrl: response.applicationUrl,
          applicationLogoUrl: response.applicationLogoUrl,
        };
        this.requestsPendingArray = response.dataAccessRequests
          .filter((x)=>x.status === DarStatus.Pending);
        this.requestsHandledArray = response.dataAccessRequests
          .filter((x)=>x.status !== DarStatus.Pending);
      
        this.darStatusForm.setControl('requestsPending', 
          this.formBuilder.array(
            this.requestsPendingArray
              .map((request) =>
                this.createrequestGroup(request)
            )));
        this.darStatusForm.setControl('requestsHandled', 
          this.formBuilder.array(
            this.requestsHandledArray
              .map((request) =>
                this.createrequestGroup(request)
            )));

            this.informativeFlow = response.informativeFlow;
        this.initColumnsRegularFlow();
      })
    );    
  }

  createrequestGroup(request: DataAccessRequest): FormGroup {
    let req = this.formBuilder.group({
      darId: [request.darId],
      status: [request.status],
      resourceName: [request.resourceName],
      resourceDescription: [request.resourceDescription],
      resourceDocumentationUrl: [request.resourceDocumentationUrl],
      requestedOn: [request.requestedOn],
      prevStatus: [request.status]
    });
    req.controls['status'].valueChanges.subscribe(value => {
      const prevStatus = req.controls['prevStatus'].value;
      if (value === DarStatus.Approved) {
        this.approve(
          request.darId,
          () => req.controls['prevStatus'].setValue(DarStatus.Approved),
          () => req.controls['status'].setValue(prevStatus, {emitEvent: false})
        );
      } else {
        this.reject(
          request.darId,
          () => req.controls['prevStatus'].setValue(DarStatus.Rejected),
          () => req.controls['status'].setValue(prevStatus, {emitEvent: false})
        );
      }
    });
    return req
  }

  get requestsPending(){
    return this.darStatusForm.get('requestsPending') as FormArray;
  }

  get requestsHandled(){
    return this.darStatusForm.get('requestsHandled') as FormArray;
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private initColumnsRegularFlow() {
    this.columns = [
      {
        name: "models.farmDataAccessRequest.resourceName",
      },
      {
        name: "models.farmDataAccessRequest.resourceDescription",
      },
      {
        name: "models.farmDataAccessRequest.status",
      },
    ];
  }

  approve(darId: string, onSuccess:() => void, onError:() => void) {
     const acceptDarSubscription = this.dataAccessRequestService
      .approveDataAccessRequest(darId)
      .subscribe({
        next: (n) => {
          onSuccess();
        },
        error: (e) =>{
          onError();
        }});
    this.subscription.add(acceptDarSubscription);
  }

  reject(darId: string, onSuccess:() => void, onError:() => void) {
    const rejectDarSubscription = this.dataAccessRequestService
      .rejectDataAccessRequest(darId)
      .subscribe({
        next: (n) => {
          onSuccess();
        },
        error: (e) =>{
          onError();
         }});
    this.subscription.add(rejectDarSubscription);
  }

  goBack() {
    this.router.navigate(["manage-data-access"]);
  }

  approveAllPending = () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "dataAccessRequestPage.popup.acceptAll",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.subscription.add(
            this.dataAccessRequestService
              .approveAllPendingDataAccessRequests(this.consumerId)
              .subscribe((_) => {
                while(this.requestsPending.controls.length > 0)
                {
                  let request = this.requestsPending.controls.pop();
                  if(request.get('status').value === DarStatus.Pending)
                  request.get('status').setValue(DarStatus.Approved, {emitEvent: false});
                  this.requestsHandled.insert(0, request);
                }
              })
          );
        }
      })
    );
  };

  rejectAllPending = () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "dataAccessRequestPage.popup.rejectAll",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.subscription.add(
            this.dataAccessRequestService
              .rejectAllPendingAccessRequests(this.consumerId)
              .subscribe((_) => {
                while(this.requestsPending.controls.length > 0)
                {
                  let request = this.requestsPending.controls.pop();
                  if(request.get('status').value === DarStatus.Pending)
                  request.get('status').setValue(DarStatus.Rejected, {emitEvent: false});
                  this.requestsHandled.insert(0, request);
                }
              })
          );
        }
      })
    );
  };

  hasApplicationUrl() {
    if (!this.consumer) return false;
    return this.consumer.applicationUrl;
  }

  hasApplicationLogoUrl() {
    if (!this.consumer) return false;
    return this.consumer.applicationLogoUrl;
  }

  arePending(): boolean {
    return this.requestsPending.controls.some((r) => r.get('status').value === DarStatus.Pending)
  }
}
