export class ConsumerRequestCard {
    consumerId: string;
    consumerName: string;
    description: string;
    applicationUrl: string;
    applicationLogoUrl: string;
    companyName: string;
    approved: number;
    rejected: number;
    pending: number;
    informativeFlow: boolean;

    get total(): number {
        return this.approved + this.pending + this.rejected;
    }
}

