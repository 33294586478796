<h1>{{ "info.manageApis" | translate }}</h1>
<app-base-datatable
  #apiTable
  [rows]="apis"
  [columns]="columns"
  [headerHeight]="70"
  [footerHeight]="50"
  [limit]="10"
  [lastColumnIcon]="true"
  [exportable]="false"
>
</app-base-datatable>
<button *ngIf="!isAdmin" type="button" (click)="createNewApi()" class="green">
  {{ "actions.createNewApi" | translate }}
</button>

<ng-template #translationTmpl let-row="row">
  <app-translatable-string-view
    [translation]="row.translations"
    property="apiName"
  ></app-translatable-string-view>
</ng-template>

<ng-template #editTmpl let-row="row">
  <button
    (click)="viewDetails(row)"
    type="button"
    aria-label="View details"
    class="card-button view-details"
  >
    <i class="fa fa-edit"></i>
  </button>
</ng-template>

<ng-template #statusTmpl let-value="value">
  <div>{{ value | ApiStatusTranslation | translate }}</div>
</ng-template>

<ng-template #editHdrTmpl let-column="column">
  <div>{{ column.name | translate }}</div>
</ng-template>
