import { Pipe, PipeTransform } from '@angular/core';
import { EventTypeStatus } from '../../data-provider-module/models/event-type';

@Pipe({
    name: 'EventTypeStatusTranslation'
})
export class EventTypeStatusTranslation implements PipeTransform {
    transform(value: string): any {
        return `models.eventType.statusses.${EventTypeStatus[value]}`;
    }
}
