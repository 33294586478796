import {
  DatahubParterTranslation,
  DatahubPartner,
  DatahubPartnerRole,
} from "../../admin/models/datahubPartner";

export class DatahubUser {
  id: string;
  identityId: string;
  firstName: string;
  lastName: string;
  email: string;
  status: DatahubUserStatus;
  roles: UserRole[];
  allowDirectMarketing: boolean;
  createdAt: Date;
}

export class DatahubUserDetails {
  id: string;
  identityId: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  allowDirectMarketing: boolean;
  roles: UserRole[];
  farms: UserDetailsFarmDTO[];
  partners: string[];
}

export class CurrentUserDetailsDTO {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: string;
  allowDirectMarketing: boolean;
  roles: UserRole[];
  userFarms: UserDetailsFarmDTO[];
  userPartners: UserPartnerDTO[];
}

export class UserPartnerDTO {
  userId: string;
  partnerId: string;
  receiveNotifications: boolean;
  partner: DataPartnerDTO;
}

export class DataPartnerDTO {
  id: string;
  name: string;
  purpose: string;
  applicationUrl: string;
  companyName: string;
  applicationLogoUrl: string;
  role: DatahubPartnerRole;
  thumbprint: string;
  isActive: boolean;
  users: Array<DatahubUser>;
  translations: Array<DatahubParterTranslation>;
  informativeFlow: boolean;
}
export class UserDetailsFarmDTO {
  farmId: string;
  kbo: string;
  receiveNotifications: boolean;
  mappings: UserDetailFarmMappingDTO[];
}

export class UserDetailFarmMappingDTO {
  type: string;
  value: string;
}

export enum DatahubUserStatus {
  Unknown = "Unknown",
  Authorized = "Authorized",
  Pending = "Pending",
}

export enum UserRole {
  Unknown = "Unknown",
  Provider = "Provider",
  Consumer = "Consumer",
  Farmer = "Farmer",
  Admin = "Admin",
}
