import {
  MSAL_INTERCEPTOR_CONFIG,
  MsalBroadcastService,
  MsalInterceptor,
  MsalInterceptorConfiguration,
} from "@azure/msal-angular";
import { Injectable, Inject } from "@angular/core";
import { Observable } from "rxjs";
import { HttpRequest, HttpXhrBackend, HttpClient } from "@angular/common/http";
import { baseurl } from "../../../baseSettings";
import { SpinnerInterceptor } from "../interceptors/spinner-http-interceptor";
import { HttpCustomInterceptorHandler } from "../HttpCustomInterceptorHandler";
import { UserAuthService } from "../user-auth.service";
import { DOCUMENT, Location } from "@angular/common";
import { SpinnerService } from "./spinner.service";
import { InvitationUrlResult } from "../../shared/models/invitation-url-result";

@Injectable()
export class InvitationService {
  private URL = `${this.baseUrl}/api/invitation`;
  private response: Observable<any>;
  private currentUrl: string;

  constructor(
    private client: HttpClient,
    private backend: HttpXhrBackend,
    @Inject(baseurl) private baseUrl: string,
    private userAuthService: UserAuthService,
    private broadcastService: MsalBroadcastService,
    private location: Location,
    @Inject(MSAL_INTERCEPTOR_CONFIG)
    private msalInterceptorConfig: MsalInterceptorConfiguration,
    private spinnerService: SpinnerService,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.currentUrl = window.location.origin;
  }

  public ClaimInvitation(invitationId: string): Observable<any> {
    const req = new HttpRequest(
      "POST",
      `${this.URL}/${invitationId}/claim`,
      null
    );

    const spinnerInterceptor = new SpinnerInterceptor(this.spinnerService);
    const msalInterceptor = new MsalInterceptor(
      this.msalInterceptorConfig,
      this.userAuthService.msalService,
      this.location,
      this.broadcastService,
      this.document
    );

    const handlerLevel2 = new HttpCustomInterceptorHandler(
      this.backend,
      msalInterceptor
    );
    const handlerLevel1 = new HttpCustomInterceptorHandler(
      handlerLevel2,
      spinnerInterceptor
    );
    this.response = handlerLevel1.handle(req);
    return this.response;
  }

  signUpAsFarmerObservable(): Observable<any> {
    const req = new HttpRequest("POST", `${this.URL}/farmer-sign-up`, null);
    const spinnerInterceptor = new SpinnerInterceptor(this.spinnerService);
    const msalInterceptor = new MsalInterceptor(
      this.msalInterceptorConfig,
      this.userAuthService.msalService,
      this.location,
      this.broadcastService,
      this.document
    );

    const handlerLevel2 = new HttpCustomInterceptorHandler(
      this.backend,
      msalInterceptor
    );

    const handlerLevel1 = new HttpCustomInterceptorHandler(
      handlerLevel2,
      spinnerInterceptor
    );

    return handlerLevel1.handle(req);
  }

  public MergeInvitations(
    invitationId: string,
    chosenEmail: string
  ): Observable<any> {
    return this.client.post(`${this.URL}/${invitationId}/merge`, {
      chosenEmail,
    });
  }

  public resendInvitation(invitationId: string): Observable<any> {
    return this.client.post(`${this.URL}/${invitationId}/resend`, null);
  }

  public getInviteLink(invitationId: string): string {
      return `${this.currentUrl}/invitation/${invitationId}`;
      // For future reference: this simply returns a string, no call is made to the backend.
  }

  public deleteInvitation(invitationId: string): Observable<any> {
      return this.client.delete(`${this.URL}/${invitationId}/delete-invitation`);
  }

  public getInvitationByEmail(email: string): Observable<InvitationUrlResult> {
    return this.client.get<InvitationUrlResult>(`${this.URL}/email/${email}`);
  }
}
