import { ConsentType } from "../../data-provider-module/models/consent-type";
import { Language } from "../../shared/models/language";

export enum ProviderApiType {
  Pull = 0,
  Push = 1
}
export class ApiResourceDisplayModel {
  id: string;
  name: string;
  providerApiName: string;
  type: ProviderApiType;

  constructor(id: string, type: ProviderApiType, name: string, providerApiName: string) {
    this.id = id;
    this.type = type;
    this.name = name;
    this.providerApiName = providerApiName;
  }

  get displayName(): string {
    return this.type === ProviderApiType.Pull ? this.name + ' - ' + this.providerApiName : `${this.name} - Push`;
  }
}

export class ApiResource {
  id: string;
  type: ProviderApiType;
  consentType: ConsentType;
  translations: Array<ApiResourceTranslation>;
}

export class ApiResourceTranslation {
  language: Language;
  name: string;
  providerApiName: string;
}
