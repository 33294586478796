import {
  Component,
  OnInit,
  OnDestroy,
  ElementRef,
  Renderer2,
  HostListener,
} from "@angular/core";
import { ResourceAccessRequestService } from "../../core/services/resourceAccessRequest.service";
import { Subscription } from "rxjs";
import { ResourceAccessRequest } from "../../shared/models/resourceAccessRequest";
import { AccessRequestStatus } from "../../shared/models/access-request-status";
import { AccessRequestStatusTranslation } from "../../shared/pipes/access-request-status";
import { ResourceRequestsCard } from "../models/resource-requests-card";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationDialogComponent } from "../../shared/confirmation-dialog/confirmation-dialog.component";
import { MatDialog } from "@angular/material/dialog";
import { ResourceAccessRequestDTO } from "../../shared/models/resourceAccessRequestDTO";
import { LangChangeEvent, TranslateService } from "@ngx-translate/core";
import { Language } from "../../shared/models/language";
import { InformativeFlowCard } from "../../shared/informative-flow-card/models/informative-flow-card";

@Component({
  selector: "app-resource-access-requests",
  templateUrl: "./resource-access-requests.component.html",
  styleUrls: ["./resource-access-requests.component.scss"],
})
export class ResourceAccessRequestsComponent implements OnInit, OnDestroy {
  private subscription = new Subscription();
  resourceAccessRequests: Array<ResourceAccessRequest>;
  columns = [];
  statusTranslator = new AccessRequestStatusTranslation();
  splittedArray: ResourceAccessRequestDTO[][];
  consumers: Array<ResourceRequestsCard>;
  pseudoconsumers: Array<ResourceRequestsCard>;
  pending: Array<ResourceRequestsCard>;
  handled: Array<ResourceRequestsCard>;
  currentLanguage: Language;
  informativeFlowArray: Array<InformativeFlowCard>;

  constructor(
    private rarService: ResourceAccessRequestService,
    private dialog: MatDialog,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private el: ElementRef,
    private renderer: Renderer2
  ) {
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = Language[event.lang];
    });
  }

  ngOnInit() {
    this.translate.currentLang
      ? (this.currentLanguage = Language[this.translate.currentLang])
      : (this.currentLanguage = Language.nl);
    this.initResourceRequestCards(this.currentLanguage);
    this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
      this.currentLanguage = Language[event.lang];
      this.initResourceRequestCards(this.currentLanguage);
    });
  }

  private initResourceRequestCards(currentLanguage) {
    this.subscription.add(
      this.rarService.getResourceAccessRequestsAsDTO().subscribe((rarDTOs) => {
        this.consumers = new Array<ResourceRequestsCard>();
        this.informativeFlowArray = new Array<InformativeFlowCard>();
        if (rarDTOs) {
          this.rarService
            .splitArrayByConsumerId(rarDTOs.sort((r1,r2) => {
              if (r1.consumerId > r2.consumerId) { return 1; }
              if (r1.consumerId < r2.consumerId) { return -1; }
              return 0;
            }))
            .forEach((requestsArray) => {
                const newResourceRequestsCard: ResourceRequestsCard =
                  new ResourceRequestsCard();
                  let translations = (requestsArray && requestsArray[0].translations) 
                    ? requestsArray[0].translations.find(t=> t.language === currentLanguage) 
                    : null;
                newResourceRequestsCard.requests = requestsArray;
                newResourceRequestsCard.consumerId =
                  requestsArray[0].consumerAccessId;
                newResourceRequestsCard.consumerName = translations.consumerName;
                newResourceRequestsCard.companyName =
                  requestsArray[0].companyName;
                newResourceRequestsCard.consumerPurpose = translations.consumerPurpose;
                newResourceRequestsCard.applicationLogoUrl =
                  requestsArray[0].applicationLogoUrl;
                newResourceRequestsCard.applicationUrl =
                  requestsArray[0].applicationUrl;
                newResourceRequestsCard.informativeFlow = requestsArray[0].informativeFlow;
                newResourceRequestsCard.creationDate = requestsArray[0].creationDate;
                this.rarService.checkRequestsStatus(newResourceRequestsCard);
                if(newResourceRequestsCard.informativeFlow){
                  this.informativeFlowArray.push(newResourceRequestsCard)
                }
                this.consumers.push(newResourceRequestsCard);
            });
            this.consumers = this.consumers.sort((r1,r2) => {
              if (r1.informativeFlow == r2.informativeFlow) 
              {
                if (r1.creationDate > r2.creationDate) return -1;
                if (r1.creationDate < r2.creationDate) return 1;
              } else if(r1.informativeFlow){
                return 1;
              } else if(r2.informativeFlow){
                return -1;
              }
              return 0;
            });
            this.pending = this.rarService.getPending(this.consumers);
            this.handled = this.rarService.getHandled(this.consumers);
        }
      })
    );
  }

  get haveConsumers() {
    return this.consumers && this.consumers.length > 0;
  }
  get haveNoConsumers() {
    return this.consumers && this.consumers.length == 0;
  }
  get havePending() {
    if (!this.consumers) {
      return false;
    }
    return this.pending && this.pending.length > 0;
  }
  get haveHandled() {
    if (!this.consumers) {
      return false;
    }
    return this.handled && this.handled.length > 0;
  }

  get haveInformativeFlow() {
    if (!this.informativeFlowArray || this.informativeFlowArray.length === 0) {
      return false;
    }
    return true;
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  isApproved(status: AccessRequestStatus): boolean {
    return status === AccessRequestStatus.Approved;
  }

  isRejected(status: AccessRequestStatus): boolean {
    return status === AccessRequestStatus.Rejected;
  }

  approve(rar: ResourceAccessRequest) {
    if (rar.status === AccessRequestStatus.Pending) {
      this.approveRar(rar);
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "popup.updateRar.updateToApprove",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.approveRar(rar);
        }
      })
    );
  }

  private approveRar(rar: ResourceAccessRequest) {
    this.subscription.add(
      this.rarService.approveResourceAccessRequest(rar.id).subscribe(() => {
        this.resourceAccessRequests[this.resourceAccessRequests.indexOf(rar)] =
          {
            ...rar,
            status: AccessRequestStatus.Approved,
            reviewDate: new Date(Date.now()),
          };
        this.resourceAccessRequests = [...this.resourceAccessRequests];
      })
    );
  }

  reject(rar: ResourceAccessRequest) {
    if (rar.status === AccessRequestStatus.Pending) {
      this.rejectRar(rar);
      return;
    }
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: true,
      data: {
        title: "popup.updateRar.updateToReject",
      },
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.rejectRar(rar);
        }
      })
    );
  }

  private rejectRar(rar: ResourceAccessRequest) {
    this.subscription.add(
      this.rarService.rejectResourceAccessRequest(rar.id).subscribe(() => {
        this.resourceAccessRequests[this.resourceAccessRequests.indexOf(rar)] =
          {
            ...rar,
            status: AccessRequestStatus.Rejected,
            reviewDate: new Date(Date.now()),
          };
        this.resourceAccessRequests = [...this.resourceAccessRequests];
      })
    );
  }

  goToEdit(id: string) {
    this.router.navigate([`provider-rars`, id], { relativeTo: this.route });
  }
}
