<h1 mat-dialog-title>
  {{ "certificate.generate.confirmation.title" | translate }}
</h1>
<form (ngSubmit)="OnConfirm()">
  <div mat-dialog-content>
    {{ "certificate.generate.confirmation.text" | translate }}.
  </div>
  <div class="button-group">
    <button type="button" (click)="onCancel()" class="rose">
      {{ "actions.cancel" | translate }}
    </button>
    <button type="submit" class="green">
      {{ "actions.confirm" | translate }}
    </button>
  </div>
</form>
