import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  Output,
  EventEmitter,
  Renderer2,
  ElementRef,
  HostListener,
} from "@angular/core";
import { ResourceRequestsCard } from "../../models/resource-requests-card";
import { ActivatedRoute, Router } from "@angular/router";
import { Subscription } from "rxjs";

@Component({
  selector: "app-resource-requests-card",
  templateUrl: "./resource-requests-card.component.html",
  styleUrls: ["./resource-requests-card.component.scss"],
})
export class ResourceRequestsCardComponent implements OnInit, OnDestroy {
  @Input() data: ResourceRequestsCard;
  subscription = new Subscription();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private el: ElementRef,
    private renderer: Renderer2
  ) {}

  public baseurl: string;

  ngOnInit() {
    const appConfig = JSON.parse(sessionStorage.getItem("appConfig"));
    this.baseurl = appConfig.DatapartnerLogoBaseUrl;
    this.route.params.subscribe((params) => {
      const id = params["id"];
    });
  }
  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  get navigationRoute(): string {
    let detailsNavigation = `details`;
    if (this.router.url === `/`) {
      detailsNavigation = `resource-access-requests/details`;
    }
    return detailsNavigation;
  }

  hasApplicationUrl() {
    return this.data.applicationUrl;
  }

  hasApplicationLogoUrl() {
    return this.data.applicationLogoUrl;
  }

  goToApplication() {
    if (this.data.applicationUrl) {
      window.open(this.data.applicationUrl);
    }
  }

  ngAfterViewInit() {
    this.checkHeightCards();
  }

  @HostListener("window:resize")
  onResize() {
    this.checkHeightCards();
  }

  private checkHeightCards() {
    const element = this.el.nativeElement.querySelector(".description");
    const height = parseInt(window.getComputedStyle(element).height);
    if (height > 100) {
      this.renderer.addClass(element, "exceeds-max-height");
    }
  }
}
