import { Component, OnInit, OnDestroy } from "@angular/core";
import { Api } from "../../models/api";
import { ApiService } from "../../../core/services/api.service";
import { Router, ActivatedRoute } from "@angular/router";
import { MatDialog } from "@angular/material/dialog";
import { Subscription, Observable, of } from "rxjs";
import { ValidationResultDialogComponent } from "./validation-result-dialog/validation-result-dialog.component";
import { UserAuthService } from "../../../core/user-auth.service";
import { PublishApiDialogComponent } from "../publish-api-dialog/publish-api-dialog.component";
import { ApiValidationResponse } from "../../models/api-validation-response";
import { ConfirmationDialogComponent } from "../../../shared/confirmation-dialog/confirmation-dialog.component";
import { switchMap } from "rxjs/operators";
import { ApiStatus } from "../../models/api-status";
import { TagService } from "../../../core/services/tag.service";
import { ApiTag } from "../../models/api-tag";
import { ResourceValidationResult } from "../../models/resource-validation-result";
import { FarmIdApiVersionType } from "../../models/farm-id-api-version-type";

@Component({
  selector: "app-api-details",
  templateUrl: "./api-details.component.html",
  styleUrls: ["./api-details.component.scss"],
})
export class ApiDetailsComponent implements OnInit, OnDestroy {
  _isRequesting: boolean;
  isAdmin: boolean;
  apiValidationResponse = new ApiValidationResponse();
  subscription = new Subscription();
  farmIdApiVersions: Array<FarmIdApiVersionType>;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private dialog: MatDialog,
    private authService: UserAuthService,
    private route: ActivatedRoute,
    private tagService: TagService
  ) {
    this.api = new Api();
    this.subscription = new Subscription();
  }

  allTags$: Observable<Array<ApiTag>>;
  apiId: string;
  api: Api;

  ngOnInit() {
    this.allTags$ = this.tagService.getTags();
    this.farmIdApiVersions = this.initFarmIdApiVersions();
    
    const api$ = this.route.paramMap.pipe(
      switchMap((params) => {
        this.apiId = params.get("id");
        if (this.apiId !== null) {
          return this.apiService.getDetails(this.apiId);
        }
        return of(new Api());
      })
    );

    this.subscription.add(
      api$.subscribe((response) => {
        this.api = response;
      })
    );

    this.isAdmin = this.authService.isAdmin();
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  onSave = async () => {
    try {
      this.subscription.add(
        this.apiService.update(this.api).subscribe(() => {
          return;
        })
      );
    } catch (errorResponse) {
      if (errorResponse.status !== 400) {
        throw errorResponse;
      }
    }
  };

  onUpdate = async () => {
    try {
      this.subscription.add(
        this.apiService.updatePublished(this.api).subscribe(() => {
          return;
        })
      );
    } catch (errorResponse) {
      if (errorResponse.status !== 400) {
        throw errorResponse;
      }
    }
  };

  back = () => {
    this.router.navigate([`manage-apis`]);
  };

  onDelete = () => {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      disableClose: false,
      data: {
        title: "manageApisPage.deleteApiDialog.content",
      },
    });

    this.subscription.add(
      dialogRef
        .afterClosed()
        .pipe(
          switchMap((value) => {
            if (value) {
              return this.apiService.delete(this.api.id);
            }
            return of();
          })
        )
        .subscribe(() => this.router.navigate([`manage-apis`]))
    );
  };

  validate = () => {
    this.subscription.add(
      this.apiService.runValidation(this.api).subscribe((validationResult) => {
        Object.assign(this.apiValidationResponse, validationResult);
        this.dialog.open(ValidationResultDialogComponent, {
          width: "fit-content",
          height: "fit-content",
          disableClose: true,
          data: { farmIdUrl: this.api.farmIdUrl, results: validationResult },
        });
      })
    );
  };

  getValidationResult(resourceId): ResourceValidationResult {
    if (
      this.apiValidationResponse !== undefined && this.apiValidationResponse !== null && this.apiValidationResponse.resourceValidationResults !== undefined && this.apiValidationResponse.resourceValidationResults !== null)
    {
      return this.apiValidationResponse.resourceValidationResults.find(
        (r) => r.resourceId === resourceId
      );
    }
  }

  submitForApproval() {
    this.subscription.add(
      this.apiService
        .submitForApproval(this.api)
        .subscribe((validationResult) => {
          this.openValidationDialog(validationResult, true);
        })
    );
  }

  private openValidationDialog(validationResult: any, isSubmitted: boolean) {
    const validationDialogRef = this.dialog.open(
      ValidationResultDialogComponent,
      {
        width: "fit-content",
        height: "fit-content",
        disableClose: true,
        data: {
          farmIdUrl: this.api.farmIdUrl,
          results: validationResult,
          isSubmitted: isSubmitted,
        },
      }
    );
    this.subscription.add(
      validationDialogRef.afterClosed().subscribe((value) => {
        if (value) {
          this.router.navigate([`manage-apis`]);
        }
      })
    );
  }

  publish() {
    const dialogRef = this.dialog.open(PublishApiDialogComponent, {
      width: "fit-content",
      height: "fit-content",
      disableClose: false,
    });

    this.subscription.add(
      dialogRef.afterClosed().subscribe((products) => {
        if (products) {
          this.subscription.add(
            this.apiService.publish(this.api.id, products).subscribe(() => {
              this.router.navigate([`manage-apis`]);
            })
          );
        }
      })
    );
  }

  reject() {
    this.subscription.add(
      this.apiService.reject(this.api.id).subscribe(() => {
        this.api.status = ApiStatus.Draft;
      })
    );
  }

  addTag(value) {
    return { id: undefined, name: value };
  }

  farmIdApiVersionIdentifier(value: number) {
    return value > 0 ? "FarmIdApiVersionType." + FarmIdApiVersionType[value] : "";
  }

  private initFarmIdApiVersions(): any[]{
    const values = [];
    const keys = Object.values(FarmIdApiVersionType).filter((key) =>
      isNaN(Number(FarmIdApiVersionType[key]))
    );
    keys.forEach((x) => {
      const name = FarmIdApiVersionType[x].split(/(?=[A-Z])/).join(" ");
      const consentType: any = { index: x, name: name };
      values.push(consentType);
    });
    return values;
  }
}
