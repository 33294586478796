import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { isNull, isUndefined } from 'util';

import { NotificationService } from '../services/notification.service';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {

  constructor(private notificationService: NotificationService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((event) => this.notifyHttpError(event, req))
    );
  }

  private notifyHttpError(event: HttpEvent<any>, req: HttpRequest<any>): Observable<HttpEvent<any>> {
    if (event instanceof HttpErrorResponse) {
      if (!req.headers.has('Skip-Error-Handler')) {
        this.notificationService.error(this.extractErrorMessagesFrom(event));
      }
      return throwError(event);
    }
    return of(event);
  }

  private extractErrorMessagesFrom(event: HttpErrorResponse) {
    return !isUndefined(event.error) && !isNull(event.error)
      ? Object.values(event.error).join('\n')
      : 'Something went wrong...';
  }
}
