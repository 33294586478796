import { Component } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DarStatus } from "../models/data-access-request-status";
  
@Component({
    selector: "app-approve-reject-selector",
    templateUrl: "./approve-reject-selector.component.html",
    styleUrls: ["./approve-reject-selector.component.css"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ApproveRejectSelectorComponent,
            multi: true,
        },
    ],
  })

  export class ApproveRejectSelectorComponent implements ControlValueAccessor {

    onChange = (status: DarStatus) => {};
    onTouched = () => {};
    disabled = false;

    public status: DarStatus;
    public viewStatus: DarStatusView;

    registerOnChange(onChange: any): void {
        this.onChange = onChange;
    }
    registerOnTouched(onTouched: any): void {
        this.onTouched = onTouched;
    }
    setDisabledState?(isDisabled: boolean): void {
        this.disabled = isDisabled;
    }
  
    writeValue(value: DarStatus) {
        this.status = value;
        this.setViewState(value);
    }

    setViewState(value: DarStatus){
        this.viewStatus = this.isPending(value)
            ? DarStatusView.IsPending
            : this.isApproved(value)
                ? DarStatusView.IsApproved
                : DarStatusView.IsRejected;
    }
 
    /* checkstate */
    isPending(status: DarStatus): boolean {
        return status === DarStatus.Pending;
    }

    isApproved(status: DarStatus): boolean {
        return status === DarStatus.Approved;
    }

    isRejected(status: DarStatus): boolean {
        return status === DarStatus.Rejected;
    }
    /* display */
    getClass(status: DarStatus): string {
        return (!status || this.isPending(status))
                ? 'bg-pending'
                : this.isApproved(status)
                ? 'bg-approved'
                : 'bg-denied'
    }

    getText(status: DarStatus): string {
        return (!status || this.isPending(status))
                ? 'MakeAChoice'
                : this.isApproved(status)
                ? 'Approved'
                : 'Rejected'
    }
    /* arrange view */
    viewChangePending(viewStatus: DarStatusView): boolean {
        return  viewStatus === DarStatusView.ChangePending;
    }
    viewMustShowStatus(viewStatus: DarStatusView): boolean {
        return  viewStatus !== DarStatusView.ChangePending;
    }

    viewShowClickOpen(viewStatus: DarStatusView): boolean {
        return viewStatus === DarStatusView.IsApproved 
        || viewStatus=== DarStatusView.IsRejected 
        || viewStatus=== DarStatusView.IsPending;
    }

    viewShowApprove(viewStatus: DarStatusView): boolean {
        return  viewStatus === DarStatusView.ChangePending 
            || viewStatus === DarStatusView.ChangeReject;
    }
    viewShowReject(viewStatus: DarStatusView): boolean {
        return  viewStatus === DarStatusView.ChangePending 
            || viewStatus === DarStatusView.ChangeApprove;
    }

    /* actions */
    toggleSelect() {
        switch(this.viewStatus) {
            case DarStatusView.IsPending:
                this.viewStatus = DarStatusView.ChangePending;
                break;
            case DarStatusView.IsApproved:
                this.viewStatus = DarStatusView.ChangeApprove;
                break;
            case DarStatusView.ChangeApprove:
                this.viewStatus = DarStatusView.IsApproved;
                break;
            case DarStatusView.IsRejected:
                this.viewStatus = DarStatusView.ChangeReject;
                break;
            case DarStatusView.ChangeReject:
                this.viewStatus = DarStatusView.IsRejected;
                break;          
        }
    }

    onSubmit(status: DarStatus) {
        this.status = status;
        this.setViewState(status);
        this.onChange(status);
    }
}

export enum DarStatusView { 
    IsPending,     //Pending: show yellow button
    ChangePending,  //pending: show both approve/reject
    IsApproved,    //approved: show only approved state
    ChangeApprove, //approved: show state and also button to reject
    IsRejected,    //rejected: show only rejected state
    ChangeReject   //rejected: show state and also button to approve 
}
  