<h1>{{ "navigation.managePartners" | translate }}</h1>
<p>{{ "info.managePartners" | translate }}</p>
<button class="green" (click)="addPartner()" type="button" id="addPartner">
  <i class="fas fa-plus"></i>
  <span>
    {{ "actions.addPartner" | translate }}
  </span>
</button>

<app-base-datatable
  #partnerTable
  [rows]="partners | async"
  [columns]="columns"
  [headerHeight]="70"
  [footerHeight]="50"
  [limit]="10"
  [exportable]="false"
>
</app-base-datatable>

<ng-template #editTmpl let-row="row">
  <div class="float-right">
    <button (click)="viewDetails(row)" mat-button>
      <i class="fa fa-edit"></i>
    </button>
  </div>
</ng-template>

<ng-template #roleTmpl let-value="value">
  <div class="float-right">
    <div>{{ value | DataPartnerRoleTranslation | translate }}</div>
  </div>
</ng-template>

<ng-template #editHdrTmpl let-column="column">
  <div class="float-right">
    <div>{{ column.name | translate }}</div>
  </div>
</ng-template>

<!-- don't use [class]="fa fa-icons" because not updated after filter and sort -->
<ng-template #activateTmpl let-row="row">
  <div>
    <span class="faIcon">{{row.isActive ? '&#xf00c;' : '&#xf00d;'}}</span>
  </div>
</ng-template>

<ng-template #translationTmpl let-row="row">
  <app-translatable-string-view
    [translation]="row.translations"
    property="name"
  ></app-translatable-string-view>
  
</ng-template>
